import ApiService from "@/core/services/api.service";


// action types
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD = "action_fleet_tireoptix_report_detail_load";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD_IMAGES = "action_fleet_tireoptix_report_detail_load_images";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_DOWNLOAD_EXCEL = "action_fleet_tireoptix_report_detail_download_excel";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_DOWNLOAD_PDF = "action_fleet_tireoptix_report_detail_download_pdf";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD_IMAGE_PAGE = "action_fleet_tireoptix_report_detail_load_image_page";

// muts
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS = "muts_fleet_tireoptix_report_detail_set_page_params";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGES = "muts_fleet_tireoptix_report_detail_set_images";
export const API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGE_PAGE = "muts_fleet_tireoptix_report_detail_set_image_page"

const state = {
    records_total: null,
    url_to_trailer_readiness_report: null,
    survey_detail_info: {
        survey_by: null,
        survey_by_company: null,
        survey_date_time: null,
        account_name: null,
        address_city_state_view: null,
        has_trailer_readiness_report: null,
    },
    data: [
        {
            unit_number: null,
            unit_id: null,
            axle_and_tire_position: null,
            pressure: null,
            pressure_color: null,
            tread_depth: null,
            tread_depth_color: null,
            wear_conditions: null,
            valve: null,
            brand: null,
            product_line: null,
            tire_size: null,
            unit_images_exist: null,
            tire_images_exist: null,
            images_exist: null,
            images: null,
            tire_id: null,
            insp_id: null,
            pressure_resolution: null,
            tread_depth_resolution: null,
            wear_condition_resolution: null,
            resolution: null,
            trailer_readiness: null,
        }
    ],
    images: {
        tire_images: [],
        unit_images: []
    },
    page_images_params: {
        page_info: {
            UnitNumber: null,
            PositionLocation: null
        },
        images: [

        ]
    }
}

const getters = {
    getTireOptixReportingDetailData() {
        return state.data;
    },
    getTireOptixReportingDetailBoxes() {
        return state.survey_detail_info;
    },
    getTireOptixReportingDetailUrl() {
        return state.url_to_trailer_readiness_report;
    },
    getTireOptixReportingDetailTotalRecords() {
        return state.records_total;
    },
    getTireOptixReportingDetailImages() {
        return state.images;
    },
    getTireOptixReportingImagePage() {
        return state.page_images_params;
    }

};

const actions = {
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/detail", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD_IMAGES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_image_links", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_LOAD_IMAGE_PAGE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_images_page", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGE_PAGE, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_excel_file_survey_details", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_pdf_file_survey_details", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.data = data.data;
        state.url_to_trailer_readiness_report = data.url_to_trailer_readiness_report;
        state.records_total = data.records_total;
        state.survey_detail_info = data.survey_detail_info;
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGES](state, {data}) {
        state.images.tire_images = data.tire_images;
        state.images.unit_images = data.unit_images;
    },
    [API_FLEET_TIREOPTIX_REPORT_DETAIL_MUTS_SET_IMAGE_PAGE](state, {data}) {
        state.page_images_params.page_info.UnitNumber = data.page_info.UnitNumber;
        state.page_images_params.page_info.PositionLocation = data.page_info.PositionLocation;
        state.page_images_params.images = data.images;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};