import JwtService from "@/core/services/jwt.service";

const STORE_KEY_HEADER = "gy_portal_header";
const STORE_KEY_FOOTER = "gy_portal_footer";
const STORE_KEY_USER = "gy_portal_user";

export const APP_GET_LAST_STATE = "AppGetLastState";
export const APP_RESET_INTERFACE = "reset_interface";

export const APP_IS_AUTHENTICATED = "AppIsAuthenticated";
export const APP_SET_AUTHENTICATED = "AppSetAuthenticated";

export const APP_GET_HEADER_MENU = "AppGetHeaderMenuItems";
export const APP_SET_HEADER_MENU = "AppSetHeaderMenuItems";

export const APP_GET_FOOTER_MENU = "AppGetFooterMenuItems";
export const APP_GET_FOOTER_MENU_CALC = "AppGetFooterMenuItemsCalc";
export const APP_SET_FOOTER_MENU = "AppSetFooterMenuItems";

export const APP_GET_USER = "AppGetUser";
export const APP_SET_USER = "AppSetUser";

export const APP_GET_LANGUAGE_PACK = "AppGetLanguagePack";
export const APP_SET_LANGUAGE_PACK = "AppSetLanguagePack";

export const APP_GET_PAGE_STATE = "AppGetPageState";
export const APP_SET_PAGE_STATE = "AppSetPageState";
export const APP_REMOVE_PAGE_STATE = "AppRemovePageState";

const state = {
    UserState: {
        IsAuthenticated: false,
        User: {
            Login: null,
            UserName: null,
            UserLastName: null,
            Group: null,
            GyToken: null,
            AgreeToFhqTerms: null,
            ApiKeyRefreshTime: 0,
            UserTokenExpireTime: 0,
            UserAccessSecurities: [],
            UnitOfMeasurement: null,
            Email: null,
        }
    },

    Region: "",

    HeaderMenuItems: {},

    FooterMenuItems: {},

    FooterMenuItemsCalculate: [],

    LanguagePack: {},

    PageStates: {}
};

const getters = {
    [APP_GET_USER](state) {
        if (state.UserState.User.Login == null) {
            state.UserState.User = JSON.parse(window.localStorage.getItem(STORE_KEY_USER));
            if (state.UserState.User == null) {
                state.UserState.User = {
                    Login: null,
                    UserName: null,
                    UserLastName: null,
                    Group: null,
                    GyToken: null,
                    AgreeToFhqTerms: null,
                    ApiKeyRefreshTime: 0,
                    UserTokenExpireTime: 0,
                    UserAccessSecurities: [],
                    UnitOfMeasurement: null,
                    Email: null,
                };
            }
        }
        return state.UserState.User;
    },
    [APP_IS_AUTHENTICATED](state) {
        if (state.UserState.IsAuthenticated == null) {
            state.UserState.IsAuthenticated = JwtService.getToken() ? true : false;
        }
        if (state.UserState.User.Login == null) {
            state.UserState.IsAuthenticated = false;
        }
        return state.UserState.IsAuthenticated;
    },
    [APP_GET_HEADER_MENU](state) {
        //console.log(state.HeaderMenuItems);
        if (state.HeaderMenuItems == null) {
            state.HeaderMenuItems = JSON.parse(window.localStorage.getItem(STORE_KEY_HEADER));
        }
       
        return state.HeaderMenuItems;
    },
    [APP_GET_FOOTER_MENU](state) {
        if (state.FooterMenuItems == null) {
            state.FooterMenuItems = JSON.parse(window.localStorage.getItem(STORE_KEY_FOOTER));
        }

        return state.FooterMenuItems;
    },
    [APP_GET_FOOTER_MENU_CALC](state) {

        let countOfItems = 0;
        let oneColumn = 0;
        if (state.FooterMenuItems != null) {
            state.FooterMenuItemsCalculate = [];
            for (let option_index in state.FooterMenuItems.tabs) {
                countOfItems = state.FooterMenuItems.tabs[option_index].links.length;
                if (countOfItems >= 49) {
                    oneColumn = parseInt(countOfItems / 2);
                    state.FooterMenuItemsCalculate.push({
                        columns: 2,
                        col1: oneColumn,
                        col2: countOfItems-oneColumn,
                        name: state.FooterMenuItems.tabs[option_index].name,
                        items: state.FooterMenuItems.tabs[option_index].links
                    });
                } else {
                    state.FooterMenuItemsCalculate.push({
                        columns: 1, 
                        name: state.FooterMenuItems.tabs[option_index].name,
                        items: state.FooterMenuItems.tabs[option_index].links
                    });
                }
            }
        } else {
            state.FooterMenuItemsCalculate = [];
        }

        return state.FooterMenuItemsCalculate;
    },
    
    [APP_GET_LANGUAGE_PACK](state) {
        return state.LanguagePack;
    },
    
    [APP_GET_PAGE_STATE](state) {
        return state.PageStates;
    }
    
};

const actions = {
    [APP_GET_LAST_STATE](context) {
        //console.log("restore last state");
        state.HeaderMenuItems = JSON.parse(window.localStorage.getItem(STORE_KEY_HEADER));
        state.FooterMenuItems= JSON.parse(window.localStorage.getItem(STORE_KEY_FOOTER));
        state.UserState.User = JSON.parse(window.localStorage.getItem(STORE_KEY_USER));
        if (state.UserState.User == null) {
            state.UserState.User = {
                Login: null,
                UserName: null,
                UserLastName: null,
                Group: null,
                GyToken: null,
                AgreeToFhqTerms: null,
                ApiKeyRefreshTime: 0,
                UserTokenExpireTime: 0,
                UserAccessSecurities: [],
                UnitOfMeasurement: null,
                Email: null,
            };
        }
        state.UserState.IsAuthenticated = JwtService.getToken() ? true : false;
    },
    
    [APP_RESET_INTERFACE](context) {
        context.commit(APP_RESET_INTERFACE, state);
    },
    [APP_SET_AUTHENTICATED](context, isAuth) {
        context.commit(APP_SET_AUTHENTICATED, isAuth);
    },
    [APP_SET_USER](context, params) {
        context.commit(APP_SET_USER, 
            params.login, 
            params.user_name, 
            params.user_last_name, 
            params.group, 
            params.gy_token, 
            params.token, 
            params.api_key, 
            params.user_token_expire_time, 
            params.api_key_refresh_time, 
            params.agree_to_fhq_terms,
            params.user_access_securities,
            params.unit_of_measurement,
            params.email
        )
    },
    [APP_SET_HEADER_MENU](context, data) {
        context.commit(APP_SET_HEADER_MENU, data);
    },
    [APP_SET_FOOTER_MENU](context, data) {
        context.commit(APP_SET_FOOTER_MENU, data);
    },
    [APP_SET_FOOTER_MENU](context, data) {
        context.commit(APP_SET_FOOTER_MENU, data);
    },
    [APP_SET_LANGUAGE_PACK](context, data) {
        context.commit(APP_SET_LANGUAGE_PACK, data);
    }
};

const mutations = { 
    [APP_RESET_INTERFACE](state) {

        window.localStorage.removeItem(STORE_KEY_HEADER);
        window.localStorage.removeItem(STORE_KEY_FOOTER);
        window.localStorage.removeItem(STORE_KEY_USER);

        state.UserState.IsAuthenticated = false;
        state.UserState.User.Login = null;
        state.UserState.User.UserName = null;
        state.UserState.User.UserLastName = null;
        state.UserState.User.Group = null;
        state.UserState.User.GyToken = null;
        state.UserState.User.AgreeToFhqTerms = null;
        state.UserState.User.ApiKeyRefreshTime = 0;
        state.UserState.User.UserTokenExpireTime = 0;
        state.UserState.User.UnitOfMeasurement = null;
        state.UserState.User.UserAccessSecurities = [];
        state.UserState.User.Email = null;

        //state.Region = "en_US";
        state.HeaderMenuItems = {};
        state.FooterMenuItems = {};
        //state.LanguagePack = {};
    },
    [APP_SET_PAGE_STATE](context, page) {
        if (page && page.hasOwnProperty('name') && page.hasOwnProperty('state')) {
            state.PageStates[page.name] = page.state;
        }
    },
    [APP_REMOVE_PAGE_STATE](context, page) {
        if (page && page.hasOwnProperty('name')) {
            delete  state.PageStates[page.name];
        }
    },

    
    [APP_SET_USER](state, params) {
        state.UserState.User.Login = params.login;
        state.UserState.User.UserName = params.user_name;
        state.UserState.User.UserLastName = params.user_last_name;
        state.UserState.User.Group = params.group;
        state.UserState.User.GyToken = params.gy_token;
        state.UserState.User.AgreeToFhqTerms = params.agree_to_fhq_terms;
        state.UserState.User.ApiKeyRefreshTime = params.api_key_refresh_time;
        state.UserState.User.UserTokenExpireTime = params.user_token_expire_time;
        state.UserState.User.UnitOfMeasurement = params.unit_of_measurement;
        state.UserState.User.UserAccessSecurities = params.user_access_securities;
        state.UserState.User.Email = params.email;
        
        if (params.token != null) {
            JwtService.saveToken(params.token);
        }
        if (params.token && state.UserState.User.Login) {
            state.UserState.IsAuthenticated = true;
        } else {
            state.UserState.IsAuthenticated = false;
        } 
        if (params.api_key != null) {
            JwtService.saveApiKey(params.api_key);
        }
        
        //console.log('in set user app.state.module.js');
        //console.log(state.UserState.User);
        window.localStorage.setItem(STORE_KEY_USER, JSON.stringify(state.UserState.User));
    },

    [APP_SET_AUTHENTICATED](state, auth_state) {
        state.UserState.IsAuthenticated = auth_state;
    },

    [APP_SET_HEADER_MENU](state, data) {
        //console.log('set header');
        state.HeaderMenuItems = data;
        if(state.HeaderMenuItems.tabs) {
            state.HeaderMenuItems.tabs.forEach(element => {
                element.isCollapsed = true;
            });
        }
        window.localStorage.setItem(STORE_KEY_HEADER, JSON.stringify(state.HeaderMenuItems));
    },

    [APP_SET_FOOTER_MENU](state, data) {
        //console.log('set footer');
        state.FooterMenuItems = data;

        let countOfItems = 0;
        let oneColumn = 0;
        if (state.FooterMenuItems != null) {
            state.FooterMenuItemsCalculate = [];
            for (let option_index in state.FooterMenuItems.tabs) {
                countOfItems = state.FooterMenuItems.tabs[option_index].links.length;
                if (countOfItems >= 49) {
                    oneColumn = parseInt(countOfItems / 2);
                    state.FooterMenuItemsCalculate.push({
                        columns: 2,
                        col1: oneColumn,
                        col2: countOfItems-oneColumn,
                        name: state.FooterMenuItems.tabs[option_index].name,
                        items: state.FooterMenuItems.tabs[option_index].links
                    });
                } else {
                    state.FooterMenuItemsCalculate.push({
                        columns: 1, 
                        name: state.FooterMenuItems.tabs[option_index].name,
                        items: state.FooterMenuItems.tabs[option_index].links
                    });
                }
            }
        } else {
            state.FooterMenuItemsCalculate = [];
        }

        window.localStorage.setItem(STORE_KEY_FOOTER, JSON.stringify(state.FooterMenuItems));
    },

    [APP_SET_LANGUAGE_PACK](state, data) {
        state.LanguagePack = data;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
