import idb from '../idb.js';
import { TO_USE_LOCAL_STORAGE, DB_NAME } from '../idb.js';

export const DB_UPLOADED_UNITS = "db_uploaded_units";


export default {
    deleteUnit(unit) {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                if(db) {
                    const tsn = db.transaction([DB_UPLOADED_UNITS], 'readwrite');
                    tsn.oncomplete = () => {
                        resolve();
                    }
        
                    const store = tsn.objectStore(DB_UPLOADED_UNITS)
                    store.delete(unit.db_id);
                } else {
                    const allUnits = JSON.parse(window.localStorage.getItem(DB_NAME));
                    const newUnits = allUnits.filter(u => u.unit_number != unit.unit_number);
                    window.localStorage.setItem(DB_NAME, JSON.stringify(newUnits));
                    resolve();
                }
                
            })
            .catch((err) => {
                reject(err);
            })
            
        })


    },
    getUnits () {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                if(db) {
                    let tsn = db.transaction([DB_UPLOADED_UNITS], 'readonly');
                    tsn.oncomplete = () => {
                        resolve(units);
                    }

                    const store = tsn.objectStore(DB_UPLOADED_UNITS)
                    const units = [];
                    
                    store.openCursor().onsuccess = e => {
                        const cursor = e.target.result;
                        if (cursor) {
                            units.push(cursor.value);
                            cursor.continue();
                        }
                    }
                } else {
                    let allUnits = JSON.parse(window.localStorage.getItem(DB_NAME));
                    if(!allUnits) allUnits = [];
                    resolve(allUnits);
                }
                
            })
            .catch((err) => {
                reject(err);
            })
        });
    },
    getUnit(key) {
        return new Promise((resolve, reject) => {
            key = Number(key);
            idb.getDB()
            .then((db) => {
                if(db){
                    let tsn = db.transaction([DB_UPLOADED_UNITS], 'readonly');
                    tsn.oncomplete = () => {
                        resolve(objectStoreRequest?.result);
                    }
                    tsn.onerror = () => {
                        reject();
                    }
                    const store = tsn.objectStore(DB_UPLOADED_UNITS)
                    let objectStoreRequest = store.get(key);
                }  else {
                    const unitToFind = JSON.parse(window.localStorage.getItem(DB_NAME)).find(u => u.unit_number === key);
                    resolve(unitToFind)
                }
            })
            .catch((err) => {
                reject(err);
            })
        });
    },
    putAllUnits(units){
        // idb.getDB -> if null local storage : forEarch( u => putUnit(u))
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                if (db) {
                    const promises = units.map(u => this.putUnit(u));
                    Promise.all(promises)
                    .then(() =>{
                        resolve();
                        return;
                    })
                    .catch((e) => reject(e));
                    
                }  else {
                    window.localStorage.setItem(DB_NAME, JSON.stringify(units));
                    resolve();
                }
            })
        }).catch((err) => {
            reject(err);
        })
    },
    putUnit (unit) {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                let tsn = db.transaction([DB_UPLOADED_UNITS], 'readwrite')
                tsn.oncomplete = () => {
                    resolve('from unit')
                }
                
                let store = tsn.objectStore(DB_UPLOADED_UNITS);
                
                // if(unit.db_id == null) {
                //     const countRequest = store.count();
                //     countRequest.onsuccess = () => {
                //         unit.db_id = countRequest.result + 1;
                //         console.log(unit.db_id);
                //     }
                // }

                store.put(unit);
            })
            .catch((err) => {
                reject(err);
            })

        });
        
    },
    addUnit (unit) {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                console.log('db', db);
                if(db) {
                    let tsn = db.transaction([DB_UPLOADED_UNITS], 'readwrite')
                    tsn.oncomplete = () => {
                        resolve()
                    }
                    
                    let store = tsn.objectStore(DB_UPLOADED_UNITS);
                    
                    // if(unit.db_id == null) {
                    //     const allkeys = store.getAllKeys();
                    //     allkeys.onsuccess = () => {
                    //         console.log(allkeys.result);
                    //         // unit.db_id = countRequest.result + 1;
                    //         // console.log(unit.db_id);
                    //     }
                    // }

                    store.add(unit);
                } else {
                    let allUnits = JSON.parse(window.localStorage.getItem(DB_NAME));
                    if(!allUnits) allUnits = [];
                    allUnits.push(unit);
                    window.localStorage.setItem(DB_NAME, JSON.stringify(allUnits));
                    resolve();
                }
            })
            .catch((err) => {
                console.log(err)
                reject(err);
            })

        });
        
    },
    updateUnit (unit, key) {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                let tsn = db.transaction([DB_UPLOADED_UNITS], 'readwrite')
                tsn.oncomplete = () => {
                    resolve()
                }

                let store = tsn.objectStore(DB_UPLOADED_UNITS);
                store.put(unit, key);
            })
            .catch((err) => {
                reject(err);
            })

        });
        
    },
    clearUnits() {
        return new Promise((resolve, reject) => {
            idb.getDB()
            .then((db) => {
                if (db) {
                    let tsn = db.transaction([DB_UPLOADED_UNITS], 'readwrite')
                    tsn.oncomplete = () => {
                        resolve([])
                    }

                    let store = tsn.objectStore(DB_UPLOADED_UNITS);
                    store.clear();
                } else {
                    window.localStorage.removeItem(DB_NAME);
                    resolve([]);
                }
            })
            .catch((err) => {
                reject(err);
            })

        });
    }
}