import db from '../db-tables/uploaded.units.db';
import { API_FS_SET_UPLOADED_UNITS } from './fleet.survey.module';

const actions = {
    putUnitToDB({ commit }, unit) {
        return new Promise((resolve, reject) => {
            db.putAllUnits(unit)
            .then(() => {
                resolve();
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    addUnitToDB({ commit }, { unit }) {
        return new Promise((resolve, reject) => {
            db.addUnit(unit)
            .then(() => {
                resolve();
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    updateUnitFromDB({ commit }, { unit, key }) {
        console.log(unit, key);
        return new Promise((resolve, reject) => {
            db.updateUnit(unit, key)
            .then(() => {
                resolve();
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    getUnitsFromDB({ commit }) {
        return new Promise((resolve, reject) => {
            db.getUnits()
            .then((units) => {
                commit(API_FS_SET_UPLOADED_UNITS, {data: {units: units}});
                resolve(units);
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    getUnitFromDB({ commit }, { key }) {
        return new Promise((resolve, reject) => {
            db.getUnit(key)
            .then((unit) => {
                resolve(unit);
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    putAllUnitsToLocalStorage(units) {
        return db.putAllUnits(units);
    },
    deleteUnitFromDB({ commit }, { unit }) {
        return new Promise((resolve, reject) => {
            console.log(unit);
            db.deleteUnit(unit)
            .then(() => {
                resolve();
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    clearUnitsFromDB({ commit }) {
        return new Promise((resolve, reject) => {
            db.clearUnits()
            .then((units) => {
                commit(API_FS_SET_UPLOADED_UNITS, {data: {units: units}});
                resolve(units);
            })
            .catch(e => {
                reject(e);
            })
        })
    },
    isUnitNumberExist({ commit }, { unitNumber }) {
        return new Promise((resolve, reject) => {
            db.getUnits()
            .then((units) => {
                resolve(units.map(u => u.unit_number).includes(unitNumber));
            })
            .catch(e => {
                reject(e);
            })
        })
    } 
};

export default {actions};