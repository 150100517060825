import ApiService from "@/core/services/api.service";

// action types
export const API_COMMON_CONTROLS_VOCATIONS_ACTION_LOAD = "action_common_controls_vocations_load";
export const API_COMMON_CONTROLS_GOVERNMENT_ACCOUNTS_ACTION_LOAD = "action_common_controls_government_accounts";
export const API_COMMON_CONTROLS_US_NATIONAL_ACCOUNTS_ACTION_LOAD = "action_common_controls_us_national_accounts";
export const API_COMMON_CONTROLS_CANADIAN_ACCOUNTS_ACTION_LOAD = "action_common_controls_canadian_accounts";
export const API_COMMON_CONTROLS_CANADIAN_COMMON_OWNERS_ACTION_LOAD = "action_common_controls_canadian_common_owners";
export const API_COMMON_CONTROLS_PREFFERED_FLEETS_ACTION_LOAD = "action_common_controls_preffered_fleets";
export const API_COMMON_CONTROLS_LEASINGS_ACTION_LOAD = "action_common_controls_leasings";
export const API_COMMON_CONTROLS_LEASING_LOCATIONS_ACTION_LOAD = "action_common_controls_leasing_locations";
export const API_COMMON_CONTROLS_US_NATIONAL_GLOBAL_ACCOUNTS_ACTION_LOAD = "action_common_controls_us_national_global_accounts";
export const API_COMMON_CONTROLS_CANADIAN_GLOBAL_ACCOUNTS_ACTION_LOAD = "action_common_controls_canadian_global_accounts";
export const API_COMMON_CONTROLS_COUNTRIES_ACTION_LOAD = "action_common_controls_countries_load";
export const API_COMMON_CONTROLS_STATES_ACTION_LOAD = "action_common_controls_states_load";
export const API_COMMON_CONTROLS_FLEET_LOCATION_STRUCTURE_ACTION_LOAD = "action_common_controls_fleet_location_structure";
export const API_COMMON_CONTROLS_FLEET_LOCATION_LIST_ACTION_LOAD = "action_common_controls_fleet_location_list";
export const API_COMMON_CONTROLS_GET_LOCATION_DATA_ACTION_LOAD = "action_common_controls_get_location_data";
export const API_COMMON_CONTROLS_GET_TIRE_SIZES_ACTION_LOAD = "action_common_controls_get_tire_sizes";
export const API_COMMON_CONTROLS_GET_LOAD_RANGES_ACTION_LOAD = "action_common_controls_get_load_ranges";
export const API_COMMON_CONTROLS_GET_POSITIONS_ACTION_LOAD = "action_common_controls_get_positions";
export const API_COMMON_CONTROLS_GET_UNIT_TYPES_ACTION_LOAD = "action_common_controls_get_unit_types";
export const API_COMMON_CONTROLS_GET_REPORTS_ACTION_LOAD = "action_common_controls_get_reports";
export const API_COMMON_CONTROLS_GET_VERIFIED_ACCOUNTS_ACTION_LOAD = "action_common_controls_get_verified_accounts";
export const API_COMMON_CONTROLS_SURVEYS_LOAD = "action_common_controls_surveys_load";
export const API_COMMON_CONTROLS_SELECTED_SURVEYS_LOAD = "action_common_controls_selected_surveys_load";
export const API_COMMON_CONTROLS_FULL_SURVEYS_LOAD = "action_common_controls_full_surveys_load";
export const API_COMMON_CONTROLS_FULL_SELECTED_SURVEYS_LOAD = "action_common_controls_full_selected_surveys_load";
export const API_COMMON_CONTROLS_UNITS_LOAD = "action_common_controls_units_load";
export const API_COMMON_CONTROLS_SELECTED_UNITS_LOAD = "action_common_controls_selected_units_load";
export const API_COMMON_CONTROLS_FULL_UNITS_LOAD = "action_common_controls_full_units_load";
export const API_COMMON_CONTROLS_FULL_SELECTED_UNITS_LOAD = "action_common_controls_full_selected_units_load";
export const API_COMMON_CONTROLS_OWNERS_LOAD = "action_common_controls_owners_load";
export const API_COMMON_CONTROLS_GET_TECHNICIAN_NUMBER_EXISTENCE_ACTION_LOAD = "action_common_controls_get_technician_number_existence";
export const API_COMMON_CONTROLS_GET_USER_PREFERENCES_ACTION_LOAD = "action_common_controls_get_user_preferences";

// muts
export const API_COMMON_CONTROLS_SET_VOCATIONS = "muts_common_controls_set_vocations";
export const API_COMMON_CONTROLS_SET_GOVERNMENT_ACCOUNTS = "muts_common_controls_set_government_accounts";
export const API_COMMON_CONTROLS_SET_US_NATIONAL_ACCOUNTS = "muts_common_controls_set_us_national_accounts";
export const API_COMMON_CONTROLS_SET_CANADIAN_ACCOUNTS = "muts_common_controls_set_canadian_accounts";
export const API_COMMON_CONTROLS_SET_CANADIAN_COMMON_OWNERS = "muts_common_controls_set_canadian_common_owners";
export const API_COMMON_CONTROLS_SET_PREFFERED_FLEETS = "muts_common_controls_set_preffered_fleets";
export const API_COMMON_CONTROLS_SET_LEASINGS = "muts_common_controls_set_leasings";
export const API_COMMON_CONTROLS_SET_LEASING_LOCATIONS = "muts_common_controls_set_leasing_locations";
export const API_COMMON_CONTROLS_SET_US_NATIONAL_GLOBAL_ACCOUNTS = "muts_common_controls_set_us_national_global_accounts";
export const API_COMMON_CONTROLS_SET_CANADIAN_GLOBAL_ACCOUNTS = "muts_common_controls_set_canadian_global_accounts";
export const API_COMMON_CONTROLS_SET_COUNTRIES = "muts_common_controls_set_countries";
export const API_COMMON_CONTROLS_SET_STATES = "muts_common_controls_set_states";
export const API_COMMON_CONTROLS_SET_FLEET_LOCATION_STRUCTURE = "muts_common_controls_set_fleet_location_structure";
export const API_COMMON_CONTROLS_SET_FLEET_LOCATION_LIST = "muts_common_controls_set_fleet_location_list";
export const API_COMMON_CONTROLS_SET_LOCATION_DATA = "muts_common_controls_set_location_data";
export const API_COMMON_CONTROLS_SET_TIRE_SIZES = "muts_common_controls_set_tire_sizes";
export const API_COMMON_CONTROLS_SET_LOAD_RANGES = "muts_common_controls_set_load_ranges";
export const API_COMMON_CONTROLS_SET_POSITIONS = "muts_common_controls_set_positions";
export const API_COMMON_CONTROLS_SET_UNIT_TYPES = "muts_common_controls_set_unit_types";
export const API_COMMON_CONTROLS_SET_REPORTS = "muts_common_controls_set_reports";
export const API_COMMON_CONTROLS_SET_VERIFIED_ACCOUNTS = "muts_common_controls_set_verified_accounts";
export const API_COMMON_CONTROLS_SET_SURVEYS = "muts_common_controls_set_surveys";
export const API_COMMON_CONTROLS_SET_FULL_SURVEYS = "muts_common_controls_set_full_surveys";
export const API_COMMON_CONTROLS_SET_SURVEYS_TABLE_PARAMS = "muts_common_controls_set_surveys_table_params";
export const API_COMMON_CONTROLS_SET_SELECTED_SURVEYS = "muts_common_controls_set_selected_surveys";
export const API_COMMON_CONTROLS_SET_FULL_SELECTED_SURVEYS = "muts_common_controls_set_full_selected_surveys";
export const API_COMMON_CONTROLS_SET_SELECTED_SURVEYS_TABLE_PARAMS = "muts_common_controls_set_selected_surveys_table_params";
export const API_COMMON_CONTROLS_SET_UNITS = "muts_common_controls_set_units";
export const API_COMMON_CONTROLS_SET_SELECTED_UNITS = "muts_common_controls_set_selected_units";
export const API_COMMON_CONTROLS_SET_FULL_UNITS = "muts_common_controls_set_full_units";
export const API_COMMON_CONTROLS_SET_FULL_SELECTED_UNITS = "muts_common_controls_set_full_selected_units";
export const API_COMMON_CONTROLS_SET_UNITS_TABLE_PARAMS = "muts_common_controls_set_units_table_params";
export const API_COMMON_CONTROLS_SET_SELECTED_UNITS_TABLE_PARAMS = "muts_common_controls_set_selected_units_table_params";
export const API_COMMON_CONTROLS_SET_OWNERS = "muts_common_controls_set_owners";
export const API_COMMON_CONTROLS_SET_TECHNICIAN_NUMBER_EXISTENCE = "muts_common_controls_set_technician_number_existence";
export const API_COMMON_CONTROLS_GET_USER_PREFERENCES = "muts_common_controls_get_user_preferences";

const state = {
    vocations: [],
    government_accounts: [],
    us_national_accounts: [],
    canadian_accounts: [],
    canadian_common_owners: [],
    preffered_fleets: [],
    leasings: [],
    leasing_locations: [],

    national_global_accounts: [],
    canadian_national_global_accounts: [],

    fleet_locations: [],
    fleet_locations_table_param: {
        page_number: 1,
        page_size: 10,
        total_items: 0,
        total_selected_items: 0,
    },

    tire_sizes: [],
    load_ranges: [],
    positions: [],

    show_unknown: false,
    show_local_book: false,
    show_government: false,
    show_preferred_fleet: false,
    show_national_account: false,
    show_canadian_national_account: false,
    show_leasing: false,
    show_canadian_common_owner: false,
    report_type:null,

    verified_accounts: {},
    unit_types: [],
    reports: [],

    countries: [],
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },

    surveys: [],
    surveys_table_params: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: []
    },
    
    selected_surveys: [],
    selected_surveys_table_params: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: []
    },

    full_surveys: [],
    full_selected_surveys: [],
    
    units: [],
    units_table_params: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: []
    },

    selected_units: [],
    selected_units_table_params: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: []
    },

    full_units: [],
    full_selected_units: [],

    owners: [],

    fleetLocationTypes: [
        { value: "0", text: "Unknown", },
        { value: "1", text: "Local Book", },
        { value: "2", text: "Preferred Fleet", },
        { value: "3", text: "National Account", },
        { value: "4", text: "Canadian National Account", },
        { value: "5", text: "Government", },
        { value: "9", text: "Goodyear Leasing Customer", },
        { value: "10", text: "Canadian Common Owner", },
    ],

    isLoaded: {
        countries: false,
        states: false,
        vocations: false,
        governments: false,
        national_global: false,
        canadian_national_global: false,
        us_national_accounts: false,
        canadian_accounts: false,
        canadian_common_owners: false,
        preffered_fleets: false,
        leasings: false,
        leasing_locations: false,
        fleetLocationTypes: false,
        fleet_locations: false,
        tire_sizes: false,
        load_ranges: false,
        positions: false,
    },

    location_data: null,

    technician_number_exists: false,

    user_preferences: {
        currency_symbol: "$",
    },
};

const getters = {
    getCommonControlsFleetLocationTypes: () => state.fleetLocationTypes,
    getCommonControlsIsLoaded: () => state.isLoaded,
    getCommonControlsCountries: () => state.countries,
    getCommonControlsStatesCanadian: () => state.states.CA_states,
    getCommonControlsStatesUS: () => state.states.US_states,
    getCommonControlsStatesMexico: () => state.states.MX_states,
    getCommonControlsStatesColumbian: () => state.states.CO_states,
    getCommonControlsStatesAustralian: () => state.states.AU_states,
    getCommonControlsStatesChine: () => state.states.CN_provinces,
    getCommonControlsVocations: () => state.vocations,
    getCommonControlsGovernmentAccounts: () => state.government_accounts,
    getCommonControlsUSNationalAccounts: () => state.us_national_accounts,
    getCommonControlsCanadianAccounts: () => state.canadian_accounts,
    getCommonControlsCanadianCommonOwners: () => state.canadian_common_owners,
    getCommonControlsPrefferedFleets: () => state.preffered_fleets,
    getCommonControlsLeasings: () => state.leasings,
    getCommonControlsLeasingLocations: () => state.leasing_locations,
    getCommonControlsGlobalUSNationalAccounts: () => state.national_global_accounts,
    getCommonControlsGlobalCanadianAccounts: () => state.canadian_national_global_accounts,
    getCommonControlsUnitTypes: () => state.unit_types,
    getCommonControlsReports: () => state.reports,
    getCommonControlsVerifiedAccounts: () => state.verified_accounts?.verified_account,
    getCommonControlsIsAccountsVerificationFailed: () => state.verified_accounts?.is_accounts_verification_failed,
    getCommonControlsFleetLocations: () => state.fleet_locations,
    getCommonControlsFleetLocationsTabParam: () => state.fleet_locations_table_param,
    getCommonControlsLocationData: () => state.location_data,
    getCommonControlsTireSizes: () => state.tire_sizes,
    getCommonControlsLoadRanges: () => state.load_ranges,
    getCommonControlsPositions: () => state.positions,
    getCommonControlsSurveys: () => state.surveys,
    getCommonControlsFullSurveys: () => state.full_surveys,
    getCommonControlsFullSelectedSurveys: () => state.full_selected_surveys,
    getCommonControlsSurveysTableParams: () => state.surveys_table_params,
    getCommonControlsSelectedSurveys: () => state.selected_surveys,
    getCommonControlsSelectedSurveysTableParams: () => state.selected_surveys_table_params,
    getCommonControlsUnits: () => state.units,
    getCommonControlsFullUnits: () => state.full_units,
    getCommonControlsFullSelectedUnits: () => state.full_selected_units,
    getCommonControlsUnitsTableParams: () => state.units_table_params,
    getCommonControlsSelectedUnits: () => state.selected_units,
    getCommonControlsSelectedUnitsTableParams: () => state.selected_units_table_params,
    getCommonControlsOwners: () => state.owners,
    getCommonControlsTechnicianNumberExists: () => state.technician_number_exists,
    getUserPreferences: () => state.user_preferences,
    
    getCommonControlsStatesByCountry: (state) => (countryAbbr) => {
        if(countryAbbr == 'CA') {
            return state.states.CA_states;
        }
        else if(countryAbbr == 'US') {
            return state.states.US_states;
        }
        else if(countryAbbr == 'MX') {
            return state.states.MX_states;
        }
        else if(countryAbbr == 'CO') {
            return state.states.CO_states;
        }
        else if(countryAbbr == 'AU') {
            return state.states.AU_states;
        }
        else if(countryAbbr == 'CN') {
            return state.states.CN_provinces;
        }
        else{
            return [];
        }
    }, 

    getCommonControlsFleetLocationStructureShowTypes() {
        return {
            show_unknown: state.show_unknown,
            show_local_book: state.show_local_book,
            show_government: state.show_government,
            show_preferred_fleet: state.show_preferred_fleet,
            show_national_account: state.show_national_account,
            show_canadian_national_account: state.show_canadian_national_account,
            show_leasing: state.show_leasing,
            show_canadian_common_owner: state.show_canadian_common_owner,
            report_type:state.report_type,
        };
    },
};

const actions = {
    [API_COMMON_CONTROLS_VOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_vocations", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_VOCATIONS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GOVERNMENT_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_government_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_GOVERNMENT_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_US_NATIONAL_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_us_national_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_US_NATIONAL_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_CANADIAN_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_canadian_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_CANADIAN_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_CANADIAN_COMMON_OWNERS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_canadian_common_owners", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_CANADIAN_COMMON_OWNERS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_PREFFERED_FLEETS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_preffered_fleets", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_PREFFERED_FLEETS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_LEASINGS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_leasings", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_LEASINGS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_LEASING_LOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_leasing_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_LEASING_LOCATIONS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_US_NATIONAL_GLOBAL_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_us_national_global_accounts/" + params, params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_US_NATIONAL_GLOBAL_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_CANADIAN_GLOBAL_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_canadian_global_accounts/" + params, params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_CANADIAN_GLOBAL_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_COUNTRIES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_countries/" + (process.env.VUE_APP_REGION_NAME || 'NA'), params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_COUNTRIES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_STATES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_states", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_STATES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FLEET_LOCATION_STRUCTURE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_fleet_location_structure", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FLEET_LOCATION_STRUCTURE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FLEET_LOCATION_LIST_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_fleet_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FLEET_LOCATION_LIST, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_LOCATION_DATA_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_location_data/" + params, params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_LOCATION_DATA, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    [API_COMMON_CONTROLS_GET_TIRE_SIZES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_tire_sizes", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_TIRE_SIZES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_LOAD_RANGES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_load_ranges", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_LOAD_RANGES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_POSITIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_positions", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_POSITIONS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_UNIT_TYPES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_unit_types", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_UNIT_TYPES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_REPORTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_reports/" + params, params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_REPORTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_VERIFIED_ACCOUNTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_verified_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_VERIFIED_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_SURVEYS, data);
                    context.commit(API_COMMON_CONTROLS_SET_SURVEYS_TABLE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_SELECTED_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_SELECTED_SURVEYS, data);
                    context.commit(API_COMMON_CONTROLS_SET_SELECTED_SURVEYS_TABLE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FULL_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FULL_SURVEYS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FULL_SELECTED_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FULL_SELECTED_SURVEYS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_UNITS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_units", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_UNITS, data);
                    context.commit(API_COMMON_CONTROLS_SET_UNITS_TABLE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_SELECTED_UNITS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_units", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_SELECTED_UNITS, data);
                    context.commit(API_COMMON_CONTROLS_SET_SELECTED_UNITS_TABLE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FULL_UNITS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_units", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FULL_UNITS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_FULL_SELECTED_UNITS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_units", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_FULL_SELECTED_UNITS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_OWNERS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_owners", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_OWNERS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_TECHNICIAN_NUMBER_EXISTENCE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("common/get_technician_number_existence", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_SET_TECHNICIAN_NUMBER_EXISTENCE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_COMMON_CONTROLS_GET_USER_PREFERENCES_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("common/get_user_preferences", params, context)
                .then(({ data }) => {
                    context.commit(API_COMMON_CONTROLS_GET_USER_PREFERENCES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_COMMON_CONTROLS_SET_VOCATIONS](state, data) {
        state.vocations = data.items;
        state.isLoaded.vocations = true;
    },
    [API_COMMON_CONTROLS_SET_GOVERNMENT_ACCOUNTS](state, data) {
        state.government_accounts = data.items;
        state.isLoaded.governments = true;
    },
    [API_COMMON_CONTROLS_SET_US_NATIONAL_ACCOUNTS](state, data) {
        state.us_national_accounts = data.items;
        state.isLoaded.us_national_accounts = true;
    },
    [API_COMMON_CONTROLS_SET_CANADIAN_ACCOUNTS](state, data) {
        state.canadian_accounts = data.items;
        state.isLoaded.canadian_accounts = true;
    },
    [API_COMMON_CONTROLS_SET_CANADIAN_COMMON_OWNERS](state, data) {
        state.canadian_common_owners = data.items;
        state.isLoaded.canadian_common_owners = true;
    },
    [API_COMMON_CONTROLS_SET_PREFFERED_FLEETS](state, data) {
        state.preffered_fleets = data.items;
        state.isLoaded.preffered_fleets = true;
    },
    [API_COMMON_CONTROLS_SET_LEASINGS](state, data) {
        state.leasings = data.map(item => ({value: item.customerNumber, text: item.customerName}));
        state.isLoaded.leasings = true;
    },
    [API_COMMON_CONTROLS_SET_LEASING_LOCATIONS](state, data) {
        state.leasing_locations = data.map(item => ({value: item.locationNumber, text: item.locationAddress, headNumber: item.headNumber}));
        state.isLoaded.leasing_locations = true;
    },
    [API_COMMON_CONTROLS_SET_US_NATIONAL_GLOBAL_ACCOUNTS](state, data) {
        state.national_global_accounts = data.items;
        state.isLoaded.national_global = true;
    },
    [API_COMMON_CONTROLS_SET_CANADIAN_GLOBAL_ACCOUNTS](state, data) {
        state.canadian_national_global_accounts = data.items;
        state.isLoaded.canadian_national_global = true;
    },
    [API_COMMON_CONTROLS_SET_COUNTRIES](state, data) {
        state.countries = data;
        state.isLoaded.countries = true;
    },
    [API_COMMON_CONTROLS_SET_STATES](state, data) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;

        state.isLoaded.states = true;
    },
    [API_COMMON_CONTROLS_SET_FLEET_LOCATION_STRUCTURE](state, data) {
        state.show_unknown = data.show_unknown;
        state.show_local_book = data.show_local_book;
        state.show_government = data.show_government;
        state.show_preferred_fleet = data.show_preferred_fleet;
        state.show_national_account = data.show_national_account;
        state.show_canadian_national_account = data.show_canadian_national_account;
        state.show_leasing = data.show_leasing;
        state.show_canadian_common_owner = data.show_canadian_common_owner;
        state.report_type = data.report_type

        state.isLoaded.fleetLocationTypes = true;
    },
    [API_COMMON_CONTROLS_SET_FLEET_LOCATION_LIST](state, data) {
        state.fleet_locations = data.items;

        state.fleet_locations_table_param.page_number = data.page_number;
        state.fleet_locations_table_param.page_size = data.page_size;
        state.fleet_locations_table_param.total_items = data.total_items;
        state.fleet_locations_table_param.total_selected_items = data.total_selected_items;

        state.isLoaded.fleet_locations = true;
    },
    [API_COMMON_CONTROLS_SET_LOCATION_DATA](state, data) {
        state.location_data = data;
    },
    [API_COMMON_CONTROLS_SET_TIRE_SIZES](state, data) {
        state.tire_sizes = data;
        state.isLoaded.tire_sizes = true;
    },
    [API_COMMON_CONTROLS_SET_LOAD_RANGES](state, data) {
        state.load_ranges = data;
        state.isLoaded.load_ranges = true;
    },
    [API_COMMON_CONTROLS_SET_POSITIONS](state, data) {
        state.positions = data;
        state.isLoaded.positions = true;
    },
    [API_COMMON_CONTROLS_SET_UNIT_TYPES](state, data) {
        state.unit_types = data;
    },
    [API_COMMON_CONTROLS_SET_REPORTS](state, data) {
        state.reports = data;
    },
    [API_COMMON_CONTROLS_SET_VERIFIED_ACCOUNTS](state, data) {
        state.verified_accounts = data;
    },
    [API_COMMON_CONTROLS_SET_SURVEYS](state, data) {
        state.surveys = data.surveys;
    },
    [API_COMMON_CONTROLS_SET_FULL_SURVEYS](state, data) {
        state.full_surveys = data.surveys;
    },
    [API_COMMON_CONTROLS_SET_FULL_SELECTED_SURVEYS](state, data) {
        state.full_selected_surveys = data.surveys;
    },
    [API_COMMON_CONTROLS_SET_SURVEYS_TABLE_PARAMS](state, data) {
        state.surveys_table_params.page_number = data.page_number;
        state.surveys_table_params.page_size = data.page_size;
        state.surveys_table_params.total_items = data.total_items;
        state.surveys_table_params.total_selected_items = data.total_selected_items;
    },
    [API_COMMON_CONTROLS_SET_SELECTED_SURVEYS](state, data) {
        state.selected_surveys = data.surveys;
    },
    [API_COMMON_CONTROLS_SET_SELECTED_SURVEYS_TABLE_PARAMS](state, data) {
        state.selected_surveys_table_params.page_number = data.page_number;
        state.selected_surveys_table_params.page_size = data.page_size;
        state.selected_surveys_table_params.total_items = data.total_items;
        state.selected_surveys_table_params.total_selected_items = data.total_selected_items;
    },
    [API_COMMON_CONTROLS_SET_UNITS](state, data) {
        state.units = data.units;
    },
    [API_COMMON_CONTROLS_SET_FULL_UNITS](state, data) {
        state.full_units = data.units;
    },
    [API_COMMON_CONTROLS_SET_UNITS_TABLE_PARAMS](state, data) {
        state.units_table_params.page_number = data.page_number;
        state.units_table_params.page_size = data.page_size;
        state.units_table_params.total_items = data.total_items;
        state.units_table_params.total_selected_items = data.total_selected_items;
        
    },
    [API_COMMON_CONTROLS_SET_SELECTED_UNITS](state, data) {
        state.selected_units = data.units;
    },
    [API_COMMON_CONTROLS_SET_FULL_SELECTED_UNITS](state, data) {
        state.full_selected_units = data.units;
    },
    [API_COMMON_CONTROLS_SET_SELECTED_UNITS_TABLE_PARAMS](state, data) {
        state.selected_units_table_params.page_number = data.page_number;
        state.selected_units_table_params.page_size = data.page_size;
        state.selected_units_table_params.total_items = data.total_items;
        state.selected_units_table_params.total_selected_items = data.total_selected_items;
    },
    [API_COMMON_CONTROLS_SET_OWNERS](state, data) {
        state.owners = data.dealers;
    },
    [API_COMMON_CONTROLS_SET_TECHNICIAN_NUMBER_EXISTENCE](state, data) {
        state.technician_number_exists = data;
    },
    [API_COMMON_CONTROLS_GET_USER_PREFERENCES](state, data) {
        state.user_preferences = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};