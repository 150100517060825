import ApiService from "@/core/services/api.service";
import { 
    SELECT_FLEET_LOCATIONS_STEP,
    SELECT_TIREOPTIX_FILTERS_STEP
} from "@/utils/constants/constants";

// action types
export const API_FLEET_TIREOPTIX_REPORT_HOME_PAGE_ACTION_LOAD = "action_fleet_tireoptix_report_home_page_load";
export const API_FLEET_TIREOPTIX_REPORT_ACTION_LOAD = "action_fleet_tireoptix_report_load";
export const API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_PDF = "action_fleet_tireoptix_report_download_pdf";
export const API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_EXCEL = "action_fleet_tireoptix_report_download_excel";
export const API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_ALL_DETAILS = "action_fleet_tireoptix_report_download_all_details";

// muts
export const API_FLEET_TIREOPTIX_REPORT_MUTS_SET_PAGE_PARAMS = "muts_fleet_tireoptix_report_set_page_params";
export const API_FLEET_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS = "muts_fleet_tireoptix_report_set_report_params";

export const GETTERS = {
    GET_HAS_SYSTEM_LEVEL_ACCESS: "(fleet-tireoptix getter) get if user has system access",
    GET_ACCOUNTS_FILTER: "(fleet-tireoptix getter) get accounts filter"
}

export const ACTIONS = {
    GET_HAS_SYSTEM_LEVEL_ACCESS: "(fleet-tireoptix getter) get if user has system access from api",
    CHANGE_STEP: "(fleet-tireoptix action) change step",
    CLOSE_STEP: "(fleet-tireoptix action) close step",
    UPDATE_STATE_OBJECT: "(fleet-tireoptix action) update object in state",
}

export const MUTATIONS = {
    SET_HAS_SYSTEM_LEVEL_ACCESS: "(fleet-tireoptix mutation) set if user has system access after api call",
    UPDATE_STATE_OBJECT: "(fleet-tireoptix mutation) update object in state",
    SAVE_STEP: "(fleet-tireoptix mutation) save entire step and place in saved prop",
    RESTORE_FROM_SAVED: "(fleet-tireoptix mutation) set all from saved object to object",
}

const state = {
    us_national_accounts: [],
    canadian_common_owners_accounts: [],
    canadian_national_accounts: [],
    preferred_fleet_accounts: [],
    government_accounts: [],
    leasings: [],
    countries_with_states: [
        {
            country: {
                text: null,
                value: null
            },
            states: [
                {
                    text: null,
                    value: null
                }
            ]
        }
    ],
    report_types: [
        {
            text: null,
            value: null
        }
    ],
    survey_by_users: [
        {
            text: null,
            value: null
        }
    ],
    filter_selects: {
        states: [],
        countries_with_states: [],
        report_types: [],
        survey_by_users: [],
    },
    report_params: {
        result_status: null,
        message: null,
        records_total: null,
        surveys: null,
        tires: null,
        units: null,
        units_with_exceptions: null,
        exceptions: null,
        total_of_trucks_surveyed: null,
        total_of_trailers_surveyed: null,
        inspected_units: null,
        not_inspected_units: null,
        inspections: null,
        inspected_vehicles: null,
        fleet_vehicles: null,
        data: [
            {
                survey_id: null,
                fleet_location_name: null,
                fleet_location: null,
                survey_date: null,
                company: null,
                fleet_name: null,

                survey_by: null,
                total_tires: null,
                total_units: null,
                exceptions: null,
                units_with_exceptions: null,
                trailer_units_count: null,
                tractor_units_count: null,
                tractors_surveyed_percentage: null,
                trailers_surveyed_percentage: null,
                trailer_readiness: null,
            }
        ]
    },
    has_system_level_access: false,
    accounts_filter: {
        include_local_books: false,
        preferred_fleet_accounts: [],
        us_national_accounts: [],
        canadian_national_accounts: [],
        government_accounts: [],
        leasings: [],
        canadian_common_owners_accounts: [],
        
        locations: [],
        locations_info: [],
        locations_load: false,
    }
}

const getters = {
    getTireOptixReportingUSNationalAccounts() {
        return state.us_national_accounts;
    },
    getTireOptixReportingCanadianCOAccounts() {
        return state.canadian_common_owners_accounts;
    },
    getTireOptixReportingCanadianNationalAccounts() {
        return state.canadian_national_accounts;
    },
    getTireOptixReportingPreferredFleetAccounts() {
        return state.preferred_fleet_accounts;
    },
    getTireOptixReportingGovernmentAccounts() {
        return state.government_accounts;
    },
    getTireOptixReportingCountries() {
        return state.countries_with_states;
    },
    getTireOptixReportingReportTypes() {
        return state.report_types;
    },
    getTireOptixReportingReportParams() {
        return state.report_params;
    },
    getTireOptixReportingTableData() {
        return state.report_params.data;
    },
    getTireOptixReportingRecordsTotal() {
        return state.report_params.records_total;
    },
    getTireOptixReportingSurveyByUsers() {
        return state.survey_by_users;
    },
    getTireOptixReportingLeasings() {
        return state.leasings;
    },
    getTireOptixFleetLocationsStep() {
        return state.selectFleetLocationsStep;
    },
    getTireOptixFiltersStep() {
        return state.selectTireOptixFiltersStep;
    },
    [GETTERS.GET_HAS_SYSTEM_LEVEL_ACCESS]() {
        return state.has_system_level_access;
    },
    [GETTERS.GET_ACCOUNTS_FILTER]() {
        return state.accounts_filter;
    },
};

const actions = {
    [API_FLEET_TIREOPTIX_REPORT_HOME_PAGE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("survey/get_home_page", context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TIREOPTIX_REPORT_MUTS_SET_PAGE_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/report", params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_pdf_file_survey_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_excel_file_survey_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FLEET_TIREOPTIX_REPORT_ACTION_DOWNLOAD_ALL_DETAILS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/detailsreport", params, context)
                .then(({ data }) => {
                    console.log(params);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [ACTIONS.GET_HAS_SYSTEM_LEVEL_ACCESS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("survey/has_system_level_access", context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_HAS_SYSTEM_LEVEL_ACCESS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    /**************************************************************************
    * Other
    ***************************************************************************/
    [ACTIONS.CHANGE_STEP](context, number) {
        switch(number) {
            case SELECT_FLEET_LOCATIONS_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_FLEET_LOCATIONS_STEP.STATE_KEY, obj: {isOpen: true}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_TIREOPTIX_FILTERS_STEP.STATE_KEY, obj: {isOpen: false}});
                break;
            }
            case SELECT_TIREOPTIX_FILTERS_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_FLEET_LOCATIONS_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_TIREOPTIX_FILTERS_STEP.STATE_KEY, obj: {isOpen: true}});
                break;
            }
            default:
                break;
        }
    },
    [ACTIONS.CLOSE_STEP](context, number) {
        switch(number) {
            case SELECT_FLEET_LOCATIONS_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_FLEET_LOCATIONS_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_TIREOPTIX_FILTERS_STEP.STATE_KEY, obj: {isOpen: true}});
                                
                context.commit(MUTATIONS.SAVE_STEP, SELECT_FLEET_LOCATIONS_STEP.STATE_KEY);
                break;
            }
            case SELECT_TIREOPTIX_FILTERS_STEP.NUMBER: {
                
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_FLEET_LOCATIONS_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_TIREOPTIX_FILTERS_STEP.STATE_KEY, obj: {isOpen: false}});
                
                context.commit(MUTATIONS.SAVE_STEP, SELECT_FLEET_LOCATIONS_STEP.STATE_KEY);
                context.commit(MUTATIONS.SAVE_STEP, SELECT_TIREOPTIX_FILTERS_STEP.STATE_KEY);
                break;
            }

            default:
                break;
        }
    },
    [ACTIONS.UPDATE_STATE_OBJECT](context, data) {
        context.commit(MUTATIONS.UPDATE_STATE_OBJECT, data);
    },
};

const mutations = {
    [API_FLEET_TIREOPTIX_REPORT_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.us_national_accounts = data.us_national_accounts;
        state.canadian_common_owners_accounts = data.canadian_common_owners_accounts;
        state.canadian_national_accounts = data.canadian_national_accounts;
        state.preferred_fleet_accounts = data.preferred_fleet_accounts;
        state.government_accounts = data.government_accounts;
        state.countries_with_states = data.countries_with_states;
        state.report_types = data.report_types;
        state.survey_by_users = data.survey_by_users;
        state.leasings = data.leasings;
    },
    [API_FLEET_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS](state, {data}) {
        state.report_params.message = data.message;
        state.report_params.result_status = data.result_status;
        state.report_params.records_total = data.records_total;
        state.report_params.surveys = data.surveys;
        state.report_params.tires = data.tires;
        state.report_params.units = data.units;
        state.report_params.units_with_exceptions = data.units_with_exceptions;
        state.report_params.exceptions = data.exceptions;
        state.report_params.total_of_trucks_surveyed = data.total_of_trucks_surveyed;
        state.report_params.total_of_trailers_surveyed = data.total_of_trailers_surveyed;
        state.report_params.inspected_units = data.inspected_units;
        state.report_params.not_inspected_units = data.not_inspected_units;
        state.report_params.inspections = data.inspections;
        state.report_params.inspected_vehicles = data.inspected_vehicles;
        state.report_params.fleet_vehicles = data.fleet_vehicles;
        state.report_params.data = data.data;
    },
    [MUTATIONS.SET_HAS_SYSTEM_LEVEL_ACCESS](state, {data}) {
        state.has_system_level_access = data.has_access;
    },
    [MUTATIONS.UPDATE_STATE_OBJECT](state, { key, obj }) {
        for (const objKey in obj) {
            if (state[key] && state[key].hasOwnProperty(objKey)) {
                state[key][objKey] = obj[objKey];
            }
        }
    },
    [MUTATIONS.SAVE_STEP](state, stateKey) {
        var toSave = JSON.parse(JSON.stringify(state[stateKey]));
        delete toSave["saved"];

        state[stateKey].saved = toSave;
    },
    [MUTATIONS.RESTORE_FROM_SAVED](state, stateKey) {
        if(Object.keys(state[stateKey].saved || {}).length) {
            for (const key in state[stateKey]) {
                if(key !== "saved" && state[stateKey].saved.hasOwnProperty(key)) {
                    state[stateKey][key] = state[stateKey].saved[key];
                }
            }
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};