import { COLORS } from "@/utils/constants/FleetDashboard.constants";

class Dataset {
    constructor({
        label, 
        color = COLORS.GRAY,
        xAxisID,
        stack,
        maxBarThickness,
        includeLabels,
        labelFormat,
        type,
        legend
    }) {
        this.label = label;
        this.borderColor = color;  
        this.backgroundColor = color;  
        this.hoverBackgroundColor = color;
        this.xAxisID = xAxisID;
        this.stack = stack;
        this.maxBarThickness = maxBarThickness;
        this.datasetType = type;
        this.legend = legend;
        if(includeLabels) {
            this.datalabels = new DataLabels({
                format: labelFormat
            })
        }
        else {
            this.datalabels = new DataLabels();
        }
    }
    stack = null;
    label = "";
    data = [];
    items = [];
    xAxisID = null;
    maxBarThickness = null;
    backgroundColor = "";
    hoverBackgroundColor = "";
    borderCapStyle = null;
    datasetType = null;
    legend = null
    borderColor = "";
    showLine = true;
    fill = false;
    lineTension = 0;
    datalabels = new DataLabels();
}

class DatasetItem {
    constructor({
        datasetId,
        dataItem,
        tooltip,
        color,
        type,
        xAxisID,
        datasetLabel,
        legend
    }) {
        this.datasetId = datasetId;
        this.dataItem = dataItem;
        this.datasetLabel = datasetLabel;

        this.tooltip = tooltip;
        this.legend = legend;
        this.color = color;
        this.type = type;
        this.xAxisID = xAxisID;
    }

    xAxisID = null;
    datasetId = null;
    dataItem = null;
    datasetLabel = null;
    type = null;

    tooltip = null;
    legend = null;
    color = COLORS.GRAY;
}
class DataLabels {
    constructor(config) {
        if(config) {
            const color = config?.color || "black";
            const align = config?.align || "center";
            const format = config?.format || "$v";

            
            this.color =  color;
            this.anchor = "center";
            this.rotation = -90;
            this.font = (context) => {
                var width = context.chart.width;
                var size = Math.round(width / 80);
                return {
                    size: size,
                    weight: 600
                };
            }
            this.align = align;

            this.formatter = (value, {dataset, dataIndex}) => {
                var item = dataset.items[dataIndex];
                return format.replace("$v", item["datasetLabel"])
            };

            this.display = true;
                
        }
    }

    color = null;
    anchor = null;
    display = false;
    align = null;
    rotation = 0;
    font = null;
    formatter = null;

    
}

export {
    Dataset,
    DatasetItem,
    DataLabels
}