import ApiService from "@/core/services/api.service";

// action types
export const API_FS_LOCATIONS_LOAD = "action_fs_locations_load";
export const API_FS_LOCATION_LOAD = "action_fs_location_load";
export const API_FS_PREF_ACCOUNTS_LOAD = "action_fs_pref_accounts_load";
export const API_FS_FLEET_ADD = "action_fs_fleet_add";
export const API_FS_GET_NONSIGS = "action_fs_get_nonsigs";
export const API_FS_ALL_SELECTS_LOAD = "action_fs_all_selects_load";
export const API_FS_GET_FLEET_DASHBOARD = "action_fs_get_fleet_dashboard";
export const API_FS_GET_CREATE_UNIT_FORM = "action_fs_get_create_unit_form";
export const API_FS_GET_UNIT_CONFIGURATIONS = "action_fs_get_unit_configurations";
export const API_FS_GET_LAST_5_SURVEYS = "action_fs_get_last_5_surveys";
export const API_FS_GET_UNITS = "action_fs_get_units";
export const API_FS_GET_UNIT = "action_fs_get_unit";
export const API_FS_SAVE_UNIT = "action_fs_save_unit";
export const API_FS_SAVE_UNITS = "action_fs_save_units";
export const API_FS_GET_UNITS_PDF = "action_fs_get_units_pdf";
export const API_FS_GET_UNITS_EXCEL = "action_fs_get_units_excel";
export const API_FS_IMPORT_UNITS = "action_fs_import_units";
export const API_FS_GET_SURVEYS = "action_fs_get_surveys";
export const API_FS_GET_SURVEYS_PDF = "action_fs_get_surveys_pdf";
export const API_FS_GET_SURVEYS_EXCEL = "action_fs_get_surveys_excel";
export const API_FS_GET_UNIT_NUMBERS = "action_fs_get_unit_numbers";
export const API_FS_GET_OWNERS = "action_fs_get_owners";
export const API_FS_GET_UNIT_BY_NUMBER = "action_fs_get_unit_by_number";
export const API_FS_GET_UNIT_OVERVIEW_SIDEBAR = "action_fs_get_unit_overview_sidebar";
export const API_FS_GET_UNIT_OVERVIEW_INFO = "action_fs_get_unit_overview_info";
export const API_FS_GET_UNIT_DETAILS_IMAGES = "action_fs_get_unit_details_images";
export const API_FS_UPLOAD_UNIT_IMAGE = "action_fs_upload_unit_image";
export const API_FS_UPLOAD_TIRE_IMAGE = "action_fs_upload_tire_image";
export const API_FS_DELETE_IMAGE = "action_fs_delete_unit_details_image";
export const API_FS_GET_UNIT_AXLES = "action_fs_get_unit_axles";
export const API_FS_GET_WEAR_CONDITIONS = "action_fs_get_wear_conditions";
export const API_FS_SEARCH_WEAR_CONDITIONS = "action_fs_search_wear_conditions";
export const API_FS_UPDATE_TIRE = "action_fs_update_tire";
export const API_FS_DELETE_UNIT_ON_SURVEY = "action_fs_delete_unit_on_survey";
export const API_FS_DELETE_UNIT_ALL = "action_fs_delete_unit_all";
export const API_FS_GET_WHEEL_POSITION_INFO = "action_fs_get_wheel_position_info";
export const API_FS_GET_EDIT_TIRE_SELECTS = "action_fs_get_edit_tire_selects";
export const API_FS_GET_TREAD_SPEC = "action_fs_get_tread_spec";
export const API_FS_UPDATE_TIRE_DETAILS = "action_fs_update_tire_details";
export const API_FS_UPDATE_UNIT_INSPECTION = "action_fs_update_unit_inspection";
export const API_FS_SAVE_SURVEY = "action_fs_save_survey";
export const API_FS_GET_CREATE_UNIT_OPTIONS = "action_fs_get_create_unit_options";
export const API_FS_CREATE_UNIT_COPY = "action_fs_create_unit_copy";
export const API_FS_CLONE_UNIT = "action_fs_clone_unit";
export const API_FS_GET_REMOVED_TIRES = "action_fs_get_removed_tires";
export const API_FS_GET_REMOVED_TIRES_PDF = "action_fs_get_removed_tires_pdf";
export const API_FS_GET_REMOVED_TIRES_EXCEL = "action_fs_get_removed_tires_excel";
export const API_FS_GET_REMOVED_TIRE_INFO = "action_fs_get_removed_tire_info";
export const API_FS_REPLACE_TIRES = "action_fs_replace_tires";
export const API_FS_UNDO_REPLACE_TIRES = "action_fs_undo_replace_tires";
export const API_FS_ADD_NEW_UNIT_TO_SURVEY = "action_fs_add_new_unit_to_survey";
export const API_FS_ADD_EXISTING_UNIT_TO_SURVEY = "action_fs_add_existing_unit_to_survey";
export const API_FS_GET_REMOVAL_REASONS = "action_fs_get_removal_reasons";
export const API_FS_UPDATE_REMOVED_TIRE = "action_fs_update_removed_tire";
export const API_FS_DELETE_REMOVED_TIRE = "action_fs_delete_removed_tire";
export const API_FS_FINISH_SURVEY = "action_fs_finish_survey";
export const API_FS_DELETE_SURVEY = "action_fs_delete_survey";
export const API_FS_SEND_REPORTS_TO_EMAILS = "action_fs_send_reports_to_emails";
export const API_FS_UPDATE_SURVEY_DETAILS = "action_fs_update_survey_details";

// muts 
export const API_FS_MUTS_SET_LOCATIONS = "muts_fs_set_locations";
export const API_FS_MUTS_SET_LOCATION = "muts_fs_set_location";
export const API_FS_MUTS_SET_PREF_ACCOUNTS = "muts_fs_set_pref_accounts";
export const API_FS_MUTS_SET_NONSIGS = "muts_fs_set_nonsigs";
export const API_FS_MUTS_SET_ALL_SELECTS = "muts_fs_set_all_selects";
export const API_FS_SET_FLEET_DASHBOARD = "action_fs_set_fleet_dashboard";
export const API_FS_SET_CREATE_UNIT_FORM = "action_fs_set_create_unit_form";
export const API_FS_SET_UNIT_CONFIGURATIONS = "action_fs_set_unit_configurations";
export const API_FS_SET_LAST_5_SURVEYS = "action_fs_set_last_5_surveys";
export const API_FS_SET_UNITS = "action_fs_set_units";
export const API_FS_SET_UNIT = "action_fs_set_unit";
export const API_FS_SET_STATUS_ERROR = "action_fs_set_status_error";
export const API_FS_SET_STATUS_SUCCESS = "action_fs_set_status_success";
export const API_FS_SET_UPLOADED_UNITS = "action_fs_set_uploaded_units";
export const API_FS_SET_UNIT_ID = "action_fs_set_unit_id";
export const API_FS_SET_SURVEYS = "action_fs_set_surveys";
export const API_FS_SET_OWNERS = "action_fs_set_owners";
export const API_FS_SET_UNIT_NUMBERS = "action_fs_set_unit_numbers";
export const API_FS_SET_UNIT_BY_NUMBER = "action_fs_set_unit_by_number";
export const API_FS_SET_UNIT_OVERVIEW_SIDEBAR = "action_fs_set_unit_overview_sidebar";
export const API_FS_SET_UNIT_OVERVIEW_INFO = "action_fs_set_unit_overview_info";
export const API_FS_SET_UNIT_DETAILS_IMAGES = "action_fs_get_unit_details_images";
export const API_FS_SET_TIRE_DETAILS_IMAGES = "action_fs_get_tire_details_images";
export const API_FS_SET_UPLOADED_UNIT_DETAILS_IMAGES = "action_fs_set_uploaded_unit_details_images";
export const API_FS_SET_UNIT_AXLES = "action_fs_set_unit_axles";
export const API_FS_SET_WEAR_CONDITIONS = "action_fs_set_wear_conditions";
export const API_FS_SET_WHEEL_POSITION_INFO = "action_fs_set_wheel_position_info";
export const API_FS_SET_EDIT_TIRE_SELECTS = "action_fs_set_edit_tire_selects";
export const API_FS_SET_UPDATED_TIRE = "action_fs_set_updated_tire";
export const API_FS_SET_UPDATED_TIRE_DETAILS = "action_fs_set_updated_tire_details";
export const API_FS_SET_UPDATED_UNIT_INSPECTION = "action_fs_set_updated_unit_inspection";
export const API_FS_SET_CREATE_UNIT_OPTIONS = "action_fs_set_create_unit_options";
// export const API_FS_SET_CLONE_UNIT = "action_fs_set_clone_unit";
export const API_FS_SET_REMOVED_TIRES = "action_fs_set_removed_tires";
export const API_FS_SET_REMOVED_TIRE_INFO = "action_fs_set_removed_tire_info";
export const API_FS_SET_REMOVAL_REASONS = "action_fs_set_removal_reasons";


const state = {
    locations_table: {
        page_number: null,
        page_size: null,
        total_items: null,
        items: []
    },
    pref_accounts_table: {
        page_number: null,
        page_size: null,
        total_items: null,
        items: []
    },
    removed_tires_table: {
        page_number: null,
        page_size: null,
        total_items: null,
        items: []
    },
    // dropdowns
    countries: [],
    fleets: {
        national_global_account_names: [],
        canadian_national_global_account_names: [],
        government_account_names: [],
    },
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },
    vocations: [],
    is_show_unknown: false,
    is_show_localbook: false,
    is_show_goverment: false,
    is_show_preferred_fleet: false,

    nonsigs: [],

    create_unit_page: {
        axle_manufacturers: [],
        axle_types: [],
        braking_systems: [],
        engine_manufacturers: [],
        engine_types: [],
        fifth_wheel_settings: [],
        loading_systems: [],
        manufacturers: [],
        suspension_types: [],
        transmission_manufacturers: [],
        transmission_types: [],
        types: [],
        vocations: []
    },
    unit_configurations: [],
    dashboard_summary: {
        surveys: null,
        units: null,
        tires: null,
        exceptions: null
    },
    last_five_surveys: [],
    all_units_table: {
        units: [],
        page_number: 0,
        page_size: 0,
        total_items: 0
    },
    status: {
        status: "",
        description: "",
        isError: true
    },
    imported_units: [],
    imported_units_errors: [],
    unitId: null,
    surveys_table: {
        page_number: null,
        page_size: null,
        total_items: null,
        items: []
    },
    unit_numbers: [],
    unit_by_number: null,
    owners: [],
    sidebar_units: [],
    survey_details: {},
    first_unit_info: {},
    unit_info: {
        last_survey_date: null,
        vocation: null,
        manufacturer: null,
        year: null,
        type: null,
        model: null,
        license_plate: null,
        unit_number: null,
        vin: null,
        dot: null,
        is_tires_exist: null,
        license_plate_state: null,
    },
    unit_details_images: [],
    tire_details_images: [],
    unit_axles: [],
    wear_conditions_on_tire: [],
    wheel_position_info: [],
    edit_tire_selects: {
        brands: [],
        product_lines: [],
        tire_sizes: [],
        retreaded_by_brands: [],
        wear_conditions: [],
        valve_types: []
    },
    updated_tire: {},
    updated_tire_details_id: null,
    unit_inspection_info: {
        mileage: null,
        hours: null,
        comments: null,
        unit_inspection_id: null,
    },
    selected_location: null,
    unit: null,
    create_unit_options: {
        is_unit_exists_on_survey: false,
        is_unit_exists: false,
        tractor_copy_option: false,
        trailer_copy_option: false,
        dolly_copy_option: false,
        misc_copy_option: false,
    },
    removed_tire_info: {
        odometer: null,
        hours: null,
        tread_depth: null,
        comments: null,
        removal_reasons: [],
        wear_conditions: [],
        product_information: null,
        retread_product_information: null,
        id: null,
    },
    removal_reasons: [],
};

const getters = {
    getFSLocationsTable() {
        return state.locations_table;
    },
    getFSCountries() {
        return state.countries;
    },
    getFSShowTypes() {
        return {
            is_show_unknown: state.is_show_unknown,
            is_show_localbook: state.is_show_localbook,
            is_show_goverment: state.is_show_goverment,
            is_show_preferred_fleet: state.is_show_preferred_fleet
        };
    },
    getFSFleetsNA() {
        return state.fleets.national_global_account_names;
    },
    getFSFleetsCA() {
        return state.fleets.canadian_national_global_account_names;
    },
    getFSFleetsG() {
        return state.fleets.government_account_names;
    },
    getFSStatesCA() {
        return state.states.CA_states;
    },
    getFSStatesUS() {
        return state.states.US_states;
    },
    getFSStatesMX() {
        return state.states.MX_states;
    },
    getFSStatesCO() {
        return state.states.CO_states;
    },
    getFSStatesAU() {
        return state.states.AU_states;
    },
    getFSStatesCN() {
        return state.states.CN_provinces;
    },
    getFSVocations() {
        return state.vocations;
    },
    getFSNonsigs() {
        return state.nonsigs;
    },
    getFSPrefAccountsTable() {
        return state.pref_accounts_table;
    },
    getFSCreateUnitSelects() {
        return state.create_unit_page;
    },
    getFSUnitConfigurations() {
        return state.unit_configurations;
    },
    getFSDashboardSummary() {
        return state.dashboard_summary;
    },
    getFSLast5Surveys() {
        return state.last_five_surveys;
    },
    getFSAllUnitsTable() {
        return state.all_units_table;
    },
    getOperationStatus() {
        return state.status;
    },
    getFSImportedUnits() {
        return state.imported_units;
    },
    getFSImportedUnitsErrors() {
        return state.imported_units_errors;
    },
    getFSSavedUnitId() {
        return state.unitId;
    },
    getFSSurveysTable() {
        return state.surveys_table;
    },
    getFSUnitNumbers() {
        return state.unit_numbers;
    },
    getFSUnitByNumber() {
        return state.unit_by_number;
    },
    getFSSidebarUnits() {
        return state.sidebar_units;
    },
    getFSSurveyDetails() {
        return state.survey_details;
    },
    getFSUnitInfo() {
        return state.unit_info;
    },
    getFSUnitDetailsImages() {
        return state.unit_details_images;
    },
    getFSTireDetailsImages() {
        return state.tire_details_images;
    },
    getFSUnitAxles() {
        return state.unit_axles;
    },
    getFSWearConditionsOnTire() {
        return state.wear_conditions_on_tire;
    },
    getFSWheelPositionInfo() {
        return state.wheel_position_info;
    },
    getFSEditTireSelects() {
        return state.edit_tire_selects;
    },
    getFSUpdatedTire() {
        return state.updated_tire;
    },
    getFSUpdatedTireDetailsId() {
        return state.updated_tire_details_id;
    },
    getFSOwners() {
        return state.owners;
    },
    getFSUnitInspectionInfo() {
        return state.unit_inspection_info;
    },
    getFSSelectedLocation() {
        return state.selected_location;
    },
    getFSUnit() {
        return state.unit;
    },
    getFSCreateUnitOptions() {
        return state.create_unit_options;
    },
    getFSRemovedTiresTable() {
        return state.removed_tires_table;
    },
    getFSRemovedTireInfo() {
        return state.removed_tire_info;
    },
    getFSRemovalReasons() {
        return state.removal_reasons;
    }
};

const actions = {
    [API_FS_UPDATE_SURVEY_DETAILS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/update_survey_details", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_LOCATIONS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/select_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_LOCATION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_location/"+ params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_LOCATION, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_PREF_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/select_preff_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_PREF_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_FLEET_ADD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/add_location", params, context)
                .then(({ data }) => {
                    // context.commit(API_FS_MUTS_SET_STATUS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_ALL_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_ALL_SELECTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_NONSIGS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_nonsigs/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FS_MUTS_SET_NONSIGS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_FLEET_DASHBOARD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/summary", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_FLEET_DASHBOARD, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_CREATE_UNIT_FORM](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_unit_selects", context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_CREATE_UNIT_FORM, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_CONFIGURATIONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_unit_configurations/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_CONFIGURATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_SAVE_UNIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/save_unit", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_ID, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_SAVE_UNITS](context, params) {
        let cancelTokenSource = params.cancelTokenSource;
        delete params.cancelTokenSource;
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/save_units", params, context, cancelTokenSource)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_LAST_5_SURVEYS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_last_five_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_LAST_5_SURVEYS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNITS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_units", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNITS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_unit/"+ params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNITS_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_units_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNITS_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_units_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_IMPORT_UNITS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/import_units", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UPLOADED_UNITS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_SURVEYS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_SURVEYS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_SURVEYS_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_surveys_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_SURVEYS_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_surveys_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_NUMBERS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_unit_numbers", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_NUMBERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_BY_NUMBER](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_unit_by_number", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_BY_NUMBER, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_OWNERS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_owners", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_OWNERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_OVERVIEW_SIDEBAR](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/"+ params + "/units", context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_OVERVIEW_SIDEBAR, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_OVERVIEW_INFO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/" + params.surveyId + "/units/" + params.unitId, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_OVERVIEW_INFO, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_DETAILS_IMAGES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_unit_inspection_images/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_DETAILS_IMAGES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UPLOAD_UNIT_IMAGE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/add_unit_image/" + params.unitInspectionId , params.formData, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_DETAILS_IMAGES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UPLOAD_TIRE_IMAGE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/add_tire_image", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_TIRE_DETAILS_IMAGES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_DELETE_IMAGE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_survey/delete_image?storageId=" + params.storageId + "&unitInspectionId=" + params.unitInspectionId + (params.tireId ? "&tireId=" + params.tireId : ""), context)
                .then(({ data }) => {
                    if(params.tireId) {
                        context.commit(API_FS_SET_TIRE_DETAILS_IMAGES, {data});
                    }
                    else {
                        context.commit(API_FS_SET_UNIT_DETAILS_IMAGES, {data});
                    }
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_GET_UNIT_AXLES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/" + params.surveyId + "/axles/" + params.unitId, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_AXLES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_WEAR_CONDITIONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_wear_conditions", context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_WEAR_CONDITIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_UPDATE_TIRE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/save_tire", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UPDATED_TIRE, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_DELETE_UNIT_ON_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_survey/delete_unit/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_DELETE_UNIT_ALL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_survey/delete_unit_all/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_DELETE_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_survey/delete_survey/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_WHEEL_POSITION_INFO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_wheel_position_info", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_WHEEL_POSITION_INFO, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_EDIT_TIRE_SELECTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_edit_tire_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_EDIT_TIRE_SELECTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_GET_TREAD_SPEC](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_tread_depth_spec", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UPDATE_TIRE_DETAILS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/update_tire_details", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UPDATED_TIRE_DETAILS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UPDATE_UNIT_INSPECTION](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/update_unit_inspection", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UPDATED_UNIT_INSPECTION, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_SAVE_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/create_survey", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_GET_CREATE_UNIT_OPTIONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_create_unit_options", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_CREATE_UNIT_OPTIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_CREATE_UNIT_COPY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/create_unit_copy", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_ID, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_CLONE_UNIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/clone_unit", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_UNIT_ID, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_REMOVED_TIRES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/get_removed_tires", params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_REMOVED_TIRES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_REMOVED_TIRES_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_removed_tires_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_GET_REMOVED_TIRES_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_survey/get_removed_tires_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FS_REPLACE_TIRES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/replace_tires", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UNDO_REPLACE_TIRES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/undo_tire_replace", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_ADD_NEW_UNIT_TO_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/add_new_unit_to_survey", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_ADD_EXISTING_UNIT_TO_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/add_existing_unit_to_survey", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_GET_REMOVED_TIRE_INFO](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_removed_tire_info/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_REMOVED_TIRE_INFO, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_GET_REMOVAL_REASONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_survey/get_removal_reasons", context)
                .then(({ data }) => {
                    context.commit(API_FS_SET_REMOVAL_REASONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_UPDATE_REMOVED_TIRE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.put("fleet_survey/update_removed_tire", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_DELETE_REMOVED_TIRE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_survey/delete_removed_tire/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_FINISH_SURVEY](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/finish_survey", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
    [API_FS_SEND_REPORTS_TO_EMAILS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_survey/send_email_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_FS_MUTS_SET_LOCATIONS](state, {data}) {
        state.locations_table.items = data.accounts;
        state.locations_table.page_number = data.page_number;
        state.locations_table.page_size = data.page_size;  
        state.locations_table.total_items = data.total_items; 
    },
    [API_FS_MUTS_SET_LOCATION](state, {data}) {
        state.selected_location = data.location;
    },
    [API_FS_MUTS_SET_PREF_ACCOUNTS](state, {data}) {
        state.pref_accounts_table.items = data.locations;
        state.pref_accounts_table.page_number = data.page_number;
        state.pref_accounts_table.page_size = data.page_size;  
        state.pref_accounts_table.total_items = data.total_items; 
    },
    [API_FS_SET_REMOVED_TIRES](state, {data}) {
        state.removed_tires_table.items = data.removed_tires;
        state.removed_tires_table.page_number = data.page_number;
        state.removed_tires_table.page_size = data.page_size;  
        state.removed_tires_table.total_items = data.total_items; 
    },
    [API_FS_SET_STATUS_ERROR](state, {data}) {
        if(data) {
            state.status.status = data.status?.Status;
            state.status.description = data.status?.Description;
            state.status.isError = true;
        }
    },
    [API_FS_MUTS_SET_ALL_SELECTS](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;  

        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;

        state.countries = data.countries;

        state.vocations = data.vocations;

        state.dealers = data.dealers;
        state.dealers_ctsc = data.dealers_ctsc;

        state.is_show_localbook = data.is_show_localbook;
        state.is_show_unknown = data.is_show_unknown;
        state.is_show_goverment = data.is_show_goverment;
        state.is_show_preferred_fleet = data.is_show_preferred_fleet;
    },
    [API_FS_MUTS_SET_NONSIGS](state, {data}) {
        state.nonsigs = data.nonsigs_list; 
    },
    [API_FS_SET_FLEET_DASHBOARD](state, {data}) {
        state.dashboard_summary.units = data.units;
        state.dashboard_summary.surveys = data.surveys;
        state.dashboard_summary.tires = data.tires;
        state.dashboard_summary.exceptions = data.exceptions;
    },
    [API_FS_SET_CREATE_UNIT_FORM](state, {data}) {
        state.create_unit_page.axle_manufacturers = data.axle_manufacturers;
        state.create_unit_page.axle_types = data.axle_types;
        state.create_unit_page.braking_systems = data.braking_systems;
        state.create_unit_page.engine_manufacturers = data.engine_manufacturers;
        state.create_unit_page.engine_types = data.engine_types;
        state.create_unit_page.fifth_wheel_settings = data.fifth_wheel_settings;
        state.create_unit_page.loading_systems = data.loading_systems;
        state.create_unit_page.manufacturers = data.manufacturers;
        state.create_unit_page.suspension_types = data.suspension_types;
        state.create_unit_page.transmission_manufacturers = data.transmission_manufacturers;
        state.create_unit_page.transmission_types = data.transmission_types;
        state.create_unit_page.types = data.types;
        state.create_unit_page.vocations = data.vocations;
    },
    [API_FS_SET_UNIT_CONFIGURATIONS](state, {data}) {
        state.unit_configurations = [];
        if(data?.configurations) {
            data.configurations.forEach(element => {
                let obj = {
                    text: element.text,
                    value: element.value,
                    axles: []
                };

                let axles = [];
                for (let index = 0; index < element.axles.length; index++) {
                    const el = element.axles[index];
                    
                    let devided = Number(el) / 2;
                    let left = [];
                    let right = [];
                    for (let i = 0; i < devided; i++) {
                        left.push({
                            index: i
                        });
                        right.push({
                            index: i
                        });
                    }

                    axles.push({
                        number: index + 1,
                        leftTires: left,
                        rightTires: right
                    })
                }

                obj.axles = axles;
                state.unit_configurations.push(obj);
            });
        }
    },
    [API_FS_SET_LAST_5_SURVEYS](state, {data}) {
        state.last_five_surveys = data.surveys;
    },
    [API_FS_SET_UNITS](state, {data}) {
        state.all_units_table.units = data.units;
        state.all_units_table.page_number = data.page_number;
        state.all_units_table.page_size = data.page_size;
        state.all_units_table.total_items = data.total_items;
    },
    [API_FS_SET_UPLOADED_UNITS](state, {data}) {
        state.imported_units = data.units;
        if(data.errors) {
            state.imported_units_errors = data.errors;
        }
    },
    [API_FS_SET_UNIT_ID](state, {data}) {
        state.unitId = data.id;
    },
    [API_FS_SET_SURVEYS](state, {data}) {
        state.surveys_table.items = data.surveys;
        state.surveys_table.page_number = data.page_number;
        state.surveys_table.page_size = data.page_size;
        state.surveys_table.total_items = data.total_items;
    },
    [API_FS_SET_UNIT_NUMBERS](state, {data}) {
        state.unit_numbers = data.unit_numbers;
    },
    [API_FS_SET_UNIT_BY_NUMBER](state, {data}) {
        state.unit_by_number = data.unit;
    },
    [API_FS_SET_UNIT_OVERVIEW_SIDEBAR](state, {data}) {
        state.sidebar_units = data.units_overview.units;
        state.survey_details = data.units_overview.survey_details; 
    },
    [API_FS_SET_UNIT_OVERVIEW_INFO](state, {data}) {

        state.survey_details = data.unit_info.survey_details; 

        state.unit_info.last_survey_date = data.unit_info.unit_info?.last_survey_date;
        state.unit_info.vocation = data.unit_info.unit_info?.vocation;
        state.unit_info.manufacturer = data.unit_info.unit_info?.manufacturer;
        state.unit_info.year = data.unit_info.unit_info?.year;
        state.unit_info.type = data.unit_info.unit_info?.type;
        state.unit_info.model = data.unit_info.unit_info?.model;
        state.unit_info.license_plate = data.unit_info.unit_info?.license_plate;
        state.unit_info.unit_number = data.unit_info.unit_info?.unit_number;
        state.unit_info.license_plate_state = data.unit_info.unit_info?.license_plate_state;
        state.unit_info.vin = data.unit_info.unit_info?.vin;
        state.unit_info.dot = data.unit_info.unit_info?.dot;
        state.unit_info.is_tires_exist = data.unit_info.unit_info?.is_tires_exist;
        
        state.sidebar_units = data.unit_info.units;
        
        state.unit_inspection_info.mileage = data.unit_info.unit_inspection.mileage;
        state.unit_inspection_info.hours = data.unit_info.unit_inspection.hours;
        state.unit_inspection_info.comments = data.unit_info.unit_inspection.comments;
        state.unit_inspection_info.unit_inspection_id = data.unit_info.unit_inspection.unit_inspection_id;
    
    },
    [API_FS_SET_UNIT_DETAILS_IMAGES](state, {data}) {
        state.unit_details_images = data.images;
    },
    [API_FS_SET_TIRE_DETAILS_IMAGES](state, {data}) {
        state.tire_details_images = data.images;
    },
    [API_FS_SET_UPLOADED_UNIT_DETAILS_IMAGES](state, {data}) {
        // state.unit_details_images = data;
        console.log(data);
    },
    [API_FS_SET_UNIT_AXLES](state, {data}) {
        state.unit_axles = data.fleet_survey_axles_list;
    },
    [API_FS_SET_WEAR_CONDITIONS](state, {data}) {
        state.wear_conditions_on_tire = data.wear_conditions;
    },
    [API_FS_SET_WHEEL_POSITION_INFO](state, {data}) {
        state.wheel_position_info = data.wheel_position_info;
    },
    [API_FS_SET_EDIT_TIRE_SELECTS](state, {data}) {
        state.edit_tire_selects.brands = data.brands || [];
        state.edit_tire_selects.product_lines = data.product_lines || [];
        state.edit_tire_selects.tire_sizes = data.tire_sizes || [];
        state.edit_tire_selects.retreaded_by_brands = data.retreaded_by_brands || [];
        state.edit_tire_selects.wear_conditions = data.wear_conditions || [];
        state.edit_tire_selects.valve_types = data.valve_types || [];
    },
    [API_FS_SET_UPDATED_TIRE](state, {data}) {
        state.updated_tire = data.updated_wheel;
    },
    [API_FS_SET_UPDATED_TIRE_DETAILS](state, {data}) {
        state.updated_tire_details_id = data.id;
    },
    [API_FS_SET_OWNERS](state, {data}) {
        state.owners = data.dealers;
    },
    [API_FS_SET_UPDATED_UNIT_INSPECTION](state, {data}) {
        state.unit_inspection_info.comments = data.unit_info.comments;
        state.unit_inspection_info.mileage = data.unit_info.mileage;
        state.unit_inspection_info.hours = data.unit_info.hours;
        state.unit_inspection_info.unit_inspection_id = data.unit_info.unit_inspection_id;
    },
    [API_FS_SET_UNIT](state, {data}) {
        state.unit = data.unit;
    },
    [API_FS_SET_CREATE_UNIT_OPTIONS](state, {data}) {
        state.create_unit_options.is_unit_exists_on_survey = data.create_unit_options.is_unit_exists_on_survey;
        state.create_unit_options.is_unit_exists = data.create_unit_options.is_unit_exists;
        state.create_unit_options.tractor_copy_option = data.create_unit_options.tractor_copy_option;
        state.create_unit_options.trailer_copy_option = data.create_unit_options.trailer_copy_option;
        state.create_unit_options.dolly_copy_option = data.create_unit_options.dolly_copy_option;
        state.create_unit_options.misc_copy_option = data.create_unit_options.misc_copy_option;
    },
    [API_FS_SET_REMOVED_TIRE_INFO](state, {data}) {
        state.removed_tire_info.hours = data.removed_tire.hours;
        state.removed_tire_info.comments = data.removed_tire.comments;
        state.removed_tire_info.odometer = data.removed_tire.odometer;
        state.removed_tire_info.id = data.removed_tire.id;
        state.removed_tire_info.product_information = data.removed_tire.product_information;
        state.removed_tire_info.retread_product_information = data.removed_tire.retread_product_information;
        state.removed_tire_info.removal_reasons = data.removed_tire.removal_reasons;
        state.removed_tire_info.tread_depth = data.removed_tire.tread_depth;
        state.removed_tire_info.wear_conditions = data.removed_tire.wear_conditions;
    },
    [API_FS_SET_REMOVAL_REASONS](state, {data}) {
        state.removal_reasons = data.removal_reasons;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};