import ApiService from "@/core/services/api.service";


// action types
export const API_CHECKPOINT_REPORT_DETAIL_ACTION_LOAD = "action_checkpoint_report_detail_load";
export const API_CHECKPOINT_REPORT_DETAIL_ACTION_DOWNLOAD_PDF = "action_checkpoint_report_detail_download_pdf";
export const API_CHECKPOINT_DETAIL_REPORT_ACTION_DOWNLOAD_PDF = "action_checkpoint_detail_report_download_pdf";
export const API_CHECKPOINT_REPORT_DETAIL_ACTION_DOWNLOAD_EXCEL = "action_checkpoint_report_detail_download_excel";

// muts
export const API_CHECKPOINT_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS = "muts_checkpoint_report_detail_set_page_params";

const state = {
    pressure_format: null,
    tread_depth_format: null,
    title: null,
    tiles: {
        unit: null,
        date_time: null,
        license_plate_url: null,
        location: null,
        license_number: null,
        records_total: null,
        non_metric: null,
        is_bidirectional: null,
        crossingdirection: null,
    },
    tires: [
        {
            axle_number: null,
            mismatch: null,
            mismatch_axle_color: null,
            is_lift_axle: null,

            left_side: {              
                mismatch: null,
                mismatch_duals_color: null,
                wheels: [
                    {                          
                        position: null,
                        pressure: null,
                        pressure_color: null,
                        tread_depth: null,
                        tread_depth_color: null,
                    }
                ]
            },

            right_side: {              
                mismatch_duals: null,
                mismatch_duals_color: null,
                wheels: [
                    {                          
                        position: null,
                        pressure: null,
                        pressure_color: null,
                        tread_depth: null,
                        tread_depth_color: null,
                    }
                ]
            },
        }
    ],
}

const getters = {
    getCheckPointReportingDetailTires() {
        return state.tires;
    },
    getCheckPointReportingDetailData() {
        return state.data;
    },
    getCheckPointReportingDetailBoxes() {
        return state.tiles;
    },
    getCheckPointReportingDetailPageParams() {
        return state;
    },

};

const actions = {
    [API_CHECKPOINT_REPORT_DETAIL_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("checkpoint/detail", params, context)
                .then(({ data }) => {
                    context.commit(API_CHECKPOINT_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_DETAIL_ACTION_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_excel_file_checkpoint_details", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_DETAIL_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_pdf_file_checkpoint_details", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_DETAIL_REPORT_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_pdf_file_checkpoint_detail", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_CHECKPOINT_REPORT_DETAIL_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.pressure_format = data.pressure_format;
        state.tread_depth_format = data.tread_depth_format;
        state.title = data.title;
        state.tiles = data.tiles;
        state.tires = data.data;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};