import ApiService from "@/core/services/api.service";

// action types
export const API_ProductWareRateReport_ACCOUNTS_LOAD = "action_productWareRateReport_accounts_load";
export const API_ProductWareRateReport_ACCOUNT_IDS_LOAD = "action_productWareRateReport_account_ids_load";
export const API_ProductWareRateReport_ALL_SELECTS_LOAD = "action_productWareRateReport_all_selects_load";
export const API_ProductWearRateReport_HAS_SYSTEM_LEVEL_ACCESS = "action_productWareRateReport_has_system_level_access";

// muts
export const API_ProductWareRateReport_MUTS_SET_ACCOUNTS = "muts_productWareRateReport_set_accounts";
export const API_ProductWareRateReport_MUTS_SET_ACCOUNT_IDS = "muts_productWareRateReport_set_account_ids";
export const API_ProductWareRateReport_MUTS_SET_ALL_SELECTS = "muts_productWareRateReport_set_all_selects";
export const API_ProductWareRateReport_MUTS_SET_HAS_SYSTEM_LEVEL_ACCESS = "muts_productWareRateReport_set_has_system_level_access";

const state = {
    countries: [],
    fleets: {
        national_global_account_names: [],
        canadian_national_global_account_names: [],
        government_account_names: [],
    },
    is_show_unknown: false,
    is_show_localbook: false,
    is_show_goverment: false,
    is_show_preferred_fleet: false,
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },
    vocations: [],
    table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    account_ids: [],
    hasSystemLevelAccess: false,

    
    is_wear_rate_accounts_verification_failed: false,
    wear_rate_verified_accounts: []
}

const getters = {
    getProductWareRateReportShowTypes() {
        return {
            is_show_unknown: state.is_show_unknown,
            is_show_localbook: state.is_show_localbook,
            is_show_goverment: state.is_show_goverment,
            is_show_preferred_fleet: state.is_show_preferred_fleet
        };
    },
    getProductWareRateReportCountries() {
        return state.countries;
    },
    getProductWareRateReportFleetsNA() {
        return state.fleets.national_global_account_names;
    },
    getProductWareRateReportFleetsCA() {
        return state.fleets.canadian_national_global_account_names;
    },
    getProductWareRateReportFleetsG() {
        return state.fleets.government_account_names;
    },
    getProductWareRateReportStatesCA() {
        return state.states.CA_states;
    },
    getProductWareRateReportStatesUS() {
        return state.states.US_states;
    },
    getProductWareRateReportStatesMX() {
        return state.states.MX_states;
    },
    getProductWareRateReportStatesCO() {
        return state.states.CO_states;
    },
    getProductWareRateReportStatesAU() {
        return state.states.AU_states;
    },
    getProductWareRateReportStatesCN() {
        return state.states.CN_provinces;
    },
    getProductWareRateReportVocations() {
        return state.vocations;
    },
    getProductWareRateReportAccountsTable() {
        return state.table;
    },
    getProductWareRateReportAccountIds() {
        return state.account_ids;
    },
    hasProductWearSystemLevelAccess() {
        return state.hasSystemLevelAccess;
    },
    getProductWearRateAccountVerification() {
        return state.is_wear_rate_accounts_verification_failed;
    },
    getProductWearRateVerifiedAccounts() {
        return state.wear_rate_verified_accounts;
    }
}

const actions = {
    [API_ProductWareRateReport_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_ProductWareRateReport_MUTS_SET_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ProductWareRateReport_ACCOUNT_IDS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("product_wear_rate_report/select_account_ids", context)
                .then(({ data }) => {
                    context.commit(API_ProductWareRateReport_MUTS_SET_ACCOUNT_IDS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ProductWareRateReport_ALL_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/get_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_ProductWareRateReport_MUTS_SET_ALL_SELECTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ProductWearRateReport_HAS_SYSTEM_LEVEL_ACCESS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("product_wear_rate_report/has_system_level_access", context)
                .then(({ data }) => {
                    context.commit(API_ProductWareRateReport_MUTS_SET_HAS_SYSTEM_LEVEL_ACCESS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {
    [API_ProductWareRateReport_MUTS_SET_HAS_SYSTEM_LEVEL_ACCESS](state, {data}) {
        state.hasSystemLevelAccess = data.has_access;
    },
    [API_ProductWareRateReport_MUTS_SET_ACCOUNTS](state, {data}) {
        state.table.accounts = data.accounts;
        state.table.page_number = data.page_number;
        state.table.page_size = data.page_size;  
        state.table.total_items = data.total_items; 
        state.table.total_selected_items = data.total_selected_items;  
    },
    [API_ProductWareRateReport_MUTS_SET_ACCOUNT_IDS](state, {data}) {
        state.account_ids = data.account_ids; 
    },
    [API_ProductWareRateReport_MUTS_SET_ALL_SELECTS](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;  

        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;

        state.countries = data.countries.filter(v => v.value);

        state.vocations = data.vocations.filter(item => item.value);

        state.is_show_localbook = data.is_show_localbook;
        state.is_show_unknown = data.is_show_unknown;
        state.is_show_goverment = data.is_show_goverment;
        state.is_show_preferred_fleet = data.is_show_preferred_fleet;
        state.is_wear_rate_accounts_verification_failed = data.is_accounts_verification_failed;
        state.wear_rate_verified_accounts = data.verified_account;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
