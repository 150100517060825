import ApiService from "@/core/services/api.service";


// action types
export const API_CHECKPOINT_REPORT_ACTION_LOAD = "action_checkpoint_report_load";
export const API_CHECKPOINT_REPORT_CUSTOMER_LOCATIONS_ACTION_LOAD = "action_checkpoint_report_customer_locations_load";
export const API_CHECKPOINT_REPORT_ACTION_DOWNLOAD_PDF = "action_checkpoint_report_download_pdf";
export const API_CHECKPOINT_REPORT_ACTION_DOWNLOAD_EXCEL = "action_checkpoint_report_download_excel";
export const API_CHECKPOINT_REPORT_HOME_PAGE_ACTION_LOAD = "action_checkpoint_report_home_page_load";
export const API_CHECKPOINT_REPORT_ACTION_ALL_DETAILS_DOWNLOAD_EXCEL = "action_checkpoint_report_all_details_download_excel";


// muts
export const API_CHECKPOINT_REPORT_MUTS_SET_REPORT_PARAMS = "muts_checkpoint_report_set_report_params";
export const API_CHECKPOINT_REPORT_MUTS_SET_CUSTOMER_LOCATIONS = "muts_checkpoint_report_set_customer_locations";
export const API_CHECKPOINT_REPORT_MUTS_SET_PAGE_PARAMS = "muts_checkpoint_report_set_page_params";

const state = {
    us_national_accounts: [],
    canadian_common_owners_accounts: [],
    canadian_national_accounts: [],
    preferred_fleet_accounts: [],
    countries_with_states: [
        {
            country: {
                text: null,
                value: null
            },
            states: [
                {
                    text: null,
                    value: null
                }
            ]
        }
    ],
    report_types: [
        {
            text: null,
            value: null
        }
    ],
    survey_by_users: [
        {
            text: null,
            value: null
        }
    ],
    report_params: {
        records_total: null,
        pressure_format: null,
        tread_depth_format: null,
        tiles: {
            total_units: null,
            total_tires: null,
            critical_exceptions: null,
            warning_exceptions: null,
        },
        data: [
            {
                unit_id: null,
                survey_id: null,
                unit_number: null,
                fleet_location: null,
                unit_date: null,
                unit_time: null,
                license_number: null,
                total_tires: null,
                total_tires_read_count: null,
                warning_exceptions: null,
                critical_exceptions: null,
                critical_pressure_count: null,
                critical_tread_depth_count: null,
                critical_tread_mismatch_axle_count: null,
                critical_tread_mismatch_duals_count: null,
                warning_pressure_count: null,
                warning_tread_depth_count: null,
                warning_tread_mismatch_axle_count: null,
                warning_tread_mismatch_duals_count: null,
            }
        ]
    },
    customer_locations: [
        {
            text: null,
            value: null
        }
    ]
};

const getters = {
    getCheckPointReportingReportParams() {
        return state.report_params;
    },
    getCheckPointReportingTableData() {
        return state.report_params.data;
    },
    getCheckPointReportingCustomerLocations() {
        return state.customer_locations;
    },
    getCheckPointReportingUSNationalAccounts() {
        return state.us_national_accounts;
    },
    getCheckPointReportingCanadianCOAccounts() {
        return state.canadian_common_owners_accounts;
    },
    getCheckPointReportingCanadianNationalAccounts() {
        return state.canadian_national_accounts;
    },
    getCheckPointReportingPreferredFleetAccounts() {
        return state.preferred_fleet_accounts;
    },
    getCheckPointReportingCountries() {
        return state.countries_with_states;
    },
};

const actions = {
    [API_CHECKPOINT_REPORT_HOME_PAGE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("checkpoint/get_home_page", context)
                .then(({ data }) => {
                    context.commit(API_CHECKPOINT_REPORT_MUTS_SET_PAGE_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("checkpoint/report", params, context)
                .then(({ data }) => {
                    context.commit(API_CHECKPOINT_REPORT_MUTS_SET_REPORT_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_CUSTOMER_LOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("checkpoint/get_customer_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_CHECKPOINT_REPORT_MUTS_SET_CUSTOMER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_pdf_file_checkpoint_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_CHECKPOINT_REPORT_ACTION_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_excel_file_checkpoint_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },    
    [API_CHECKPOINT_REPORT_ACTION_ALL_DETAILS_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("checkpoint/download_excel_file_checkpoint_all_details_report", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_CHECKPOINT_REPORT_MUTS_SET_PAGE_PARAMS](state, {data}) {
        state.us_national_accounts = data.us_national_accounts;
        state.canadian_common_owners_accounts = data.canadian_common_owners_accounts;
        state.canadian_national_accounts = data.canadian_national_accounts;
        state.preferred_fleet_accounts = data.preferred_fleet_accounts;
        state.countries_with_states = data.countries_with_states;
        state.report_types = data.report_types;
        state.survey_by_users = data.survey_by_users;
    },
    [API_CHECKPOINT_REPORT_MUTS_SET_REPORT_PARAMS](state, {data}) {
        state.report_params.records_total = data.records_total;
        state.report_params.pressure_format = data.pressure_format;
        state.report_params.tread_depth_format = data.tread_depth_format;
        state.report_params.tiles = data.tiles;
        state.report_params.data = data.data;
        
    },
    [API_CHECKPOINT_REPORT_MUTS_SET_CUSTOMER_LOCATIONS](state, {data}) {
        state.customer_locations = data.customer_locations;
        
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};

