import ApiService from "@/core/services/api.service";

// action types
export const API_BRAND_SEARCH = "action_brand_search";
export const API_PRODUCT_LINE_SEARCH = "action_product_line_search";
export const API_LOAD_RANGE_SEARCH = "action_load_range_search";
export const API_TIRE_SIZE_SEARCH = "action_tire_size_search";
export const API_VOCATION_SEARCH = "action_vocation_search";
export const API_WHEEL_POSITION_SEARCH = "action_wheel_position_search";
export const API_AXEL_CONFIGURATION_SEARCH = "action_axel_configuration_search";
export const API_RETREAD_SEARCH = "action_axel_retread_search";
export const API_GET_AVAILABLE_TIRES = "action_get_available_tires";
export const API_GET_ALL_FILTERS = "action_get_all_filters";

// muts
export const API_MUTS_SET_BRANDS = "muts_set_brands";
export const API_MUTS_SET_PRODUCT_LINE = "muts_set_product_line";
export const API_MUTS_SET_TIRE_SIZE = "muts_set_tire_size";
export const API_MUTS_SET_LOAD_RANGE = "muts_set_load_range";
export const API_MUTS_SET_WHEEL_POSITION = "muts_set_wheel_position";
export const API_MUTS_SET_AXEL_CONFIGURATION = "muts_set_axel_configuration";
export const API_MUTS_SET_VOCATION = "muts_set_vocation";
export const API_MUTS_SET_RETREAD = "muts_set_retread";
export const API_MUTS_SET_AVAILABLE_TIRES = "must_set_available_tires";
export const API_MUTS_SET_ALL_FILTERS = "muts_set_all_filters";



const state = {
    tire_sizes: [],
    brands: [],
    product_lines: [],
    load_ranges: [],
    vocations: [],
    wheel_positions: [],
    axel_configurations: [],
    retreads: [],
    available_tires: null,
}

const getters = {
    getBrands() {
        return state.brands;
    },
    getProductLines() {
        return state.product_lines;
    },
    getTireSize() {
        return state.tire_sizes;
    },
    getVocations() {
        return state.vocations;
    },
    getLoadRanges() {
        return state.load_ranges;
    },
    getWheelPosition() {
        return state.wheel_positions;
    },
    getAxelConfiguration() {
        return state.axel_configurations;
    },
    getRetread() {
        return state.retreads;
    },
    getAvailableTires() {
        return state.available_tires;
    }
}

const actions = {
    [API_BRAND_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_brand", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_BRANDS, data.search_result);
                    resolve( data.search_result);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_RETREAD_SEARCH](context, params) {
        return new Promise((resolve, reject) => {

            ApiService.post("product_wear_rate_report/get_retread", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_RETREAD, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PRODUCT_LINE_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_product_line", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_PRODUCT_LINE, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_TIRE_SIZE_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_tire_size", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_TIRE_SIZE, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_WHEEL_POSITION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_wheel_position", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_WHEEL_POSITION, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_AXEL_CONFIGURATION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_axel_configuration", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_AXEL_CONFIGURATION, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LOAD_RANGE_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_load_range", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_LOAD_RANGE, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_VOCATION_SEARCH](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/search_vocation", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_VOCATION, data.search_result);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_GET_AVAILABLE_TIRES](context, params) {
        let cancelTokenSource = params.cancelTokenSource;
        delete params.cancelTokenSource;
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/get_available_tires", params, context, cancelTokenSource)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_AVAILABLE_TIRES, data.tire_count);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_GET_ALL_FILTERS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/get_all_filters", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_ALL_FILTERS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    }
}

const mutations = {
    [API_MUTS_SET_BRANDS](state, data) {
        state.brands = data;
    },
    [API_MUTS_SET_PRODUCT_LINE](state, data) {
        state.product_lines = data;
    },
    [API_MUTS_SET_RETREAD](state, data) {
        state.retreads = data;
    },
    [API_MUTS_SET_TIRE_SIZE](state, data) {
        state.tire_sizes = data;
    },
    [API_MUTS_SET_PRODUCT_LINE](state, data) {
        state.product_lines = data;
    },
    [API_MUTS_SET_LOAD_RANGE](state, data) {
        state.load_ranges = data;
    },
    [API_MUTS_SET_WHEEL_POSITION](state, data) {
        state.wheel_positions = data;
    },
    [API_MUTS_SET_AXEL_CONFIGURATION](state, data) {
        state.axel_configurations = data;
    },
    [API_MUTS_SET_VOCATION](state, data) {
        state.vocations= data;
    },
    [API_MUTS_SET_AVAILABLE_TIRES](state, data) {
        state.available_tires= data;
    },
    [API_MUTS_SET_ALL_FILTERS](state, data) {
        state.brands = data.brands;
        state.product_lines = data.productLines;
        state.load_ranges = data.loadRanges;
        state.retreads = data.retreads;
        state.tire_sizes = data.tireSizes;
        state.vocations = data.vocations;
        state.wheel_positions = data.wheelPositions;
        state.axel_configurations = data.axelConfigurations;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
