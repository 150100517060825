import ApiService from "@/core/services/api.service";

// action types
export const API_TIRE_TYPES_LOAD = "action_tire_types_load";
export const API_BRANDS_LOAD = "action_brands_load";
export const API_PRODUCT_LINES_LOAD = "action_product_lines_load";
export const API_TIRE_SIZES_LOAD = "action_tire_sizes_load";
export const API_VOCATIONS_LOAD = "action_vocations_load";


// muts
export const API_MUTS_SET_TIRE_TYPES = "muts_set_tire_types";
export const API_MUTS_SET_BRANDS = "muts_set_brands";
export const API_MUTS_SET_PRODUCT_LINES = "muts_set_product_lines";
export const API_MUTS_SET_TIRE_SIZES = "muts_set_tire_sizes";
export const API_MUTS_SET_VOCATIONS = "muts_set_vocations";

const state = {
    tire_types: [],
    brands: [],
    product_lines: [],
    sizes: [],
    load_ranges: [],
    vocations: []
}

const getters = {
    getProductTireTypes() {
        return state.tire_types;
    },
    getProductBrands() {
        return state.brands;
    },
    getProductProductLines() {
        return state.product_lines;
    },
    getProductSizes() {
        return state.sizes;
    },
    getProductVocations() {
        return state.vocations;
    }
}

const actions = {
    [API_TIRE_TYPES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_tire_types", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_TIRE_TYPES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_BRANDS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_brands", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_BRANDS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PRODUCT_LINES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_product_lines", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_PRODUCT_LINES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_TIRE_SIZES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_tire_sizes", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_TIRE_SIZES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {
    [API_MUTS_SET_TIRE_TYPES](state, data) {
        state.tire_types = data.types;
    },
    [API_MUTS_SET_BRANDS](state, data) {
        state.brands = data.brands;
    },
    [API_MUTS_SET_PRODUCT_LINES](state, data) {
        state.product_lines = data.product_lines;
    },
    [API_MUTS_SET_TIRE_SIZES](state, data) {
        state.sizes = data.sizes;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
