import ApiService from "@/core/services/api.service";

// action types
export const API_FCR_REPORT_GENERATE = "action_fcr_report_load";
export const API_FCR_GENERATED_REPORT_LOAD = "action_fcr_generated_report_load";
export const API_FCR_GENERATED_WEB_REPORT_LOAD = "action_fcr_generated_web_report_load";
export const API_FCR_REPORTS_LOAD = "action_fcr_reports_load";
export const API_FCR_REPORT_TO_PDF_LOAD = "action_fcr_report_to_pdf_load";
export const API_FCR_REPORT_TO_EXCEL_LOAD = "action_fcr_report_to_excel_load";


// muts
export const API_FCR_MUTS_SET_REPORT = "muts_fcr_set_report";
export const API_FCR_MUTS_SET_REPORTS = "muts_fcr_set_reports";

const state = {
    fleet_tires: {
        AccountTireReportsData: [],
        ImagesData: [],
        StatisticData: [],
        AccountData: null,
        SurveyData: [],
        CoverPage: [],
        GraphPageHeader: null,
        FleetConditionalData: null,
        MultipleGraphs: [],
        PotentialLossData: null,
        UnitsData: null,
        SurveyData: null,
        OwnerData: null,
        SpecInformationData: null,
        Comment: "",
        ReportName: "",
    },
    reports: []
}

const getters = {
    getFCRReportImages() {
        return state.fleet_tires.ImagesData;
    },
    getFCRReportStatistics() {
        return state.fleet_tires.StatisticData;
    },
    getFCRReportAccountData() {
        return state.fleet_tires.AccountData;
    },
    getFCRReportSurveyData() {
        return state.fleet_tires.SurveyData;
    },
    getFCRReportCoverPage() {
        return state.fleet_tires.CoverPage;
    },
    getFCRReportGraphHead() {
        return state.fleet_tires.GraphPageHeader;
    },
    getFCRReportFleetConditionalData() {
        return state.fleet_tires.FleetConditionalData;
    },
    getFCRReportAccountTireReportsData() {
        return state.fleet_tires.AccountTireReportsData;
    },
    getFCRReportMultipleGraphs() {
        return state.fleet_tires.MultipleGraphs;
    },
    getFCRReportPotentialLossData() {
        return state.fleet_tires.PotentialLossData;
    },
    getFCRReportUnitsData() {
        return state.fleet_tires.UnitsData;
    },
    getFCRReportSurveyData() {
        return state.fleet_tires.SurveyData;
    },
    getFCRReportOwnerData() {
        return state.fleet_tires.OwnerData;
    },
    getFCRReportSpecInformationData() {
        return state.fleet_tires.SpecInformationData;
    },
    getFCRPrevGeneratedReports() {
        return state.reports;
    },
    getFCRReportName() {
        return state.fleet_tires.ReportName;
    },
    getFCRReportComment() {
        return state.fleet_tires.Comment;
    }
}

const actions = {
    [API_FCR_REPORT_GENERATE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/generate_reports", params, context)
                .then(({ data }) => {
                    // context.commit(API_FCR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_REPORTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_conditions/get_reports", context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_REPORTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_GENERATED_WEB_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/get_report", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_GENERATED_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_conditions/get_report", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_REPORT_TO_PDF_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_conditions/get_pdf_report", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_REPORT_TO_EXCEL_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("fleet_conditions/get_excel_report", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_FCR_MUTS_SET_REPORT](state, {data}) {
        state.fleet_tires.AccountTireReportsData = data.fleet_tires.AccountTireReportsData;
        state.fleet_tires.ImagesData = data.fleet_tires.ImagesData;
        state.fleet_tires.StatisticData = data.fleet_tires.StatisticData;
        state.fleet_tires.MultipleGraphs = data.fleet_tires.MultipleGraphs;
        state.fleet_tires.PotentialLossData = data.fleet_tires.PotentialLossData;
        state.fleet_tires.AccountData = data.fleet_tires.AccountData;
        state.fleet_tires.UnitsData = data.fleet_tires.UnitsData;
        state.fleet_tires.SurveyData = data.fleet_tires.SurveyData;
        state.fleet_tires.OwnerData = data.fleet_tires.OwnerData;
        state.fleet_tires.FleetConditionalData = data.fleet_tires.FleetConditionalData;
        state.fleet_tires.CoverPage = data.fleet_tires.CoverPage;
        state.fleet_tires.GraphPageHeader = data.fleet_tires.GraphPageHeader;
        state.fleet_tires.SpecInformationData = data.fleet_tires.SpecInformationData;
        state.fleet_tires.Comment = data.fleet_tires.Comment;
        state.fleet_tires.ReportName = data.fleet_tires.ReportName;
    },
    [API_FCR_MUTS_SET_REPORTS](state, {data}) {
        state.reports = data.reports;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};