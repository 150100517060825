import ApiService from "@/core/services/api.service";

// action types
export const API_FAVORITE_REPORTS_ACTION_SAVE = "action_favorite_reports_save";

// muts

const state = {
    
}

const getters = { 
    
}

const actions = {
    [API_FAVORITE_REPORTS_ACTION_SAVE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(process.env.VUE_APP_PORTAL_WEB_API + "/" + "favorite_reports/save", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {
    
}

export default {
    state,
    actions,
    mutations,
    getters
};