import ApiService from "@/core/services/api.service";

// action types
export const API_GET_REPORT = "action_get_report";
export const API_DOWNLOAD_XML = "action_download_xml";
export const API_DOWNLOAD_PDF = "action_download_pdf";

// muts
export const API_MUTS_SET_REPORT = "muts_set_report";
export const API_MUTS_SET_FILE = "muts_set_file";

const state = {
    wear_rate_data: [],
    tire_count: 1,
    page_info: {
        page_number: 1, 
        page_size: 10, 
        order_by: 0, 
        is_sort_desc: false
    },
    file: null
}

const getters = {
    getResultTableData(){
        return state.wear_rate_data;
    },
    getPageInfo(){
        return state.page_info
    },
    getReportResultFile(){
        return state.file
    }
}

const actions = {
    [API_GET_REPORT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_wear_rate_report/get_product_wear_rates", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_REPORT, data.product_wear_rates);
                    resolve( data.product_wear_rates);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DOWNLOAD_XML](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("product_wear_rate_report/get_product_wear_rates_report_xls", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_FILE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("product_wear_rate_report/get_product_wear_rates_report_pdf", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_SET_FILE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = { 
    [API_MUTS_SET_REPORT](state, data) {
        state.wear_rate_data = data;
    },
    [API_MUTS_SET_FILE](state, data) {
        state.file = data;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
