import { Report } from "../../../../scripts/reports/Report";

import { 
    filterFieldsByServiceType,
    getLinkToHeatMap,
} from "./ServiceReportHelper";

import {
    TAB_TYPES,
    TableFields,
    LOAD_PARTS_ORDER,
} from "@/utils/constants/FleetDashboard.constants.js"

class CallsByStateReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.SERVICE}, settings, models, loader);
        
        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.heat_maps) {
            this.heatMapImage = data?.heat_maps[0];
        }
        
        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.isIncludeNatAvg = null;
        this.tableFields = TableFields[this.reportType];
        this.loadOrder = LOAD_PARTS_ORDER.FULL;
    }

    tableItems = [];
    heatMapImage = null;

    isTableSelectable = false;
    tableFields = [];

    getFilteredFields() {
        if(!this.tableFields) return [];

        return filterFieldsByServiceType(this.tableFields, this.settings.serviceTypes);
    };

    getTableItems() {
        return this.tableItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length && this.heatMapImage) return false;

        return true;
    }
    getLinkToHeatMap(id) {
        return getLinkToHeatMap(id);
    }

}

export {
    CallsByStateReport
}