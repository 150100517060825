import { isObject } from "./CommonHelper";

export const range = (start, end) => {
    return new Array(end - start + 1).fill().map((d, i) => i + start);
}
export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } 
        else {
            collection.push(item);
        }
    });
    
    return map;
}

export const fill = (source, length) => {
    if(!length) return [];
    return new Array(length - 1).fill().map(_ => source);
}
export const sortStringArrayAsc = (key) => {
    return function(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
    }
};
export const verifyExactly = (arr, verified) => {
    return arr.filter(x => verified.includes(x));
}
export const verify = (arr, verified) => {
    let arrStringed = arr.map(x => isObject(x) ? JSON.stringify(x) : x.toString());
    let verifiedStringed = verified.map(x => isObject(x) ? JSON.stringify(x) : x.toString())
    return verifyExactly(arrStringed, verifiedStringed);
}
export const toArray = (arr = []) => {
    let copy = [];
    if(!Array.isArray(arr)) {
        copy = [arr];
    }
    else {
        copy = [...arr];
    }

    return copy;
}
