import ApiService from "@/core/services/api.service";

// action types
export const API_PAR_REPORT_GENERATE = "action_par_report_load";
export const API_PAR_GENERATED_REPORT_LOAD = "action_par_generated_report_load";
export const API_PAR_GENERATED_WEB_REPORT_LOAD = "action_par_generated_web_report_load";
export const API_PAR_REPORTS_LOAD = "action_par_reports_load";

export const API_PAR_GET_REPORT = "action_par_get_report";
export const API_PAR_GET_REPORT_TO_PDF = "action_par_get_report_to_pdf";
export const API_PAR_GET_REPORT_TO_EXCEL = "action_par_get_report_to_excel";
export const API_PAR_HAS_SYSTEM_LEVEL_ACCESS = "action_par_has_system_level_access";

// muts
export const API_PAR_MUTS_SET_REPORT = "muts_par_set_report";
export const API_PAR_MUTS_SET_REPORTS = "muts_par_set_reports";
export const API_PAR_MUTS_HAS_SYSTEM_LEVEL_ACCESS = "muts_par_has_system_level_access"

const state = {
    CoverPage: null,
    AccountData: null,
    GraphPageHeader: null,
    ProductData: null,
    AnotherProductData: null,
    Comments: null,
    HasSystemLevelAccess: false,
    report_name: null,

    reports: []
}

const getters = {
    getPARCoverPage() {
        return state.CoverPage;
    },
    getPARAccountData() {
        return state.AccountData;
    },
    getPARGraphPageHeader() {
        return state.GraphPageHeader;
    },
    getPARProductData() {
        return state.ProductData;
    },
    getPARAnotherProductData() {
        return state.AnotherProductData;
    },
    getPARComments() {
        return state.Comments;
    },
    getPARPrevGeneratedReports() {
        return state.reports;
    },
    getSARReportName() {
        return state.report_name;
    },
    hasSystemLevelAccess() {
        return state.HasSystemLevelAccess;
    },
}

const actions = {

    [API_PAR_GET_REPORT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_product_report", params, context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_GET_REPORT_TO_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("product_analysis_report/get_product_report_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_GET_REPORT_TO_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("product_analysis_report/get_product_report_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_REPORT_GENERATE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/generate_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_REPORTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("product_analysis_report/get_reports", context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_REPORTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_GENERATED_WEB_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_report", params, context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_GENERATED_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("product_analysis_report/get_report", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    
    
    [API_PAR_HAS_SYSTEM_LEVEL_ACCESS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("product_analysis_report/has_system_level_access", context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_HAS_SYSTEM_LEVEL_ACCESS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}


const mutations = {
    [API_PAR_MUTS_SET_REPORT](state, {data}) {
        state.CoverPage = data.product_comp_model.CoverPage;
        state.AccountData = data.product_comp_model.AccountData;
        state.GraphPageHeader = data.product_comp_model.GraphPageHeader;
        state.ProductData = data.product_comp_model.ProductReportsData[0];
        state.AnotherProductData = data.product_comp_model.ProductReportsData[1];
        state.Comments = data.product_comp_model.Comment;
        state.report_name = data.report_name;
    },
    [API_PAR_MUTS_SET_REPORTS](state, {data}) {
        state.reports = data.reports;
    },
    [API_PAR_MUTS_HAS_SYSTEM_LEVEL_ACCESS](state, {data}) {
        state.HasSystemLevelAccess = data.has_access;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};