import { 
    SELECT_FILTER_STEP 
} from "@/utils/constants/FleetDashboard.constants";

class Tab {

    constructor({title, switchTextLeft, switchTextRight, checkboxLabel, fields, sortEnum}) {
        this.title = title;
        this.switchTextRight = switchTextRight;
        this.switchTextLeft = switchTextLeft;
        this.checkboxLabel = checkboxLabel;
        this.fields = fields;
        this.sortEnum = sortEnum;
    }
    currentPage = 1;
    perPage = 10;
    orderBy = 1;
    isSortDesc = false;
    keyword = "";
    selectedIDs = [];
    selectedLocations = [];
    switchTextRight = "";
    switchTextLeft = "";
    isAll = true;
    title = "";
    checkboxLabel = "";
    table = {};
    fields = [];
    sortEnum = [];
}

class Filters {

    title = SELECT_FILTER_STEP.TITLE;
    number = SELECT_FILTER_STEP.NUMBER;

    serviceTypes= {
        emergency: true,
        scheduled: true,
        stock: true
    };
    unitLocations= {
        roadside: true,
        yard: true,
        shop: true
    };
    wheelPositions= {
        steer: true,
        drive: true,
        trail: true,
        allpositions: true,
        unknown:true,
    };
    status= {
        retread: true,
        used: true,
        rejected: true
    };

    saved = {};

    isOpen = true;
    tabType = 0;
    selectedInnerTab = 0;

}

export {
    Filters,
    Tab
}