import { COLORS } from "@/utils/constants/FleetDashboard.constants"

class ChartConfig {
    constructor() {}
    
    data = {
        labels: [],
        datasets: [],
    }
}

export {
    ChartConfig
}