import { ChartConfig } from "..";

import { Dataset } from "./Dataset";

import {
    GRAPH_POINTS_TYPE
}
from "@/utils/constants/FleetDashboard.constants.js"

import { groupBy } from "@/utils/helpers/ArrayHelper";

class PieChartConfigOptions {
    layout = {
        padding: {
            left: 20,
            right: 20,
            top: 50,
            bottom: 100
        }
    };
    scales = {
        xAxes: [{
            display: false
        }],
        yAxes: [{
            display: false
        }]

    };
    legend = {
        display: false
    };
    tooltips  = {
        enabled: false
    }
    zoomOutPercentage = 55;
    rotation = 0;
    
}

class PieChartConfig extends ChartConfig{
    constructor(current) {
        super();

        var currentDataSets = [];

        if(current) {
            var datasets = groupBy(current.points, point => point["datasetId"]);
            var i = 1;
            datasets.forEach((items, label) => {
                var dataset = new Dataset({
                    label: label,
                    color: items[0]?.color,
                    includeOutlabels: true,
                    outlabels: current.outlabels,
                });
                dataset.data = items.map(item => item.dataItem);
                var maxData = Math.max(...dataset.data);
                var maxDataIndex = dataset.data.indexOf(maxData);
                dataset.displacements = new Array(dataset.data.length).fill(0);
                dataset.displacements[maxDataIndex] = 20;
                dataset.items = items;
                dataset.backgroundColor = items.map(i => i.color);
                dataset.borderColor = items.map(i => i.color);
                currentDataSets.push(dataset);
                i++;
            });
        }


        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets)
        }

        this.data.labels = current?.labels || [];

        
    }
    
    type = "cutOutPie";

    options = new PieChartConfigOptions();
}

export {
    PieChartConfig
}