import ApiService from "@/core/services/api.service";
const UH = "unit_history_report/";

export const API_UH_GET_ACCOUNTS = "action_uh_get_accounts";
export const API_UH_GET_UNIT_NUMBERS = "action_uh_get_unit_numbers";
export const API_UH_GET_REPORT = "action_uh_get_report";
export const API_UH_GET_PDF_FILE = "action_uh_get_pdf_file";
export const API_UH_GET_EXCEL_FILE = "action_uh_get_excel_file";
export const API_UH_GET_ALL_DATA_FILE = "action_uh_get_alL_data_file";
export const API_UH_GET_ATTACHMENTS = "action_uh_get_attachments";
export const API_UH_GET_ATTACHMENT = "action_uh_get_attachment";
export const API_UH_GENERATE_ATTACHMENT = "action_uh_generate_attachment";

export const API_UH_SET_ACCOUNTS = "muts_uh_set_accounts";
export const API_UH_SET_UNIT_NUMBERS = "muts_uh_set_unit_numbers";
export const API_UH_SET_REPORT = "muts_uh_set_report";
export const API_MUTS_UH_SET_FILE = "muts_uh_set_file";
export const API_MUTS_UH_SET_ATTACHMENTS = "muts_uh_set_attachments";

const state = {
    us_national_accounts: [], 
    canadian_common_owners: [], 
    canadian_nonsigs: [],
    unit_numbers: [],
    result_table: [],
    total_items: 0,
    page_info: {
        page_number: 1,
        page_size: 20
    },
    file: null,
    attachments: [],
}

const getters = {
    getUHUSNationalAccounts() {
        return state.us_national_accounts;
    },
    getUHCanadianCommonOwners() {
        return state.canadian_common_owners;
    },
    getUHUSCanadianNonsigs() {
        return state.canadian_nonsigs;
    },
    getUHUnitNumbers() {
        return state.unit_numbers;
    },
    getUHResultTable() {
        return state.result_table;
    },
    getUHTotalItems() {
        return state.total_items;
    },
    getUHPageInfo() {
        return state.page_info;
    },
    getUHFileToDownload() {
        return state.file;
    },
    getUHGeneratedAttachments() {
        return state.attachments;
    },
}

const actions = {
    [API_UH_GET_EXCEL_FILE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob(UH + "download_excel", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_UH_SET_FILE, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_PDF_FILE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob(UH + "download_pdf", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_UH_SET_FILE, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_ALL_DATA_FILE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob(UH + "download_all_details", params, context)
                .then(({ data }) => {
                    context.commit(API_MUTS_UH_SET_FILE, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_ACCOUNTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(UH + "get_accounts", context)
                .then(({ data }) => {
                    context.commit(API_UH_SET_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_UNIT_NUMBERS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(UH + "get_unit_numbers", params.body, context, params.cancelToken)
                .then(({ data }) => {
                    context.commit(API_UH_SET_UNIT_NUMBERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    // context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_REPORT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(UH + "get_unit_history_report", params.body, context, params.cancelToken)
                .then(({ data }) => {
                    context.commit(API_UH_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    // context.commit(API_FS_SET_STATUS_ERROR, response);
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_ATTACHMENTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(UH + "get_reports", context)
                .then(({ data }) => {
                    context.commit(API_MUTS_UH_SET_ATTACHMENTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GET_ATTACHMENT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob(UH + "get_report", params, context)
                .then((obj) => {
                    resolve(obj);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
    [API_UH_GENERATE_ATTACHMENT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(UH + "generate_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response || {});
                });
        });
    },
}

const mutations = {
    [API_UH_SET_ACCOUNTS](state, {data}) {
        state.us_national_accounts = data.us_national_accounts || [];
        state.canadian_common_owners = data.canadian_common_owners || [];
        state.canadian_nonsigs = data.canadian_nonsigs || [];  
    },
    [API_UH_SET_UNIT_NUMBERS](state, {data}) {
        state.unit_numbers = data.unit_numbers || [];
    },
    [API_UH_SET_REPORT](state, {data}) {
        state.result_table = data.unit_history_report_data || [];
        state.page_info = {
            page_number: data.page_number, 
            page_size: data.page_size}
        state.total_items = data.total_items || 0;
    },
    [API_MUTS_UH_SET_FILE](state, {data}) {
        state.file = data.file;
    },
    [API_MUTS_UH_SET_ATTACHMENTS](state, {data}) {
        state.attachments = data.reports;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};