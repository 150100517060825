import ApiService from "@/core/services/api.service";

// action types
export const API_LEASESALES_CONFIGURATOR_ACTION_LOAD = "action_leasesales_configurator_load";
export const API_LEASESALES_CONFIGURATOR_SUBMIT_ACTION_LOAD = "action_leasesales_configurator_submit_load";
export const API_LEASESALES_CONFIGURATOR_STRUCTURE_ACTION_LOAD = "action_leasesales_configurator_structure_load";
export const API_LEASESALES_CONFIGURATOR_DOWNLOAD_PDF_ACTION_LOAD = "action_leasesales_configurator_download_pdf_load";
export const API_LEASESALES_CONFIGURATOR_DOWNLOAD_EXCEL_ACTION_LOAD = "action_leasesales_configurator_download_excel_load";
export const API_LEASESALES_GET_GEOCODE_ACTION = "action_leasesales_get_geocode";

// muts
export const API_LEASESALES_SET_CONFIGURATOR_STRUCTURE = "muts_leasesales_set_configurator_structure";

const state = {
    structure: {
        us_national_accounts: [{ value: null, text: null }],
        canadian_common_owners_accounts: [{ value: null, text: null }],
        fssm_names: [{ value: null, text: null, is_selected: false }],
        regions: [{ value: null, text: null, is_selected: false }],
        fleet_types: [{ value: null, text: null }],
        lease_terms: [{ value: null, text: null }],
        fleet_sizes: [{ value: null, text: null, rate: 0, color: 0 }],
        precipitations: [{ value: null, text: null, rate: 0, color: 0 }],
        yard_slopes: [{ value: null, text: null, rate: 0, color: 0 }],
        yard_surfaces: [{ value: null, text: null, rate: 0, color: 0 }],
        vehicle_crossing_frequencies: [{ value: null, text: null, rate: 0, color: 0 }],
        traffic_flows: [{ value: null, text: null, rate: 0, color: 0 }],
        pressure_specifications: [{ value: null, text: null, rate: 0, color: 0 }],
        vehicle_loads: [{ value: null, text: null, rate: 0, color: 0 }],
        tread_patterns: [{ value: null, text: null, rate: 0, color: 0 }],
        cleanings: [{ value: null, text: null, rate: 0, color: 0 }],
        fleet_list_managements: [{ value: null, text: null, rate: 0, color: 0 }],
        multi_unit_trailer_configurations: [{ value: null, text: null, rate: 0, color: 0 }],

        country_states: [{ country: null, states: [{ value: null, text: null }] }],
    },
};

const getters = {
    getLeaseSalesConfiguratorStructure() {
        return state.structure;
    },
    getLeaseSalesConfiguratorCountries() {
        return state.structure.country_states;
    },
    getLeaseSalesConfiguratorUSNatAccounts() {
        return state.structure.us_national_accounts;
    },
    getLeaseSalesConfiguratorCanComOwnAccounts() {
        return state.structure.canadian_common_owners_accounts;
    },
    getLeaseSalesConfiguratorFSSMNames() {
        return state.structure.fssm_names;
    },
    getLeaseSalesConfiguratorRegions() {
        return state.structure.regions;
    },
};

const actions = {
    [API_LEASESALES_CONFIGURATOR_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("leasesales/configurator", params, context)
                .then(({ data }) => {

                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LEASESALES_CONFIGURATOR_STRUCTURE_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("leasesales/configurator_structure", params, context)
                .then(({ data }) => {
                    context.commit(API_LEASESALES_SET_CONFIGURATOR_STRUCTURE, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LEASESALES_CONFIGURATOR_SUBMIT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("leasesales/configurator_submit", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LEASESALES_CONFIGURATOR_DOWNLOAD_PDF_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("leasesales/configurator_download_pdf_file", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LEASESALES_CONFIGURATOR_DOWNLOAD_EXCEL_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("leasesales/configurator_download_excel_file", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LEASESALES_GET_GEOCODE_ACTION](context, params) {
        return new Promise((resolve, reject) => {
            var url = "leasesales/get_geocode?address=" + params.address + "&city=" + params.city + "&state=" + params.state + "&zip=" + params.zip;
            ApiService.get(url, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_LEASESALES_SET_CONFIGURATOR_STRUCTURE](state, data) {
        state.structure.us_national_accounts = data.us_national_accounts;
        state.structure.canadian_common_owners_accounts = data.canadian_common_owners_accounts;
        state.structure.fssm_names = data.fssm_names;
        state.structure.regions = data.regions;
        state.structure.fleet_types = data.fleet_types;      
        state.structure.lease_terms = data.lease_terms;      
        state.structure.fleet_sizes = data.fleet_sizes;     
        state.structure.precipitations = data.precipitations;     
        state.structure.yard_slopes = data.yard_slopes;     
        state.structure.yard_surfaces = data.yard_surfaces;     
        state.structure.vehicle_crossing_frequencies = data.vehicle_crossing_frequencies;     
        state.structure.traffic_flows = data.traffic_flows;     
        state.structure.pressure_specifications = data.pressure_specifications;     
        state.structure.vehicle_loads = data.vehicle_loads;     
        state.structure.tread_patterns = data.tread_patterns;     
        state.structure.cleanings = data.cleanings;     
        state.structure.fleet_list_managements = data.fleet_list_managements; 
        state.structure.multi_unit_trailer_configurations = data.multi_unit_trailer_configurations; 
        
        state.structure.country_states = data.country_states;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};