export const toCommas = (number) => {
    if(!number) return number;

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getRounded = (number = 0, size = 2) => {
    if (Number.isInteger(number)) return number;

    number = Number(number);
    let rounded = number.toFixed(size);
    if (Object.is(rounded, "-0")) return "0";
    return rounded;
}

export const getPercentage = (target, from) => {
    if(!from) return 0;
    return target * 100 / from;
}