import { Report } from "../../../../scripts/reports/Report";

import {
    TAB_TYPES,
    TableFields
} from "@/utils/constants/FleetDashboard.constants.js"

class Top5RetreadersReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.RETREADING}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.tableFields = TableFields[this.reportType];
    }
    
    tableItems = [];
    isTableSelectable = false;
    tableFields = [];

    getFilteredFields() {
        if(!this.tableFields) return [];

        return this.tableFields;
    };

    getTableItems() {
        return this.tableItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length) return false;

        return true;
    };
}

export {
    Top5RetreadersReport
}