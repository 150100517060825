<template>
    <div id="date-picker">
        <b-input-group class="gy-date-time-picker">
            <b-form-input
                id="date-start-input"
                v-model="dateInputComputed"
                type="text"
                placeholder="MM/DD/YYYY"
                autocomplete="off"
                :class="errorMessage ? 'border-danger' : ''"
                @keypress="onKeyPress"
                @paste="onPaste"
                :disabled="disabled"
            ></b-form-input>
            <b-input-group-append>
                <b-form-datepicker
                    v-model="datePickerComputed"
                    button-only
                    value-as-date
                    right
                    :disabled="disabled"
                    aria-controls="date-start-input"
                    :max="max"
                ></b-form-datepicker>
            </b-input-group-append>
        </b-input-group>
        <div class="text-danger" style="font-size: 12px;" v-if="errorMessage">
            {{$trans(errorMessage)}}
        </div>
    </div>
</template>

<script>
// 47 // slash
const allowedCodes = [
    47
]

export default {
    props: {
        isRequired: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        max: {
            type: Date,
            default: null
        },
    },
    data() {
        return {
            errorMessage: "",
            date: null
        }
    },
    computed: {
        dateInputComputed: {
            get() {
                if(this.checkDateRegex(this.value)) {
                    this.date = new Date(this.value);
                }
                
                return this.value;
            },
            set(value) {
                this.updateValue(value);
            }
        },
        datePickerComputed: {
            get() {
                return this.date;
            },
            set(value) {
                this.dateInputComputed = (value.getMonth() + 1) + "/" + value.getDate() + "/" + value.getFullYear();
            }
        }
    },
    methods: {
        updateValue(value) {
            let isInvalid = this.checkDateFormat(value);
            
            if(!isInvalid && value) {
                this.date = new Date(value);
            }
            else {
                this.date = value;
            }

            this.$emit("update:value", {
                value: value,
                error: isInvalid
            });
        },
        checkDateFormat(value) {
            if(!value) {
                if(this.isRequired) {
                    this.errorMessage = this.$trans("Date is required.");
                    return true;
                }
                else {
                    this.errorMessage = "";
                    return false;
                }
            }
    
            if(!value.match(/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/)) {
                this.errorMessage = this.$trans("Please enter date in the correct format");
                return true;
            }

            this.errorMessage = "";
            return false;
        },
        checkDateRegex(dateString) {
            if(dateString === null) return false;

            if(!dateString && !dateString?.length) return true;

            if(!dateString.match(/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/)) {
                return false;
            }

            return true;
        },
        onKeyPress(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;

            let isAllowed = allowedCodes.includes(charCode) || (charCode >= 48 && charCode <= 57);

            if (isAllowed) {
                return true;
            } 
            else {
                e.preventDefault();
            }
        },
        onPaste(e) {

            let text = (e.clipboardData || window.clipboardData).getData('text');
            let isTextCorrect = true;

            for (let i = 0; i < text.length; i++) {
                let charCode = text.charCodeAt(i);
                if (!allowedCodes.includes(charCode) && !(charCode >= 48 && charCode <= 57)) {
                    isTextCorrect = false;
                    break;
                }
            };

            if(!isTextCorrect) {
                e.preventDefault();
            }
        }
    }
}
</script>

<style>

</style>
