import Enum from '@/utils/helpers/EnumHelper.js';


// PRODUCT WEAR RATE REPORT CONSTANTS
export const BRAND = "BRAND";
export const PRODUCT_LINE = "PRODUCT_LINE";
export const TIRE_SIZES = "TIRE_SIZES";
export const VOCATIONS = "VOCATIONS";
export const WHEEL_POSITIONS = "WHEEL_POSITIONS";
export const AXEL_CONFIGURATION = "AXEL_CONFIGURATION";
export const LOAD_RANGE = "LOAD_RANGE";
export const RETREAD = "RETREAD";

// Common 
export const TOP_RECORDS = 100;

// AVAILABLE FILE TYPES
export const FileTypes = Enum({
    XML: 'XML',
    PDF: 'PDF',
    PNG: 'PNG',
    EXCEL: "EXCEL",
    
    MEDIA_XML: 'application/vnd.ms-excel',
    MEDIA_PDF: 'application/pdf',
    MEDIA_PNG: 'image/png'
});

export const UnitOfMeasure = Enum({
    METRIC_BAR: "0",
    MILES: "1",
    METRIC_PSI: "2"
})

export const AVG_DISTANCE_LABEL = Enum({
    METRIC_LABEL: 'Average KM Per MM',
    MILES_LABEL: 'Average Miles Per 32nd'
})

export const TREAD_MAX = Enum({
    METRIC_MAX: 79.38,
    //32nds
    MILES_MAX: 100,
    
})

export const PRESSURE_MAX = Enum({
    METRIC_MAX: 13.79,
    //32nds
    MILES_MAX: 200
})

export const UserGroup = Enum({
    GYR: 'GYR',
    CUS: 'CUS',
    ADMIN: 'ADMIN'
})

// metric constants to calculate mismatching
export const MetricConstants = {
    VALUES: {
        OK: 3.1750000032385,
        WARNING: 4.7625000048578,
        DANGER: 6.3500000064770
    },
    WITH_METRIC_VALUES: {
        OK: 4.00,
        WARNING: 6.00,
        DANGER: 8.00
    }
}

export const COUNT_CONSTANTS = {
    COUNT_1000: 1000,
    COUNT_1500: 1500
}

export const REPORT_TYPE = Enum({
    MANAGE_SPECS: 0,
    FLEET_CONDITION_DASHBOARD: 1,
    PRODUCT_ANALYSIS: 2,
    PRODUCT_WEAR_RATE: 3,
    SCRAP_ANALYSIS: 4, 
    FLEET_SURVEY: 5,
	TIREOPTIX: 6,
    TORQUE_SUMMARY: 7,
    EMPTY:-1,
})

export const REPORT_STORE_CODES = Enum({
    FLT_COND: '0',
    PRD_COMP: '1',
    SCRAP: '2',
    UNT_HSTR: '3',
})

export const SELECT_FLEET_LOCATIONS_STEP = {
    TITLE: "Select Location",
    SYSTEM_TYTLE: "Select Location or System Level",
    NUMBER: 1,
    STATE_KEY: "selectFleetLocationsStep"
}

export const SELECT_TIREOPTIX_FILTERS_STEP = {
    TITLE: "Select Filters",
    NUMBER: 2,
    STATE_KEY: "selectTireOptixFiltersStep"
}

export const ERRORS = {
    UNIQUE_KEY: "VIOLATION OF UNIQUE KEY CONSTRAINT",
}

