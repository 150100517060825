<template>
    <b-form-datepicker
        v-model="__vmodel"
        button-only
        right
        :disabled="disabled"
        ref="btn"
    >
        <template #button-content="{isHovered}">
            <b-icon-calendar-fill v-if="isHovered">

            </b-icon-calendar-fill>
            <b-icon-calendar v-else>
                
            </b-icon-calendar>
        </template>
    </b-form-datepicker>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        disabled: [Boolean, String],
        value: [String]
    },
    computed: {
        __vmodel: {
            get() {
                try {
                    return moment(this.value, 'MM/DD/YYYY').format('YYYY-MM-DD');
                } 
                catch {
                    return moment().format('YYYY-MM-DD');
                }
            },
            set(v) {
                this.$emit("input", moment(v, "YYYY-MM-DD").format("MM/DD/YYYY"));
            }
        },
    }
}
</script>