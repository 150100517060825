<template>
    <div class="modal-mask">
        <div
            :id="_id" 
            :class="['modal-dialog', sizeClass ? sizeClass : '']">
            <div class="modal-content">
                <div class="modal-header" v-if="title">
                    <h4 class="modal-title">{{ $trans(title) }}</h4>
                    <button v-if="isClosable" type="button" class="close" @click="close">x</button>
                </div>
                <div class="modal-body">
                    <p :class="{ 'alert alert-danger': isError, 'alert alert-success': isSuccess }" 
                        v-if="message && _typeOfMessage=='string'"
                    >
                        {{ $trans(message) }}
                    </p>
                    <div v-else-if="message">
                        <p v-for="(msg, key) in message"
                            :key="key"
                        >
                            {{ $trans(msg) }}
                        </p>
                    </div>
                    <slot name="content"></slot>
                    <div v-if="isWaiting" class="gy-spinner-container">    
                        <span class="gy-spinner-icon"></span>    
                        <div class="preloader-wrapper big active">        
                            <div class="spinner-layer spinner-blue-only">            
                                <div class="circle-clipper left">                
                                    <div class="circle">
                                    </div>            
                                </div>
                                <div class="gap-patch">                
                                    <div class="circle">
                                    </div>            
                                </div>
                                <div class="circle-clipper right">                
                                    <div class="circle">
                                    </div>            
                                </div>        
                            </div>    
                        </div>    
                        <br>
                    </div>
                </div>
                <div v-if="$scopedSlots.buttons || $slots.buttons" class="modal-footer">
                    <slot name="buttons" :close="close" :ok="ok"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        message: {
            type: [String, Array]
        },        
        sizeClass: {
            type: String,
            default: "",
        },
        isError: {
            type: Boolean,
            default: false
        },
        isSuccess: {
            type: Boolean,
            default: false
        },
        isClosable: {
            type: Boolean,
            default: true
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isOutsideClickable: {
            type: Boolean,
            default: false
        },
        blockBackdrop: Boolean,
        noBodyOverflow: Boolean,
    },
    data() {
        return {
            numberOfModals: 0
        }
    },
    mounted() {
        window.addEventListener("click", this.checkOutsideClick);
        
        if(this.noBodyOverflow) {
            document.body.classList.add("modal-open");
        }
        this.removeTabIndexFromDocument();
        this.makePopupTabable();

    },
    beforeDestroy() {
        if(this.noBodyOverflow) {
            document.body.classList.remove("modal-open");
        }

        window.removeEventListener("click", this.checkOutsideClick);
    },
    destroyed() {
        this.clearTabIndex();
    },
    methods: {
        close() {
            this.$emit("close");
        },
        ok() {
            this.$emit("ok")
        },
        checkOutsideClick(event) {
            if(this.isOutsideClickable && event.target.className == "modal-mask") {
                this.$emit("close");
            }
        },
        removeTabIndexFromDocument() {
            if(this.blockBackdrop) {
                this.numberOfModals = document.querySelectorAll('.modal-dialog').length;
    
                let dinputs = document.querySelectorAll("input,select,textarea,button,a,button.btn,th,td");
                dinputs.forEach(di => {
                    var tabIndex = di.getAttribute('tabindex');
    
                    let attr = "modal-tabindex-" + this.numberOfModals;
                    di.setAttribute(attr, tabIndex)
                    di.setAttribute('tabindex', -1);
                    
                });
            }
        },
        makePopupTabable() {
            if(this.blockBackdrop) {
                let popup = document.getElementById(this._id);
                if(popup) {
                    let inputs = popup.querySelectorAll('button,input,select,textarea,a,button.dropdown-toggle');
                    inputs.forEach(input => {
                        input.setAttribute('tabindex', 0)
                    });
                }
            }
        },
        clearTabIndex() {
            if(this.blockBackdrop) { 
                let dinputs = document.querySelectorAll("input,select,textarea,button,a,button.btn,th,td");
                dinputs.forEach(di => {
                    let attr = "modal-tabindex-" + this.numberOfModals;
                    var dataIndex = di.getAttribute(attr);
    
                    di.setAttribute('tabindex', dataIndex || 0);
                });
            }
        }
    },
    computed: {
        _id() {
            return "modal__" + this._uid;
        },
        _typeOfMessage() {
            return typeof this.message;
        }

    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /*display: table;*/
    transition: opacity 0.3s ease;
    overflow: auto;
}
.modal-header {
    display: block !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.modal-header .modal-title {
    width: 85%;
    overflow: hidden;
}
.modal-footer {
    display: block !important;
}
.modal-dialog {
    width: auto !important;
}
.modal-dialog.w-100 {
    width: 100%;
    max-width: none;
}
.modal-dialog.w-75 {
    max-width: 75vw;
}
.modal-dialog.w-80 {
    max-width: 80vw;
}
.modal-dialog.w-50 {
    max-width: 50vw;
}
.btn-default {
    padding: 6px 12px;
}
@media (max-width: 576px) {
     
    .modal-dialog {
        padding: 15px 5px;
        max-width: 98vw !important;
        width: auto !important;
        margin: 1.75rem auto;
    }
    .modal-content {
        position: relative;
        left: 0px;
        right: 0;
        top: 0;
        margin: 0;
    }
}
@media (max-width: 768px) {
    .modal-dialog.w-100 {
        max-width: 100vw;
    }
    .modal-dialog.w-75 {
        max-width: 90vw;
    }   
    .modal-dialog.w-80 {
        max-width: 95vw;
    }   
    .modal-dialog.w-50 {
        max-width: 75vw;
    }
    
    .modal-content {
        position: relative;
        left: 0px;
        right: 0;
        top: 0;
        margin: 0;
    }
}
</style>
