import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vSelect from "vue-select";
import Vuelidate from 'vuelidate'
import App from './App.vue';
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import { API_VERIFY_AUTH, API_GYAUTH, API_USER_REDIRECT, API_CHECK_PERMISSIONS } from "@/core/services/api/auth.module";
import { APP_RESET_INTERFACE, APP_GET_LAST_STATE } from "@/core/services/app.state.module";
import { API_TRANSLATIONS_ACTION_LOAD } from "@/core/services/api/translations.module";
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import SlideUpDown from 'vue-slide-up-down'
import VueI18n from 'vue-i18n';
import VueMobileDetection from "vue-mobile-detection";
import Popup from '@/components/Popup.vue';
import NumericInput from '@/components/NumericInput.vue';
import GYSpinner from '@/components/Spinner.vue'
import DatePicker from '@/components/DatePicker.vue'
import GYDatePicker from '@/components/GYDatePicker.vue'
import LittleSpinner from "@/components/LittleSpinner.vue";
import './scripts/ieClosest.module';
import './scripts/ieEvent.module';
import './scripts/ieDetect.module';
import { hasSecurity } from './modules/user.permissions.module';
import { showGoToTMPopup } from "./modules/popup.module";
import { toCommas } from '@/utils/helpers/NumberHelper';

Vue.use(VueI18n)
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCustomTooltip, {
    name: 'VueCustomTooltip',
    color: '#fff',
    background: '#000',
    borderRadius: 6,
    fontWeight: 100
});
Vue.use(VueMobileDetection);


// API service init
ApiService.init();
 
Vue.config.productionTip = true;
Vue.component('slide-up-down', SlideUpDown);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('gy-popup', Popup);
Vue.component("v-select", vSelect);
Vue.component("n-input", NumericInput);
Vue.component("gy-spinner", GYSpinner);
Vue.component("gy-little-spinner", LittleSpinner);
Vue.component("date-picker", DatePicker);
Vue.component("gy-date-picker", GYDatePicker);

var _ = require('lodash'); 

const DEFAULT_TITLE = 'Goodyear Tire Management Portal';
const showIETooLongPopupEvent = new window.CustomEvent('showURLIsTooLong');

router.afterEach((to, from) => {
    if (to.query.hasOwnProperty('user')) {
        history.replaceState("", document.title, window.location.pathname);
    }
  	Vue.nextTick(() => {
		if (to.meta.title != undefined) {
			document.title = DEFAULT_TITLE;
		} 
		else {
			document.title = DEFAULT_TITLE;
		} 
  	});
});

router.beforeEach((to, from, next) => {
  	Promise.all([store.dispatch(APP_GET_LAST_STATE)]);

	if (window.isIE() && location.href.length > 2000) {
		//if URL more then 1kb - show message box

		window.gy_app_is_too_long_request_popup = true;
		document.dispatchEvent(showIETooLongPopupEvent);
	}

	if (to.query.hasOwnProperty('lang')) {
		let selectedLanguage = to.query.lang;
		let i18n = new VueI18n();

		Promise.all([store.dispatch(API_TRANSLATIONS_ACTION_LOAD, selectedLanguage)])
		.then(() => {
			i18n.setLocaleMessage(selectedLanguage, App.components.HeaderMenu.computed.Message);
			i18n.locale = selectedLanguage;
			window.localStorage.setItem("USER_LANGUAGE", selectedLanguage);
			const langChooseEvent = new window.CustomEvent('langChooseEvent');
			document.dispatchEvent(langChooseEvent);
		})
		.catch(()=>{
			// reset config to initial state
			store.dispatch(APP_RESET_INTERFACE);
		}); 
	}

	if (to.query.hasOwnProperty('user')) {
		document.body.classList.add('start');
		document.body.classList.add('loading-app');
		
		Promise.all([store.dispatch(API_GYAUTH, {Token: to.query.user})])
		.then(()=>{
			document.body.classList.remove('start');
			document.body.classList.remove('loading-app');

			let return_url_params = new URLSearchParams();
			let url = to.query;
			for (let index_param in url) {
				if (index_param == 'user' || index_param == 'lang') continue;

				return_url_params.set(index_param, url[index_param]);
			}
			location.search = return_url_params.toString();
		})
		.catch(()=>{
			// reset config to initial state
			store.dispatch(APP_RESET_INTERFACE);

			store.dispatch(API_USER_REDIRECT);
			
			document.body.classList.remove('start');
			document.body.classList.remove('loading-app');
		});
		
	} 
	else if (to.hasOwnProperty('meta') && to.meta.hasOwnProperty('requiresAuth')) {

		if (to.meta.requiresAuth) {
			store.dispatch(API_CHECK_PERMISSIONS, { path: to.path })
			.then((isAccess) => {
				if(to.meta.hasOwnProperty('security') &&  to.meta.security) {

					if(hasSecurity(to.meta.security)) {
						next();
					}
					else {
						showGoToTMPopup();
					}
				}
				else {
					next();
				}
			})
			.catch(()=>{
				if (!window.gy_app_is_too_long_request_popup) {
					// reset config to initial state
				
					store.dispatch(API_USER_REDIRECT);
				}
				
				store.dispatch(APP_RESET_INTERFACE);

				document.body.classList.remove('start');
				document.body.classList.remove('loading-app');
			});
		} 
		else { 
			next(); 
		}
	} 

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

i18nService.initI18n(store)
.then(function(i18n) {
	document.body.classList.remove('start');
	document.body.classList.remove('loading-app');

	Vue.prototype.$numberWithCommas = function (number) {
		return toCommas(number);
	};

	Vue.prototype.$trans = function (binding) {
		if(i18n?.locale == 'en-US') return (binding);

		if (i18n.te(binding.toLowerCase())) {
			return i18n.t(binding.toLowerCase());
		} 
		else {
			return (binding);
		}
	};

	window.app = new Vue({
		router,
		store,
		i18n,
		render: h => h(App)
	}).$mount('#vuejs-app')
})
.catch(()=>{
	// reset config to initial state

	document.getElementById('loading-message').innerHTML = "Could not connect to server.<br />Please try again later.";
	document.body.classList.remove('start');
});