import { Report } from "../../Report";
import { PieChartConfig } from "../../../charts/pie/";

import {
    TAB_TYPES,
    TableFields,
    TireStatus,
} from "@/utils/constants/FleetDashboard.constants.js";
import { TopCasingBrandsConfig } from "../../../charts/pie/casingDurability/TopCasingBrands";
import { ACTIONS } from '@/core/services/api/fleet-dashboard/actions';
import {
    mapSettingOptionsWithDefaults,
    getTopCasingBrands,
} from "./CasingDurabilityReportHelper";
import { TooltipConfig } from "../../../charts/pie/TooltipConfig";
import store from "@/core/services/store";

class Top10CasingBrandsReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.CASING_DURABILITY}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
        }

        if(data?.graphs) {
            this.chartItems = data.graphs["0"];
        }

        const reportType = data.report_type; 
        if(data?.filters?.tire_sizes) {
            !this.settings.tireSizes && (this.settings.tireSizes = mapSettingOptionsWithDefaults(data?.filters.tire_sizes));
            let tireSizes = data.filters.tire_sizes;
            store.dispatch(ACTIONS.SET_TIRE_SIZE_OPTIONS, {tireSizes,reportType});
        }

        this.settings.loadAfter.tireSizes = true;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.settings.isComparePrev = null;
        this.tableFields = TableFields[this.reportType];

        this.isTableSelectable = false;
    }

    tableItems = [];
    tableFields = [];
    

    getChartConfig(id) {
        var current;
        current = getTopCasingBrands(this.chartItems);
        var config = new TopCasingBrandsConfig(current);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };
    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        return this.tableFields;
    };

    getTableItems() {
        return this.tableItems;
    };

    getDisabledAddBtn() {
        return !(this.tableItems.length);
    };

    setSettingsBefore() {
    //    this.settings.tireSizes = null;
    };

    setSettingsAfter() {
    //    !this.settings.tireSizes && (this.settings.tireSizes = mapSettingOptionsWithDefaults(store.getters[GETTERS.GET_TIRE_SIZES]));
    };
}

export {
    Top10CasingBrandsReport 
}