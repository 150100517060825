import Enum from '@/utils/helpers/EnumHelper.js';

const Currencies = Enum({
    DOLLAR: "$"
});

const Signs = {
    PERCENTAGE: '%'
}

export {
    Currencies,
    Signs
}