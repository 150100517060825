import { PieChartConfig } from "..";
import ChartDataOutlabels from 'chartjs-plugin-piechart-outlabels';
class TopCasingBrandsConfig extends PieChartConfig {
    constructor(current, prev) {
        super(current, prev);
    }

    noLegend = true;
    plugins = [ChartDataOutlabels];
    
    
}

export {
    TopCasingBrandsConfig
}