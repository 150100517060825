import ApiService from "@/core/services/api.service";

// action types
export const API_DEALER_SELECTS_DEALERS_LOAD = "action_dealer_selects_dealers_load";
export const API_DEALER_SELECTS_CTSC_REGIONS_LOAD = "action_dealer_selects_ctsc_regions_load";
export const API_DEALER_SELECTS_DEALER_LOCATION_LOAD = "action_dealer_selects_dealer_locations_load";


// muts
export const API_DEALER_SELECTS_MUTS_SET_DEALERS = "muts_dealer_selects_set_dealers";
export const API_DEALER_SELECTS_MUTS_SET_CTSC_REGIONS = "muts_dealer_selects_set_ctsc_regions";
export const API_DEALER_SELECTS_MUTS_SET_DEALER_LOCATIONS = "muts_dealer_selects_set_dealer_locations";

const state = {
    dealers: [],
    dealers_ctsc: [],
    dealers_locations: []
}

const getters = {
    getDealerSelectsDealers() {
        return state.dealers;
    },
    getDealerSelectsCTSCRegions() {
        return state.dealers_ctsc;
    },
    getDealerSelectsDealerLocations() {
        return state.dealers_locations;
    },
}

const actions = {
    [API_DEALER_SELECTS_DEALERS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("scrap_analysis_report/get_dealers", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_SELECTS_MUTS_SET_DEALERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_SELECTS_CTSC_REGIONS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("scrap_analysis_report/get_dealer_ctsc_locations", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_SELECTS_MUTS_SET_CTSC_REGIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_SELECTS_DEALER_LOCATION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_dealers_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_SELECTS_MUTS_SET_DEALER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_DEALER_SELECTS_MUTS_SET_DEALERS](state, {data}) {
        state.dealers = data.dealers;
    },
    [API_DEALER_SELECTS_MUTS_SET_CTSC_REGIONS](state, {data}) {
        state.dealers_ctsc = data.dealers_ctsc;
    },
    [API_DEALER_SELECTS_MUTS_SET_DEALER_LOCATIONS](state, {data}) {
        state.dealers_locations = data.dealers_locations;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
