import {
    COLORS,
    PIE_COLORS,
    CASING_DURABILITY_BARS_COLORS,
    SUMMARY_LABELS,
    COEFFICIENT_MINIMUM,
} from "@/utils/constants/FleetDashboard.constants";

import { DatasetItem as BarDatasetItem} from "../../../charts/bars/Dataset";
import { DatasetItem as PieDatasetItem,
         DatasetOutlabels as PieDatasetOutlabels} from "../../../charts/pie/Dataset";

import { toPercentage } from "@/utils/helpers/StringHelper";
import { toCommas, getRounded } from "@/utils/helpers/NumberHelper";

export function mapSettingOptionsWithDefaults(loadedOptions) {
    if (loadedOptions?.length) {
        let newObjFromOptions = {};
        loadedOptions.forEach(item => {
            newObjFromOptions[item.Value] = true;
        });
        return newObjFromOptions;
    }
    return null;
}


export const getTopCasingBrands = (brands) => {
    if(!brands) return null;

    const getContent = (brand) => {
        return [
            {text: `${brand["Brand"]} - ${toPercentage(getRounded(brand["TirePercentage"]))}`},
            {text: 'Count', value: `${toCommas(brand["Quantity"])}`, fontWeight: 'normal'},
        ]
    }
    let points = [];
    let labels = [];
    let outlabels = new PieDatasetOutlabels({text: "%p"})
    brands.forEach((p, i) => {
        let tooltip = {
            content: getContent(p)
        };
        points.push(new PieDatasetItem({
            datasetId: 'Brand',
            dataItem: p["Quantity"],
            tooltip: tooltip,
            color: PIE_COLORS[i] 
        }));
        labels.push(p['Brand']);
    });
    return {points, labels, outlabels};
}

export const getTopBrandsAvg = (data, tireStatusSetting) => {
    if(!data) return null;

    const colorRetread = COLORS.BLUE;
    const colorUsed = COLORS.GREEN;
    const colorRejected = COLORS.YELLOW;

    let points = [];
    let labels = [];

    // min visible value for bars
    const maxRetreadTiresAvgAge = getTopBrandsMaxPropertyValue(data, 'RetreadTiresAvgAge');
    const maxUsedTiresAvgAge = getTopBrandsMaxPropertyValue(data, 'UsedTiresAvgAge');
    const maxRejectedTiresAvgAge = getTopBrandsMaxPropertyValue(data, 'RejectedTiresAvgAge');    
    const maxYValue = Math.max(maxRetreadTiresAvgAge, maxUsedTiresAvgAge, maxRejectedTiresAvgAge);
    let minYValue = maxYValue * 0.1;

    data.forEach(chartItem => {
        labels.push(chartItem["CasingBrandName"]);
        
        if (tireStatusSetting?.retread) {
            points.push(new BarDatasetItem({
                datasetId: `Retread`,
                dataItem: (chartItem['RetreadTiresAvgAge'] < minYValue && chartItem['RetreadTiresAvgAge'] != 0) ? minYValue : chartItem['RetreadTiresAvgAge'],
                color: colorRetread,
                type: 0,
                legend: { backgroundColor: colorRetread },
                tooltip: {
                    title: chartItem["CasingBrandName"],
                    content: [
                        { text: `Retread`, value: toCommas(chartItem["RetreadTiresAvgAge"]) },
                ],
                    color: colorRetread,
                },
            }));
        }

        if (tireStatusSetting?.used) {
            points.push(new BarDatasetItem({
                datasetId: SUMMARY_LABELS.USED_TIRES,
                dataItem: (chartItem['UsedTiresAvgAge'] < minYValue && chartItem['UsedTiresAvgAge'] != 0) ? minYValue : chartItem['UsedTiresAvgAge'],
                color: colorUsed,
                type: 0,
                legend: { backgroundColor: colorUsed },
                tooltip: {
                    title: chartItem["CasingBrandName"],
                    content: [
                        { text: SUMMARY_LABELS.USED_TIRES, value: toCommas(chartItem["UsedTiresAvgAge"]) },
                    ],
                    color: colorUsed,
                },
            }));
        }

        if (tireStatusSetting?.rejected) {
            points.push(new BarDatasetItem({
                datasetId: `Rejected`,
                dataItem: (chartItem['RejectedTiresAvgAge'] < minYValue && chartItem['RejectedTiresAvgAge'] != 0) ? minYValue : chartItem['RejectedTiresAvgAge'],
                color: colorRejected,
                type: 0,
                legend: { backgroundColor: colorRejected },
                tooltip: {
                    title: chartItem["CasingBrandName"],
                    content: [
                        { text: `Rejected`, value: toCommas(chartItem["RejectedTiresAvgAge"]) },
                    ],
                    color: colorRejected,
                },
            }));
        }

    });

    return {points, labels};
}

export const getAvgPreviousCaps = (data) => {
    if(!data) return null;

    let points = [];
    let labels = [];

    // min visible value for bars
    const maxYValue = getMaxPropertyValueAcrossData(data, 'avg_prev_caps');
    let minYValue = maxYValue * 0.1;
    data.forEach(chartItem => {
        labels.push(chartItem["Position"]);
        
        chartItem.Brands.forEach((brand, index) => {
            points.push(new BarDatasetItem({
                datasetId: brand['casing_brand_name'],
                dataItem: (brand['avg_prev_caps'] < minYValue  && brand['avg_prev_caps'] != 0) ? minYValue : brand['avg_prev_caps'],
                color: CASING_DURABILITY_BARS_COLORS[index],
                type: 0,
                legend: { backgroundColor: CASING_DURABILITY_BARS_COLORS[index] },
                tooltip: {
                    title: brand['position'],
                    content: [
                        { text: brand['casing_brand_name'], value: toCommas(brand['avg_prev_caps']) },
                ],
                    color: CASING_DURABILITY_BARS_COLORS[index],
                },
            }));
        });
    });

    return {points, labels};
}

export const getRejectTireRate = (data) => {
    if(!data) return null;

    let points = [];
    let labels = [];

    // min visible value for bars
    const maxYValue = getMaxPropertyValueAcrossData(data, 'reject_rate_percentage');
    let minYValue = maxYValue / 10;
    data.forEach(chartItem => {
        labels.push(chartItem["Position"]);
        
        chartItem.Brands.forEach((brand, index) => {
            points.push(new BarDatasetItem({
                datasetId: brand['brand'],
                dataItem: (brand['reject_rate_percentage'] < minYValue && brand['reject_rate_percentage'] != 0) ? minYValue : brand['reject_rate_percentage'],
                color: CASING_DURABILITY_BARS_COLORS[index],
                type: 0,
                legend: { backgroundColor: CASING_DURABILITY_BARS_COLORS[index] },
                tooltip: {
                    title: brand['position'],
                    content: [
                        { text: brand['brand'], value: toPercentage(toCommas(brand['reject_rate_percentage'])) },
                ],
                    color: CASING_DURABILITY_BARS_COLORS[index],
                },
            }));
        });
    });

    return {points, labels};
}

export const getCasingSubmitted = (data) => {
    if(!data) return null;

    let points = [];
    let labels = [];

    // min visible value for bars
    const maxYValue = getMaxPropertyValueAcrossData(data, 'casing_submitted');
    let minYValue = maxYValue * 0.01;
    data.forEach(chartItem => {
        labels.push(chartItem["PositionDisplay"]);
        
        chartItem.Brands.forEach((brand, index) => {
            points.push(new BarDatasetItem({
                datasetId: brand['casing_brand_name'],
                dataItem: (brand['casing_submitted'] < minYValue && brand['casing_submitted'] != 0) ? minYValue : brand['casing_submitted'],
                color: CASING_DURABILITY_BARS_COLORS[index],
                type: 0,
                legend: { backgroundColor: CASING_DURABILITY_BARS_COLORS[index] },
                tooltip: {
                    title: brand['position_display'],
                    content: [
                        { text: brand['casing_brand_name'], value: toCommas(brand['casing_submitted']) },
                ],
                    color: CASING_DURABILITY_BARS_COLORS[index],
                },
            }));
        });
    });

    return {points, labels};
}

const getTopBrandsMaxPropertyValue = (data, propertyName) => {
    let maxValue = 0;
  
    data.forEach((chartItem) => {
      maxValue = Math.max(maxValue, chartItem[propertyName]);
    });
  
    return maxValue;
};

const getMaxPropertyValueAcrossData = (data, propertyName) => {
    let maxPropertyValue = 0;
  
    data.forEach((chartItem) => {
      const maxBrandValue = Math.max(...chartItem.Brands.map(brand => brand[propertyName]));
      maxPropertyValue = Math.max(maxPropertyValue, maxBrandValue);
    });
  
    return maxPropertyValue;
};