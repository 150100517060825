import { ChartConfig } from "..";

import { Dataset } from "./Dataset";

import {
    GRAPH_POINTS_TYPE
}
from "@/utils/constants/FleetDashboard.constants.js"

import { groupBy } from "@/utils/helpers/ArrayHelper";

const LINE_TYPE = {
    SOLID: null, 
    DOTTED: [0, 5],
    DASHED: [10, 10]
}

class BarChartConfigOptions {
    layout = {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    };
    scales = {
        xAxes: [
        ],
        yAxes: []

    };
    title = {
        display: true,
        text: 'Units',
        position: "left"
    }

    legendCallback = (chart) => {
        var innerHTML = "";
        var datasets = chart.data.datasets.slice();

        var currents = [];
        var previos = [];
        var nats = [];


        datasets.forEach(ds => {
            if(ds.datasetType == GRAPH_POINTS_TYPE.CURRENT) currents.push(ds);
            if(ds.datasetType == GRAPH_POINTS_TYPE.PREVIOUS) previos.push(ds);
            if(ds.datasetType == GRAPH_POINTS_TYPE.NAT_AVG) nats.push(ds);
        });

        currents.forEach((current, i) => {
            
            innerHTML += `<div class="chart-legends-container">`;

            const currentLegend = currents[i].legend;
            var legendBox = 
                `<div class="legend-canvas-wrapper">
                    <canvas shape='${currentLegend?.shape || ''}' backgroundColor='${currentLegend?.backgroundColor || ''}' patternColor='${currentLegend?.patternColor || ''}'></canvas>
                </div>`;

            var legendText = `<div class="legend-text">${current.label}</div>`
            innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            
            if(previos[i]) {
                const previousLegend = previos[i].legend;
                legendBox = 
                    `<div class="legend-canvas-wrapper">
                        <canvas shape='${previousLegend?.shape || ''}' backgroundColor='${previousLegend?.backgroundColor || ''}' patternColor='${previousLegend?.patternColor || ''}'></canvas>
                    </div>`;
                legendText = `<div class="legend-text">${previos[i].label}</div>`
                innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            }
            if(nats[i]) {
                const natsLegend = nats[i].legend;
                legendBox = 
                    `<div class="legend-canvas-wrapper">
                        <canvas shape='${natsLegend?.shape || ''}' backgroundColor='${natsLegend?.backgroundColor || ''}' patternColor='${natsLegend?.patternColor || ''}'></canvas>
                    </div>`;
                legendText = `<div class="legend-text">${nats[i].label}</div>`
                innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            }


            innerHTML += "</div>"
        });

        return innerHTML;
    };
}

class BarChartConfig extends ChartConfig{
    constructor(current, prev) {
        super();

        this.data.labels = current?.labels || [];
        
        if(prev && this.data.labels.length < prev.labels.length) {
            var start = this.data.labels.length;
            for (let i = start; i < prev.labels.length; i++) {
                this.data.labels[i] = prev.labels[i];
            }
        }

        
    }
    
    type = "bar";

    options = new BarChartConfigOptions();
}

export {
    BarChartConfig
}