export default class EmitModal {
    
    constructor(title, message, isError, isClosable) {
        if(title) this.title = title;
        if(message) this.message = message;
        if(isError) this.isError = isError;
        if(isClosable == false) this.isClosable = isClosable;
    }

    show = false;
    title = "Title";
    message = "Message";

    isError = false;
    isClosable = true;

    leftBtn = null;
    rightBtn = {
        callback: () => this.close(),
        label: "Ok",
    };

    close() {
        this.show = false;
    };

    open() {
        this.show = true;
    };

    setLeftBtn(f, label) {
        if(this.leftBtn) {
            this.leftBtn.callback = f ? f : () => this.close();
            this.leftBtn.label = label ? label : "Close";
        }
        else {
            this.leftBtn = {
                label: label ? label : "Close",
                callback: f ? f : () => this.close()
            }
        }
    };

    setRightBtn(f, label) {
        if(this.rightBtn) {
            this.rightBtn.callback = f ? f : () => this.close();
            this.rightBtn.label = label ? label : "Ok";
        }
        else {
            this.rightBtn = {
                label: label ? label : "Ok",
                callback: f ? f : () => this.close()
            }
        }
    }
}