import Enum from '@/utils/helpers/EnumHelper.js';

const RouteTypes = Enum({
    REPORT: 0,  
    SURVEY: 1,  
    DASHBOARD: 2,
});

const ReportDisplayNames = Enum({
    SCRAP_ANALYSIS: "Scrap Analysis",
    FLEET_CONDITION: "Fleet Condition Dashboard",
    PRODUCT_ANALYSIS: "Product Analysis",
    PRODUCT_WEAR_RATE: "Product Wear Rate",
    UNIT_HISTORY: "Unit History",
    TORQUE_SUMMARY: "Torque Summary"
})

export {
    RouteTypes,
    ReportDisplayNames
}