import { Report } from "../../../../scripts/reports/Report";
import { EmergencyVSScheduledConfig,TooltipEmergencyVsShceduledConfig } from "../../../charts/bars/purchases/EmergencyVSScheduled";

import { 
    getEmergencyVSScheduledItems,
    getEmergencyVSScheduledChart
} from "./PurchaseReportHelper";

import { groupBy } from "@/utils/helpers/ArrayHelper";

import {
    TAB_TYPES,
    TableFields,
    GRAPH_POINTS_TYPE
} from "@/utils/constants/FleetDashboard.constants.js"

class EmergencyVSScheduledReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.PURCHASES}, settings, models, loader);

        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.graphs) {
            this.chartItems = data?.graphs;
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.unitLocations = null;
        this.settings.serviceTypes = null;
        this.settings.isIncludeNatAvg = null;

        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    chartItems = null;
    isTableSelectable = true;
    tableFields = [];

    getChartConfig(id) {
        let current, prev;
        current = getEmergencyVSScheduledChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT, this.models.selectedMonth, this.settings.serviceTypes);
        if(this.settings.isComparePrev) {
            prev = getEmergencyVSScheduledChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS, this.models.selectedMonth, this.settings.serviceTypes);
        }
        
        let config = new EmergencyVSScheduledConfig(current, prev);
        config.options.tooltips = new TooltipEmergencyVsShceduledConfig(id);

        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length && this.chartItems && Object.keys(this.chartItems).length) return false;

        return true;
    }
    
    getFilteredFields() {
        if(!this.tableFields) return [];

        let filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);
        }

        if(this.models.selectedMonth) {
            filtered = filtered.filter(f => !f.showMonth);
        }
        else {
            filtered = filtered.filter(f => !f.showWeek);
        }
        
        return filtered;
    };

    getTableItems() {
        let grouped = groupBy(this.tableItems, week => week["MonthString"]);
        return getEmergencyVSScheduledItems(grouped, this.models.selectedMonth);
    };
}

export {
    EmergencyVSScheduledReport
}