import ApiService from "@/core/services/api.service";


// action types
export const API_FLEET_TRAILER_READINESS_REPORT_ACTION_LOAD = "action_fleet_trailer_readiness_report_load";

// muts 
export const API_FLEET_TRAILER_READINESS_REPORT_MUTS_SET_REPORT_PARAMS = "muts_fleet_trailer_readiness_report_set_report_params";

const state = {
    model: {
        trailer_readiness_units: [
            {
                fields: [
                    {
                        name: null,
                        selected_value: null,
                        notes: null,
                    }
                ],
                unit_number: null,
                passed: null
            }
        ],
        trailers_count: null,
        trailers_passed: null,
        trailers_failed: null,
        account_name: null,
        account_address: null,
        survey_to_display: null,
        survey_update_time_stamp: null

    },
    translation: {
        pairs: [
            {
                key: null,
                value: null
            }
        ]
    }
}

const getters = {
    getFleetTrailerReadinessReportingBoxes() {
        return {
            trailers_count: state.model.trailers_count,
            trailers_passed: state.model.trailers_passed,
            trailers_failed: state.model.trailers_failed,
            account_name: state.model.account_name,
            account_address: state.model.account_address,
            survey_update_time_stamp: state.model.survey_update_time_stamp,
        }
    },
    getFleetTrailerReadinessReportingId() {
        return state.model.survey_to_display;
    },
    getFleetTrailerReadinessReportingTableData() {
        return state.model.trailer_readiness_units ? state.model.trailer_readiness_units : null;
    },
    getFleetTrailerReadinessReportingUnitInfo() {
        return {
            unit_number: state.model.trailer_readiness_units[0].unit_number,
            passed: state.model.trailer_readiness_units[0].passed
        }
    },
    getFleetTrailerReadinessReportingTranslations() {
        return state.translation.pairs
    }

};

const actions = {
    [API_FLEET_TRAILER_READINESS_REPORT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("survey/get_trailer_readiness_report/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FLEET_TRAILER_READINESS_REPORT_MUTS_SET_REPORT_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_FLEET_TRAILER_READINESS_REPORT_MUTS_SET_REPORT_PARAMS](state, {data}) {
        state.model = data.model;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};