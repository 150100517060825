import { Report } from "../../../../scripts/reports/Report";
import { RetreadTurnaroundTimeChartConfig } from "../../../charts/linear/retreading/RetreadTurnaroundTime";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";
import { DatasetItem as BarDatasetItem} from "../../../charts/bars/Dataset";
import { groupBy } from "@/utils/helpers/ArrayHelper";
import { getRounded } from "@/utils/helpers/NumberHelper";

import {
    TAB_TYPES,
    TableFields,
    GRAPH_POINTS_TYPE,
    MONTHS,
    COLORS,
    ByRetreader,
} from "@/utils/constants/FleetDashboard.constants.js"

class RetreadTurnaroundTimeReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.RETREADING}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
        }
        if(data?.graphs) {
            this.chartItems = data.graphs;
        }

        !this.settings.byRetreader && (this.settings.byRetreader = ByRetreader);
        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;

        this.tableFields = TableFields[this.reportType];
        this.isTableSelectable = true;
    }

    tableItems = [];
    chartItems = null;
    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        let current, prev;
        if(this.settings.isComparePrev) {
            prev = getRetreadTurnaroundTimeChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS, this.models.selectedMonth);
        }

        current = getRetreadTurnaroundTimeChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT, this.models.selectedMonth);
        let config = new RetreadTurnaroundTimeChartConfig(current, prev);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        let filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);
        }

        if(this.models.selectedMonth) {
            filtered = filtered.filter(f => !f.showMonth);
        }
        else {
            filtered = filtered.filter(f => !f.showWeek);
        }
        
        return filtered;
    };

    getTableItems() {
        let grouped = groupBy(this.tableItems, week => week["MonthString"]);
        return getRetreadTurnaroundTimeItems(grouped, this.models.selectedMonth);
    };

    getDisabledAddBtn() {
        if(this.tableItems.length) return false;

        return true;
    };
}

const getRetreadTurnaroundTimeChart = (data, periodType, selectedMonth) => {
    let dataByPeriod = data?.[periodType];
    if(!dataByPeriod) return null;

    const getContent = (month, postfix) => {
        return [
            {text: `Average Days ${postfix ? `(${postfix})` : ""}`, value: month["AverageDays"]},
        ]
    }

    const addYear = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0; 
    const postfix = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : (Object.keys(data || {}).length > 1 ? "Current" : null);
    
    let points = [];
    let labels = [];

    if(selectedMonth) {
        let monthWeeks = dataByPeriod.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {
            week["AverageDays"] = getRounded(week["AverageDays"]);

            let tooltip = {
                title: getXAxisLabelMonth(week),
                content: getContent(week, postfix),
            }
    
            labels.push(week["WeekString"]);
            points.push(new BarDatasetItem({
                datasetId: `Average Days ${postfix ? `(${postfix})` : ""}`,
                dataItem: week["AverageDays"] || 0,
                tooltip: tooltip,
                color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_TURNAROUND + COLORS.TRANSPARENT65 : COLORS.RET_TURNAROUND,
                type: periodType
            }));
    
        });

    }
    else {
        let yearMonths = groupBy(dataByPeriod, w => w["MonthString"]);
        yearMonths.forEach(monthWeeks => {
            let month = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };

            month["Quantity"] = monthWeeks.reduce((ac, c) => ac + c["Quantity"], 0) || 0;
            month["TotalDays"] = monthWeeks.reduce((ac, c) => ac + c["TotalDays"], 0) || 0;
            month["AverageDays"] = getRounded(month["Quantity"] > 0 ? month["TotalDays"] / month["Quantity"] : 0);

            let tooltip = {
                title: getXAxisLabelMonth(month),
                content: getContent(month, postfix),
            }
    
            labels.push(getXAxisLabelMonth(month));
            points.push(new BarDatasetItem({
                datasetId: `Average Days ${postfix ? `(${postfix})` : ""}`,
                dataItem: month["AverageDays"] || 0,
                tooltip: tooltip,
                color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_TURNAROUND + COLORS.TRANSPARENT65 : COLORS.RET_TURNAROUND,
                type: periodType
            }));
    
        });
    }

    return {points, labels};
}

function getXAxisLabelMonth(month, addYear = 0) {
    const year = (month["Year"] + addYear) || "YYYY";
    return `${MONTHS[month.MonthNumber]} ${year.toString().slice(-2)}`;
}

export const getRetreadTurnaroundTimeItems = (grouped, selectedMonth) => {
    let items = [];
    if(selectedMonth && selectedMonth["MonthString"]) {
        let weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                ...week,
                AverageDays: getRounded(week.AverageDays), 
                AverageDaysPrev: getRounded(week.AverageDaysPrev),
            })
        });
    }
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];

            let totalDays = weeks.reduce((ac, c) => ac + c["TotalDays"], 0) || 0;
            let totalDaysPrev = weeks.reduce((ac, c) => ac + c["TotalDaysPrev"], 0) || 0;

            let quantity = weeks.reduce((ac, c) => ac + c["Quantity"], 0) || 0;
            let quantityPrev = weeks.reduce((ac, c) => ac + c["QuantityPrev"], 0) || 0;

            let avg = quantity > 0 ? (totalDays / quantity) : 0;
            let avgPrev = quantityPrev > 0 ? (totalDaysPrev / quantityPrev) : 0;

            items.push({
                ...firstWeek,
                MonthString: monthString,
                Quantity: getRounded(quantity),
                QuantityPrev: getRounded(quantityPrev),
                TotalDays: getRounded(totalDays),
                TotalDaysPrev: getRounded(totalDaysPrev),
                AverageDays: getRounded(avg),
                AverageDaysPrev: getRounded(avgPrev),
            })
        });
    }

    return items;
}

export {
    RetreadTurnaroundTimeReport
}