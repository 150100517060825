import { LineChartConfig } from "..";
import DateHelper from "@/utils/helpers/DateHelper";

class CallsByMonthChartConfig extends LineChartConfig {
    constructor(current, prev) {
        super(current, prev);

        this.options.scales.yAxes.push({
            ticks: {
                beginAtZero: true,
                // max: 3600 * 7,
                // stepSize: 3600,
                callback: (value) => {
                    return value;
                }
                
                
            },
            gridLines:{
                drawOnChartArea: true
            }
        });

        this.options.scales.xAxes.push({
            offset: true,
            gridLines:{
                drawOnChartArea: true
            },
            ticks: {
                callback: function(value, index, values) {
                    return value;
                }
            }
        });

        this.options.legend = {
            display: false
            
        }
    }

}

export {
    CallsByMonthChartConfig
}