import { Report } from "../../../../scripts/reports/Report";
import { RejectedTireFailuresByReasonConfig } from "../../../charts/pie/retreading/RejectedTireFailuresByReason";
import { TooltipConfig } from "../../../charts/pie/TooltipConfig";

import { toPercentage } from "@/utils/helpers/StringHelper";
import { toCommas, getRounded, getPercentage } from "@/utils/helpers/NumberHelper";

import { PIE_COLORS } from "@/utils/constants/FleetDashboard.constants";

import { 
    DatasetOutlabels,
    DatasetItem as PieDatasetItem, 
    DatasetOutlabels as PieDatasetOutlabels,
} from "../../../charts/pie/Dataset";


import {
    TAB_TYPES,
    TableFields
} from "@/utils/constants/FleetDashboard.constants.js"

class RejectedTireFailuresByReasonReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.RETREADING}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
        }
        if(data?.graphs) {
            this.chartItems = data.graphs["0"];
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.settings.isComparePrev = null;

        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    chartItems = null;
    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        var current;
        current = getRejectedTireFailuresByReasonChart(this.chartItems);
        var config = new RejectedTireFailuresByReasonConfig(current);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {

        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        return this.tableFields;
    };

    getTableItems() {
        return this.tableItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length) return false;

        return true;
    };
}

const getRejectedTireFailuresByReasonChart = (rejectedTires) => {
    if(!rejectedTires) return null;

    var totalRejectedTires = rejectedTires.reduce((accumulator, currentValue) => accumulator + currentValue["RejectTires"], 0) || 0;

    const getContent = (tire) => {
        return [
            {text: `${tire["RejectReason"]} - ${toPercentage(getRounded(getPercentage(tire["RejectTires"], totalRejectedTires),0))}`},
            {text: 'Quantity', value: toCommas(tire["RejectTires"]), fontWeight: 'normal'},
        ]
    }
    var points = [];
    var labels = [];    
    rejectedTires.forEach((tire, idx) => {
        var tooltip = {
            content: getContent(tire)
        };
        points.push(new PieDatasetItem({
            datasetId: 'RejectReason',
            dataItem: tire["RejectTires"],
            tooltip: tooltip,
            color: PIE_COLORS[idx] 
        }));

        labels.push(tire['RejectReason']);
    });   

    var outlabels = new DatasetOutlabels({text: "%p"})

    return {points, labels, outlabels};
}

export {
    RejectedTireFailuresByReasonReport
}