import ApiService from "@/core/services/api.service";

// action types
export const FORGOT_PASSWORD_ACTION_SEND_EMAIL = "forgot_password_action_send_email";

// muts 
export const FORGOT_PASSWORD_MUTS_SET_STATUS = "forgot_password_set_status";

const state = {
    status: {
        status: '',
        description: ''
    }
}

const getters = { 
    getForgotPasswordStatus() {
        return state.status;
    }
};

const actions = {
    [FORGOT_PASSWORD_ACTION_SEND_EMAIL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("admin/admin/email_user_creds_email", params, context)
                .then(({ data }) => {
                    context.commit(FORGOT_PASSWORD_MUTS_SET_STATUS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [FORGOT_PASSWORD_MUTS_SET_STATUS](state, {data}) {
        state.status = data.status;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};
