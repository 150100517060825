import { Report } from "../../Report";

import {
    TAB_TYPES,
    WheelPosition,
} from "@/utils/constants/FleetDashboard.constants.js"

class StatisticsReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.CASING_DURABILITY}, settings, models, loader);

        if(data?.tables) {
            this.statistics = Object.values(data.tables)[0] || [];
        }
        
        this.settings.tireStatuses = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.settings.isComparePrev = null;
        !this.settings.wheelPositions && (this.settings.wheelPositions = WheelPosition);
    }
    summaryBoxes = true;
    statistics = [];

    getFilteredFields() {
        if(!this.tableFields) return [];

        return this.tableFields;
    };

    getTableItems() {
        return null;
    };

    getDisabledAddBtn() {
        if (!this.statistics || this.statistics.length === 0 || this.statistics === undefined) {
            return true;
        }
        
        if (this.statistics[0].TotalCount === 0) {
            return true;
        }
        return false;
    }

    getTiles() {
        let avgTimeRecap = null;
        let rejectedTireRate = null;
        let totalCount = null;
        let avgAge = null;

        if (this.statistics.length > 0) {
            avgTimeRecap = this.statistics.map(x => x['AverageTimesRecap']);
            rejectedTireRate = this.statistics.map(x => x['RejectTireRate']);
            totalCount = this.statistics.map(x => x['TotalCount']);
            avgAge = this.statistics.map(x => x['AverageAge']);
        }
       
        return {
            tval1: avgAge,
            tval2: avgTimeRecap,
            tval3: rejectedTireRate,
            tval4: totalCount,
        }
    };

    getTilesComponent() {
        return import("../../../../result/tiles/casing-durability/StatisticsTiles.vue");
    }
}

export {
    StatisticsReport
}