//   basic helpers like date format and etc.


export const formatISODate = (date, format) => {
    // 2022-12-06T16:01:50.124 -> 2022-12-06
    if(!format){
        return date.split('T')[0]
    }
    return date;
}

export const isObject = (obj) => {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}