<template>
    <div id="gy__date__picker__id">
        <b-input-group class="gy-date-time-picker">
            <b-form-input
                v-model="__vmodel"
                type="text"
                placeholder="MM/DD/YYYY"
                :class="_isBorderDanger ? 'border-danger' : ''"
                @keypress="onKeyPress"
                @paste="onPaste"
                :disabled="disabled"
            ></b-form-input>
            <b-input-group-append>
                <date-picker-btn
                    v-model="__vmodel"
                    :disabled="disabled"
                >

                </date-picker-btn>
            </b-input-group-append>
        </b-input-group>
        <div class="text-danger" style="font-size: 12px;" 
            v-if="errorMessage"
        >
            {{$trans(errorMessage)}}
        </div>
    </div>
</template>

<script>
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';

const dateRgx = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
// 47 // slash
const allowedCodes = [
    47
]

import DatePickerButton from './date-picker/DatePickerButton.vue';

export default {
    props: {
        required: Boolean,
        value: [Object, String],
        disabled: Boolean
    },
    data() {
        return {
            errorMessage: "",
            isRedBorder: false,
        }
    },
    
    mounted() {
    },
    
    // -> string -> $v.model = string -> $v
    computed: {
        __vmodel: {
            get() {
                return this.value || "";
            },
            set(v) {
                this.checkFormat(v);
                this.$emit("input", {
                    date: v,
                    error: this._isBorderDanger
                })
            }
        },
        _isBorderDanger() {
            return this.errorMessage || this.isRedBorder;
        }
        
    },
    methods: {
        touch() {
            this.checkFormat(this.__vmodel);
        },
        setBorder(b) {
            this.isRedBorder = b;
        },
        
        
        checkFormat(str) {
            this.isRedBorder = false;
            
            if(str) {
                if(str.match(dateRgx)) {
                    this.errorMessage = "";
                    return true;
                }
                else {
                    this.errorMessage = "Please enter date in the correct format";
                    return false;
                }
            }
            else if(this.required){
                this.errorMessage = "Date is required";
            }
            else {
                this.errorMessage = "";
                return true;
            }
        },
        onKeyPress(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;

            let isAllowed = allowedCodes.includes(charCode) || (charCode >= 48 && charCode <= 57);

            if (isAllowed) {
                return true;
            } 
            else {
                e.preventDefault();
            }
        },
        onPaste(e) {

            let text = (e.clipboardData || window.clipboardData).getData('text');
            let isTextCorrect = true;

            for (let i = 0; i < text.length; i++) {
                let charCode = text.charCodeAt(i);
                if (!allowedCodes.includes(charCode) && !(charCode >= 48 && charCode <= 57)) {
                    isTextCorrect = false;
                    break;
                }
            };

            if(!isTextCorrect) {
                e.preventDefault();
            }
        }
    },
    components: {
        "date-picker-btn": DatePickerButton
    }
}
</script>

<style>
#gy__date__picker__id .btn-secondary:focus, #gy__date__picker__id .btn-secondary.focus {
    box-shadow: none !important;
}
</style>
