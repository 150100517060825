<template>
    <div class="little preloader-wrapper active">
        <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div>
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
#sarSpinner.preloader-wrapper{
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 5%;
}
.little.preloader-wrapper{
    display: inline-block;
    width: 24px;
    height: 24px;
    float: right;
}
.absolute.preloader-wrapper {
    position: absolute;
    right: 5px;
    top: 5px;
}
.x-center.preloader-wrapper {
    position: absolute;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
}
.y-center.preloader-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
#fcrSpinner.preloader-wrapper{
    display: inline-block;
    width: 24px;
    height: 24px;
    float: right;
}
.float-right.preloader-wrapper{
    display: inline-block;
    width: 24px;
    height: 24px;
    float: right;
}
.preloader-wrapper.flex-right{
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: auto;
}
</style>