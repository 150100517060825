import { BarChartConfig } from "..";
import { Dataset } from "../Dataset";
import { toCommas } from "@/utils/helpers/NumberHelper.js";
import { groupBy } from "@/utils/helpers/ArrayHelper";
import {    
    SUMMARY_LABELS,
} from "@/utils/constants/FleetDashboard.constants";

class Top10BrandsAvgAgeConfig extends BarChartConfig {
    constructor(current) {
        super(current);

        let currentDataSets = [];

        const getDatasetStackName = (datasetId) => {
            switch (datasetId) {
                case SUMMARY_LABELS.RETREAD: return 'column-1-stack';
                case SUMMARY_LABELS.USED_TIRES: return 'column-2-stack';
                case SUMMARY_LABELS.REJECTED: return 'column-3-stack';
                default: return 'default-stack';
            }

        };

        if(current) {

            let datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    color: items[0]?.color,
                    label: label,
                    stack: getDatasetStackName(items[0]?.datasetId),
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                })
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets);
        }

        this.options.scales.yAxes.push({
            ticks: {
                beginAtZero: true,
                stacked: true,
                padding: 20,
                callback: (value) => {
                    return toCommas(value);
                }
                
                
            },
            gridLines:{
                drawOnChartArea: true,
                tickMarkLength: 0
            }
        });

        this.options.scales.xAxes.push({
            stacked: true,
            display: false,
        })
        this.options.scales.xAxes.push(
            {
                id: 'x-axis-id',
                type: 'category',
                offset: true,
                gridLines: {
                    drawOnChartArea: true,
                    offsetGridLines: true,
                    drawTicks: false
                },
                ticks: {
                    padding: 10
                }
            }
        )

        this.options.legend = {
            display: false 
        }
        this.options.title = {
            display: true,
            text: 'Age',
            position: "left"
        }
    }

    
}

export {
    Top10BrandsAvgAgeConfig
}