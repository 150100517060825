import { Report } from "../../../../scripts/reports/Report";

import { 
    getCallsByMonthItems,
    filterFieldsByServiceType,
    getCallsByMonthChart,
    getLinkToServiceHistory
} from "./ServiceReportHelper";

import { CallsByMonthChartConfig } from "../../../charts/linear/service/CallsByMonth";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";
import { groupBy } from "@/utils/helpers/ArrayHelper";

import {
    SERVICE_TYPE_CALLS,
    TAB_TYPES,
    TableFields,
    GRAPH_POINTS_TYPE,
} from "@/utils/constants/FleetDashboard.constants.js"

class CallsByMonthReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.SERVICE}, settings, models, loader);

        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.graphs) {
            this.chartItems = data?.graphs;
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.isIncludeNatAvg = null;
        this.isTableSelectable = true;
        this.tableFields = TableFields[this.reportType];
    }

    summaryBoxes = true;
    tableItems = [];
    chartItems = null;

    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        var current, prev;
        if(this.settings.isComparePrev) {
            prev =  getCallsByMonthChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS, this.models.selectedMonth, this.settings.serviceTypes)
        }

        current = getCallsByMonthChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT, this.models.selectedMonth, this.settings.serviceTypes)

        var config = new CallsByMonthChartConfig(current, prev);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        var filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);
        }

        filtered = filterFieldsByServiceType(filtered, this.settings.serviceTypes);
        
        if(this.models.selectedMonth) {
            filtered = filtered.filter(f => !f.showMonth);
        }
        else {
            filtered = filtered.filter(f => !f.showWeek);
        }
        
        return filtered;
    };

    getTableItems() {
        var grouped = groupBy(this.tableItems, week => week["MonthString"]);
        return getCallsByMonthItems(grouped, this.models.selectedMonth);
    };

    getTiles() {
        var emergency = null;
        var scheduled = null;
        var stock = null;
        var total = null;

        if(this.settings.serviceTypes.emergency) {
            emergency = this.tableItems.reduce((sum, item) => {
                return sum + item[SERVICE_TYPE_CALLS.EMERGENCY];
            }, 0) || 0;
        }
        if(this.settings.serviceTypes.scheduled) {
            scheduled = this.tableItems.reduce((sum, item) => {
                return sum + item[SERVICE_TYPE_CALLS.SCHEDULED];
            }, 0) || 0;
        }
        if(this.settings.serviceTypes.stock) {
            stock = this.tableItems.reduce((sum, item) => {
                return sum + item[SERVICE_TYPE_CALLS.STOCK];
            }, 0) || 0;
        }
        total = this.tableItems.reduce((sum, item) => {
            return sum + item[SERVICE_TYPE_CALLS.TOTAL];
        }, 0) || 0;

        return {
            emergency,
            total,
            scheduled,
            stock
        }
    };

    getTilesComponent() {
        return import("../../../../result/tiles/service/CallsByMonthTiles.vue");
    }

    getDisabledAddBtn() {
        if(this.tableItems.length && this.chartItems && Object.keys(this.chartItems).length) return false;

        return true;
    }
    getLinkToServiceHistory(e) {
        return getLinkToServiceHistory(this.settings.serviceTypes, this.settings.unitLocations, e);
    }

}

export {
    CallsByMonthReport
}