import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { APP_RESET_INTERFACE, APP_SET_AUTHENTICATED } from "@/core/services/app.state.module";
import router from '@/router';

// action types
export const API_LOGIN = "login";
export const API_LOGOUT = "logout";
export const API_VERIFY_AUTH = "verify";
export const API_START_APP = "start_app";
export const API_USER_REDIRECT = "authRedirectByUserGroup";

export const API_GYAUTH = "login_by_gy_token";

export const API_CHECK_PERMISSIONS = "check_permissions";

// mutation types
export const API_PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
    last_error: "",
};

const getters = {
    getAuthLasstError() {
        return state.last_error;
    },
    getRouter() {
        return router;
    }
};

const actions = {
    [API_LOGIN](context, credentials, pageContext) {
        return new Promise((resolve, reject) => {
            
        });
    },
    [API_GYAUTH](context, credentials, pageContext) {
        return new Promise((resolve, reject) => {
            ApiService.post("authorization/login_by_gy", credentials, context)
                .then(({ data }) => {
                    context.commit(APP_SET_AUTHENTICATED, true);
                    context.commit(SET_AUTH, data);
                    //context.commit(API_USER_REDIRECT, context);
                    resolve(data);
                })
                .catch(({ response }) => {
                    //context.commit(SET_ERROR, response.data.errors);
                    context.commit(APP_SET_AUTHENTICATED, false);
                    context.dispatch(APP_RESET_INTERFACE);
                    context.commit(API_PURGE_AUTH);
                    if (response && response.data) {
                        context.commit(SET_AUTH, response.data);
                    }
                    reject(response);
                });
        });
    },
    [API_START_APP](context) {
        return new Promise((resolve, reject) => {
            console.log('API_START_APP');
            ApiService.get("authorization/start", context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(() => {
                    reject();
                });
        });
    },
    [API_LOGOUT](context) {
        context.commit(API_PURGE_AUTH);
        context.commit(APP_SET_AUTHENTICATED, false);
        context.dispatch(APP_RESET_INTERFACE);
        
        context.commit(API_USER_REDIRECT, context);
    },
    [API_USER_REDIRECT](context) {
        context.commit(API_USER_REDIRECT, context);
    },
    [API_VERIFY_AUTH](context) {
        return new Promise((resolve, reject) => {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                ApiService.get("authorization/verify", context)
                    .then(({ data }) => {
                        context.commit(APP_SET_AUTHENTICATED, true);
                        context.commit(SET_AUTH, data);
                        resolve(this);
                    })
                    .catch(() => {
                        context.commit(APP_SET_AUTHENTICATED, false);
                        context.commit(APP_RESET_INTERFACE);
                        context.commit(API_PURGE_AUTH);
                        reject();
                    });
            } else {
                context.commit(APP_SET_AUTHENTICATED, false);
                context.dispatch(APP_RESET_INTERFACE);
                context.commit(API_PURGE_AUTH);
                reject();
            }
        });
    },
    [API_CHECK_PERMISSIONS](context, params) {
        console.log('API_CHECK_PERMISSIONS');
        if (ApiService.isCheckPermissionSent == true) {
            return new Promise((resolve, reject) => { resolve();});
        }
        ApiService.isCheckPermissionSent = true;
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.post("authorization/check_permissions", params, context)
                .then(() => {
                    ApiService.isCheckPermissionSent = false;
                    resolve();
                })
                .catch(() => {
                    ApiService.isCheckPermissionSent = false;
                    reject();
                });
        });    
    }
};

const mutations = { 
    [API_USER_REDIRECT](state, context) {
        //console.log('in auth.module.js');
        if (!this.getters.AppIsAuthenticated) {

            let url = window.app.$route.query;
            // window.app.$root.$emit('show-notify', 'Help us to translate our service to local language');

            let returnHost = location.origin; 
            let returnPath = location.pathname; 
            let returnParams = "";
            if (location.search) {
                returnParams = location.search.substring(1);
                returnParams = "&" + returnParams;
            }

            if (returnHost.includes("solutions-tiremgmt-test-east.azurewebsites.net") && process.env.VUE_APP_TM_HOST_REAL) {
                returnHost = process.env.VUE_APP_TM_HOST_REAL;
            }

            var return_url = new URL((process.env.VUE_APP_LOGIN_HOST + "/membership/login"));
            let return_url_params = new URLSearchParams(return_url);
            return_url_params.set('returnHost', returnHost);
            return_url_params.set('returnUrl', returnPath);
            return_url.search = return_url_params.toString() + returnParams;
            
            if (!window.gy_app_is_too_long_request_popup) {
                location = (return_url);
            }
                   
        }
    },   
    [SET_AUTH](state, userObject) {
        if (userObject.hasOwnProperty('validateStatusResponce')) {
            if (userObject.validateStatusResponce.hasOwnProperty('status')) {
                if (userObject.validateStatusResponce.status == "Success") { //success
                    this.commit(APP_SET_AUTHENTICATED, true);
                }
            }
        }
        if (userObject.hasOwnProperty('authorizationStatus')) {
            
            if (userObject.authorizationStatus.hasOwnProperty('status')) {
                if (userObject.authorizationStatus.status == 0) { //success
                    state.last_error = "";
                    //JwtService.saveToken(userObject.authorizationStatus.jwtToken);
                }
                if (userObject.authorizationStatus.status == 1) { //locked
                    JwtService.destroyToken();
                    state.last_error = "Account is locked due to too many failed request attempts";
                }
                if (userObject.authorizationStatus.status == 3) { //failure
                    JwtService.destroyToken();
                    state.last_error = "Email and password combination not recognised. Please try, again.";
                }
            }
        }
    },
    [API_PURGE_AUTH]() {
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
