import { Report } from "../../Report";
import { TopProductsConfig } from "../../../charts/pie/purchases/TopProducts";
import { TooltipConfig } from "../../../charts/pie/TooltipConfig";

import { 
    getTopProductsChart
    
} from "./PurchaseReportHelper";

import {
    TAB_TYPES,
    TableFields
} from "@/utils/constants/FleetDashboard.constants.js"

class TopProductsReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.PURCHASES}, settings, models, loader);
        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.graphs) {
            this.chartItems = data.graphs["0"];
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.settings.isComparePrev = null;

        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    chartItems = null;
    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        var current;
        current = getTopProductsChart(this.chartItems);
        var config = new TopProductsConfig(current);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {

        return this.chartItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length && this.chartItems && Object.keys(this.chartItems).length) return false;

        return true;
    }
    
    getFilteredFields() {
        if(!this.tableFields) return [];
        
        return this.tableFields;
    };

    getTableItems() {
        return this.tableItems;
    };
}

export {
    TopProductsReport
}