import { COLORS, GRAPH_POINTS_TYPE } from "@/utils/constants/FleetDashboard.constants";

const LINE_TYPE = {
    SOLID: null, 
    DOTTED: [0, 5],
    DASHED: [10, 10]
}
class Dataset {
    constructor(label, color = COLORS.GRAY, lineType = GRAPH_POINTS_TYPE.CURRENT, xAxisID) {
        this.label = label;
        this.borderColor = color;  // line fill color
        this.backgroundColor = color;  // line point color
        this.lineType = lineType;
        this.xAxisID = xAxisID;
        switch (lineType) {
            case GRAPH_POINTS_TYPE.PREVIOUS:
                this.borderDash = LINE_TYPE.DASHED;
                break;
            case GRAPH_POINTS_TYPE.NAT_AVG:
                this.borderDash = LINE_TYPE.DOTTED;
                this.borderCapStyle = 'round'
                break;
        
            default:
                break;
        }
    }

    label = "";
    data = [];
    items = [];
    lineType = null;
    xAxisID = null;
    backgroundColor = "";
    borderDash = LINE_TYPE.SOLID;
    borderCapStyle = null;
    borderColor = "";
    showLine = true;
    fill = false;
    lineTension = 0;
    pointRadius = 5;
    pointHoverRadius = 6;
}

class DatasetItem {
    constructor(datasetId, dataItem, tooltip, color, xAxesId) {
        this.datasetId = datasetId;
        this.dataItem = dataItem;
        this.xAxesId = xAxesId;
        this.tooltip = tooltip;
        this.color = color;
    }

    datasetId = null;
    dataItem = null;
    xAxesId = null;
    tooltip = null;
    color = COLORS.GRAY;
}

export {
    Dataset,
    DatasetItem
}