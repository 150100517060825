import { 
    GRAPH_POINTS_TYPE,
    MONTHS,
    COLORS,
    EMERGENCY_COLORS,
    SCHEDULED_COLORS,
    GRAPH_SHAPE_MAP,
    GRAPH_SHAPES,
    SERVICE_TYPE_KEYS,
    PIE_COLORS
} from "@/utils/constants/FleetDashboard.constants";

import { groupBy } from "@/utils/helpers/ArrayHelper";

import { DatasetItem as BarDatasetItem} from "../../../charts/bars/Dataset";
import { DatasetItem as PieDatasetItem,
         DatasetOutlabels as PieDatasetOutlabels} from "../../../charts/pie/Dataset";
import pattern from "patternomaly";
import { toMoney, toPercentage } from "@/utils/helpers/StringHelper";
import { toCommas, getRounded, getPercentage } from "@/utils/helpers/NumberHelper";

export const getEmergencyVSScheduledItems = (grouped, selectedMonth) => {
    let items = [];

    if(selectedMonth && selectedMonth["MonthString"]) {
        let weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                ...week,
                WeekString: "Week " + week["WeekNumber"]
            })
        });
    } 
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];
            let tCost = 0, tCostPrev = 0, emCost = 0, emCostPrev = 0, scCost = 0, scCostPrev = 0, emPercent = 0, emPercentPrev = 0, scPercent = 0, scPercentPrev = 0;
            tCost = getRounded(weeks.reduce((ac, c) => ac + c["TotalCost"], 0),0);
            tCostPrev = getRounded(weeks.reduce((ac, c) => ac + c["TotalCostPrev"], 0),0);
            emCost = getRounded(weeks.reduce((ac, c) => ac + c["EmergencyCost"], 0),0);
            emCostPrev = getRounded(weeks.reduce((ac, c) => ac + c["EmergencyCostPrev"], 0),0);
            scCost = getRounded(weeks.reduce((ac, c) => ac + c["ScheduledCost"], 0),0);
            scCostPrev = getRounded(weeks.reduce((ac, c) => ac + c["ScheduledCostPrev"], 0),0);
            emPercent = getRounded(getPercentage(weeks.reduce((ac, c) => ac + c["EmergencyCost"], 0), weeks.reduce((ac, c) => ac + c["TotalCost"], 0)),0);
            emPercentPrev = getRounded(getPercentage(weeks.reduce((ac, c) => ac + c["EmergencyCostPrev"], 0), weeks.reduce((ac, c) => ac + c["TotalCostPrev"], 0)),0);
            scPercent = getRounded(getPercentage(weeks.reduce((ac, c) => ac + c["ScheduledCost"], 0), weeks.reduce((ac, c) => ac + c["TotalCost"], 0)),0);
            scPercentPrev = getRounded(getPercentage(weeks.reduce((ac, c) => ac + c["ScheduledCostPrev"], 0), weeks.reduce((ac, c) => ac + c["TotalCostPrev"],0)),0);

            items.push({
                Date: firstWeek["Date"],
                Year: firstWeek["Year"],
                MonthNumber: firstWeek["MonthNumber"],
                WeekNumber: firstWeek["WeekNumber"],
                MonthString: monthString,
                TotalCost: tCost,
                TotalCostPrev: tCostPrev,
                EmergencyCost: emCost,
                EmergencyCostPrev: emCostPrev,
                ScheduledCost: scCost,
                ScheduledCostPrev: scCostPrev,
                EmergencyPercent: emPercent,
                EmergencyPercentPrev: emPercentPrev,
                ScheduledPercent: scPercent,
                ScheduledPercentPrev: scPercentPrev,
            })
        });
    }

    return items;
}
export const getEmergencyServiceItems = (grouped, selectedMonth) => {
    let items = [];
    
    if(selectedMonth && selectedMonth["MonthString"]) {
        let weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                WeekString: "Week " + week["WeekNumber"],
                ...week,
            })
        });
    }
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];

            let tCost = weeks.reduce((ac, c) => ac + c["TotalCost"], 0) || 0;
            let tCostPrev = weeks.reduce((ac, c) => ac + c["TotalCostPrev"], 0) || 0;

            let eCost = weeks.reduce((ac, c) => ac + c["EmergencyCost"], 0) || 0;
            let eCostPrev = weeks.reduce((ac, c) => ac + c["EmergencyCostPrev"], 0) || 0;

            let ePerc = getPercentage(eCost, tCost);
            let ePercPrev = getPercentage(eCostPrev, tCostPrev);

            items.push({
                ...firstWeek,
                MonthString: monthString,
                TotalCost: getRounded(tCost,0),
                TotalCostPrev: getRounded(tCostPrev,0),
                EmergencyCost: getRounded(eCost,0),
                EmergencyCostPrev: getRounded(eCostPrev,0),
                EmergencyPercent: getRounded(ePerc,0),
                EmergencyPercentPrev: getRounded(ePercPrev,0),
            })
        });
    }

    return items;

}
export const getRetreadSalesItems = (grouped, selectedMonth) => {
    let items = [];
    if(selectedMonth && selectedMonth["MonthString"]) {
        let weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                ...week
            })
        });
    }
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];

            let n = weeks.reduce((ac, c) => ac + c["New"], 0) || 0;
            let nPrev = weeks.reduce((ac, c) => ac + c["NewPrev"], 0) || 0;

            let r = weeks.reduce((ac, c) => ac + c["Retread"], 0) || 0;
            let rPrev = weeks.reduce((ac, c) => ac + c["RetreadPrev"], 0) || 0;

            let ratio = n > 0 ? (r / n) : 0;
            let ratioPrev = nPrev > 0 ? (rPrev / nPrev) : 0;

            items.push({
                ...firstWeek,
                MonthString: monthString,
                New: getRounded(n),
                NewPrev: getRounded(nPrev),
                Retread: getRounded(r),
                RetreadPrev: getRounded(rPrev),
                RetreadToNewRatio: getRounded(ratio),
                RetreadToNewRatioPrev: getRounded(ratioPrev)
            })
        });
    }

    return items;
}

export const getRepairAndRetreadItems = (groupedItems, selectedMonth) => {
    let items = [];
    
    if(selectedMonth && selectedMonth["MonthString"]){
        let weeks = groupedItems.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                WeekString: "Week " + week["WeekNumber"],
                ...week,
            })
        });
    } else {
        groupedItems.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];

            let tRepair = weeks.reduce((ac, c) => ac + c["Repair"], 0) || 0;
            let tRepairPrev = weeks.reduce((ac, c) => ac + c["RepairPrev"], 0) || 0;

            let eRetread = weeks.reduce((ac, c) => ac + c["Retread"], 0) || 0;
            let eRetreadPrev = weeks.reduce((ac, c) => ac + c["RetreadPrev"], 0) || 0;

            let eRepairPercent = getPercentage(tRepair, tRepair + eRetread);
            let eRepairPercentPrev = getPercentage(tRepairPrev, tRepairPrev + eRetreadPrev);

            items.push({
                ...firstWeek,
                MonthString: monthString,
                Repair: getRounded(tRepair),
                RepairPrev: getRounded(tRepairPrev),
                Retread: getRounded(eRetread),
                RetreadPrev: getRounded(eRetreadPrev),
                RepairPercent: getRounded(eRepairPercent,2),
                RepairPercentPrev: getRounded(eRepairPercentPrev,2),
            })
        });
    }

    return items;
}

export const getEmegencyServiceChart = (data, type, selectedMonth) => {
    let weeks = data?.[type];
    if(!weeks) return null;

    const getContent = (month, postfix) => {
        return [
            {text: `Total Service ${postfix ? `(${postfix})` : ""}`, value: toMoney(toCommas(month["TotalCost"]))},
            {text: `Emergency ${postfix ? `(${postfix})` : ""}`, value: toMoney(toCommas(month["EmergencyCost"]))},
        ]
    }
    const color = type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.EMERGENCY + COLORS.TRANSPARENT65 : COLORS.EMERGENCY;
    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0; 
    const postfix = type == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : (Object.keys(data || {}).length > 1 ? "Current" : null);
    
    let points = [];
    let labels = [];

    if(selectedMonth) {
        let monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {
            let tooltip = {
                title: week["WeekDescription"],
                content: getContent(week, postfix)
            }
            labels.push(getXAxisLabelWeek(week));
            let dataItem = week["IsEmpty"] ? NaN : week["EmergencyPercent"];
            points.push(new BarDatasetItem({
                datasetId: `Emergency ${postfix ? `(${postfix})` : ""}`,
                dataItem,
                tooltip: tooltip,
                color: color,
                type: type
            }));

        });
    }
    else {
        let months = groupBy(weeks, w => w["MonthString"]);
        months.forEach(monthWeeks => {
            let week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };
            let isWeekEmpty = monthWeeks.every(w => w["IsEmpty"]);
            week["TotalCost"] = getRounded(monthWeeks.reduce((ac, c) => ac + c["TotalCost"], 0) || 0);
            week["EmergencyCost"] =  getRounded(monthWeeks.reduce((ac, c) => ac + c["EmergencyCost"], 0) || 0);
            week["EmergencyPercent"] = getPercentage(week["EmergencyCost"], week["TotalCost"]);
            
            let tooltip = {
                title: getXAxisLabelMonth(week),
                content: getContent(week, postfix),
            }
    
            labels.push(getXAxisLabelMonth(week));
            let dataItem = isWeekEmpty ? NaN : week["EmergencyPercent"];
            points.push(new BarDatasetItem({
                datasetId: `Emergency ${postfix ? `(${postfix})` : ""}`,
                dataItem,
                tooltip: tooltip,
                color: color,
                type: type
            }));

        });
    }

    return {points, labels};

}

export const getRepairAndRetreadChart = (data, type,  selectedMonth) => {
    let weeks = data?.[type];
    if(!weeks) return null;


    const colorRepair = type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.REPAIR + COLORS.TRANSPARENT65 : COLORS.REPAIR;
    const colorRetread = type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RETREAD + COLORS.TRANSPARENT65 : COLORS.RETREAD;

    const repairLegend = {
        backgroundColor: colorRepair,
    }
    const retreadLegend = {
        backgroundColor: colorRetread,
    }
    const getRepairContent = (month, postfix) => {
        return [
            {text: `Repair ${postfix ? '(' + postfix + ')' : ""}`, value: toMoney(toCommas(month["Repair"]))},
        ]
    }
    const getRetreadContent = (month, postfix) => {
        return [
            {text: `Retread ${postfix ? '(' + postfix + ')' : ""}`, value: toMoney(toCommas(month["Retread"]))},
        ]
    }
    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0; 
    const postfix = type == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : (Object.keys(data || {}).length > 1 ? "Current" : null);

    const points = [];
    let labels = [];
    if(selectedMonth){
        let monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {

            let retreadTooltip = {
                title: week["WeekDescription"],
                content: getRetreadContent(week, postfix),
                color: COLORS.RETREAD
            }
            let repairTooltip = {
                title: week["WeekDescription"],
                content: getRepairContent(week, postfix),
                color: COLORS.NEW
            }

        
            labels.push(getXAxisLabelWeek(week));

            points.push(new BarDatasetItem({
                datasetId: `Repair ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: repairTooltip,
                dataItem: week['Repair'],
                color: colorRepair,
                type: type,
                legend: repairLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Retread ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: retreadTooltip,
                dataItem: week['Retread'],
                color: colorRetread,
                type: type,
                legend: retreadLegend
            }));

        });
        
    } else {
        let months = groupBy(weeks, w => w["MonthString"]);
        months.forEach(monthWeeks => {
            let week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };

            week["Repair"] = getRounded(monthWeeks.reduce((ac, c) => ac + c["Repair"], 0) || 0);
            week["Retread"] =  getRounded(monthWeeks.reduce((ac, c) => ac + c["Retread"], 0) || 0);

            let retreadTooltip = {
                title: getXAxisLabelMonth(week),
                content: getRetreadContent(week, postfix),
                color: COLORS.RETREAD
            }
            let repairTooltip = {
                title: getXAxisLabelMonth(week),
                content: getRepairContent(week, postfix),
                color: COLORS.REPAIR
            }

        
            labels.push(getXAxisLabelMonth(week));

            points.push(new BarDatasetItem({
                datasetId: `Repair ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: repairTooltip,
                dataItem: week['Repair'],
                color: colorRepair,
                type: type,
                legend: repairLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Retread ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: retreadTooltip,
                dataItem: week['Retread'],
                color: colorRetread,
                type: type,
                legend: retreadLegend
            }));



        });
    }
    return {points, labels};
}

export const getRetreadSalesChart = (data, type, selectedMonth) => {
    let weeks = data?.[type];
    if(!weeks) return null;

    const colorNew = type === GRAPH_POINTS_TYPE.CURRENT ? COLORS.NEW : COLORS.NEW_PREV;
    const colorRetread = type === GRAPH_POINTS_TYPE.CURRENT ? COLORS.RETREAD : COLORS.RETREAD_PREV;

    const newLegend = {
        backgroundColor: colorNew,
    }
    const retreadLegend = {
        backgroundColor: colorRetread,
    }
    const getContent = (month, postfix) => {
        return [
            {text: `Retread ${postfix ? '(' + postfix + ')' : ""}`, value: toCommas(month["Retread"])},
        ]
    }
    const getNewContent = (month, postfix) => {
        return [
            {text: `New ${postfix ? '(' + postfix + ')' : ""}`, value: toCommas(month["New"])},
        ]
    }
    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0; 
    const postfix = type == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : (Object.keys(data || {}).length > 1 ? "Current" : null);

    const points = [];
    let labels = [];

    if(selectedMonth) {
        let monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {

            let retreadTooltip = {
                title: getXAxisLabelMonth(week, addYear),
                content: getContent(week, postfix),
                color: COLORS.RETREAD
            }
            let newTooltip = {
                title: getXAxisLabelMonth(week, addYear),
                content: getNewContent(week, postfix),
                color: COLORS.NEW
            }
            
            labels.push(getXAxisLabelWeek(week));
    
            points.push(new BarDatasetItem({
                datasetId: `New ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: newTooltip,
                dataItem: week['New'],
                color: colorNew,
                type: type,
                legend: newLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Retread ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: retreadTooltip,
                dataItem: week['Retread'],
                color: colorRetread,
                type: type,
                legend: retreadLegend
            }));

        });
    }
    else {
        let months = groupBy(weeks, w => w["MonthString"]);
        months.forEach(monthWeeks => {
            let week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };

            week["New"] = getRounded(monthWeeks.reduce((ac, c) => ac + c["New"], 0) || 0);
            week["Retread"] =  getRounded(monthWeeks.reduce((ac, c) => ac + c["Retread"], 0) || 0);

            let retreadTooltip = {
                title: getXAxisLabelMonth(week, addYear),
                content: getContent(week, postfix),
                color: COLORS.RETREAD
            }
            let newTooltip = {
                title: getXAxisLabelMonth(week, addYear),
                content: getNewContent(week, postfix),
                color: COLORS.NEW
            }
            
            labels.push(getXAxisLabelSeparatedYear(week, addYear));
    
            points.push(new BarDatasetItem({
                datasetId: `New ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: newTooltip,
                dataItem: week['New'],
                color: colorNew,
                type: type,
                legend: newLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Retread ${postfix ? '(' + postfix + ')' : ""}`,
                tooltip: retreadTooltip,
                dataItem: week['Retread'],
                color: colorRetread,
                type: type,
                legend: retreadLegend
            }));



        });
    }

    return {points, labels};
}
export const getEmergencyVSScheduledChart = (data, type, selectedMonth) => {
    let weeks = data?.[type];
    if(!weeks) return null;

    const emergencyColor = type == GRAPH_POINTS_TYPE.PREVIOUS ? pattern.draw(GRAPH_SHAPES.DIAGONAL_RIGHT_LEFT, COLORS.EMERGENCY_PREV) : COLORS.EMERGENCY;
    const scheduledColor = type == GRAPH_POINTS_TYPE.PREVIOUS ? pattern.draw(GRAPH_SHAPES.DOT, COLORS.SCHEDULED_PREV) : COLORS.SCHEDULED;

    const getEmergencyContent = (value, postfix) => {
        return [
            {text: `Emergency ${postfix ? `(${postfix})` : ""}`, value: toPercentage(value)},
        ]
    }
    const getScheduledContent = (value, postfix) => {
        return [
            {text: `Scheduled ${postfix ? `(${postfix})` : ""}`, value: toPercentage(value)},
        ]
    }

    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0; 
    const postfix = type == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : (Object.keys(data || {}).length > 1 ? "Current" : null);

    const emergencyLegend = {
        shape: getShape(type, SERVICE_TYPE_KEYS.EMERGENCY),
        backgroundColor: EMERGENCY_COLORS[type],
    }
    const scheduledLegend = {
        shape: getShape(type, SERVICE_TYPE_KEYS.SCHEDULED),
        backgroundColor: SCHEDULED_COLORS[type],
    }

    let labels = [];
    let points = [];
    if(selectedMonth) {
        let monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {
            let emergencyTooltip = {
                title: week["WeekDescription"],
                content: getEmergencyContent(week.EmergencyPercent, postfix),
                color: COLORS.EMERGENCY
            }
            
            let scheduledTooltip = {
                title: week["WeekDescription"],
                content: getScheduledContent(week.ScheduledPercent, postfix),
                color: COLORS.SCHEDULED
            }
            
            labels.push(getXAxisLabelWeek(week));
            points.push(new BarDatasetItem({
                datasetId: `Scheduled ${postfix ? `(${postfix})` : ""}`,
                dataItem: week["ScheduledCost"],
                datasetLabel: week["ScheduledPercent"],
                tooltip: scheduledTooltip,
                color: scheduledColor,
                type: type,
                legend: scheduledLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Emergency ${postfix ? `(${postfix})` : ""}`,
                dataItem: week["EmergencyCost"],
                datasetLabel: week["EmergencyPercent"],
                tooltip: emergencyTooltip,
                color: emergencyColor,
                type: type,
                legend: emergencyLegend
            }));
        });
    }
    else {
        let months = groupBy(weeks, w => w["MonthString"]);
        months.forEach(monthWeeks => {
            let tCost = monthWeeks.reduce((ac, c) => ac + c["TotalCost"], 0);
            let scCost = monthWeeks.reduce((ac, c) => ac + c["ScheduledCost"], 0);
            let emCost = monthWeeks.reduce((ac, c) => ac + c["EmergencyCost"], 0);
            let week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };
            let emergencyTooltip = {
                title: getXAxisLabelMonth(week),
                content: getEmergencyContent(getRounded(getPercentage(emCost, tCost),0), postfix),
                color: COLORS.EMERGENCY
            }
            
            let scheduledTooltip = {
                title: getXAxisLabelMonth(week),
                content: getScheduledContent(getRounded(getPercentage(scCost, tCost),0), postfix),
                color: COLORS.SCHEDULED
            }
    
            labels.push(getXAxisLabelSeparatedYear(week, addYear));

            points.push(new BarDatasetItem({
                datasetId: `Scheduled ${postfix ? `(${postfix})` : ""}`,
                dataItem: getRounded(scCost),
                datasetLabel: getRounded(getPercentage(scCost, tCost),0),
                tooltip: scheduledTooltip,
                color: scheduledColor,
                type: type,
                legend: scheduledLegend
            }));
            points.push(new BarDatasetItem({
                datasetId: `Emergency ${postfix ? `(${postfix})` : ""}`,
                dataItem: getRounded(emCost),
                datasetLabel: getRounded(getPercentage(emCost, tCost),0),
                tooltip: emergencyTooltip,
                color: emergencyColor,
                type: type,
                legend: emergencyLegend
            }));
        });
    }

    return { points, labels };
}
export const getTopProductsChart = (products) => {
    if(!products) return null;

    let totalSpend = products.reduce((ac, c) => ac + c["TotalSpend"], 0) || 0;

    const getContent = (product) => {
        return [
            {text: `${product["Product"]} - ${toPercentage(getRounded(getPercentage(product["TotalSpend"], totalSpend),0))}`},
            {text: 'Total Spend', value: `${toMoney(toCommas(product["TotalSpend"]))}`, fontWeight: 'normal'},
            {text: 'Quantity', value: toCommas(product["Quantity"]), fontWeight: 'normal'},
        ]
    }
    let points = [];
    let labels = [];
    var outlabels = new PieDatasetOutlabels({text: "%p"})
    products.forEach((p, i) => {
        let tooltip = {
            content: getContent(p)
        };
        points.push(new PieDatasetItem({
            datasetId: 'Product',
            dataItem: p["TotalSpend"],
            tooltip: tooltip,
            color: PIE_COLORS[i] 
        }));

        labels.push(p['Product']);
    });

    return {points, labels, outlabels};
}


function getXAxisLabelMonth(month, addYear = 0) {
    const year = (month["Year"] + addYear) || "YYYY";
    return `${MONTHS[month.MonthNumber]} ${year.toString().slice(-2)}`;
}
function getXAxisLabelSeparatedYear(month, addYear = 0) {
    let year = month["Year"];
    let nextYear;
    if(addYear > 0) {
        nextYear = year + addYear;
        return `${MONTHS[month.MonthNumber]} ${nextYear.toString().slice(-2)} / ${year.toString().slice(-2)}`;
    }
    
    return `${MONTHS[month.MonthNumber]} ${year.toString().slice(-2)}`;
}

function getXAxisLabelWeek(week) {
    return week["WeekString"];
}

function getShape(type, serviceType) {
    switch (type) {
        case GRAPH_POINTS_TYPE.PREVIOUS:
            return GRAPH_SHAPE_MAP.get(serviceType);
        default:
            return GRAPH_SHAPES.NONE;
    }
}
