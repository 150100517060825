
import { capitalizeFirstLetter } from "@/utils/helpers/StringHelper.js";
import DateHelper from '@/utils/helpers/DateHelper';
import { toCommas } from "@/utils/helpers/NumberHelper";
import { DatasetItem } from "../../../charts/linear/Dataset";

import { 
    GRAPH_POINTS_TYPE,
    MONTHS,
    COLORS,
    GRAPH_TYPE_TEXT,
    SERVICE_HISTORY_KEYS, TAB_TYPES
} from "@/utils/constants/FleetDashboard.constants";

import {
    groupBy,
}
from '@/utils/helpers/ArrayHelper.js'

import store from "@/core/services/store";
import 
{
    GETTERS
}
from "@/core/services/api/fleet-dashboard/fleet.dashboard.module.js"

export const filterFieldsByServiceType = (fields, serviceTypes) => {
    for (const key in serviceTypes) {
        const value = serviceTypes[key];
        if(!value) {
            fields = fields.filter(f => !f.key.includes(capitalizeFirstLetter(key)));
        }
    }
    return fields;
}

export const getAvgRollTimeItems = (grouped, selectedMonth) => {
    var items = [];

    if(selectedMonth && selectedMonth["MonthString"]) {
        var weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                WeekString: "Week " + week["WeekNumber"],
                ...week,
            })
        });
    }
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];
            const lastWeek = weeks[weeks.length - 1];
            let calls = 0, callsPrev = 0, avgRollTime = 0, avgRollTimePrev = 0;
            calls = weeks.reduce((ac, c) => ac + c["Calls"], 0);
            callsPrev = weeks.reduce((ac, c) => ac + c["CallsPrev"], 0);
            if (calls > 0) {
                avgRollTime = weeks.reduce((ac, c) => ac + c["AvgRollTime"] * c["Calls"], 0) / calls;
            }
              
            if (callsPrev > 0) {
                avgRollTimePrev = weeks.reduce((ac, c) => ac + c["AvgRollTimePrev"] * c["CallsPrev"], 0) / callsPrev;
            }

            items.push({
                Date: firstWeek["Date"],
                Year: firstWeek["Year"],
                MonthNumber: firstWeek["MonthNumber"],
                WeekNumber: firstWeek["WeekNumber"],
                MonthString: monthString,
                Calls: calls,
                CallsPrev: callsPrev,
                AvgRollTime: avgRollTime,
                AvgRollTimePrev: avgRollTimePrev,
                WeekStart: firstWeek.WeekStart,
                WeekEnd: lastWeek.WeekEnd
            })
        });
    }

    return items;
};

export const getCallsByMonthItems = (grouped, selectedMonth) => {
    var items = [];
    if(selectedMonth && selectedMonth["MonthString"]) {
        var weeks = grouped.get(selectedMonth["MonthString"]) || [];
        weeks.forEach(week => {
            items.push({
                WeekString: "Week " + week["WeekNumber"],
                ...week,
            })
        });
    }
    else {
        grouped.forEach((weeks, monthString) => {
            const firstWeek = weeks[0];
            const lastWeek = weeks[weeks.length - 1];
            var emCalls = 0, emCallsPrev = 0, stCalls = 0, stCallsPrev = 0, scCalls = 0, scCallsPrev = 0, tCalls = 0, tCallsPrev = 0;
            emCalls = weeks.reduce((ac, c) => ac + c["EmergencyCalls"], 0);
            emCallsPrev = weeks.reduce((ac, c) => ac + c["EmergencyCallsPrev"], 0);
            stCalls = weeks.reduce((ac, c) => ac + c["StockCalls"], 0);
            stCallsPrev = weeks.reduce((ac, c) => ac + c["StockCallsPrev"], 0);
            scCalls = weeks.reduce((ac, c) => ac + c["ScheduledCalls"], 0);
            scCallsPrev = weeks.reduce((ac, c) => ac + c["ScheduledCallsPrev"], 0);
            tCalls = weeks.reduce((ac, c) => ac + c["TotalCalls"], 0);
            tCallsPrev = weeks.reduce((ac, c) => ac + c["TotalCallsPrev"], 0);

            items.push({
                Date: firstWeek["Date"],
                Year: firstWeek["Year"],
                MonthNumber: firstWeek["MonthNumber"],
                WeekNumber: firstWeek["WeekNumber"],
                MonthString: monthString,
                EmergencyCalls: emCalls,
                EmergencyCallsPrev: emCallsPrev,
                StockCalls: stCalls,
                StockCallsPrev: stCallsPrev,
                ScheduledCalls: scCalls,
                ScheduledCallsPrev: scCallsPrev,
                TotalCalls: tCalls,
                TotalCallsPrev: tCallsPrev,
                WeekStart: firstWeek.WeekStart,
                WeekEnd: lastWeek.WeekEnd
            })


        });
    }

    return items;
};

export const getAvgRollTimeChart = (data, type, selectedMonth) => {
    let weeks = data?.[type];
    let isNA = type == GRAPH_POINTS_TYPE.NAT_AVG;
    if(!weeks) return null;

    let labels = [];
    const getContent = (avgTime, callsCount, postfix) => {
        let tooltipContent = [ 
            {text: `Avg Roll Time ${postfix ? `(${postfix})` : ""}`, value: DateHelper.getTimeFromSeconds(avgTime)}
        ];
        if (!isNA) {
            tooltipContent.push(
                {text: `Emergency Calls ${postfix ? `(${postfix})` : ""}`, value: toCommas(callsCount)}
            );
        }
        return tooltipContent;
    }

    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0;
    const postfix = Object.keys(data).length == 1 ? "" : GRAPH_TYPE_TEXT[type];
    const color = isNA ? COLORS.NAT_AVG : 
        type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.EMERGENCY + COLORS.TRANSPARENT65 : 
        COLORS.EMERGENCY;

    let points = [];
    if(selectedMonth) {
        let monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {
            let callsCount = week["Calls"] > 0 ? week["Calls"] : 1;
            let dataItem = week["AvgRollTimeSeconds"] / callsCount;
            let tooltip = {
                title: week["WeekDescription"],
                content: getContent(week["AvgRollTimeSeconds"] / callsCount, callsCount, postfix)
            }
            points.push(new DatasetItem(`Avg Roll Time ${postfix ? `(${postfix})` : ""}`, dataItem, tooltip, color));
            labels.push(getXAxisLabelWeek(week));
        });
    }
    else {
        let months = groupBy(weeks, w => w["MonthString"]);
        months.forEach((monthWeeks, monthString) => {
            let calls = 0, time = 0, count = 0;
            monthWeeks.forEach(week => {
                if(!week["IsEmpty"]) {
                    calls += week["Calls"];
                    time += week["AvgRollTimeSeconds"];
                    count++;
                }
            });
            
            let week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };
            week["Calls"] = calls;
            week["AvgRollTimeSeconds"] = time / (calls > 0 ? calls : 1);
            let tooltip = {
                title: getXAxisLabelMonth(week),
                content: getContent(week["AvgRollTimeSeconds"], week["Calls"], postfix)
            }
            let dataItem = week["AvgRollTimeSeconds"];
            points.push(new DatasetItem(`Avg Roll Time ${postfix ? `(${postfix})` : ""}`, dataItem, tooltip, color));
            labels.push(getXAxisLabelMonth(week, addYear));
        });
    }

    return { points, labels };
}

export const getCallsByMonthChart = (data, type, selectedMonth, serviceTypes) => {
    var weeks = data?.[type];
    if(!weeks) return null;

    var labels = [];

    const getContent = (month, postfix, serviceTypeKey) => {
        const text = capitalizeFirstLetter(serviceTypeKey);
        const value = month[capitalizeFirstLetter(serviceTypeKey) + "Calls"];

        return [
            {text: `${text} ${postfix ? `(${postfix})` : ""}`, value: toCommas(value)},
        ]
    }

    const addYear = type == GRAPH_POINTS_TYPE.PREVIOUS ? 1 : 0;
    const postfix = Object.keys(data).length == 1 ? "" : GRAPH_TYPE_TEXT[type];

    var points = [];
    if(selectedMonth) {
        var monthWeeks = weeks.filter(w => w["MonthNumber"] == selectedMonth.MonthNumber && w["Year"] == selectedMonth.Year - addYear);
        monthWeeks.forEach(week => {
            for (const key in serviceTypes) {
                const checked = serviceTypes[key];
                if(checked) {
                    var tooltip = {
                        title: week["WeekDescription"],
                        content: getContent(week, postfix, key)
                    }
                    var dataItem = naNIfEmpty(week, capitalizeFirstLetter(key) + "Calls");
                    points.push(new DatasetItem(`${capitalizeFirstLetter(key)} ${postfix ? `(${postfix})` : ""}`, dataItem, tooltip, 
                        type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS[key.toUpperCase()] + COLORS.TRANSPARENT65: COLORS[key.toUpperCase()]));
                }
            }

            var tooltip = {
                title: week["WeekDescription"],
                content: getContent(week, postfix, "total")
            }
            points.push(new DatasetItem(`Total ${postfix ? `(${postfix})` : ""}`, naNIfEmpty(week, "TotalCalls"), tooltip, 
            type == GRAPH_POINTS_TYPE.PREVIOUS ?  COLORS.TOTAL + COLORS.TRANSPARENT65: COLORS.TOTAL));
            labels.push(getXAxisLabelWeek(week));
        });
    }
    else {
        var months = groupBy(weeks, w => w["MonthString"]);
        months.forEach((monthWeeks, monthString) => {
            var week = Object.assign({}, monthWeeks[0]) || { MonthString: "N/A YY" };
            for (const key in serviceTypes) {
                const checked = serviceTypes[key];
                const field = capitalizeFirstLetter(key) + "Calls"; 

                if(checked) {
                    week[field] = monthWeeks.reduce((ac, c) => ac + c[field], 0);
                    var tooltip = {
                        title: getXAxisLabelMonth(week),
                        content: getContent(week, postfix, key)
                    }
                    var dataItem = monthWeeks.every(x => x["IsEmpty"]) ? NaN : week[field];
                    points.push(new DatasetItem(`${capitalizeFirstLetter(key)} ${postfix ? `(${postfix})` : ""}`, dataItem, tooltip,
                        type == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS[key.toUpperCase()]+COLORS.TRANSPARENT65: COLORS[key.toUpperCase()]))
                }
                
            }
            
            week["TotalCalls"] = monthWeeks.reduce((ac, c) => ac + c["TotalCalls"], 0);
            var tooltip = {
                title: getXAxisLabelMonth(week),
                content: getContent(week, postfix, "total")
            }
            var dataItem = monthWeeks.every(x => x["IsEmpty"]) ? NaN : week["TotalCalls"];
            points.push(new DatasetItem(`Total ${postfix ? `(${postfix})` : ""}`, dataItem, tooltip, type == GRAPH_POINTS_TYPE.PREVIOUS ?  COLORS.TOTAL + COLORS.TRANSPARENT65: COLORS.TOTAL))
            labels.push(getXAxisLabelMonth(week, addYear));
        });
    }

    return { points, labels };
}

export const getLinkToServiceHistory = (serviceTypes, unitLocations, { WeekStart, WeekEnd }) => {
    const url = new URL(process.env.VUE_APP_SERVICE_HOST + "FleetCentral/ServiceHistory");
    const baseParams = store.getters[GETTERS.GET_STORED_BASE_PARAMS];
    const tabParams = store.getters[GETTERS.GET_TAB_PARAMS_BY_TAB](TAB_TYPES.SERVICE);

    let href = `${url.href}?`;

    // account
    for (const baseKey in baseParams) {
        const key = SERVICE_HISTORY_KEYS.get(baseKey);

        if(key) {
            if(baseKey == "date_type") continue;
            
            href += `${key}=${baseParams[baseKey]}&`;
        }
    }

    // date
    href += `dr=${DateHelper.getFormattedDate(WeekStart)},${DateHelper.getFormattedDate(WeekEnd)}&`

    let key = "";

    // location codes
    if(!tabParams["is_all_locations"]) {
        key = "locations"; 
        href += `${SERVICE_HISTORY_KEYS.get(key)}=${tabParams[key].toString()}&`;
    }

    // service types
    let serviceKeys = [];
    for (const key in serviceTypes) {
        if(serviceTypes[key]) {
            serviceKeys.push(SERVICE_HISTORY_KEYS.get(key));
        }
    }
    key = "service_type";
    href += `${SERVICE_HISTORY_KEYS.get(key)}=${serviceKeys.toString()}&`;
    
    // unit locations
    let unitLocationKeys = [];
    for (const key in unitLocations) {
        if(unitLocations[key]) {
            unitLocationKeys.push(SERVICE_HISTORY_KEYS.get(key));
        }
    }
    key = "unit_location";
    href += `${SERVICE_HISTORY_KEYS.get(key)}=${unitLocationKeys.toString()}&`;
    
    // customer location regions
    let regions = baseParams['regions'];
    Object.values(regions || {}).forEach(element => {
        if (element.naNumber == baseParams['us_national_account'] && element.code != 'All') {
            href += `${SERVICE_HISTORY_KEYS.get(element.type)}=${element.code}&`;
        }
    });

    return href.slice(0, -1);
}

export const getLinkToHeatMap = (id) => {
    const url = new URL(process.env.VUE_APP_PW_SLOT_HOST + "dashboard/heat-map");

    let href = `${url.href}?account_type=fleet&storage_id=${id}`;

    return href;
}

function getXAxisLabelMonth(month, addYear = 0) {
    const year = (month["Year"] + addYear) || "YYYY";
    return `${MONTHS[month.MonthNumber]} ${year.toString().slice(-2)}`;
}
function getXAxisLabelWeek(week) {
    return week["WeekString"];
}
const naNIfEmpty = (obj, key) => {
    return obj["IsEmpty"] ? NaN : obj[key];
}