import { DateTypes } from "@/utils/enums/DateEnums";

import {
    SELECT_LOCATION_STEP,
    SELECT_DATE_STEP,
    TAB_TYPE_MAP,
    TAB_TYPES,
    DROPDOWN_STATUS_CODES,
    REPORT_TYPE,
} from "@/utils/constants/FleetDashboard.constants.js"

import { PurchaseFilters } from "@/view/pages/fleet-dashboard/scripts/filters/purchases/PurchaseFilters";
import { ServiceFilters } from "@/view/pages/fleet-dashboard/scripts/filters/service/ServiceFilters";
import { RetreadingFilters } from "@/view/pages/fleet-dashboard/scripts/filters/retreading/RetreadingFilters";
import { CasingDurabilityFilters } from "@/view/pages/fleet-dashboard/scripts/filters/casingDurability/CasingDurabilityFilters";

import { mutations } from "./mutations";
import { actions } from "./actions";


export const GETTERS = {
    GET_REPORTS: "(fleet-dashboard getter) get all reports object",
    GET_ACCOUNT_SELECTS: "(fleet-dashboard getter) get accounts dropdown options",
    GET_SERVICE_REPORTS: "(fleet-dashboard getter) get service reports",
    GET_PURCHASE_REPORTS: "(fleet-dashboard getter) get purchase reports",
    GET_RETREADING_REPORTS: "(fleet-dashboard getter) get retreading reports",
    GET_CASING_DURABILITY_REPORTS: "(fleet-dashboard getter) get casing durability reports",
    GET_TEMP_BASKET: "(fleet-dashboard getter) get temp basket",
    GET_FILTERS: "(fleet-dashboard getter) get filters",
    GET_FILTERS_BY_TAB: "(fleet-dashboard getter) get filters by tab",
    GET_FILTERS_BODY_BY_TAB: "(fleet-dashboard getter) get filters post body by tab",
    GET_STORED_BASE_PARAMS: "(fleet-dashboard getter) get saved/stored base params",
    GET_BASE_PARAMS: "(fleet-dashboard getter) get base params",
    GET_TAB_PARAMS_BY_TAB: "(fleet-dashboard getter) get tab params by tab",
    GET_TABS: "(fleet-dashboard getter) get tabs",
    GET_ACCOUNT_STEP: "(fleet-dashboard getter) get account step",
    GET_ACCOUNT_BODY: "(fleet-dashboard getter) get account post body",
    GET_ACCOUNT_REGIONS_BODY: "(fleet-dashboard getter) get account regions post body",
    GET_RESTRICTED_LOCATIONS_CONDITION: "(fleet-dashboard getter) get restricted locations condition",
    GET_DATE_STEP: "(fleet-dashboard getter) get date step",
    GET_DATE_BODY: "(fleet-dashboard getter) get date body",
    GET_OBJECT_TO_STORE: "(fleet-dashboard getter) get object to store",
    GET_EXPORT_TITLES: "(fleet-dashboard getter) get titles for export",
    GET_LOCATIONS_TABLE_BY_TAB: "(fleet-dashboard getter) get table for locations by tab",
    GET_TABLE_BY_INNERTAB_BY_TAB: "(fleet-dashboard getter) get table for filters by inner tab and by tab",
    GET_INNERTAB_BY_TAB: "(fleet-dashboard getter) get inner tab at filters by tab",
    GET_SAVED_REPORTS: "(fleet-dashboard getter) get table for saved reports from api",
    GET_SAVED_REPORTS_BODY: "(fleet-dashboard getter) get post body for saved reports from api",
    GET_BASKET: "(fleet-dashboard getter) get basket",
    GET_BASKET_NAMES: "(fleet-dashboard getter) get basket names",
    GET_BASKET_IS_NOT_EMPTY: "(fleet-dashboard getter) get true if basket is not empty",
    GET_SELECTED_TAB: "(fleet-dashboard getter) get selected tab",
    GET_IS_BASE_PARAMS_CHANGED: "(fleet-dashboard getter) get true or false whenever base params has changed",
    GET_IS_HAS_ADDED_REPORTS: "(fleet-dashboard getter) get true if basket not empty, false other",
    GET_CUSTOMER_LOCATION_GROUPS: "(getter) get customer location groups list",
    GET_CUSTOMER_LOCATION_REGIONS: "(getter) get customer location regions list",
    GET_TIRE_BRANDS: "(fleet-dashboard getter) get tire brands list",
    GET_TIRE_PRODUCT_LINES: "(fleet-dashboard getter) get tire product lines list",
    GET_TIRE_SIZES: "(fleet-dashboard getter) get tire sizes list",
    GET_DROPDOWNS_STATUS: "(fleet-dashboard getter) get loading dropdowns status",
}

export const defaultState = {

    baseParams: {},

    _selects: {
        usNationalAccountOptions: [],
        canadianCommonOwnerOptions: [],
        canadianNonsigOptions: [],
        locationGroupsOptions: null,
    },

    locationGroups: null,
    locationRegions: null,

    tireBrandOptions: [],
    tireProductLineOptions: [],
    tireSizeOption: [],

    dropDownStatus: {
        tireBrandDd: Object.values(REPORT_TYPE).reduce((acc, reportType) => {
            acc[reportType] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
            return acc;
        }, {}),
        tireProductLineDd: Object.values(REPORT_TYPE).reduce((acc, reportType) => {
            acc[reportType] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
            return acc;
        }, {}),
        tireSizeDd:  Object.values(REPORT_TYPE).reduce((acc, reportType) => {
            acc[reportType] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
            return acc;
        }, {}),
    },

    /**************************************************************************
    * STATE FOR SELECT LOCATION STEP
    ***************************************************************************/
    selectLocationStep: {
        title: SELECT_LOCATION_STEP.TITLE,
        number: SELECT_LOCATION_STEP.NUMBER,

        // step logic props
        isOpen: true,  // isOpen or closed step
        loading: false,
        account: {},
        regions: null,
        saved: {},
    },

    /**************************************************************************
    * STATE FOR SELECT DATE RANGE STEP
    ***************************************************************************/
    selectDateStep: {
        // readonly props
        title: SELECT_DATE_STEP.TITLE,
        number: SELECT_DATE_STEP.NUMBER,

        // step logic props
        isOpen: false,  // isOpen or closed step

        // data logic props 
        dateType: DateTypes.LAST_30_DAYS,

        year: null,
        quarter: null,
        quarterFrom: null,
        quarterTo: null,

        dateFrom: null,
        dateTo: null,
        dateFromErr: null,
        dateToErr: null,

        saved: {},
    },

    filters: {
        purchases: new PurchaseFilters(),
        service: new ServiceFilters(),
        retreading: new RetreadingFilters(),
        casingdurability: new CasingDurabilityFilters(),
    },

    selectedTab: TAB_TYPES.PURCHASES,
    tabs: {
        purchases: {
            disabled: false,
            loading: false,
        },
        service: {
            disabled: false,
            loading: false,
        },
        retreading: {
            disabled: false,
            loading: false,
        },
        casingdurability: {
            disabled: false,
            loading: false,
        },
        report: {
            disabled: false,
            loading: false,
        },
    },



    _dashboardTemplates: {
        totalItems: 0,
        isBusy: false,
        numberOfPages: 1,
        currentPage: 1,
        perPage: 20,
        order_by: 7,
        is_sort_desc: true,
        keyword: '',
        items: [],
        fields: [
            { key: 'account_name', label:'Account', sortable: true, thClass: "sorting", thStyle: 'width: 20%'},
            { key: 'report_name', label: 'Name of Report', sortable: true, thClass: "sorting", tdClass: "text-break", thStyle: 'width: 20%'},
            { key: 'report_type_display', label: 'Report Type', sortable: true, thClass: "sorting", thStyle: 'width: 15%'},
            { key: 'report_dates', label: 'Report Dates', sortable: true, thClass: "sorting", thStyle: 'width: 15%'},
            { key: 'date_created', label: 'Date Created', sortable: true, thClass: "sorting", thStyle: 'width: 15%'},
            { key: 'action', label: 'Actions', sortable: false, thStyle: 'width: 15%'}
        ],
        sortEnum: [
            {value: 2, key: 'account_name'},
            {value: 3, key: 'report_name'},
            {value: 4, key: 'report_type_display'},
            {value: 6, key: 'report_dates'},
            {value: 7, key: 'date_created'}
        ]
    },

    /**************************************************************************
    * STATE FOR REPORTS FROM API
    ***************************************************************************/
    reports: {
        purchases: [],
        service: [],
        retreading: [],
        casingdurability:[],
    },
    basketReports: {
        purchases: [],
        service: [],
        retreading: [],
        casingdurability:[],
    },
    basketNames: {
        purchases: "purchases",
        service: "service",
        retreading: "retreading",
        casingdurability: "casing durability",
    },
    tempBasket: {},
    restoredReportParams: {},
    restoredReport: {},
    exportTitles: {
        customerName: '',
        title: '',
        subtitle: '',
        isDefault: true
    },
    isMapUpdating: false
    
}
const state = JSON.parse(JSON.stringify(defaultState));

// getters
const getters = {
    getFDIsMapUpdating(){
        return state.isMapUpdating;
    },
    getFDIsSeparated(){
        return state.separated;
    },
    //#region api getters
    [GETTERS.GET_ACCOUNT_SELECTS]() {
        return state._selects;
    },

    [GETTERS.GET_CUSTOMER_LOCATION_GROUPS]() {
        return state.locationGroups;
    },

    [GETTERS.GET_CUSTOMER_LOCATION_REGIONS]() {
        return state.locationRegions;
    },

    //#endregion
    [GETTERS.GET_IS_HAS_ADDED_REPORTS](){
        var result = false;
        for(const key in state.basketReports){
            if (state.basketReports[key].length) {
                result = true;
                break;
            }
        }
        return result;
    },
    //#region app getters
    [GETTERS.GET_OBJECT_TO_STORE]() {
        var params = {};

        // select account step
        params["selectAccountSaved"] = state.selectLocationStep.saved;
                
        // select date step
        params["selectDateSaved"] = state.selectDateStep.saved;
        
        // select filters
        params["selectFiltersSaved"] = state.filters.service.saved;
        // purchases select filters
        params["selectFiltersSavedPurchases"] = state.filters.purchases.saved;
        // retreading select filters
        params["selectFiltersSavedRetreading"] = state.filters.retreading.saved;
        // casing durability select filters
        params["selectFiltersSavedCasingDurability"] = state.filters.casingdurability.saved;
        // export titles
        params["exportTitles"] = state.exportTitles;

        // basket reports
        params["basketReports"] = state.basketReports;
        
        // other options
        params["selectedTab"] = state.selectedTab;

        return JSON.parse(JSON.stringify(params));
    },
    [GETTERS.GET_EXPORT_TITLES](){
        return state.exportTitles;
    },
    [GETTERS.GET_DATE_STEP]() {
        return state.selectDateStep;
    },
    [GETTERS.GET_DATE_BODY]() {
        let postBody = {
            date_type: state.selectDateStep.saved.dateType,
        }
        let currentDate = new Date();
        switch(postBody.date_type){
            case DateTypes.QUARTER:
                postBody["date_from"] = state.selectDateStep.saved.quarterFrom;
                postBody["date_to"] = state.selectDateStep.saved.quarterTo;
                break;
            case DateTypes.CUSTOM:
                postBody["date_from"] = state.selectDateStep.saved.dateFrom;
                postBody["date_to"] = state.selectDateStep.saved.dateTo;
                break;
            case DateTypes.LAST_30_DAYS:
                postBody["date_to"] = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
                postBody["date_from"] = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
                break;
            case DateTypes.MONTH_TO_DATE:
                postBody["date_to"] = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
                postBody["date_from"] = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
                break;
            case DateTypes.YEAR_TO_DATE:
                postBody["date_to"] = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
                postBody["date_from"] = new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0);
                break;
            default:
                break;
        }

        return postBody;
    },
    [GETTERS.GET_ACCOUNT_STEP]() {
        return state.selectLocationStep;
    },
    [GETTERS.GET_ACCOUNT_REGIONS_BODY]() {
        let cust_loc_regions = [];
        Object.values(state.selectLocationStep.saved.regions || {}).forEach(element => {
            cust_loc_regions.push({ naNumber: element.naNumber, type: element.type, code: element.value, description: element.text });
        });
        return {
            regions: cust_loc_regions,
        }
    },
    [GETTERS.GET_ACCOUNT_BODY]() {
        var accountValue = Object.values(state.selectLocationStep.account || {})[0]?.value;
        var accountKey = Object.keys(state.selectLocationStep.account || {})[0];

        return {
            [accountKey]: accountValue  // ex: us_national_account: 0007288
        }
    },
    [GETTERS.GET_BASE_PARAMS](state, getters) {
        return {
            ...getters[GETTERS.GET_ACCOUNT_BODY],
            ...getters[GETTERS.GET_DATE_BODY],
            ...getters[GETTERS.GET_ACCOUNT_REGIONS_BODY]
        }
    },
    [GETTERS.GET_RESTRICTED_LOCATIONS_CONDITION](state, getters) {
        let cust_loc_regions = getters[GETTERS.GET_ACCOUNT_REGIONS_BODY].regions;
        return (state._selects.locationGroupsOptions && state._selects.locationGroupsOptions.length > 0) || 
            (cust_loc_regions && cust_loc_regions.length > 0 && cust_loc_regions.some(reg => reg.code != 'All'));
    },
    [GETTERS.GET_FILTERS_BODY_BY_TAB]: (state, getters) => (tab) => {
        let locationsNotRestricted = state.filters[tab].saved.isAllAvailableLocations && !getters[GETTERS.GET_RESTRICTED_LOCATIONS_CONDITION];

        return {
            locations: locationsNotRestricted ? [] : (state.filters[tab].saved.selectedIDs || []),
            customer_locations: locationsNotRestricted ? [] : (state.filters[tab].saved.selectedLocations || []),
            is_all_locations: locationsNotRestricted,
            unit_locations: state.filters[tab].saved.unitLocations,
            wheel_positions: state.filters[tab].saved.wheelPositions,
            service_types: state.filters[tab].saved.serviceTypes,
            status: state.filters[tab].saved.status,
        }
    },
    [GETTERS.GET_LOCATIONS_TABLE_BY_TAB]: (state) => (tab) => {

        return state.filters[TAB_TYPE_MAP.get(tab)].table;  
    },
    [GETTERS.GET_TABLE_BY_INNERTAB_BY_TAB]: (state) => (tab, innerTab = 0) => {
        return state.filters[TAB_TYPE_MAP.get(tab)]?.tabs[innerTab]?.table;  
    },
    [GETTERS.GET_INNERTAB_BY_TAB]: (state) => (tab, innerTab = 0) => {
        return state.filters[TAB_TYPE_MAP.get(tab)]?.tabs[innerTab];  
    },
    [GETTERS.GET_TABS]() {
        return state.tabs;
    },
    [GETTERS.GET_SERVICE_REPORTS]() {
        return state.reports.service;
    },
    [GETTERS.GET_PURCHASE_REPORTS]() {
        return state.reports.purchases;
    },
    [GETTERS.GET_RETREADING_REPORTS]() {
        return state.reports.retreading;
    },
    [GETTERS.GET_CASING_DURABILITY_REPORTS]() {
        return state.reports.casingdurability;
    },
    [GETTERS.GET_REPORTS]() {
        return state.reports;
    },
    [GETTERS.GET_TEMP_BASKET]() {
        return state.tempBasket;
    },
    [GETTERS.GET_FILTERS]() {
        return state.filters;
    },
    [GETTERS.GET_FILTERS_BY_TAB]: (state) => (tab) => {
        return state.filters[TAB_TYPE_MAP.get(tab)];
    },
    [GETTERS.GET_STORED_BASE_PARAMS]() {
        return state.baseParams;
    },
    [GETTERS.GET_TAB_PARAMS_BY_TAB]: (state, getters) => (tab) => {
        const current = state.filters[TAB_TYPE_MAP.get(tab)];
        let locationsNotRestricted = current.saved.tabs[0]?.isAll && !getters[GETTERS.GET_RESTRICTED_LOCATIONS_CONDITION];

        return {
            tab_type: current.tabType,
            is_all_locations: locationsNotRestricted,
            locations: locationsNotRestricted ? [] : current.saved.tabs[0]?.selectedIDs,
            customer_locations: locationsNotRestricted ? [] : current.saved.tabs[0]?.selectedLocations,
            is_all_nonsigs: current.saved.tabs[1]?.isAll,
            nonsigs: current.saved.tabs[1]?.isAll ? [] : current.saved.tabs[1]?.selectedIDs,
            status: current.saved.status,
            wheel_positions: current.saved.wheelPositions,
        };
    },
    [GETTERS.GET_SAVED_REPORTS]() {
        return state._dashboardTemplates;
    },
    [GETTERS.GET_SAVED_REPORTS_BODY]() {
        return {
            page_number: state._dashboardTemplates.currentPage,
            page_size: state._dashboardTemplates.perPage,
            order_by: state._dashboardTemplates.order_by,
            is_sort_desc: state._dashboardTemplates.is_sort_desc,
            key_word: state._dashboardTemplates.keyword
        }
    },
    [GETTERS.GET_BASKET](){
        return state.basketReports;
    },
    [GETTERS.GET_BASKET_NAMES](){
        return state.basketNames;
    },
    [GETTERS.GET_BASKET_IS_NOT_EMPTY](){
        let result = true;
        result = Object.keys(state.basketReports).some(r => {
            return !!state.basketReports[r].length;
        })
        return result;
    },
    [GETTERS.GET_SELECTED_TAB]() {
        return state.selectedTab;
    },
    [GETTERS.GET_IS_BASE_PARAMS_CHANGED](state, getters) {
        const BaseParams = getters[GETTERS.GET_BASE_PARAMS];
        const StoredBaseParams = getters[GETTERS.GET_STORED_BASE_PARAMS];

        if(!Object.keys(BaseParams || {}).length) return false;

        const isChanged = JSON.stringify(StoredBaseParams) != JSON.stringify(BaseParams);
        if(isChanged) console.log("base params changed"); 
        return isChanged;
    },
    //#endregion

    [GETTERS.GET_TIRE_BRANDS]: () => (reportType) => {
        const tireBrandsArray = Object.values(state.tireBrandOptions[reportType]||{});
        return tireBrandsArray;
    },
    [GETTERS.GET_TIRE_PRODUCT_LINES]: () => (reportType) => {
        const productLinesArray = Object.values(state.tireProductLineOptions[reportType]||{});
        return productLinesArray;
    },
    [GETTERS.GET_TIRE_SIZES]: () => (reportType) => {
        const tireSizesArray = Object.values(state.tireSizeOption[reportType]||{});
        return tireSizesArray;
    },
    [GETTERS.GET_DROPDOWNS_STATUS]() {
        return state.dropDownStatus;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};