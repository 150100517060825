<template>
    <div id="app">
        <div class="content">
            <header-menu></header-menu>
            
            <router-view
                :key="$route.name"
            >

            </router-view>
            
        </div>
        <footer-menu></footer-menu>
        <Loader ref="AppLoader"></Loader>
        <EmitModal ref="AppEmitModal"></EmitModal>
        <Popup v-if="showSessionTimeoutPopup"
            title="Session expired"
            message="Your session has expired due to inactivity. Please log in again."
            :isOutsideClickable="true"
            @close="closeSessionTimeoutPopup"
            >
            <template v-slot:buttons="buttonsProps">
                <button type="button" class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("OK") }}</button>
            </template>
        </Popup>
        <Popup v-if="showIETooLongRequest"
            title="Too long URL"
            message="Internet explorer doesn't support long URL. Please use different browser."
            :isOutsideClickable="true"
            @close="closeURLISTooLongPopup"
            >
            <template v-slot:buttons="buttonsProps">
                <button type="button" class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("OK") }}</button>
            </template>
        </Popup>
        <Popup v-if="showPermissionsPopup"
            title="Error"
            message="You don't have permissions to this page!"
            :isError="true"
            @close="closePermissionsPopup"
            >
            <template #buttons="buttonsProps">
                <button class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("Close") }}</button>
            </template>
        </Popup>
        <Popup v-if="showPermissionsTMPopup"
            title="Error"
            message="You don't have permissions to this portal!"
            :isError="true"
            @close="closePermissionsTMPopup"
            >
            <template #buttons="buttonsProps">
                <button class="btn btn-primary btn-lg" @click="buttonsProps.close">{{ $trans("Close") }}</button>
            </template>
        </Popup>
        <Popup v-if="show500ErrorPopupEvent"
            title="System Error"
            message="Something went wrong please try again"
            :isError="true"
            @close="close500ErrorPopupEvent"
            >
            <template #buttons="">
                <button class="btn btn-primary btn-lg" @click="close500ErrorPopupEvent">{{ $trans("OK") }}</button>
            </template>
        </Popup>
    </div>
    
</template>

<script>
    import Loader from "@/components/Loader.vue";
    import HeaderMenu from "@/view/menu/HeaderMenu.vue";
    import FooterMenu from "@/view/menu/FooterMenu.vue";
    import { mapGetters, mapActions } from "vuex";
    import { API_VERIFY_AUTH, API_USER_REDIRECT, API_CHECK_PERMISSIONS } from "@/core/services/api/auth.module";
    import { APP_SET_AUTHENTICATED, APP_RESET_INTERFACE } from "@/core/services/app.state.module";
    import Vue from "vue";
    import Popup from './components/Popup.vue';
    import EmitModal from './components/EmitModal.vue';
    export default {
        name: 'app',
        data: function () {
            return {
                // refreshTokenInterval: null,
                callCheckPermissionsInterval: null,
                transitionName: 'slide-left',
                showTermsPopup: false,
                showSessionTimeoutPopup: false,
                showIETooLongRequest: false,
                showPermissionsPopup: false,
                showPermissionsTMPopup: false,
                show500ErrorPopupEvent: false,
            }
        },
        methods: {
            refreshTokenTimer() {
                var now = new Date().getTime();
                //console.log(window._session_timeout_time < now);
                if (this.IsAuthorized && window._session_timeout_time < now) {
                    this.showSessionTimeoutPopup = true;
                    // this.$store.dispatch(API_VERIFY_AUTH)
                    // .catch(() => {
                        this.$store.dispatch(APP_RESET_INTERFACE).catch(() => {});
                        this.showSessionTimeoutPopup = true;
                    // })
                }  
            },
            callCheckPermissions() {
                if(this.User.ApiKeyRefreshTime > 0) {
                    this.$store.dispatch(API_CHECK_PERMISSIONS, { path: location.pathname } );
                }
            },
            closeSessionTimeoutPopup() {
                this.showSessionTimeoutPopup = false;
                this.$store.dispatch(API_USER_REDIRECT);
            },
            closeURLISTooLongPopup() {
                //console.log('closeURLISTooLongPopup');
                this.showIETooLongRequest = false;
                window.gy_app_is_too_long_request_popup = false;

                let return_url_params = new URLSearchParams(location.search);
                if (return_url_params.has('user')) {
                    location.search = "?user="+return_url_params.get('user');
                }
                location.search = "?";

                //this.$store.dispatch(API_USER_REDIRECT);
            },
            closePermissionsPopup() {
                this.showPermissionsPopup = false;
                this.$router.push({ name: "Index"});
            },
            closePermissionsTMPopup() {
                this.showPermissionsTMPopup = false;
                location = (new URL(process.env.VUE_APP_LOGIN_HOST));
            },
            close500ErrorPopupEvent() {
                this.show500ErrorPopupEvent = false;
                this.$root.$emit('close-loader');
            }
        },
        computed: {
			...mapGetters({
                IsAuthorized: "AppIsAuthenticated",
                User: "AppGetUser"
            }),
        },
        created() {
            //refresh every 45sec token
            this.refreshTokenInterval = window.setInterval(this.refreshTokenTimer, 15*1000);
            //this.callCheckPermissionsInterval = window.setTimeout(this.callCheckPermissions, this.User.ApiKeyRefreshTime);
            if (window.gy_app_is_too_long_request_popup != undefined && window.gy_app_is_too_long_request_popup == true) {
                this.showIETooLongRequest = true;
            }
            document.addEventListener('showPermissionsPopupEvent', () => {
                this.showPermissionsPopup = true;
			})
            document.addEventListener('showPermissionsTMPopupEvent', () => {
                this.showPermissionsTMPopup = true;
			})
            document.addEventListener('showSessionTimeoutPopupEvent', () => {
                this.$store.dispatch(APP_SET_AUTHENTICATED, false);
                if (!window.gy_app_is_too_long_request_popup) {
                    this.showSessionTimeoutPopup = true;
                }
				
			})

            document.addEventListener('show500ErrorPopupEvent', () => {
                this.show500ErrorPopupEvent = true;
			})

            document.addEventListener('showURLIsTooLong', () => {
                //this.$store.dispatch(APP_SET_AUTHENTICATED, false);
				this.showIETooLongRequest = true;
                //console.log('event showURLIsTooLong fired');
			})

            document.addEventListener("touchstart", function() {},false);

        },
        beforeDestroy() {
            window.clearInterval(this.refreshTokenInterval);
            //window.clearTimeout(this.callCheckPermissionsInterval);
        },
        components: { HeaderMenu, FooterMenu, Loader, Popup, EmitModal }
    };
</script>

<style src='bootstrap/dist/css/bootstrap.css'></style>
<style src='bootstrap-vue/dist/bootstrap-vue.css'></style>
 
<style >
    @import "@p/assets/styles/common.css"; /*"https://portal.goodyeartrucktires.com/theme/0.0.0/styles/common.css"; */
    @import "@p/assets/styles/universal-menu.min.css"; /*"https://portal.goodyeartrucktires.com/theme/0.0.0/styles/universal-menu.min.css"; */
    @import "https://portal.goodyeartrucktires.com/theme/0.0.0/styles/unauthorized-footer.min.css";

    @import "@p/assets/styles/GYLoginPortal.css";
    @import "@p/assets/styles/custom.css";
    @import "@p/assets/styles/app.css";
    
</style>