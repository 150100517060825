import { BarChartConfig } from "..";
import { Dataset } from "../Dataset";
import { toCommas } from "@/utils/helpers/NumberHelper.js";
import { groupBy } from "@/utils/helpers/ArrayHelper";

class RejectTireRateConfig extends BarChartConfig {
    constructor(current) {
        super(current);

        let currentDataSets = [];

        if(current) {

            let datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    color: items[0]?.color,
                    label: label,
                    stack: items[0]?.datasetId,
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                })
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets);
        }

        this.options.scales.yAxes.push({
            ticks: {
                beginAtZero: true,
                stacked: true,
                padding: 20,
                callback: (value) => {
                    return toCommas(value);
                }
            },
            gridLines:{
                drawOnChartArea: true,
                tickMarkLength: 0
            }
        });

        this.options.scales.xAxes.push({
            stacked: true,
            display: false,
        })

        this.options.scales.xAxes.push(
            {
                id: 'x-axis-id',
                type: 'category',
                offset: true,
                gridLines: {
                    drawOnChartArea: true,
                    offsetGridLines: true,
                    drawTicks: false
                },
                ticks: {
                    padding: 10
                }
            }
        )

        this.options.legend = {
            display: false 
        }
        this.options.title = {
            display: true,
            text: 'Reject Rate (%)',
            position: "left"
        }
    }
}

export {
    RejectTireRateConfig
}