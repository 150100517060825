<template>
        
        <footer id="global-footer" class="fix-to-bottom">
        <div class="container">
            <div class="row" v-show="IsAuthorized">
                <div class="footer-columns">

                    <div v-for="(column, $index) in FooterMenuCalc" :key="$index" :class="'column-'+$index" >
                        <div v-bind:class="{'column col-xl-3 col-md-6 col-12': column.columns == 1, 'column col-xl-4 col-lg-4 col-md-6 col-12': column.columns == 2}"   >    
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="footer-heading">{{ $trans(column.name) }}</div>
                                </div>
                            </div>
                            
                            <div class="row col-1-items" v-if="column.columns == 1">
                                <div class="col-12">
                                    <div class="footer-links">
                                        <div class="footer-link" v-for="link in column.items" :key="link.key">
                                            <a @click="followLink" :href="replaceLink(link.url)" :sso-id="link.sso_id" :target="link.new_window==true ? '_blank' : '_self'">{{ $trans(link.name) }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row col-2-items" v-if="column.columns == 2">
                                <div class="col-xl-6 col-lg-12">
                                    <div class="footer-links">
                                        <div class="footer-link" v-for="link in column.items.slice(0, column.col1)" :key="link.key">
                                            <a @click="followLink" :href="replaceLink(link.url)" :sso-id="link.sso_id" :target="link.new_window==true ? '_blank' : '_self'">{{ $trans(link.name) }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12">
                                    <div class="footer-links">
                                        <div class="footer-link" v-for="link in column.items.slice(column.col1, column.col1+column.col2)" :key="link.key">
                                            <a @click="followLink" :href="replaceLink(link.url)" :sso-id="link.sso_id" :target="link.new_window==true ? '_blank' : '_self'">{{ $trans(link.name) }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div :class="'separator-'+$index" />
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom-section">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-8 offset-md-1" v-if="showPageFooter()">
                    <div class="footer-contact-block">
                        <div class="footer-contact-heading">{{ $trans(data.footerSolutionSupp.header) }}</div>
                        <div class="footer-email"><a :href=data.footerSolutionSupp.emailRef>{{ $trans(data.footerSolutionSupp.email) }}</a></div>
                        <div class="footer-phone"><a :href=data.footerSolutionSupp.phoneRef>{{ $trans(data.footerSolutionSupp.phone) }}</a> {{ $trans(data.footerSolutionSupp.option) }}</div>
                    </div>
                    <div class="footer-contact-block">
                        <div class="footer-contact-heading">{{ $trans(data.footerGTRACSSupp.header) }}</div>
                        <div class="footer-email"><a :href=data.footerGTRACSSupp.emailRef>{{ $trans(data.footerGTRACSSupp.email) }}</a></div>
                        <div class="footer-phone"><a :href=data.footerGTRACSSupp.phoneRef>{{ $trans(data.footerGTRACSSupp.phone) }}</a> {{ $trans(data.footerGTRACSSupp.option) }}</div>
                    </div>
                    <div class="footer-contact-block">
                        <div class="footer-contact-heading">{{ $trans(data.footerRoadSupp.header) }}</div>
                        <div class="footer-email"><a :href=data.footerRoadSupp.emailRef>{{ $trans(data.footerRoadSupp.email) }}</a></div>
                        <div class="footer-phone"><a :href=data.footerRoadSupp.phoneRef>{{ $trans(data.footerRoadSupp.phone) }}</a> {{ $trans(data.footerRoadSupp.option) }}</div>
                    </div>
                    <div class="footer-contact-block">
                        <div class="footer-contact-heading">{{ $trans(data.footerTireHqSupp.header) }}</div>
                        <div class="footer-email"><a :href=data.footerTireHqSupp.emailRef>{{ $trans(data.footerTireHqSupp.email) }}</a></div>
                        <div class="footer-phone"><a :href=data.footerTireHqSupp.phoneRef>{{ $trans(data.footerTireHqSupp.phone) }}</a> {{ $trans(data.footerTireHqSupp.option) }}</div>
                    </div>
                    <div class="footer-contact-block">
                        <div class="footer-contact-heading">{{ $trans(data.footerExpAppSupp.header) }}</div>
                        <div class="footer-email"><a :href=data.footerExpAppSupp.emailRef>{{ $trans(data.footerExpAppSupp.email) }}</a></div>
                        <div class="footer-phone"><a :href=data.footerExpAppSupp.phoneRef>{{ $trans(data.footerExpAppSupp.phone) }}</a> {{ $trans(data.footerExpAppSupp.option) }}</div>
                    </div>
                </div>
                <div class="col-12 col-md-5 col-lg-3 copyright-link">
                    <div>&copy; {{ new Date().getFullYear() }} {{ $trans(data.footerCompanyName) }}</div>
                    <div><a :href=data.footerTermsLink target="_blank">{{ $trans(data.footerTerms) }}</a></div>
                </div>
            </div>
        </div>
        
    </footer>
    
	
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    import { isMobileBrowser } from "@/scripts/mobileBrowserDetect.module";

    import { API_VERIFY_AUTH } from "@/core/services/api/auth.module";

	export default {
        name: "footer-menu",
        props:["footer"],
        data() {
            return {
                data:{
                    footerSolutionSupp:{
                        header:"Solutions Support",
                        email:"solutions@goodyear.com",
                        emailRef:"mailto:solutions@goodyear.com",
                        phone:"866-503-0510",
                        phoneRef:"tel:866-503-0510",
                        option:""
                    },
                    footerGTRACSSupp: {
                        header:"GTRACS Support",
                        email:"gtracssupport@goodyear.com",
                        emailRef:"mailto:gtracssupport@goodyear.com",
                        phone:"800-335-1965",
                        phoneRef:"tel:800-335-1965",
                        option:""
                    },
                    footerRoadSupp: {
                        header:"Road Service Support",
                        email:"fhq_review@goodyear.com",
                        emailRef:"mailto:fhq_review@goodyear.com",
                        phone:"866-353-3847",
                        phoneRef:"tel:866-353-3847",
                        option:"Option 4"
                    },
                    footerTireHqSupp:{
                        header:"Tire-HQ Support",
                        email:"tirehq.helpdesk@goodyear.com",
                        emailRef:"mailto:tirehq.helpdesk@goodyear.com",
                        phone:"800-331-4541",
                        phoneRef:"tel:800-331-4541",
                        option:"Ext. 5"
                    },
                    footerExpAppSupp:{
                        header:"Expedited Approvals Support",
                        email:"expedited_approvals@goodyear.com",
                        emailRef:"mailto:expedited_approvals@goodyear.com",
                        phone:"866-503-0510",
                        phoneRef:"tel:866-503-0510",
                        option:"Option 5"
                    },
                    footerCompanyName: "Goodyear Fleet Central",
                    footerTerms: "Terms & Conditions and Privacy Policy",
                    footerTermsLink: "http://www.goodyear.com/legal/privacy.html",

                    

                }
            }
        },
        computed: {
            //console: () => console,
            ...mapGetters({
                UserState: "AppGetUser",
                IsAuthorized: "AppIsAuthenticated",
                FooterMenuItems: "AppGetFooterMenuItems",
                FooterMenuCalc: "AppGetFooterMenuItemsCalc"
            })  
        },
        methods: {
            followLink(e) {
                e.preventDefault();
				this.$store.dispatch(API_VERIFY_AUTH)
				.then(() => {
					let followLink= e.target.closest("a");
                    let ssoId = followLink.getAttribute('sso-id');
					if (followLink) {
						let url = new URL(followLink.href);
                        var a = document.createElement('a');
                        if(ssoId == 1){
							a.href = followLink.href + `?mode=sso&token=${encodeURIComponent(this.UserState.GyToken)}&email=${this.UserState.Email}&source=fleet-central`;
						}
						else {
                            if (url.search == "") {
                                a.href = followLink.href + "?user=" + this.UserState.GyToken + "&lang=" + localStorage.getItem('USER_LANGUAGE');
                            } else {
                                a.href = followLink.href + "&user=" + this.UserState.GyToken + "&lang=" + localStorage.getItem('USER_LANGUAGE');
                            }
                        }

                        if (isMobileBrowser()) {
                            a.target = "_self";
                        }
                        else {
                            a.target = followLink.target;
                        }
						document.body.appendChild(a);
                        //console.log(a.href)
						a.click();
					}
				})
			},
            calculateWideOfColumnesFooter() {
                
                let countOfItems = 0;
                let oneColumn = 0;
                if (this.FooterMenuItems != null) {
                    for (let option_index in this.FooterMenuItems.tabs) {
                        countOfItems = this.FooterMenuItems.tabs[option_index].links.length;
                        //console.log(countOfItems);
                        if (countOfItems >= 49) {
                            oneColumn = parseInt(countOfItems / 2);
                            this.FooterMenuCalc.push({
                                columns: 2,
                                col1: oneColumn,
                                col2: countOfItems-oneColumn,
                                name: this.FooterMenuItems.tabs[option_index].name,
                                items: this.FooterMenuItems.tabs[option_index].links
                            });
                        } else {
                            this.FooterMenuCalc.push({
                                columns: 1, 
                                name: this.FooterMenuItems.tabs[option_index].name,
                                items: this.FooterMenuItems.tabs[option_index].links
                            });
                        }
                    }
                } else {
                    this.FooterMenuCalc = [];
                }
                
                //console.log(this.footer_column_size);
            },
			replaceLink(link) {
				if(link.includes(process.env.VUE_APP_REPLACE_TARGET)) {
					return link.replace(process.env.VUE_APP_REPLACE_TARGET, process.env.VUE_APP_REPLACE_DOMEN);
				}
				if(link.includes(process.env.VUE_APP_REPLACE_TARGET_TM)) {
					return link.replace(process.env.VUE_APP_REPLACE_TARGET_TM, process.env.VUE_APP_REPLACE_DOMEN_TM);
				}
				return link;
            },
            showPageFooter() {
                const currentRegionName = process.env.VUE_APP_REGION_NAME || 'NA';

                return currentRegionName === 'NA';
            }
        },
        mounted() { 
            //this.calculateWideOfColumnesFooter();             
        },
        created() {
            document.addEventListener('click', this.documentClicked);
            
            
        },
		destroyed() {
			document.removeEventListener('click', this.documentClicked);
		}
    };
</script>

<style  scoped>

/* #global-footer .container {
    width: 1170px;
} */

#global-footer .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

#global-footer .container .footer-columns .column-3 .column {
    border-right: 0px solid rgba(255,255,255,.1);
}

@media (max-width: 1200px) {
    /* #global-footer .container {
        width: 1170px;
    } */
    #global-footer .container .footer-columns .separator-1 { 
        clear: both;
        display: table;
        padding: 15px;
    }
    #global-footer .container .footer-columns .column-1 .column {
        border-right: 0px solid rgba(255,255,255,.1);
    }
}
/* @media (max-width: 992px) {
    #global-footer .container {
        width: 970px;
    } */
    
/* } */
@media (max-width: 395px) {
    /* #global-footer .container {
        width: 375px !important;
    } */
    
}
@media (max-width: 768px) {
    /* #global-footer .container {
        width: 750px;
    } */
    .footer-bottom-section {
        padding: 20px;
    }
}

@media (max-width: 770px) {
    #global-footer .col-md-12, #global-footer .column {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>
