import ApiService from "@/core/services/api.service";

// action types
export const API_TSR_REPORT_LOAD = "action_tsr_report_load";
export const API_TSR_REPORT_TO_PDF_LOAD = "action_tsr_report_to_pdf_load";
export const API_TSR_REPORT_TO_EXCEL_LOAD = "action_tsr_report_to_excel_load";

// muts
export const API_TSR_MUTS_SET_REPORT = "muts_tsr_set_report"; 

const state = {
    CoverPage: null,
    LocationsPage: null,
    TorqueSummaryPage: null,
    ReportData: null,
}

const getters = {
    getTSRCoverPage() {
        return state.CoverPage;
    },
    getTSRLocationsPage() {
        return state.LocationsPage;
    },
    getTSRTorqueSummaryPage() {
        return state.TorqueSummaryPage;
    },
    getTSRReportData() {
        return state.ReportData;
    },
}

const actions = {
    [API_TSR_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("torque_summary_report/get_report", params, context)
                .then(({ data }) => {
                    context.commit(API_TSR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_TSR_REPORT_TO_PDF_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("torque_summary_report/download_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_TSR_REPORT_TO_EXCEL_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("torque_summary_report/download_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_TSR_MUTS_SET_REPORT](state, {data}) {
        state.CoverPage = data.cover_page;
        state.LocationsPage = data.locations_page;
        state.TorqueSummaryPage = data.torque_summary_page;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};