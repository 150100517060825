import {
    SESSION_STORAGE_KEYS
} from "@/utils/constants/FleetDashboard.constants.js"

export default {
    getValue(key) {
        return sessionStorage[key];
    },
    getTabId() {
        return  sessionStorage[SESSION_STORAGE_KEYS.TAB_ID] ? sessionStorage[SESSION_STORAGE_KEYS.TAB_ID] : 
                sessionStorage[SESSION_STORAGE_KEYS.TAB_ID] = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    },
    remove(key) {
        sessionStorage.removeItem(key);
    },
    setValue(key, value) {
        return sessionStorage[key] = value;
    }
}