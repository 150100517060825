import ApiService from "@/core/services/api.service";
import {API_PAR_MUTS_SET_REPORTS} from './product.analysis.report.module';

// action types
export const API_PAR_ACCOUNTS_LOAD = "action_par_accounts_load";
export const API_PAR_ACCOUNT_IDS_LOAD = "action_par_account_ids_load";
export const API_PAR_ALL_SELECTS_LOAD = "action_par_all_selects_load";


// muts
export const API_PAR_MUTS_SET_ACCOUNTS = "muts_par_set_accounts";
export const API_PAR_MUTS_SET_ACCOUNT_IDS = "muts_par_set_account_ids";
export const API_PAR_MUTS_SET_ALL_SELECTS = "muts_par_set_all_selects";

const state = {
    countries: [],
    fleets: {
        national_global_account_names: [],
        canadian_national_global_account_names: [],
        government_account_names: [],
    },
    is_show_unknown: false,
    is_show_localbook: false,
    is_show_goverment: false,
    is_show_preferred_fleet: false,
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },
    vocations: [],
    table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    account_ids: [],
    is_account_verification_failed: false,
    verified_accounts: []
}

const getters = {
    getPARShowTypes() {
        console.log("state", state)
        return {
            is_show_unknown: state.is_show_unknown,
            is_show_localbook: state.is_show_localbook,
            is_show_goverment: state.is_show_goverment,
            is_show_preferred_fleet: state.is_show_preferred_fleet
        };
    },
    getPARCountries() {
        return state.countries;
    },
    getPARFleetsNA() {
        return state.fleets.national_global_account_names;
    },
    getPARFleetsCA() {
        return state.fleets.canadian_national_global_account_names;
    },
    getPARFleetsG() {
        return state.fleets.government_account_names;
    },
    getPARStatesCA() {
        return state.states.CA_states;
    },
    getPARStatesUS() {
        return state.states.US_states;
    },
    getPARStatesMX() {
        return state.states.MX_states;
    },
    getPARStatesCO() {
        return state.states.CO_states;
    },
    getPARStatesAU() {
        return state.states.AU_states;
    },
    getPARStatesCN() {
        return state.states.CN_provinces;
    },
    getPARVocations() {
        return state.vocations;
    },
    getPARAccountsTable() {
        return state.table;
    },
    getPARAccountIds() {
        return state.account_ids;
    },
    getPARAccountVerification() {
        return state.is_account_verification_failed;
    },
    getPARVerifiedAccounts() {
        return state.verified_accounts
    }
}

const actions = {
    [API_PAR_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_ACCOUNT_IDS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("product_analysis_report/select_account_ids", context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_ACCOUNT_IDS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_PAR_ALL_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("product_analysis_report/get_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_PAR_MUTS_SET_ALL_SELECTS, {data});
                    context.commit(API_PAR_MUTS_SET_REPORTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_PAR_MUTS_SET_ACCOUNTS](state, {data}) {
        state.table.accounts = data.accounts;
        state.table.page_number = data.page_number;
        state.table.page_size = data.page_size;  
        state.table.total_items = data.total_items; 
        state.table.total_selected_items = data.total_selected_items;  
    },
    [API_PAR_MUTS_SET_ACCOUNT_IDS](state, {data}) {
        state.account_ids = data.account_ids; 
    },
    [API_PAR_MUTS_SET_ALL_SELECTS](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;  

        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;

        state.countries = data.countries;

        state.vocations = data.vocations.filter(item => item.value);

        state.is_show_localbook = data.is_show_localbook;
        state.is_show_unknown = data.is_show_unknown;
        state.is_show_goverment = data.is_show_goverment;
        state.is_show_preferred_fleet = data.is_show_preferred_fleet;
        state.is_account_verification_failed = data.is_accounts_verification_failed;
        state.verified_accounts = data.verified_account;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
