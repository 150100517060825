import {
    REPORT_TYPE,
    INCLUDE_OPTIONS_MAP,
    REPORT_PARTS,
    SET_TAB_REPORTS_ACTIONS
} from "@/utils/constants/FleetDashboard.constants.js";

import { Top5ServicingDealersReport } from "./tabs/service/Top5ServicingDealersReport";
import { CallsByMonthReport } from "./tabs/service/CallsByMonthReport";
import { CallsByStateReport } from "./tabs/service/CallsByStateReport";
import { AvgRollTimeReport } from "./tabs/service/AvgRollTimeReport";

import { EmergencyServiceReport } from "./tabs/purchase/EmergencyServiceReport";
import { RetreadSalesToNewTiresSales } from "./tabs/purchase/RetreadSalesToNewTiresSalesReport";
import { EmergencyVSScheduledReport } from "./tabs/purchase/EmergencyVSScheduledReport";
import { RepairAndRetreadCostReport } from './tabs/purchase/RepairAndRetreadCostReport';
import { TopProductsReport } from "./tabs/purchase/TopProductsReport";

import { Top5RetreadersReport } from "./tabs/retreading/Top5RetreadersReport";
import { RejectedTireFailuresByReasonReport } from "./tabs/retreading/RejectedTireFailuresByReasonReport";
import { RetreadTurnaroundTimeReport } from './tabs/retreading/RetreadTurnaroundTimeReport';
import { RepairSummaryReport } from "./tabs/retreading/RepairSummaryReport";

import { StatisticsReport } from "./tabs/casing-durability/StatisticsReport";
import { Top10CasingBrandsReport } from "./tabs/casing-durability/Top10CasingBrandsReport";
import { Top10BrandsAvgAgeReport } from "./tabs/casing-durability/Top10BrandsAvgAgeReport";
import { AvgPreviousCapsReport } from "./tabs/casing-durability/AvgPreviousCapsReport";
import { RejectTireRateReport } from "./tabs/casing-durability/RejectTireRateReport";
import { CasingsSubmittedReport } from "./tabs/casing-durability/CasingsSubmittedReport";

import { ReportModels } from "./Report";

export default {
    createWithFilters(data, filters) {
        let settings;
        if(filters) {
            settings = {
                unitLocations: filters.unitLocations, 
                serviceTypes: filters.serviceTypes,
                wheelPositions: filters.wheelPositions, 
                tireStatuses: filters.status,
            }
        }

        return this._create(data, settings, null, null);
    },
    createOnExistingReport(data, report) {
        return this._create(data, report?.settings, report?.models, report?.loader);
    },
    createWithFiltersOnExistingReportForAction(data, report, filters, action) {
        // extract models, settings and loader from report param
        let models = report.models;
        let settings = report.settings;
        let loader = report.loader;

        switch (action) {
            case SET_TAB_REPORTS_ACTIONS.RUN_ON_CLICK:
                if(filters) {
                    settings = { 
                        ...settings, 
                        unitLocations: filters.unitLocations, 
                        serviceTypes: filters.serviceTypes,
                        wheelPositions: filters.wheelPositions, 
                        tireStatuses: filters.status,
                    };
                }
                break;
            case SET_TAB_REPORTS_ACTIONS.RUN_WITH_CHANGE:
                let addedPart = models.addedPart;
                models = new ReportModels({
                    addedPart: addedPart
                });
                if(filters) {
                    settings = { 
                        ...settings, 
                        unitLocations: filters.unitLocations, 
                        serviceTypes: filters.serviceTypes,
                        wheelPositions: filters.wheelPositions, 
                        tireStatuses: filters.status,
                    };
                }
                break;
            case SET_TAB_REPORTS_ACTIONS.RESTORE_AS_EDIT:
                models.addedPart = null;
                break;
    
            default:
                break;
        }

        return this._create(data, settings, models, loader);
    },
    _create(data, settings, models, loader) {
        switch (data.report_type) {
            // service tab
            case REPORT_TYPE.TOP_5_SERVICING_DEALERS:
                return new Top5ServicingDealersReport(data, settings, models, loader);
            case REPORT_TYPE.FLEET_HQ_CALLS_BY_MONTH:
                return new CallsByMonthReport(data, settings, models, loader);
            case REPORT_TYPE.FLEET_HQ_CALLS_BY_STATE:
                return new CallsByStateReport(data, settings, models, loader);
            case REPORT_TYPE.FLEET_HQ_CALLS_AVG_ROLL_TIME:
                return new AvgRollTimeReport(data, settings, models, loader);

            // purchases tab
            case REPORT_TYPE.EMERGENCY_SERVICE:
                return new EmergencyServiceReport(data, settings, models, loader);
            case REPORT_TYPE.RETREAD_SALES_TO_NEW_TIRES_SALES: 
                return new RetreadSalesToNewTiresSales(data, settings, models, loader);
            case REPORT_TYPE.SERVICE_EMERGENCY_VS_SCHEDULED: 
                return new EmergencyVSScheduledReport(data, settings, models, loader);
            case REPORT_TYPE.REPAIR_AND_RETREAD_COST:
                return new RepairAndRetreadCostReport(data, settings, models, loader);
            case REPORT_TYPE.TOP_PRODUCTS:
                return new TopProductsReport(data, settings, models, loader);

            // retreading tab
            case REPORT_TYPE.TOP_5_RETREADERS:
                return new Top5RetreadersReport(data, settings, models, loader);
            case REPORT_TYPE.REJECTED_TIRE_FAILURES_BY_REASON: 
                return new RejectedTireFailuresByReasonReport(data, settings, models, loader);
            case REPORT_TYPE.RETREAD_TURNAROUND_TIME: 
                return new RetreadTurnaroundTimeReport(data, settings, models, loader);
            case REPORT_TYPE.REPAIR_SUMMARY:
                return new RepairSummaryReport(data, settings, models, loader);

            // casing durability tab
            case REPORT_TYPE.STATISTICS_CASING_DURABILITY:
                return new StatisticsReport(data, settings, models, loader);
            case REPORT_TYPE.TOP_10_CASING_BRANDS_CASING_DURABILITY:
                return new Top10CasingBrandsReport(data, settings, models, loader);
            case REPORT_TYPE.TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY:
                return new Top10BrandsAvgAgeReport(data, settings, models, loader);
            case REPORT_TYPE.AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY:
                return new AvgPreviousCapsReport(data, settings, models, loader);
            case REPORT_TYPE.REJECT_TIRE_RATE_CASING_DURABILITY:
                return new RejectTireRateReport(data, settings, models, loader);
            case REPORT_TYPE.CASINGS_SUBMITTED_CASING_DURABILITY:
                return new CasingsSubmittedReport(data, settings, models, loader);
                
            default:
                return null;
        }
    },
    update(data, report, part) {
        let updatedReport = this.createOnExistingReport(data, report);

        switch (part) {
            case INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_GRAPH):
                if(updatedReport.hasOwnProperty('tableItems')) {
                    updatedReport.tableItems = report.tableItems;
                }
                break;
            case INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_TABLE):
                if(updatedReport.hasOwnProperty('heatMapImage')) {
                    updatedReport.heatMapImage = report.heatMapImage;
                }
                if(updatedReport.hasOwnProperty('chartItems')) {
                    updatedReport.chartItems = report.chartItems;
                }
                break;
        
            default:
                break;
        }

        return updatedReport;
    },
    
}