import { Report } from "../../Report";
import { AvgPreviousCapsConfig } from "../../../charts/bars/casing-durability/AvgPreviousCapsConfig";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";

import {
    TAB_TYPES,
    TableFields,
    WheelPosition,
    TireStatus,
} from "@/utils/constants/FleetDashboard.constants.js";
import { ACTIONS } from '@/core/services/api/fleet-dashboard/actions';
import {
    mapSettingOptionsWithDefaults,
    getAvgPreviousCaps,
} from "./CasingDurabilityReportHelper";

import store from "@/core/services/store";

class AvgPreviousCapsReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.CASING_DURABILITY}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
        }

        if(data?.graphs) {
            this.chartItems = data.graphs["0"];
        }
        
        const reportType = data.report_type; 
        if(data?.filters?.brands) {
            !this.settings.tireBrands && (this.settings.tireBrands = mapSettingOptionsWithDefaults(data?.filters.brands));
            let brands = data.filters.brands;
            store.dispatch(ACTIONS.SET_TIRE_BRAND_OPTIONS, {brands,reportType});
        }
        if(data?.filters?.product_lines) {
            !this.settings.tireProductLines && (this.settings.tireProductLines = mapSettingOptionsWithDefaults(data?.filters.product_lines));
            let productLines = data.filters.product_lines;
            store.dispatch(ACTIONS.SET_TIRE_PRODUCT_LINE_OPTIONS, {productLines,reportType});
        }
        if(data?.filters?.tire_sizes) {
            !this.settings.tireSizes && (this.settings.tireSizes = mapSettingOptionsWithDefaults(data?.filters.tire_sizes));
            let tireSizes = data.filters.tire_sizes;
            store.dispatch(ACTIONS.SET_TIRE_SIZE_OPTIONS, {tireSizes,reportType});
        }

        this.settings.loadAfter.tireBrands = true;
        this.settings.loadAfter.tireProductLines = true;
        this.settings.loadAfter.tireSizes = true;

        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.settings.isComparePrev = null;
        this.tableFields = TableFields[this.reportType];

        this.isTableSelectable = false;
    }

    tableItems = [];
    tableFields = [];

    graphTitle = "Top 5 Brands Previous Times Cap";
    tableDisclaimer = "Previous caps should be incremented by 1 for all finished retread items. Because previous caps data is collected at initial inspection, we know that any completed item was successfully retreaded an additional time."

    getChartConfig(id) {
        let config = new AvgPreviousCapsConfig(getAvgPreviousCaps(this.chartItems));
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        return this.tableFields;
    };

    getTableItems() {
        return this.tableItems;
    };

    getDisabledAddBtn() {
        return !(this.tableItems.length);
    };

    setSettingsBefore() {
        //this.settings.tireBrands = null;
        //this.settings.tireProductLines = null;
        //this.settings.tireSizes = null;
    };

    setSettingsAfter() {
        //!this.settings.tireBrands && (this.settings.tireBrands = mapSettingOptionsWithDefaults(store.getters[GETTERS.GET_TIRE_BRANDS]));
       // !this.settings.tireProductLines && (this.settings.tireProductLines = mapSettingOptionsWithDefaults(store.getters[GETTERS.GET_TIRE_PRODUCT_LINES]));
        //!this.settings.tireSizes && (this.settings.tireSizes = mapSettingOptionsWithDefaults(store.getters[GETTERS.GET_TIRE_SIZES]));
    };
}

export {
    AvgPreviousCapsReport
}