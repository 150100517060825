import Vue from "vue";
import Router from "vue-router";
import { ReportSecurities, DashboardSecurities } from '@/utils/enums/SecurityEnums.js';
import { RouteTypes, ReportDisplayNames } from '@/utils/enums/RouterEnums.js';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "Index",
            component: () => import("@/view/pages/index.vue"),
            children:[

            ],
            meta: { requiresAuth: true, title: 'Reports' }
        }, 
        //membership
        /*{
            path: "/membership",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "login",
                    name: "Login",
                    component: () => import("@/view/pages/membership/Login.vue"),
                    meta: { requiresAuth: false, title: 'Login' }
                }
            ]
        },*/
        // dashboard
        // {
        //     path: "/dashboard",
        //     component: () => import("@/view/layout/Layout"),
        //     children: [  
        //         {
        //             path: "tire-management",
        //             name: "tire-management-dashboard",
        //             component: () => import("@/view/pages/dashboard/Home.vue"),
        //             meta: { requiresAuth: true, title: 'Dashboard' }
        //         }
        //     ]
        // },
        // tireoptix
        {
            path: '/tireoptix',
            name: 'TireOptix_Reporting_Search',
            component: () => import("@/view/pages/tireoptix/TireOptixReporting.vue"),
            meta: {requiresAuth: true, title: 'Tire Optix Reporting'}
        },
        {
            path: '/tireoptix/detail/:id',
            name: 'TireOptix_Reporting_Detail',
            component: () => import("@/view/pages/tireoptix/TireOptixDetail.vue"),
            meta: {requiresAuth: true, title: 'Detail of Tire Optix Reporting'}
        },
        {
            path: '/dlrtireoptix',
            name: 'Dealer_TireOptix_Reporting_Search',
            component: () => import("@/view/pages/tireoptix/DealerReport.vue"),
            meta: { requiresAuth: true, title: 'Dealer Tire Optix Reporting' }
        },
        
        // checkpoint
        {
            path: '/checkpoint',
            name: 'CheckPoint_Reporting_Search',
            component: () => import("@/view/pages/checkpoint/CheckPointReporting.vue"),
            meta: {requiresAuth: true, title: 'CheckPoint Reporting'}
        },
        {
            path: '/checkpoint/detail/:id',
            name: 'CheckPoint_Reporting_Detail',
            component: () => import("@/view/pages/checkpoint/CheckPointDetail.vue"),
            meta: {requiresAuth: true, title: 'Detail of CheckPoint Reporting'}
        },

        // lease sales
        {
            path: '/leasesales/configurator',
            name: 'Lease_Sales_Configurator',
            component: () => import("@/view/pages/leasesales/LeaseSalesConfigurator.vue"),
            meta: {requiresAuth: true, title: 'CheckPoint Leasing Sales Configurator'}
        },
        
        // trailer-readiness-report
        {
            path: '/trailer-readiness-report/:id',
            name: 'TrailerReadinessReport',
            component: () => import("@/view/pages/trailer-readiness-report/TrailerReadinessReport.vue"),
            meta: {requiresAuth: true, title: 'Trailer Readiness Report'}
        },
        // reports/surveys/.../unit/
        {
            path: '/reports/surveys/:surveyId/unit/:unitId/images',
            name: 'Surveys_UnitImages',
            component: () => import("@/view/pages/reports/surveys/UnitImages.vue"),
            meta: {requiresAuth: true, title: 'Unit Images of Survey'}
        },
        // reports/surveys/.../tire/
        {
            path: '/reports/surveys/:surveyId/tire/:tireId/images',
            name: 'Surveys_TireImages',
            component: () => import("@/view/pages/reports/surveys/TireImages.vue"),
            meta: {requiresAuth: true, title: 'Tire Images of Survey'}
        },

        // scrap-analysis-report
        {
            path: '/scrap-analysis-report',
            name: 'ScrapAnalysisReport',
            component: () => import("@/view/pages/scrap-analysis-report/ScrapAnalysisReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT, 
                displayName: ReportDisplayNames.SCRAP_ANALYSIS,
                security: ReportSecurities.SCRAP_ANALYSIS
            }
        },
        // fleet-condition-report
        {
            path: '/fleet-condition-report',
            name: 'FleetConditionsReport',
            component: () => import("@/view/pages/fleet-conditions-report/FleetConditionsReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT,
                displayName: ReportDisplayNames.FLEET_CONDITION,
                security: ReportSecurities.FLEET_CONDITION
            }
        },

        // product-analysis-report
        {
            path: '/product-analysis-report',
            name: 'ProductAnalysisReport',
            component: () => import("@/view/pages/product-analysis-report/ProductAnalysisReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT,
                displayName: ReportDisplayNames.PRODUCT_ANALYSIS,
                security: ReportSecurities.PRODUCT_ANALYSIS
            }
        },

        // product-wear-rate-report
        {
            path: '/product-wear-rate-report',
            name: 'ProductWearRateReport',
            component: () => import("@/view/pages/product-wear-rate-report/ProductWearRateReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT,
                displayName: ReportDisplayNames.PRODUCT_WEAR_RATE,
                security: ReportSecurities.PRODUCT_WEAR_RATE
            }
        },

        // unit-history-report
        {
            path: '/unit-history-report',
            name: 'UnitHistoryReport',
            component: () => import("@/view/pages/unit-history-report/UnitHistoryReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT,
                displayName: ReportDisplayNames.UNIT_HISTORY,
                security: ReportSecurities.UNIT_HISTORY,
            },
        },

        // torque-summary-report
        {
            path: '/torque-summary-report',
            name: 'TorqueSummaryReport',
            component: () => import("@/view/pages/torque-summary-report/TorqueSummaryReport.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.REPORT,
                displayName: ReportDisplayNames.TORQUE_SUMMARY,
                security: ReportSecurities.TORQUE_SUMMARY,
            },
        },

        // locations
        {
            path: '/locations',
            name: 'FleetSurvey_Locations',
            component: () => import("@/view/pages/fleet-survey/FleetSurvey.vue"),
            meta: {requiresAuth: true},
            children: [
                // locations/
                {
                    path: '',
                    name: 'FleetSurvey_SelectLocation',
                    component: () => import("@/view/pages/fleet-survey/SelectLocation.vue"),
                    meta: {requiresAuth: true},
                },
                // locations/{locationId}
                {
                    path: '/locations/:locationId',
                    name: 'FleetSurvey_SelectedLocation',
                    redirect: {
                        name: 'FleetSurvey_Dashboard'
                    },
                    meta: {requiresAuth: true},
                },
                // locations/{locationId}/surveys
                {
                    path: '/locations/:locationId/surveys',
                    name: 'FleetSurvey_SurveyOverview',
                    component: () => import("@/view/pages/fleet-survey/survey-overview/SurveyOverview.vue"),
                    meta: {requiresAuth: true},
                },
                // locations/{locationId}/dashboard
                {
                    path: '/locations/:locationId/dashboard',
                    name: 'FleetSurvey_Dashboard',
                    component: () => import("@/view/pages/fleet-survey/dashboard/Dashboard.vue"),
                    meta: {requiresAuth: true}
                },
                // locationsy/{locationId}/surveys/add
                {
                    path: '/locations/:locationId/surveys/add',
                    name: 'FleetSurvey_Add',
                    component: () => import("@/view/pages/fleet-survey/create-survey/CreateFleetSurvey.vue"),
                    meta: {requiresAuth: true},
                },
            ]
        },
        //locations/{locationId}/dashboard/units
        {
            path: '/locations/:locationId/dashboard/units',
            name: 'FleetSurvey_DashboardUnits',
            meta: {requiresAuth: true},
            redirect: {
                name: 'FleetSurvey_Dashboard'
            }
        },
        //locations/{locationId}/units/add
        {
            path: '/locations/:locationId/units/add',
            name: 'FleetSurvey_CreateUnit',
            component: () => import("@/view/pages/fleet-survey/create-unit/CreateUnitPage.vue"),
            meta: {requiresAuth: true},
        },
        //locations/{locationId}/units/edit-:id
        {
            path: '/locations/:locationId/units/edit-:unitId',
            name: 'FleetSurvey_EditUnit',
            component: () => import("@/view/pages/fleet-survey/create-unit/EditUnitPage.vue"),
            meta: {requiresAuth: true},
        },
        ///locations/:locationId/survey-details/edit-:surveyId
        {
            path: '/locations/:locationId/survey-details/edit-:surveyId',
            name: 'FleetSurvey_EditSurveyDetails',
            component: () => import("@/view/pages/fleet-survey/create-survey/EditSurveyDetailsPage.vue"),
            meta: {requiresAuth: true},
        },
        //locations/{locationId}/units/edit
        {
            path: '/locations/:locationId/units/edit',
            name: 'FleetSurvey_EditUploadedUnit',
            component: () => import("@/view/pages/fleet-survey/create-unit/EditUploadedUnitPage.vue"),
            meta: {requiresAuth: true},
        },
        // fleet-survey
        {
            path: '/fleet-survey',
            name: 'FleetSurvey',
            meta: {requiresAuth: true},
            redirect: {
                name: 'FleetSurvey_Locations'
            }
        },
        // fleet-survey/{surveyId}
        {
            path: '/fleet-survey/:surveyId',
            name: 'FleetSurvey_SurveyId',
            meta: {requiresAuth: true},
            redirect: {
                name: 'FleetSurvey_UnitsOverviewSidebar'
            }
        },
        // fleet-survey/{surveyId}/overview
        {
            path: '/fleet-survey/:surveyId/overview',
            name: 'FleetSurvey_UnitsOverviewSidebar',
            component: () => import("@/view/pages/fleet-survey/unit-overview/OverviewSidebar.vue"),
            meta: {requiresAuth: true},
            children: [
                {
                    path: '/fleet-survey/:surveyId/overview/:unitId',
                    name: 'FleetSurvey_UnitOverview',
                    component: () => import("@/view/pages/fleet-survey/unit-overview/UnitOverview.vue"),
                    meta: {requiresAuth: true},
                }
            ]
        },
        // fleet-survey/{surveyId}/inspect-{unitNumber}
        {
            path: '/fleet-survey/:surveyId/inspect-:unitNumber',
            name: 'FleetSurvey_SurveyInspection',
            meta: {requiresAuth: true},
            component: () => import("@/view/pages/fleet-survey/survey-overview/AddUnitToSurvey.vue")
        },
        
        // fleet-condition-report/history
        {
            path: '/fleet-condition-report/history',
            name: 'FleetCondition_History',
            redirect: {name: 'FleetConditionsReport'},
            meta: {requiresAuth: true}
        },
        // fleet-condition-report/history/:id
        {
            path: '/fleet-condition-report/history/:id',
            name: 'FleetCondition_History_Report',
            component: () => import("@/view/pages/fleet-conditions-report/ReportResult.vue"),
            meta: {requiresAuth: true}
        },

        // fleet-dashboard
        {
            path: '/fleet-dashboard',
            name: 'FleetDashboard',
            component: () => import("@/view/pages/fleet-dashboard/FleetDashboard.vue"),
            meta: {
                requiresAuth: true, 
                routeType: RouteTypes.DASHBOARD,
                security: DashboardSecurities.FLEET_DASHBOARD,
            }
        },

        // manage-specs
        {
            path: '/manage-specs',
            name: 'ManageSpecs',
            component: () => import("@/view/pages/manage-specs/ManageSpecs.vue"),
            meta: {requiresAuth: true}
        },

        // manage-products
        {
            path: '/manage-products',
            name: 'ManageProducts',
            component: () => import("@/view/pages/manage-products/ManageProducts.vue"),
            meta: {requiresAuth: true}
        },

        //error
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "Error-404",
            component: () => import("@/view/pages/error/404.vue"),
            meta: {requiresAuth: true}
        },
    ],
    
});