import { COLORS, GRAPH_POINTS_TYPE } from "@/utils/constants/FleetDashboard.constants";

class Dataset {
    constructor({
        label,
        color,
        includeOutlabels,
        outlabels,
    }) {
        this.label = label;
        this.borderColor = color;  // line fill color
        this.backgroundColor = color;  // line point color
        
        if(includeOutlabels) {
            this.outlabels = outlabels ? outlabels : new DatasetOutlabels();
        }
    }
    label = "";
    data = [];
    items = [];
    backgroundColor = "";
    borderColor = "";
    labels = [];
    outlabels = null;
}

class DatasetItem {
    constructor({
        datasetId,
        dataItem,
        tooltip,
        color,
        type,
        xAxisID,
        legend
    }) {
        this.datasetId = datasetId;
        this.dataItem = dataItem;

        this.tooltip = tooltip;
        this.legend = legend;
        this.color = color;
        this.type = type;
        this.xAxisID = xAxisID;
    }

    xAxisID = null;
    datasetId = null;
    dataItem = null;
    type = null;

    tooltip = null;
    legend = null;
    color = COLORS.GRAY;
    outlabels = null;
}
class DatasetOutlabels {
    constructor(config) {
        if(config?.borderColor) this.borderColor = config?.borderColor; 
        if(config?.backgroundColor) this.backgroundColor = config?.backgroundColor; 
        if(config?.text) this.text = config?.text; 
        if(config?.color) this.color = config?.color; 
        if(config?.padding) this.padding = config?.padding;
    }    

    borderColor = "#000";
    backgroundColor = "#fff";
    text = "%p.2";
    color = "#000";
}
export {
    Dataset,
    DatasetItem,
    DatasetOutlabels,
}