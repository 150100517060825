import ApiService from "@/core/services/api.service";

// action types
export const API_SAR_STATES_LOAD = "action_sar_states_load";
export const API_SAR_FLEETS_LOAD = "action_sar_fleets_load";
export const API_SAR_COUNTRIES_LOAD = "action_sar_countries_load";
export const API_SAR_VOCATIONS_LOAD = "action_sar_vocations_load";
export const API_SAR_ACCOUNTS_LOAD = "action_sar_accounts_load";
export const API_SAR_FULL_ACCOUNTS_LOAD = "action_sar_full_accounts_load";
export const API_SAR_SELECTED_ACCOUNTS_LOAD = "action_sar_selected_accounts_load";
export const API_SAR_FULL_SELECTED_ACCOUNTS_LOAD = "action_sar_full_selected_accounts_load";
export const API_SAR_SELECTED_NA_ACCOUNTS_LOAD = "action_sar_selected_na_accounts_load";
export const API_SAR_ALL_SELECTS_LOAD = "action_sar_all_selects_load";


// muts
export const API_SAR_MUTS_SET_STATES = "muts_sar_set_states";
export const API_SAR_MUTS_SET_FLEETS = "muts_sar_set_fleets";
export const API_SAR_MUTS_SET_COUNTRIES = "muts_sar_set_countries";
export const API_SAR_MUTS_SET_VOCATIONS = "muts_sar_set_vocations";
export const API_SAR_MUTS_SET_ACCOUNTS = "muts_sar_set_accounts";
export const API_SAR_MUTS_SET_FULL_ACCOUNTS = "muts_sar_set_full_accounts";
export const API_SAR_MUTS_SET_SELECTED_ACCOUNTS = "muts_sar_set_selected_accounts";
export const API_SAR_MUTS_SET_FULL_SELECTED_ACCOUNTS = "muts_sar_set_full_selected_accounts";
export const API_SAR_MUTS_SET_SELECTED_NA_ACCOUNTS = "muts_sar_set_selected_na_accounts";
export const API_SAR_MUTS_SET_ALL_SELECTS = "muts_sar_set_all_selects";

const state = {
    countries: [],
    fleets: {
        national_global_account_names: [],
        canadian_national_global_account_names: [],
        government_account_names: [],
    },
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },
    vocations: [],
    is_show_unknown: false,
    is_show_localbook: false,
    is_show_goverment: false,
    is_show_preferred_fleet: false,
    selected_table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    full_table: {
        accounts: []
    },
    full_selected_table: {
        accounts: [],
    },
    dealers: [],
    dealers_ctsc: [],

    is_scrap_accounts_verification_failed: false,
    scrap_verified_accounts: []
}

const getters = {
    getSARCountries() {
        return state.countries;
    },
    getSARShowTypes() {
        return {
            is_show_unknown: state.is_show_unknown,
            is_show_localbook: state.is_show_localbook,
            is_show_goverment: state.is_show_goverment,
            is_show_preferred_fleet: state.is_show_preferred_fleet
        };
    },
    getSARFleetsNA() {
        return state.fleets.national_global_account_names;
    },
    getSARFleetsCA() {
        return state.fleets.canadian_national_global_account_names;
    },
    getSARFleetsG() {
        return state.fleets.government_account_names;
    },
    getSARStatesCA() {
        return state.states.CA_states;
    },
    getSARStatesUS() {
        return state.states.US_states;
    },
    getSARStatesMX() {
        return state.states.MX_states;
    },
    getSARStatesCO() {
        return state.states.CO_states;
    },
    getSARStatesAU() {
        return state.states.AU_states;
    },
    getSARStatesCN() {
        return state.states.CN_provinces;
    },
    getSARVocations() {
        return state.vocations;
    },
    getSARAccountsTable() {
        return state.table;
    },
    getSARSelectedAccountsTable() {
        return state.selected_table;
    },
    getSARFullAccountsTable() {
        return state.full_table;
    },
    getSARFullSelectedAccountsTable() {
        return state.full_selected_table;
    },
    getSARDealers() {
        return state.dealers;
    },
    getSARRegions() {
        return state.dealers_ctsc;
    },
    getSARAccountVerification() {
        return state.is_scrap_accounts_verification_failed;
    },
    getSARVerifiedAccounts() {
        return state.scrap_verified_accounts;
    }
}

const actions = {
    [API_SAR_STATES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("scrap_analysis_report/get_states", context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_STATES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_FLEETS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("scrap_analysis_report/get_fleets", context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_FLEETS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_COUNTRIES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("scrap_analysis_report/get_countries/NA", context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_COUNTRIES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_VOCATIONS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_vocations", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_VOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_SELECTED_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_SELECTED_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_FULL_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_FULL_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_FULL_SELECTED_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_FULL_SELECTED_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_SELECTED_NA_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_SELECTED_NA_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_ALL_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_ALL_SELECTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_SAR_MUTS_SET_STATES](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;
    },
    [API_SAR_MUTS_SET_FLEETS](state, {data}) {
        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;
    },
    [API_SAR_MUTS_SET_COUNTRIES](state, {data}) {
        state.countries = data.countries;
    },
    [API_SAR_MUTS_SET_VOCATIONS](state, {data}) {
        state.vocations = data.vocations;
    },
    [API_SAR_MUTS_SET_ACCOUNTS](state, {data}) {
        state.table.accounts = data.accounts;
        state.table.page_number = data.page_number;
        state.table.page_size = data.page_size;  
        state.table.total_items = data.total_items;   
        state.table.total_selected_items = data.total_selected_items;
    },
    [API_SAR_MUTS_SET_SELECTED_ACCOUNTS](state, {data}) {
        state.selected_table.accounts = data.accounts;
        state.selected_table.page_number = data.page_number;
        state.selected_table.page_size = data.page_size;  
        state.selected_table.total_items = data.total_items;   
        state.selected_table.total_selected_items = data.total_selected_items;
    },
    [API_SAR_MUTS_SET_FULL_ACCOUNTS](state, {data}) {
        state.full_table.accounts = data.accounts;
    },
    [API_SAR_MUTS_SET_FULL_SELECTED_ACCOUNTS](state, {data}) {
        state.full_selected_table.accounts = data.accounts;
    },
    [API_SAR_MUTS_SET_SELECTED_NA_ACCOUNTS](state, {data}) {
        state.selected_table.accounts = data.accounts;
    },
    [API_SAR_MUTS_SET_ALL_SELECTS](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;  

        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;

        state.countries = data.countries;

        state.vocations = data.vocations;

        state.dealers = data.dealers;
        state.dealers_ctsc = data.dealers_ctsc;

        state.is_show_localbook = data.is_show_localbook;
        state.is_show_unknown = data.is_show_unknown;
        state.is_show_goverment = data.is_show_goverment;
        state.is_show_preferred_fleet = data.is_show_preferred_fleet;
        state.is_scrap_accounts_verification_failed = data.is_accounts_verification_failed;
        state.scrap_verified_accounts = data.verified_account;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
