import { Filters, Tab } from "../Filters";
import { TAB_TYPES, SELECT_FILTER_STEP, NonsigTable, LocationCodesTable } from "@/utils/constants/FleetDashboard.constants";

class PurchaseFilters extends Filters {
    constructor() {
        super();
        this.tabs[0] = new Tab({
            title: SELECT_FILTER_STEP.INNERTAB_LOCATIONS.TITLE,
            switchTextLeft: SELECT_FILTER_STEP.INNERTAB_LOCATIONS.SWITCH_TEXT_LEFT,
            switchTextRight: SELECT_FILTER_STEP.INNERTAB_LOCATIONS.SWITCH_TEXT_RIGHT,
            checkboxLabel: SELECT_FILTER_STEP.INNERTAB_LOCATIONS.CHECKBOX_LABEL,
            fields: LocationCodesTable.fields,
            sortEnum: LocationCodesTable.sortEnum,
        });

        this.tabs[1] = new Tab({
            title: SELECT_FILTER_STEP.INNERTAB_NONSIGS.TITLE,
            switchTextLeft: SELECT_FILTER_STEP.INNERTAB_NONSIGS.SWITCH_TEXT_LEFT,
            switchTextRight: SELECT_FILTER_STEP.INNERTAB_NONSIGS.SWITCH_TEXT_RIGHT,
            checkboxLabel: SELECT_FILTER_STEP.INNERTAB_NONSIGS.CHECKBOX_LABEL,
            fields: NonsigTable.fields,
            sortEnum: NonsigTable.sortEnum,
        });

        this.tabType = TAB_TYPES.PURCHASES;
    }

    tabs = {};
}

export {
    PurchaseFilters
}