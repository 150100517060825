<template>
    <div class="numeric-input-container">
        <div class="numeric-input">
            <input 
                :id="id"
                :test-id="testId"
                @blur="onBlur"
                @focus="onFocus"
                @paste="onPaste"
                @keypress="onKeyPress"
                @keydown.up="onIncClick"
                @keydown.down="onDecClick"
                @input="onInput"
                :disabled="disabled"
                :value="_value"
                class="form-control"
                :class="danger ? 'border-danger' : ''"
                lang="en"
                autocomplete="off"
            >

            <div v-if="withArrows" class="inc-dec-container" :class="{'disabled' : disabled}">
                <button class="inc btn-wrapper"
                    @mousedown="onIncClick"
                    tabindex = "-1"
                >
                    <b-icon-chevron-up>
        
                    </b-icon-chevron-up>
                </button>
    
                <button class="dec btn-wrapper"
                    @mousedown="onDecClick"
                    tabindex = "-1"
                >
                    <b-icon-chevron-down>
        
                    </b-icon-chevron-down>
                </button>
    
            </div>
        </div>
        <div class="text-danger" style="font-size:10px;" v-if="showErrorMessage">
            {{$trans(errMsg)}}
        </div>

    </div>
</template>

<script>
const numberRgx = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
import { convertToDecimal } from '@/common/common-functions';
export default {
    props: {
        min: {
            type: [Number, String],
            default: 0
        },
        max: {
            type: [Number, String],
            default: null
        },
        customClass: {
            type: String,
            default: "form-control"
        },
        maxLength: {
            type: [Number, String],
            default: 16
        },
        // number of digits in decimal part
        toFixed: {
            type: [String, Number],
            default: 2
        },
        value: [Number, String],
        disabled: [Boolean, String],
        invalid: [Boolean, String],
        noErrorMessage: Boolean,
        int: Boolean,
        addTrailingZeroes: {
            type: Boolean,
            default: false
        },
        step: {
            type: [String, Number],
            default: 1
        },
        withArrows: Boolean,
        testId: {
            type: String,
            default: 'none'
        }
    },
    mounted() {
        this.id += this._uid;
    },
    data() {
        return {
            isInvalid: false,
            errMsg: "",
            id: 'numeric__input__n__',
            cut: 2,
            key: 0,
            timeoutOptions: {
                timeout: null,
                gap: 500
            },
            insertFromPaste: false
        }
    },
    methods: {
        focusOnInput() {
            let input = document.getElementById(this.id);

            if(input && document.activeElement !== input) {
                input.focus();
            }
        },
        onIncClick(e) {
            if(this.withArrows) {
                e.preventDefault();
    
                if(this.disabled) return;
                
                this.focusOnInput();
                this.inc();
            }

        },
        inc() {
            let number = Number(this.value) || 0;
            if(this.max && number < this.max) {
                this.emit(number + this.step);
            }
            else 
            if(!this.max){
                this.emit(number + this.step);
            }
        },
        onDecClick(e) {
            if(this.withArrows) {
                e.preventDefault();
    
                if(this.disabled) return;
    
                this.focusOnInput();
                this.dec();
            }
        },
        dec() {
            let number = Number(this.value) || 0;
            if(number > this.min) {
                this.emit(number - this.step);
            }
        },
        convertToDecimal(value) {
            if(!this.int && this.toFixed > 0) {
                return convertToDecimal(value, this.toFixed);
            }

            return value;
        },
        onBlur(e) {
            let value = this.convertToDecimal(e?.target?.value);
            
            if(!value) {
                this.errMsg = "";
                this.isInvalid = false;
                this.$emit('blur', {
                    event: e, 
                    value: value
                });
                return;
            }
            let isNumbers = numberRgx.test(value); 
            if(isNumbers) {
                this.errMsg = this.checkNumberFormat(value);
                if(this.errMsg) {
                    this.isInvalid = true;
                } 
                else {
                    this.isInvalid = false;
                    this.errMsg = "";
                }
            }
            else {
                this.isInvalid = true;
            }
            this.emit(value);

            this.$emit('blur', {
                event: e, 
                value: value
            });
        },
        onFocus(e) {
            this.$emit('focus', e);
        },
        onInput(e)  {
            this.isInvalid = false;
            this.errMsg = "";

            let value = e?.target?.value;
            if(!value) {
                this.emit("");
                return;
            };

       

            this.errMsg = this.checkNumberFormat(value);
            if(this.errMsg) this.isInvalid = true;
            this.emit(value);
        },
        
        onKeyPress(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            // 8 // backspaces
            // 0  // null values
            // 46 // for dots
            // 17  // ctrl
            let allowedCodes = [
                8, 0, 17
            ];
            if(!this.int) allowedCodes.push(46);
        
            let preventCond = !allowedCodes.includes(charCode)
                            && !((charCode >= 48 && charCode <= 57));

            if (preventCond) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onPaste(e) {
            e.preventDefault();
            this.isInvalid = false;
            this.errMsg = "";

            let text = (e.clipboardData || window.clipboardData).getData('text');
            let isNumbers = numberRgx.test(text); 
            if(isNumbers) {
                this.errMsg = this.checkNumberFormat(text);
                if(this.errMsg) this.isInvalid = true;

                this.emit(this.valueToString.concat(text));
                
            }
            else {
                // e.preventDefault();
                this.emit(this.value);
                
            }
        },
        emit(model) {
            this.$emit("update:value", {
                model: model == "" ? null : model,
                error: this.isInvalid,
            });
        },
        checkNumberFormat(v) {
            if(!v){
                return;
            }
            let text = v?.toString() || "";
            let numberOfDots = text?.match(/\./g)?.length || 0;
            if(numberOfDots > this.numberOfDots) return this.dotsErrMesg;

            
            if(this.max != null && Number(text) > this.max) return this.$trans("Please enter a value less than or equal to") + " " + this.max;
            if(Number(text) < this.min) return this.$trans("Value cannot be less then") + " " + this.min;
            
            if(!this.int) {
                let value = text.split('.');

                if(typeof value[0] === 'undefined' || (typeof value[0] !== 'undefined' && !value[0].length)) {
                    return !value[1].length
                            ? this.$trans("Incorrect format. Add leading and trailing number") + " (x.y)"
                            : this.$trans("Incorrect format. Add leading number") + " (x." + value[1] + ")"
                }
                
                if(value[0].length > this.maxLength) return this.$trans("Maximum integer length is") + " " + this.maxLength;
                
                if(typeof value[1] === 'undefined') return null;
                if(!value[1].length) return this.$trans("Incorrect format. Add trailing number") + " (" + value[0] + ".x)";

                if(value[1].length > this.maxLength) return this.$trans("Maximum decimal length is") + " " + this.maxLength; 
            }

            return null;
        },
        
    },
    computed: {
        danger() {
            return this.isInvalid ? true : false; 
        },
        _value() {
            if(this.value?.toString()?.length && this.checkNumberFormat(this.value)) {
                this.isInvalid = true;
                this.errMsg = this.checkNumberFormat(this.value);
            }
            else {
                this.isInvalid = false;
            }

            return this.value;
        },
        valueToString() {
            return this.value?.toString() || "";
        },
        numberOfDots() {
            return this.int ? 0 : 1;
        },
        dotsErrMesg() {
            return this.int ? this.$trans("Incorrect Format. Please enter an integer.") : this.$trans("Incorrect number of decimals. Please use 1.")
        },
        showErrorMessage() {
            return !this.noErrorMessage && this.danger && this.errMsg;
        }
    }
}
</script>

<style>
.numeric-input {
    position: relative;
}
.inc-dec-container {
    width: 18px;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.inc-dec-container.disabled {
    display: none;
}
.inc, .dec {
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 2px;
}

.inc:hover, .dec:hover {
    background: rgb(0 0 0 / 8%);
    
}
.inc svg, .dec svg {
    height: 0.7rem;
    width: 0.7rem;
    vertical-align: 0;
}

</style>