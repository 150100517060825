import { BarChartConfig } from "..";
import { Dataset } from "../Dataset";
import { toCommas } from "@/utils/helpers/NumberHelper.js";
import { groupBy } from "@/utils/helpers/ArrayHelper";

class RetreadSalesToNewTireSalesConfig extends BarChartConfig {
    constructor(current, prev) {
        if(prev && current?.labels) {
            current.labels = []; // set empty to take labels from previos dataset
        }

        super(current, prev);

        var currentDataSets = [];
        var prevDataSets = [];

        if(current) {

            var datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                var dataset = new Dataset({
                    color: items[0]?.color,
                    label: label,
                    stack: "current-stack",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                })
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(prev) {

            var datasets = groupBy(prev.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                var dataset = new Dataset({
                    color: items[0]?.color,
                    label: label,
                    stack: "prev-stack",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                })
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                prevDataSets.push(dataset);
            });
    
        }


        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets)
        }
        if(prevDataSets.length) {
            this.data.datasets = this.data.datasets.concat(prevDataSets)
        }

        this.options.scales.yAxes.push({
            ticks: {
                beginAtZero: true,
                stacked: true,
                padding: 20,
                callback: (value) => {
                    return toCommas(value);
                }
                
                
            },
            gridLines:{
                drawOnChartArea: true,
                tickMarkLength: 0
            }
        });


        this.options.scales.xAxes.push({
            stacked: true,
            display: false,
        })
        this.options.scales.xAxes.push(
            {
                id: 'x-axis-id',
                type: 'category',
                offset: true,
                gridLines: {
                    drawOnChartArea: true,
                    offsetGridLines: true,
                    drawTicks: false
                },
                barPercentage: 1,
                maxBarThickness: 150,
                ticks: {
                    padding: 10
                }
            }
        )

        this.options.legend = {
            display: false 
        }
        this.options.title = {
            display: true,
            text: 'Units',
            position: "left"
        }
    }

    
}

export {
    RetreadSalesToNewTireSalesConfig
}