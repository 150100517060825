import LocalStorageHelper from "@/utils/helpers/LocalStorageHelper";
import SessionStorageHelper from "@/utils/helpers/SessionStorageHelper";

import {
    LOCAL_STORAGE_KEY,
    TAB_TYPES,
    REPORT_ORDER
} from "@/utils/constants/FleetDashboard.constants.js"

export const updateLocalStorage = (state) => {
    var objToStore = {};
    for (const key in state) {
        if(key.startsWith("_")) continue;

        objToStore[key] = state[key];
    }
    LocalStorageHelper.setObjAsValue(LOCAL_STORAGE_KEY, objToStore);
}

export const getReportsPostBody = (baseParams, tabParams, reportParams) => {
    return {
        tab_id: SessionStorageHelper.getTabId(),
        base_parameters: baseParams,
        tab_parameters: tabParams,
        reports_parameters: reportParams
    }
}

export const getSavedReportTabType = (basket) => {
    var tabType, count = 0;
    for (const tab in basket) {
        const tabReports = basket[tab];
        if(tabReports.length) {
            tabType = tabReports[0].tabType;
            count++;
            if(count > 1) break;
        }
    }

    return count > 1 ? TAB_TYPES.COMBINED : tabType;
}

export const compareReportOrder = (a, b, tab) => {
    var types = REPORT_ORDER[tab] || [];
    const indexA = types.findIndex(t => t == a.reportType);
    const indexB = types.findIndex(t => t == b.reportType);

    return indexA - indexB;
}

