(function () {
    if ( typeof window.isIE === "function" ) return false; //If not IE
  
    function isIE () {
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        if (isIE11) return 11;
        var myNav = navigator.userAgent.toLowerCase();
        return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
    }  
    window.isIE = isIE;
    window.gy_app_is_too_long_request_popup = false;

    if (window.isIE() && location.href.length > 2000) { //if URL more then 1kb - show message box
        window.gy_app_is_too_long_request_popup = true;
    }
})();