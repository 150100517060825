import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { APP_SET_USER, APP_SET_HEADER_MENU, APP_SET_FOOTER_MENU, APP_SET_LANGUAGE_PACK } from "@/core/services/app.state.module";
import '@/scripts/ieEvent.module.js';
import '@/classes/EmitModal.js'
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    isCheckPermissionSent: false,
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = (process.env.VUE_APP_API_SERVER);
    },
    

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        //Vue.axios.defaults.withCredentials = true;
        // Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        //Vue.axios.defaults.headers.common["api_key"] = `WWW/G+TIIFWV/IFFACXO3RISOJWZAIWASDGIRUNQLTNBLKXSS1BEPCYXP3NSD3HVNEBTG802/XQWPDCCOEUWZG==`;

        if (JwtService.getToken()) {
            Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        }
        else {
            Vue.axios.defaults.headers.common["Authorization"] = "";
        }
        if (JwtService.getApiKey()) {
            Vue.axios.defaults.headers.common["api_key"] = JwtService.getApiKey();
        }
        else {
            Vue.axios.defaults.headers.common["api_key"] = "";
        }
        Vue.axios.defaults.headers.common["user-time"] = getUserTimeString();

    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            // console.log(error);
            throw new Error(`ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     */
    get(resource, context, config) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.get(`${resource}`, config).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };
        return new Promise(call.bind({ context }));
    },
    /**
     * Send the GET HTTP request (BLOB)
     */
    getBlob(resource, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.get(`${resource}`, { responseType: 'blob' }).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };
        return new Promise(call.bind({ context }));
    },

    /**
     * Set the POST HTTP request
     */
    post(resource, params, context, cancelTokenSource, baseURL) {
        var call = function (resolve, reject) {
            var meta = {
                cancelToken: cancelTokenSource ? cancelTokenSource.token : null,
                headers: {
                    'User-Language': window.localStorage.getItem("USER_LANGUAGE"),
                },
                baseURL: baseURL || Vue.axios.defaults.baseURL
            }
            
            ApiService.setHeader();
            Vue.axios.post(`${resource}`, params, meta).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch((e) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(e?.response);
                    return reject({ 
                        response: e?.response,
                        error: e
                     });
                });
        }

        return new Promise(call.bind({ context }));
    },
    /**
     * Set the POST HTTP request
     */
    postBlob(resource, params, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.post(`${resource}`, params, { responseType: 'blob' }).
                then((response) => {
                    var fileName = response.headers['content-disposition']?.split(`filename="`)[1]?.slice(0, -1) || "";
                    var fileType = response.headers['content-type'];
                    ApiService.baseParseRequest(response.data, this.context);
                    return resolve({ data: response.data, fileConfig: {fileName: fileName, fileType: fileType} });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
    },


    /**
     * Send the UPDATE HTTP request
     */
    update(resource, slug, params) {
        ApiService.setHeader();
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     */
    put(resource, params, context) {

        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.put(`${resource}`, params).
                then(({ data }) => {
                    //console.log(data);
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    //console.log(response);
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
        //return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     */
    delete(resource, context) {
        var call = function (resolve, reject) {
            ApiService.setHeader();
            Vue.axios.delete(`${resource}`).
                then(({ data }) => {
                    ApiService.baseParseRequest(data, this.context);
                    return resolve({ data });
                }).
                catch(({ response }) => {
                    const catchErrors = ApiService.catchErrors.bind({context})
                    catchErrors(response);
                    return reject({ response });
                });
        };

        return new Promise(call.bind({ context }));
    },

    baseParseRequest(jsonResponce, context) {
        if (jsonResponce) {
            for (var paramName in jsonResponce) {
                if (paramName == "user") {
                    //console.log(jsonResponce.user);
                    ApiService.baseParseUserResponce(jsonResponce.user, context);
                }
                else if (paramName == "app_setup") {
                    ApiService.baseParseAppResponce(jsonResponce.app_setup, context);
                }
            }
        }
    },
    baseParseUserResponce(userObject, context) {
        var params = {
            login: null,
            user_name: null,
            user_last_name: null,
            group: null,
            api_key: null,
            user_token_expire_time: null,
            api_key_refresh_time: null,
            gy_token: null,
            token: null,
            agree_to_fhq_terms: null,
            user_access_securities: null,
            unit_of_measurement: null,
            email: null,
        }

        if (userObject.hasOwnProperty('login')) {
            params.login = userObject.login;
        }
        if (userObject.hasOwnProperty('user_name')) {
            params.user_name = userObject.user_name;
        }
        if (userObject.hasOwnProperty('user_last_name')) {
            params.user_last_name = userObject.user_last_name;
        }
        if (userObject.hasOwnProperty('user_access_securities')) {
            params.user_access_securities = userObject.user_access_securities;
        }
        if (userObject.hasOwnProperty('group')) {
            params.group = userObject.group;
        }
        if (userObject.hasOwnProperty('gy_token')) {
            params.gy_token = userObject.gy_token;
        }
        if (userObject.hasOwnProperty('token')) {
            params.token = userObject.token;
        }
        if (userObject.hasOwnProperty('api_key')) {
            params.api_key = userObject.api_key;
        }
        if (userObject.hasOwnProperty('api_key_refresh_time')) {
            params.api_key_refresh_time = userObject.api_key_refresh_time;
        }
        if (userObject.hasOwnProperty('user_token_expire_time')) {
            params.user_token_expire_time = userObject.user_token_expire_time;
            //console.log(params.user_token_expire_time);   
            window._session_timeout_time = new Date((new Date().getTime() + params.user_token_expire_time));
            //console.log(window._session_timeout_time);
        }
        if (userObject.hasOwnProperty('agree_to_fhq_terms')) {
            params.agree_to_fhq_terms = userObject.agree_to_fhq_terms;
        }        
        if (userObject.hasOwnProperty('email')) {
            params.email = userObject.email;
        }
        if (userObject.hasOwnProperty('unit_of_measurement')) {
            params.unit_of_measurement = userObject.unit_of_measurement;
        }

        context.commit(APP_SET_USER, params);
    },
    baseParseAppResponce(jsonObject, context) {
        //console.log(jsonObject);
        // if (jsonObject.hasOwnProperty('locale')) {
        //     AppStateService.Region = jsonObject.locale;
        // }
        if (jsonObject.hasOwnProperty('header_content') && jsonObject.header_content != null) {
            context.commit(APP_SET_HEADER_MENU, jsonObject.header_content);
        }
        if (jsonObject.hasOwnProperty('footer_content') && jsonObject.footer_content != null) {
            context.commit(APP_SET_FOOTER_MENU, jsonObject.footer_content);
        }
        if (jsonObject.hasOwnProperty('language_pack') && jsonObject.language_pack != null) {
            context.commit(APP_SET_LANGUAGE_PACK, jsonObject.language_pack);
        }
    },
    catchErrors(response) {
        if (response == undefined) return;
        if (response.status == 403) {
            const showSessionTimeoutPopupEvent = new window.CustomEvent('showSessionTimeoutPopupEvent')
            document.dispatchEvent(showSessionTimeoutPopupEvent);
            return;
        }
        if(response.status == 401) {
            const showPermissionsPopupEvent = new window.CustomEvent('showPermissionsPopupEvent');
            document.dispatchEvent(showPermissionsPopupEvent);
            if(response.hasOwnProperty('data')){
                ApiService.baseParseRequest(response.data, this.context);
            }
            return;
        }
        if(response.status == 500) {
            const show500ErrorPopupEvent = new window.CustomEvent('show500ErrorPopupEvent')
            document.dispatchEvent(show500ErrorPopupEvent);
        }
    }
};

export const portalSlotAxios = axios.create({
    baseURL: process.env.VUE_APP_PW_SLOT_HOST
})

export const loginPortalApiAxios = getLoginPortalApiAxiosAuthorizedInstance();

function getLoginPortalApiAxiosAuthorizedInstance() {
    let axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_PORTAL_WEB_API
    })

    if (JwtService.getToken()) {
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    }
    else {
        axiosInstance.defaults.headers.common["Authorization"] = "";
    }
    if (JwtService.getApiKey()) {
        axiosInstance.defaults.headers.common["api_key"] = JwtService.getApiKey();
    }
    else {
        axiosInstance.defaults.headers.common["api_key"] = "";
    }
    axiosInstance.defaults.headers.common["user-time"] = getUserTimeString();

    return axiosInstance;
}

function getUserTimeString() {
    var date = new Date();
    var day = ("0" + date.getDate()).slice(-2);       // yields date
    var month = ("0" + (date.getMonth() + 1)).slice(-2);    // yields month (add one as '.getMonth()' is zero indexed)
    var year = date.getFullYear();  // yields year
    var hour = ("0" + date.getHours()).slice(-2);     // yields hours
    var minute = ("0" + date.getMinutes()).slice(-2); // yields minutes
    var second = ("0" + date.getSeconds()).slice(-2); // yields seconds

    return month + "/" + day + "/" + year + " " + hour + ':' + minute + ':' + second;
}

export default ApiService;
