import { DB_UPLOADED_UNITS } from './db-tables/uploaded.units.db';

const DB_NAME = 'tm_index_database';
const DB_VERSION = 1;
let TO_USE_LOCAL_STORAGE = false;

let DB;

export default {
    getDB() {
        return new Promise((resolve, reject) => {
            if(DB) return resolve(DB);

            const request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = (e) => {
                console.log('Error opening db', e)
                // eslint-disable-next-line prefer-promise-reject-errors
                if(e?.target?.error?.code === 11){
                    TO_USE_LOCAL_STORAGE = true;
                    resolve(null);
                } else {
                    console.log('Error opening db', e);
                    reject('Error')
                }
                
            };

            request.onsuccess = (e) => {
                DB = e.target.result;
                resolve(DB);
            };

            request.onupgradeneeded = e => {
                let db = e.target.result
                db.createObjectStore(DB_UPLOADED_UNITS, { autoIncrement: true, keyPath: 'db_id' })
            }
        });
    }
}


export {
    TO_USE_LOCAL_STORAGE,
    DB_NAME
}
