import {
    REPORT_TYPE_MAP,
    REPORT_SUBTITLE_MAP,
    REPORT_STATUS_CODES,
    ServiceTypes,
    UnitLocations,
    LOAD_PARTS_ORDER,
    INCLUDE_OPTIONS_MAP,
    REPORT_PARTS,
    TAB_TYPES,
} from "@/utils/constants/FleetDashboard.constants.js"

import axios from 'axios';
import store from "@/core/services/store";
import { GETTERS } from "@/core/services/api/fleet-dashboard/fleet.dashboard.module";
import SessionStorageHelper from "@/utils/helpers/SessionStorageHelper";

class ReportLoader {
    constructor(obj) {
        if(obj) {
            for (const key in obj) {
                if(this.hasOwnProperty(key)) {
                    this[key] = obj[key];
                }
            }
        }
    }

    [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.BOTH)] = false;
    [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_TABLE)] = false;
    [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_GRAPH)] = false;
}
class BaseReport {
    constructor({reportType, report_type, tabType, tab_type}) {
        this.reportType = report_type || reportType || 0;
        this.tabType = tab_type || tabType || 0;

        this.title = REPORT_TYPE_MAP.get(this.reportType);
        this.subtitle = REPORT_SUBTITLE_MAP.get(this.reportType) || null;
        this.cancelTokenSource = axios.CancelToken.source();
    }

    reportType = null;
    tabType = null;
    title = "";
    subtitle = "";
    cancelTokenSource = null;

    status = REPORT_STATUS_CODES.NEVER_LOADED;
    loadOrder = LOAD_PARTS_ORDER.FULL;
}

class ReportModels {
    constructor(obj) {
        if(obj) {
            for (const key in obj) {
                if(this.hasOwnProperty(key)) {
                    this[key] = obj[key];
                }
            }
        }
    }

    comment = "";
    selectedMonth = null;
    addedPart = null;
    sortNumber = null;
    sortDesc = true;
}

class ReportSettings {
    constructor(obj, report) {
        if(obj) {
            for (const key in obj) {
                if(this.hasOwnProperty(key)) {
                    this[key] = obj[key];
                }
            }
        }
    }

    loadAfter = {};

    tireBrands = null;
    tireProductLines = null;
    tireSizes = null;
    wheelPositions = null;
    tireStatuses = null;
    serviceTypes = ServiceTypes;
    unitLocations = UnitLocations;
    byRetreader = null;

    isComparePrev = false;
    isIncludeNatAvg = false;
}

class Report extends BaseReport {
    constructor(report, settings, models, loader) {
        super(report);

        if(settings) {
            this.settings = new ReportSettings(settings, report);
        }

        if(models) {
            this.models = new ReportModels(models);
        }

        if(loader) {
            this.loader = new ReportLoader(loader);
        }
    }

    settings = new ReportSettings();
    models = new ReportModels();
    loader = new ReportLoader();

    getBaseParams() {
        return store.getters[GETTERS.GET_STORED_BASE_PARAMS];
    };
    getReportParams(part = null) {
        function getSelectedSettings(settingObj) {
            if (settingObj) {
                let selectedValues = [];
                Object.keys(settingObj).forEach(key => {
                    if (settingObj[key] == true) {
                        selectedValues.push(key);
                    }
                })
                if (selectedValues.length == 0 || selectedValues.length == Object.keys(settingObj).length){
                    return null;
                }                
                return selectedValues;
            }
            else {
                return null
            };
        }

        return {
            report_type: this.reportType,
            report_part: part,
            
            unit_locations: this.settings.unitLocations,
            service_types: this.settings.serviceTypes,
            wheel_positions: this.settings.wheelPositions,
            retreading_types: this.settings.tireStatuses,
            by_retreader: this.settings.byRetreader,
            
            graph_compare_by_NA: this.settings.isIncludeNatAvg,
            graph_compare_by_previous: this.settings.isComparePrev,
            
            tire_sizes: getSelectedSettings(this.settings.tireSizes),
            tire_product_lines: getSelectedSettings(this.settings.tireProductLines),
            tire_brands: getSelectedSettings(this.settings.tireBrands),
        }
    };
    getTabParams() {
        return store.getters[GETTERS.GET_TAB_PARAMS_BY_TAB](this.tabType);
    };
    getBody(part) {
        return {
            tab_id: SessionStorageHelper.getTabId(),
            base_parameters: this.getBaseParams(),
            tab_parameters: [this.getTabParams()],
            reports_parameters: [this.getReportParams(part)]
        }
    };
    getExportParams(part) {
        return {
            comment: this.models.comment,
            include_option: INCLUDE_OPTIONS_MAP.get(part),            
            graph_selected_year: this.models.selectedMonth?.Year,
            graph_selected_month: this.models.selectedMonth?.MonthNumber,
            table_sort_number: this.models.sortNumber,
            table_sort_desc: this.models.sortDesc
        }
    };

    getTiles() { return null; }
    getTilesComponent() { return null; }
    getChartItems() { return null; }
    getFilteredFields() { return null; }
    getTableItems() { return null; }
    getDisabledAddBtn() { return false; }
    getTableRowClass() { return null; }

    getLinkToServiceHistory() {throw new Error("Not Implemented")};
    getLinkToHeatMap() {throw new Error("Not Implemented")};

    setSettingsBefore() { return null; }
    setSettingsAfter() { return null; }
}

export {
    Report,
    ReportModels,
    ReportSettings,
    ReportLoader
}