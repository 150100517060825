import { Report } from "../../Report";

import { 
    getAvgRollTimeItems,
    filterFieldsByServiceType,
    getAvgRollTimeChart,
    getLinkToServiceHistory
} from "./ServiceReportHelper";

import { groupBy } from "@/utils/helpers/ArrayHelper";

import { AvgRollTimeChartConfig } from "../../../charts/linear/service/AvgRollTime";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";
import {
    TAB_TYPES,
    TableFields,
    GRAPH_POINTS_TYPE,
} from "@/utils/constants/FleetDashboard.constants.js"

class AvgRollTimeReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.SERVICE}, settings, models, loader);

        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.graphs) {
            this.chartItems = data?.graphs;
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.isTableSelectable = true;

        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    chartItems = null;

    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        var current, prev, nat;
        if(this.settings.isComparePrev) {
            prev = getAvgRollTimeChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS, this.models.selectedMonth);
        }

        if(this.settings.isIncludeNatAvg) {
            nat = getAvgRollTimeChart(this.chartItems, GRAPH_POINTS_TYPE.NAT_AVG, this.models.selectedMonth);
        }

        current = getAvgRollTimeChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT, this.models.selectedMonth);

        var config = new AvgRollTimeChartConfig(current, prev, nat);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        var filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);
        }

        filtered = filterFieldsByServiceType(filtered, this.settings.serviceTypes);
        
        if(this.models.selectedMonth) {
            filtered = filtered.filter(f => !f.showMonth);
        }
        else {
            filtered = filtered.filter(f => !f.showWeek);
        }
        
        return filtered;
    };

    getTableItems() {
        var grouped = groupBy(this.tableItems, week => week["MonthString"]);
        return getAvgRollTimeItems(grouped, this.models.selectedMonth);
    };

    getDisabledAddBtn() {
        if(this.tableItems.length && this.chartItems && Object.keys(this.chartItems).length) return false;

        return true;
    }

    getLinkToServiceHistory(e) {
        return getLinkToServiceHistory({emergency: true}, this.settings.unitLocations, e);
    }
}

export {
    AvgRollTimeReport
}