import { LineChartConfig } from "..";
import { toMoney } from "@/utils/helpers/StringHelper";
import { toCommas } from "@/utils/helpers/NumberHelper";

class RepairAndRetreadCostConfig extends LineChartConfig {
    constructor(current, prev) {
        super(current, prev);

        this.options.scales.yAxes.push({
            ticks: {
                beginAtZero: true,
                callback: (value) => {
                    return toMoney(toCommas(value));
                }
                
                
            },
            gridLines:{
                drawOnChartArea: true
            }
        });

        this.options.scales.xAxes.push({
            offset: true,
            gridLines:{
                drawOnChartArea: true
            },
            ticks: {
                callback: function(value, index, values) {
                    return value;
                }
            }
        });

        this.options.legend = {
            display: false
            
        }
        this.options.title = {
            display: false
        }
    }

    
}

export {
    RepairAndRetreadCostConfig
}