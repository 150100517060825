import { BarChartConfig } from "..";
import { Dataset } from "../Dataset";
import { groupBy } from "@/utils/helpers/ArrayHelper";
import ChartDataLabels from 'chartjs-plugin-datalabels';

class RepairSummaryChartConfig extends BarChartConfig {
    constructor(current, prev) {
        super(current, prev);

        let currentDataSets = [];
        let prevDataSets = [];

        if(current) {

            let datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    label: label,
                    color: items[0]?.color,
                    stack: "current-stack",
                    includeLabels: true,
                    labelFormat: "$v",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    maxBarThickness: 100,
                    xAxisID: 'x-axis-id',
                });
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(prev) {

            let datasets = groupBy(prev.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    label: label,
                    color: items[0]?.color,
                    stack: "prev-stack",
                    includeLabels: true,
                    labelFormat: "$v",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    maxBarThickness: 100,
                    xAxisID: 'x-axis-id',
                });

                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                prevDataSets.push(dataset);
            });
    
        }


        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets)
        }
        if(prevDataSets.length) {
            this.data.datasets = this.data.datasets.concat(prevDataSets)
        }

        this.options.scales.yAxes.push({
            type: 'category',
            stacked: true,
        });

        if(current) {
            this.options.scales.xAxes.push({     
                id: 'x-axis-id',
                type: 'linear',
                stacked: true,
            })
        }

        this.options.legend = {
            display: false
            
        }
        this.options.title = {
            display: false
        }

        this.type = 'horizontalBar';
    }

    plugins = [ChartDataLabels];
}

export {
    RepairSummaryChartConfig
}