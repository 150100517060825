import ApiService from "@/core/services/api.service";
import {API_FCR_MUTS_SET_REPORTS} from './fcr.module';

// action types
export const API_FCR_ACCOUNTS_LOAD = "action_fcr_accounts_load";
export const API_FCR_SELECTED_ACCOUNTS_LOAD = "action_fcr_selected_accounts_load";
export const API_FCR_FULL_ACCOUNTS_LOAD = "action_fcr_full_accounts_load";
export const API_FCR_FULL_SELECTED_ACCOUNTS_LOAD = "action_fcr_full_selected_accounts_load";
export const API_FCR_ALL_SELECTED_ACCOUNTS_LOAD = "action_fcr_selected_na_accounts_load";
export const API_FCR_ALL_SELECTS_LOAD = "action_fcr_all_selects_load";


// muts
export const API_FCR_MUTS_SET_ACCOUNTS = "muts_fcr_set_accounts";
export const API_FCR_MUTS_SET_SELECTED_ACCOUNTS = "muts_fcr_set_selected_accounts";
export const API_FCR_MUTS_SET_SELECTED_NA_ACCOUNTS = "muts_fcr_set_selected_na_accounts";
export const API_FCR_MUTS_SET_FULL_ACCOUNTS = "muts_fcr_set_full_accounts";
export const API_FCR_MUTS_SET_FULL_SELECTED_ACCOUNTS = "muts_fcr_set_full_selected_accounts";
export const API_FCR_MUTS_SET_ALL_SELECTS = "muts_fcr_set_all_selects";

const state = {
    countries: [],
    fleets: {
        national_global_account_names: [],
        canadian_national_global_account_names: [],
        government_account_names: [],
    },
    states: {
        CA_states: [],
        US_states: [],
        MX_states: [],
        CO_states: [],
        AU_states: [],
        CN_provinces: [],
    },
    vocations: [],
    is_show_unknown: false,
    is_show_localbook: false,
    is_show_goverment: false,
    is_show_preferred_fleet: false,
    selected_table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    table: {
        accounts: [],
        page_number: null,
        page_size: null,
        total_items: null,
        total_selected_items: null
    },
    full_table: {
        accounts: []
    },
    full_selected_table: {
        accounts: [],
    },
    dealers: [],
    dealers_ctsc: [],
    unit_types: [],

    is_fleet_accounts_verified_failed: false,
    fleet_verified_accounts: []
}

const getters = {
    getFCRAccountVerification(){
        return state.is_fleet_accounts_verified_failed;
    },
    getFCRVerifiedAccounts(){
        return state.fleet_verified_accounts;
    },
    getFCRUnitTypes() {
        return state.unit_types;
    },
    getFCRCountries() {
        return state.countries;
    },
    getFCRShowTypes() {
        return {
            is_show_unknown: state.is_show_unknown,
            is_show_localbook: state.is_show_localbook,
            is_show_goverment: state.is_show_goverment,
            is_show_preferred_fleet: state.is_show_preferred_fleet
        };
    },
    getFCRFleetsNA() {
        return state.fleets.national_global_account_names;
    },
    getFCRFleetsCA() {
        return state.fleets.canadian_national_global_account_names;
    },
    getFCRFleetsG() {
        return state.fleets.government_account_names;
    },
    getFCRStatesCA() {
        return state.states.CA_states;
    },
    getFCRStatesUS() {
        return state.states.US_states;
    },
    getFCRStatesMX() {
        return state.states.MX_states;
    },
    getFCRStatesCO() {
        return state.states.CO_states;
    },
    getFCRStatesAU() {
        return state.states.AU_states;
    },
    getFCRStatesCN() {
        return state.states.CN_provinces;
    },
    getFCRVocations() {
        return state.vocations;
    },
    getFCRAccountsTable() {
        return state.table;
    },
    getFCRSelectedAccountsTable() {
        return state.selected_table;
    },
    getFCRFullAccountsTable() {
        return state.full_table;
    },
    getFCRFullSelectedAccountsTable() {
        return state.full_selected_table;
    },
    getFCRDealers() {
        return state.dealers;
    },
    getFCRRegions() {
        return state.dealers_ctsc;
    }
}

const actions = {
    [API_FCR_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_SELECTED_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_SELECTED_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_ALL_SELECTED_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_SELECTED_NA_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_FULL_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_FULL_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_FULL_SELECTED_ACCOUNTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/select_accounts", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_FULL_SELECTED_ACCOUNTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FCR_ALL_SELECTS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_conditions/get_selects", params, context)
                .then(({ data }) => {
                    context.commit(API_FCR_MUTS_SET_ALL_SELECTS, {data});
                    context.commit(API_FCR_MUTS_SET_REPORTS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_FCR_MUTS_SET_ACCOUNTS](state, {data}) {
        state.table.accounts = data.accounts;
        state.table.page_number = data.page_number;
        state.table.page_size = data.page_size;  
        state.table.total_items = data.total_items; 
        state.table.total_selected_items = data.total_selected_items;  
    },
    [API_FCR_MUTS_SET_SELECTED_ACCOUNTS](state, {data}) {
        state.selected_table.accounts = data.accounts;
        state.selected_table.page_number = data.page_number;
        state.selected_table.page_size = data.page_size;  
        state.selected_table.total_items = data.total_items;   
        state.selected_table.total_selected_items = data.total_selected_items;
    },
    [API_FCR_MUTS_SET_SELECTED_NA_ACCOUNTS](state, {data}) {
        state.selected_table.accounts = data.accounts;
    },
    [API_FCR_MUTS_SET_FULL_ACCOUNTS](state, {data}) {
        state.full_table.accounts = data.accounts;
    },
    [API_FCR_MUTS_SET_FULL_SELECTED_ACCOUNTS](state, {data}) {
        state.full_selected_table.accounts = data.accounts;
    },
    [API_FCR_MUTS_SET_ALL_SELECTS](state, {data}) {
        state.states.CA_states = data.CA_states;
        state.states.US_states = data.US_states;
        state.states.MX_states = data.MX_states;
        state.states.CO_states = data.CO_states;
        state.states.AU_states = data.AU_states;
        state.states.CN_provinces = data.CN_provinces;  

        state.fleets.national_global_account_names = data.national_global_account_names;
        state.fleets.canadian_national_global_account_names = data.canadian_national_global_account_names;
        state.fleets.government_account_names = data.government_account_names;

        state.countries = data.countries?.filter(el => el.value != null);

        state.vocations = data.vocations?.filter(el => el.value != null);

        state.dealers = data.dealers;
        state.dealers_ctsc = data.dealers_ctsc;

        state.unit_types = data.unit_types;

        state.is_show_localbook = data.is_show_localbook;
        state.is_show_unknown = data.is_show_unknown;
        state.is_show_goverment = data.is_show_goverment;
        state.is_show_preferred_fleet = data.is_show_preferred_fleet;
        state.is_fleet_accounts_verified_failed = data.is_accounts_verification_failed;
        state.fleet_verified_accounts = data.verified_account;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
