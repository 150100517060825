import ApiService from "@/core/services/api.service";

// action types
export const API_TSR_LOCATION_IDS_LOAD = "action_tsr_location_ids_load";

// muts
export const API_TSR_MUTS_SET_LOCATION_IDS = "muts_tsr_set_location_ids";

const state = {
    location_ids: [],
}

const getters = {
    getTSRLocationIds() {
        return state.location_ids;
    },
}

const actions = {
    [API_TSR_LOCATION_IDS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("torque_summary_report/select_location_ids", context)
                .then(({ data }) => {
                    context.commit(API_TSR_MUTS_SET_LOCATION_IDS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_TSR_MUTS_SET_LOCATION_IDS](state, {data}) {
        state.location_ids = data.location_ids; 
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
