import ApiService from "@/core/services/api.service";

// action types
export const API_SAR_REPORT_LOAD = "action_sar_report_load";
export const API_SAR_REPORT_TO_PDF_LOAD = "action_sar_report_to_pdf_load";
export const API_SAR_REPORT_TO_EXCEL_LOAD = "action_sar_report_to_excel_load";

// muts
export const API_SAR_MUTS_SET_REPORT = "muts_sar_set_report"; 

const state = {
    scrap_tires: {
        AccountTireReportsData: [],
        ImagesData: [],
        StatisticData: [],
        AccountData: [],
        SurveyData: [],
        CoverPage: [],
        GraphHead: null
    }
}

const getters = {
    getSARReportImages() {
        return state.scrap_tires.ImagesData;
    },
    getSARReportStatistics() {
        return state.scrap_tires.StatisticData;
    },
    getSARReportAccountData() {
        return state.scrap_tires.AccountData;
    },
    getSARReportSurveyData() {
        return state.scrap_tires.SurveyData;
    },
    getSARReportCoverPage() {
        return state.scrap_tires.CoverPage;
    },
    getSARReportGraphHead() {
        return state.scrap_tires.GraphHead;
    }
}

const actions = {
    [API_SAR_REPORT_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_scrap_report", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_REPORT, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_REPORT_TO_PDF_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("scrap_analysis_report/get_report_to_pdf", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_REPORT_TO_EXCEL_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("scrap_analysis_report/get_report_to_excel", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}


const mutations = {
    [API_SAR_MUTS_SET_REPORT](state, {data}) {
        let i = 0;
        state.scrap_tires.ImagesData = data.scrap_tires.ImagesData.map(el => el = {
            image: el.Image,
            name: el.Name,
            index: i++ 
        });
        state.scrap_tires.StatisticData = data.scrap_tires.StatisticData;
        state.scrap_tires.AccountData = data.scrap_tires.AccountData;
        state.scrap_tires.SurveyData = data.scrap_tires.SurveyData;
        state.scrap_tires.CoverPage = data.scrap_tires.CoverPage;
        state.scrap_tires.GraphHead = data.scrap_tires.GraphPageHeader;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};