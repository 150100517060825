const ID_TOKEN_KEY = "gy_portal_token";
const ID_API_KEY = "gy_portal_key";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getApiKey = () => {
  return window.localStorage.getItem(ID_API_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveApiKey = token => {
  window.localStorage.setItem(ID_API_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyApiKey = () => {
  window.localStorage.removeItem(ID_API_KEY);
};

export default { getToken, saveToken, destroyToken, getApiKey, saveApiKey, destroyApiKey };
