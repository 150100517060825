<template>
        <div class="gy-spinner-container">
            <span class="gy-spinner-icon"></span>
            <div class="preloader-wrapper big active">
                <div class="spinner-layer spinner-blue-only">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
            <div v-if="title" class="loader-title">
                <label class="h5 ml-5">{{ $trans(title) }}</label>
            </div>
        </div>
</template>

<script>
export default {
    props: {
        title: String
    }
}
</script>
<style scoped>
.gy-spinner-icon {
    display: inline-block!important;
    position: relative!important;
    left: 53px!important;
    bottom: 13px!important;
}
</style>
