import { MUTATIONS } from "./mutations";
import { GETTERS } from "./fleet.dashboard.module";
import ApiService from "@/core/services/api.service";

import {
    SELECT_LOCATION_STEP,
    SELECT_DATE_STEP,
    SELECT_FILTER_STEP,
    TAB_TYPES,
    DROPDOWN_STATUS_CODES,
} from "@/utils/constants/FleetDashboard.constants.js"

export const ACTIONS = {
    SET_TAB_REPORTS: "(fleet-dashboard action) set reports for tab without report data",
    UPDATE_REPORT_MODELS: "(fleet-dashboard action) update report models",
    UPDATE_REPORT_SETTINGS: "(fleet-dashboard action) update report settings",
    UPDATE_REPORT: "(fleet-dashboard action) update report",
    UPDATE_REPORT_LOADER: "(fleet-dashboard action) update report loader",
    ADD_TO_BASKET: "(fleet-dashboard action) add report to the basket",
    REMOVE_FROM_BASKET: "(fleet-dashboard action) remove report from the basket",
    RESTORE: "(fleet-dashboard action) restore entire fleet dashboard",
    RESTORE_STEP: "(fleet-dashboard action) restore step",
    RESTORE_FILTERS: "(fleet-dashboard action) restore filters",
    UPDATE_STATE_OBJECT: "(fleet-dashboard action) update object in state",
    UPDATE_STATE_PROP: "(fleet-dashboard action) update prop in state",
    CLEAR_BASKET: "(fleet-dashboard action) clear basket",
    UPDATE_FILTERS: "(fleet-dashboard action) update filters by tab",
    UPDATE_FILTERS_INNERTAB: "(fleet-dashboard action) update inner tab  at filters",
    CHANGE_STEP: "(fleet-dashboard action) change step",
    CLOSE_STEP: "(fleet-dashboard action) close step",
    CHANGE_TAB: "(fleet-dashboard action) change tab",
    SET_TAB: "(fleet-dashboard action) set tab",
    HANDLE_TOTAL_SELECTED_BY_INNERTAB: "(fleet-dashboard action) handle total selected items on filters by inner tab",
    SET_BASKET: "(fleet-dashboard action) set basket",
    SET_BASE_PARAMS: "(fleet-dashboard action) set base params",
    RESET_FILTERS_BY_TAB: "(fleet-dashboard action) reset filters by tab",
    CLEAR_STATE: "(fleet-dashboard action) reset state to default",
    RESET_API_LOADED_SETTING_OPTIONS: "(fleet-dashboard action) reset filters (settings) loaded from API to default",

    //CasingDurability report filters
    SET_TIRE_BRAND_OPTIONS: "(fleet-dashboard action) get tire brand options",
    SET_TIRE_PRODUCT_LINE_OPTIONS: "(fleet-dashboard action) get tire product line options",
    SET_TIRE_SIZE_OPTIONS: "(fleet-dashboard action) get tire size options",
}

export const API_ACTIONS = {
    GET_REPORTS: "(fleet-dashboard action) get_reports from api",
    GET_ACCOUNTS: "(fleet-dashboard action) get_accounts from api",
    GET_CUSTOMER_LOCATION_GROUPS: "(fleet-dashboard action) get customer location groups list from api",
    GET_CUSTOMER_LOCATION_REGIONS: "(fleet-dashboard action) get customer location regions list from api",
    GET_LOCATION_CODES: "(fleet-dashboard action) get_locations from api",
    GET_NONSIG_CODES: "(fleet-dashboard action) get nonsig from api",
    GET_TEMPLATE: "(fleet-dashboard action) get_template from api by id in route",
    GET_TEMPLATES: "(fleet-dashboard action) get_templates from api",
    CREATE_TEMPLATE: "(fleet-dashboard action) create_template from api",
    DELETE_TEMPLATE: "(fleet-dashboard action) delete_template from api",

    UPDATE_REPORTS: "(fleet-dashboard action) get_reports from api with cancelation token",
    EXPORT_REPORT: "(fleet-dashboard action) export single report",
    EXPORT_BASKET: "(fleet-dashboard action) export entire basket",
}

export const actions = {
    /**************************************************************************
    * API
    ***************************************************************************/
    [API_ACTIONS.GET_ACCOUNTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("fleet_dashboard/get_accounts",context, params)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_ACCOUNTS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ACTIONS.GET_CUSTOMER_LOCATION_GROUPS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_customer_location_groups", params, context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_CUSTOMER_LOCATION_GROUPS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ACTIONS.GET_CUSTOMER_LOCATION_REGIONS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_customer_location_regions", params, context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_CUSTOMER_LOCATION_REGIONS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_ACTIONS.GET_LOCATION_CODES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_locations", params, context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_LOCATION_CODES, 
                    {
                        table: {
                            items: data.items, 
                            total_items: data.total_items, 
                            total_selected_items: data.total_selected_items
                        }, 
                        tab: params.tab,
                        innerTab: params.innerTab || 0
                    });
                    resolve(data);
                })
                .catch((e) => {
                    
                    reject(e);
                });
        });
    },
    [API_ACTIONS.GET_NONSIG_CODES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_nonsigs", params, context)
                .then(({ data }) => {
                    context.commit(MUTATIONS.SET_LOCATION_CODES, 
                    {
                        table: {
                            items: data.items, 
                            total_items: data.total_items, 
                            total_selected_items: data.total_selected_items
                        }, 
                        tab: params.tab,
                        innerTab: params.innerTab || 0
                    });
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    [API_ACTIONS.GET_REPORTS](context, body) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_reports", body, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });

        });
    },
    [API_ACTIONS.UPDATE_REPORTS](context, {body, cancelSource, part}) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_reports", body, context, cancelSource)
                .then(({ data }) => {
                    context.commit(MUTATIONS.UPDATE_REPORTS, {
                        reports: data,
                        part: part
                    });
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });

        });
    },
    [API_ACTIONS.GET_TEMPLATE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`fleet_dashboard/get_template/${id}`, context)
            .then(({data}) => {
                resolve(data);
            })
            .catch((e) => {
                reject(e);
            })

        });
    },
    [API_ACTIONS.GET_TEMPLATES](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/get_templates", params, context)
            .then(({data}) => {
                context.commit(MUTATIONS.SET_TEMPLATES, data);
                resolve(data);
            })
            .catch((e) => {
                
                reject(e);
            })

        });
    },
    [API_ACTIONS.CREATE_TEMPLATE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("fleet_dashboard/create_template", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    
                    reject(e);
                });
        });
    },
    [API_ACTIONS.DELETE_TEMPLATE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete("fleet_dashboard/delete_template/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    
                    reject(e);
                });
        });
    },
    [API_ACTIONS.EXPORT_BASKET](context, params) {
        return new Promise((resolve, reject) => {
            const format = params.format;
            delete  params.format;
            ApiService.postBlob(`fleet_dashboard/export_reports_${format}`, params, context)
            .then((obj) => {
                resolve(obj);
            })
            .catch((e) => {
                reject(e);
            })
        })
    },
    [API_ACTIONS.EXPORT_REPORT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob(`fleet_dashboard/export_single_report`, params, context)
            .then((obj) => {
                resolve(obj);
            })
            .catch((e) => {
                
                reject(e);
            })
        })
    },
    /**************************************************************************
    * Other
    ***************************************************************************/
    [ACTIONS.SET_TIRE_BRAND_OPTIONS](context, data) {
        // if (context.rootGetters[GETTERS.GET_DROPDOWNS_STATUS].tireBrandDd === DROPDOWN_STATUS_CODES.LOADED) {
        //     resolve(context.rootGetters[GETTERS.GET_TIRE_BRANDS]);
        //     return;
        // }
        context.commit(MUTATIONS.SET_TIRE_BRAND_OPTIONS, {
            brands: data.brands,
            reportType: data.reportType
        });
    },
    [ACTIONS.SET_TIRE_PRODUCT_LINE_OPTIONS](context, data) {
        context.commit(MUTATIONS.SET_TIRE_PRODUCT_LINE_OPTIONS, {
            productLines: data.productLines,
            reportType: data.reportType
        });
    },
    
    [ACTIONS.SET_TIRE_SIZE_OPTIONS](context, data) {
        context.commit(MUTATIONS.SET_TIRE_SIZE_OPTIONS, {
            tireSizes: data.tireSizes,
            reportType: data.reportType
        });
    },

    [ACTIONS.SET_TAB_REPORTS](context, data) {
        context.commit(MUTATIONS.SET_TAB_REPORTS, data);
    },
    [ACTIONS.UPDATE_REPORT_MODELS](context, data) {
        context.commit(MUTATIONS.UPDATE_REPORT_MODELS, data);
    },
    [ACTIONS.UPDATE_REPORT_SETTINGS](context, data) {
        context.commit(MUTATIONS.UPDATE_REPORT_SETTINGS, data);
    },
    [ACTIONS.UPDATE_REPORT](context, data) {
        context.commit(MUTATIONS.UPDATE_REPORT, data);
    },
    [ACTIONS.UPDATE_REPORT_LOADER](context, data) {
        context.commit(MUTATIONS.UPDATE_REPORT_LOADER, data);
    },
    [ACTIONS.ADD_TO_BASKET](context, report) {
        context.commit(MUTATIONS.ADD_TO_BASKET, report);
    },
    [ACTIONS.REMOVE_FROM_BASKET](context, report) {
        context.commit(MUTATIONS.REMOVE_FROM_BASKET, report);
    },
    [ACTIONS.CHANGE_TAB](context, tab) {
        context.commit(MUTATIONS.UPDATE_STATE_PROP, { key: "selectedTab", value: tab});
    },
    [ACTIONS.SET_TAB](context, tab) {
        context.commit(MUTATIONS.SET_TAB, tab);
    },
    [ACTIONS.RESTORE](context, restored) {
        if(Object.keys(restored.selectAccountSaved || {}).length) {
            // restore first step and close
            context.dispatch(ACTIONS.RESTORE_STEP, {
                key: SELECT_LOCATION_STEP.STATE_KEY,
                obj: restored.selectAccountSaved
            });

            if(Object.keys(restored.selectDateSaved || {}).length) {

                // restore second step and close
                context.dispatch(ACTIONS.RESTORE_STEP, {
                    key: SELECT_DATE_STEP.STATE_KEY,
                    obj: restored.selectDateSaved
                });

                let allowedTabs = [];
                if(Object.keys(restored.selectFiltersSavedPurchases || {}).length) {
                    // restore filters for purchase and close
                    context.dispatch(ACTIONS.RESTORE_FILTERS, {
                        tab: TAB_TYPES.PURCHASES,
                        obj: restored.selectFiltersSavedPurchases
                    });
                    
                    allowedTabs.push(TAB_TYPES.PURCHASES);
                }
                else {
                    context.commit(MUTATIONS.UPDATE_FILTERS, {
                        tab: TAB_TYPES.PURCHASES,
                        obj: {
                            isOpen: true
                        },
                    });
                }

                if(Object.keys(restored.selectFiltersSaved || {}).length) {
                    // restore filters for service and close
                    context.dispatch(ACTIONS.RESTORE_FILTERS, {
                        tab: TAB_TYPES.SERVICE,
                        obj: restored.selectFiltersSaved
                    });
                    
                    allowedTabs.push(TAB_TYPES.SERVICE);
                    
                }
                else {
                    context.commit(MUTATIONS.UPDATE_FILTERS, {
                        tab: TAB_TYPES.SERVICE,
                        obj: {
                            isOpen: true
                        },
                    });
                }

                if(Object.keys(restored.selectFiltersSavedRetreading || {}).length) {
                    // restore filters for retreading and close
                    context.dispatch(ACTIONS.RESTORE_FILTERS, {
                        tab: TAB_TYPES.RETREADING,
                        obj: restored.selectFiltersSavedRetreading
                    });
                    
                    allowedTabs.push(TAB_TYPES.RETREADING);
                    
                }
                else {
                    context.commit(MUTATIONS.UPDATE_FILTERS, {
                        tab: TAB_TYPES.RETREADING,
                        obj: {
                            isOpen: true
                        },
                    });
                }

                if(Object.keys(restored.selectFiltersSavedCasingDurability || {}).length) {
                    // restore filters for service and close
                    context.dispatch(ACTIONS.RESTORE_FILTERS, {
                        tab: TAB_TYPES.CASING_DURABILITY,
                        obj: restored.selectFiltersSavedCasingDurability
                    });
                    
                    allowedTabs.push(TAB_TYPES.CASING_DURABILITY);
                    
                }
                else {
                    context.commit(MUTATIONS.UPDATE_FILTERS, {
                        tab: TAB_TYPES.CASING_DURABILITY,
                        obj: {
                            isOpen: true
                        },
                    });
                }

                // restore selected tab number
                context.commit(MUTATIONS.UPDATE_STATE_PROP, {
                    key: "selectedTab",
                    value: restored.selectedTab
                });
                
                
                // set first exists tab number
                // context.commit(MUTATIONS.UPDATE_STATE_PROP, {
                //     key: "selectedTab",
                //     value: allowedTabs[0] || TAB_TYPES.PURCHASES
                // });

                if(Object.keys(restored.basketReports || {}).length) {
                    // restore reports
                    context.commit(MUTATIONS.SET_TEMP_BASKET, restored.basketReports);
                }

                if(restored.exportTitles) {
                    context.commit(MUTATIONS.UPDATE_STATE_OBJECT, {
                        key: "exportTitles",
                        obj: restored.exportTitles
                    })
                }


            }
            else {
                // open second step
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, {
                    key: SELECT_DATE_STEP.STATE_KEY,
                    obj: { isOpen: true}
                });
            }

        }
    },
    [ACTIONS.CHANGE_STEP](context, number) {

        // set data from saved to objects
        context.commit(MUTATIONS.RESTORE_FROM_SAVED, SELECT_LOCATION_STEP.STATE_KEY);
        context.commit(MUTATIONS.RESTORE_FROM_SAVED, SELECT_DATE_STEP.STATE_KEY);

        switch(number) {
            case SELECT_LOCATION_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: true}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: false}});
                break;
            }
            case SELECT_DATE_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: true}});
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: false}});
                // TODO: what next?
                
                break;
            }
            case SELECT_FILTER_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: true}});
                break;
            }
            default:
                break;
        }
    },
    [ACTIONS.CLOSE_STEP](context, number) {
        switch(number) {
            case SELECT_LOCATION_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: true}});
                
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: false}});
                
                context.commit(MUTATIONS.SAVE_STEP, SELECT_LOCATION_STEP.STATE_KEY);
                break;
            }
            case SELECT_DATE_STEP.NUMBER: {
                
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: false}});
                
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: true}});
                
                context.commit(MUTATIONS.SAVE_STEP, SELECT_LOCATION_STEP.STATE_KEY);
                context.commit(MUTATIONS.SAVE_STEP, SELECT_DATE_STEP.STATE_KEY);
                break;
            }
            case SELECT_FILTER_STEP.NUMBER: {
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_LOCATION_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_STATE_OBJECT, { key: SELECT_DATE_STEP.STATE_KEY, obj: {isOpen: false}});
                context.commit(MUTATIONS.UPDATE_FILTERS, { obj: {isOpen: false}});
                
                context.commit(MUTATIONS.SAVE_STEP, SELECT_LOCATION_STEP.STATE_KEY);
                context.commit(MUTATIONS.SAVE_STEP, SELECT_DATE_STEP.STATE_KEY);
                context.commit(MUTATIONS.SAVE_FILTERS);
                break;
            }

            default:
                break;
        }
    },
    [ACTIONS.UPDATE_STATE_OBJECT](context, data) {
        context.commit(MUTATIONS.UPDATE_STATE_OBJECT, data);
    },
    [ACTIONS.UPDATE_STATE_PROP](context, data) {
        context.commit(MUTATIONS.UPDATE_STATE_PROP, data);
    },
    [ACTIONS.CLEAR_BASKET](context) {
        context.commit(MUTATIONS.CLEAR_BASKET);
    },
    [ACTIONS.UPDATE_FILTERS](context, data) {
        context.commit(MUTATIONS.UPDATE_FILTERS, data);
    },
    [ACTIONS.RESET_FILTERS_BY_TAB](context, data) {
        context.commit(MUTATIONS.RESET_FILTERS_BY_TAB, data);
    },
    [ACTIONS.UPDATE_FILTERS_INNERTAB](context, data) {
        context.commit(MUTATIONS.UPDATE_FILTERS_INNERTAB, data);
    },
    [ACTIONS.HANDLE_TOTAL_SELECTED_BY_INNERTAB](context, data) {
        context.commit(MUTATIONS.HANDLE_TOTAL_SELECTED_BY_INNERTAB, data);
    },
    [ACTIONS.SET_BASKET](context, data) {
        context.commit(MUTATIONS.SET_BASKET, data);
    },

    [ACTIONS.RESTORE_STEP](context, {key, obj}) {
        var step = obj;
        step["saved"] = Object.assign({}, step);
        step.isOpen = false;
        context.commit(MUTATIONS.UPDATE_STATE_OBJECT, {
            key: key,
            obj: step,
        });
    },
    [ACTIONS.RESTORE_FILTERS](context, {tab, obj}) {

        for (const key in obj.tabs) {
            const innerTab = obj.tabs[key];

            context.commit(MUTATIONS.UPDATE_FILTERS_INNERTAB, {
                tab: tab,
                innerTab: key,
                obj: innerTab,
            });
        }

        context.commit(MUTATIONS.UPDATE_FILTERS, {
            tab: tab,
            obj: {
                unitLocations: obj.unitLocations,
                serviceTypes: obj.serviceTypes,
                wheelPositions: obj.wheelPositions,
                status: obj.status,
                isOpen: false
            },
        });

        context.commit(MUTATIONS.SAVE_FILTERS, tab);
    },
    [ACTIONS.SET_BASE_PARAMS](context, obj) {
        context.commit(MUTATIONS.SET_BASE_PARAMS, obj);
    },
    
    [ACTIONS.CLEAR_STATE](context, keys) {
        context.commit(MUTATIONS.CLEAR_STATE, keys);
    },

    [ACTIONS.RESET_API_LOADED_SETTING_OPTIONS](context) {
        context.commit(MUTATIONS.RESET_API_LOADED_SETTING_OPTIONS);
    },
}