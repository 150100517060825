import { COLORS } from "@/utils/constants/FleetDashboard.constants";

class TooltipConfig {
    constructor(id) {
        this.canvas = document.getElementById(id);
    }

    canvas = null;
    cornerRadius = 0;
    enabled = false;
    yAlign = 'bottom';
    xAlign = "center";
    caretPadding = 0;
    mode = "point";
    callbacks = {
        title: (points, data) => {
            let titles = [];

            points.forEach(point => {
                const ds = data.datasets[point.datasetIndex];
                const item = ds.items[point.index];

                titles.push(item);
            });

            return titles;
        }
    };

    custom = (model) => {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = `<table></table>`;
            document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (model.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        let borderColor = COLORS.GRAY;
        const backgroundColor = COLORS.BACKGROUND;

        // Set Text
        if(model.title?.length) {
            let innerHtml = '<tbody>';
            model.title.forEach((point) => {
                borderColor = point.color;
                innerHtml += `<tr><td><div style="display: flex; align-items: center; column-gap: 10px;">`;
                
                innerHtml += `<div>`;
                
                point.tooltip.content.forEach((content) => {
                    innerHtml += 
                    `<div>
                    <span style="font-weight: ${content.fontWeight || "bold"}">${content.text.trim()}${content.value ? `: </span>${content.value}`: ''}
                    </div>`
                });
                
                innerHtml += `</div>`;
                innerHtml += `</div></td></tr>`;
            });
            
            innerHtml += '</tbody>';

            let tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }


        let position = this.canvas.getBoundingClientRect();

        // Display, position, and set styles
        let {height, width} = getNodeHeightWidth(tooltipEl);

        tooltipEl.style.visibility = "hidden";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.backgroundColor = backgroundColor;
        tooltipEl.style.left = position.left + window.pageXOffset + model.caretX - (width / 2) - 7  + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + model.caretY - height - 26 + 'px';
        
        tooltipEl.style.bottom = "auto";
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.padding = "6px";
        tooltipEl.style.zIndex = 101;
        tooltipEl.style.border = `2px solid ${borderColor}`;
        tooltipEl.setAttribute("with-arrow", false)
        tooltipEl.style.visibility = "";
    }

}

function getNodeHeightWidth(node) {
    let height, width, clone = node.cloneNode(true)
    // hide the meassured (cloned) element
    clone.style.cssText = "position:fixed; top:-9999px; opacity:0;"
    // add the clone to the DOM 
    document.body.appendChild(clone)
    // meassure it
    height = clone.clientHeight;
    width = clone.clientWidth;
    // cleaup 
    clone.parentNode.removeChild(clone)
    return {height, width};
}

export {
    TooltipConfig
}