export const showGoToTMPopup = () => {
    const showPermissionsPopupEvent = new window.CustomEvent('showPermissionsPopupEvent');
    document.dispatchEvent(showPermissionsPopupEvent);
}

export const showGoToLPPopup = () => {
    const showPermissionsPopupEvent = new window.CustomEvent('showPermissionsTMPopupEvent');
    document.dispatchEvent(showPermissionsPopupEvent);
}

export const showInfoPopup = (msg) => {
    if(!msg?.length) {
        console.error(`"msg" can't be undefined or empty`);
        return;
    }

    window.app.$emit('show-popup', {
        title: "Information",
        message: msg
    });
}