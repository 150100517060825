import { BarChartConfig } from "..";
import { Dataset } from "../Dataset";
import { toMoney } from "@/utils/helpers/StringHelper.js";
import { toCommas } from "@/utils/helpers/NumberHelper.js";
import { groupBy } from "@/utils/helpers/ArrayHelper";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";

class TooltipEmergencyVsShceduledConfig  extends TooltipConfig{
    constructor(id) {
        super(id)
        this.mode = "x"
    }
}
class EmergencyVSScheduledConfig extends BarChartConfig {
    constructor(current, prev) {
        if(prev && current?.labels) {
            current.labels = [];
        }

        super(current, prev);

        let currentDataSets = [];
        let prevDataSets = [];

        if(current) {

            let datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    label: label,
                    color: items[0]?.color,
                    stack: "current-stack",
                    includeLabels: false,
                    labelFormat: "$v %",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                });
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(prev) {

            let datasets = groupBy(prev.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                let dataset = new Dataset({
                    label: label,
                    color: items[0]?.color,
                    stack: "prev-stack",
                    includeLabels: false,
                    labelFormat: "$v %",
                    type: items[0]?.type,
                    legend: items[0]?.legend,
                    xAxisID: 'x-axis-id'
                });

                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                prevDataSets.push(dataset);
            });
    
        }


        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets)
        }
        if(prevDataSets.length) {
            this.data.datasets = this.data.datasets.concat(prevDataSets)
        }

        this.options.scales.yAxes.push({
            type: 'linear',
            stacked: true,
            ticks: {
                beginAtZero: true,
                stacked: true,
                padding: 20,
                // max: 3600 * 7,
                // stepSize: 3600,
                callback: (value) => {
                    return toMoney(toCommas(value));
                }
                
                
            },
            gridLines:{
                drawBorder: false,
                drawOnChartArea: true,
                tickMarkLength: 0,
                display: true
            }
        });

        if(current) {
            this.options.scales.xAxes.push(
                {
                    id: 'x-axis-id',
                    stacked: true,
                    type: 'category',
                    offset: true,
                    gridLines: {
                        drawOnChartArea: true,
                        offsetGridLines: true,
                        z: 1,
                        drawTicks: false
                    },
                    maxBarThickness: 150,
                    ticks: {
                        padding: 10
                    }
                }
            )
        }
        

        this.options.legend = {
            display: false
            
        }
        this.options.title = {
            display: false
        }

        
    }

    plugins = [ChartDataLabels];

    
}

export {
    EmergencyVSScheduledConfig,
    TooltipEmergencyVsShceduledConfig
}