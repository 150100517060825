import ApiService from "@/core/services/api.service";

// action types
export const API_SAR_SURVEYS_LOAD = "action_sar_surveys_load";
export const API_SAR_SELECTED_SURVEYS_LOAD = "action_sar_selected_surveys_load";
export const API_SAR_FULL_SURVEYS_LOAD = "action_sar_full_surveys_load";
export const API_SAR_FULL_SELECTED_SURVEYS_LOAD = "action_sar_full_selected_surveys_load";


// muts
export const API_SAR_MUTS_SET_SURVEYS = "muts_sar_set_surveys";
export const API_SAR_MUTS_SET_PAGE_PARAMS = "muts_sar_set_page_params";
export const API_SAR_MUTS_SET_FULL_SURVEYS = "muts_sar_set_full_surveys";
export const API_SAR_MUTS_SET_SELECTED_SURVEYS = "muts_sar_set_selected_surveys";
export const API_SAR_MUTS_SET_SELECTED_PAGE_PARAMS = "muts_sar_set_selected_page_params";
export const API_SAR_MUTS_SET_FULL_SELECTED_SURVEYS = "muts_sar_set_full_selected_surveys";

const state = {
    surveys: [],
    pageParams: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: 0
    },
    selectedSurveys: [],
    selectedPageParams: {
        page_number: 1,
        page_size: 20,
        total_items: 0,
        total_selected_items: 0
    },
    fullSurveys: [],
    fullSelectedSurveys: []
}

const getters = {
    getSARSurveys() {
        return state.surveys;
    },
    getSARPageParams() {
        return state.pageParams;
    },
    getSARSelectedSurveys() {
        return state.selectedSurveys;
    },
    getSARSelectedPageParams() {
        return state.selectedPageParams;
    },
    getSARFullSurveys() {
        return state.fullSurveys;
    },
    getSARFullSelectedSurveys() {
        return state.fullSelectedSurveys;
    },
}

const actions = {
    [API_SAR_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_SURVEYS, data);
                    context.commit(API_SAR_MUTS_SET_PAGE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_SELECTED_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_SELECTED_SURVEYS, data);
                    context.commit(API_SAR_MUTS_SET_SELECTED_PAGE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_FULL_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_FULL_SURVEYS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_SAR_FULL_SELECTED_SURVEYS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("scrap_analysis_report/get_surveys", params, context)
                .then(({ data }) => {
                    context.commit(API_SAR_MUTS_SET_FULL_SELECTED_SURVEYS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_SAR_MUTS_SET_SURVEYS](state, data) {
        state.surveys = data.surveys;
    },
    [API_SAR_MUTS_SET_PAGE_PARAMS](state, data) {
        state.pageParams.page_number = data.page_number;
        state.pageParams.page_size = data.page_size;
        state.pageParams.total_items = data.total_items;
        state.pageParams.total_selected_items = data.total_selected_items
    },
    [API_SAR_MUTS_SET_SELECTED_SURVEYS](state, data) {
        state.selectedSurveys = data.surveys;
    },
    [API_SAR_MUTS_SET_SELECTED_PAGE_PARAMS](state, data) {
        state.selectedPageParams.page_number = data.page_number;
        state.selectedPageParams.page_size = data.page_size;
        state.selectedPageParams.total_items = data.total_items;
        state.selectedPageParams.total_selected_items = data.total_selected_items;
    },
    [API_SAR_MUTS_SET_FULL_SURVEYS](state, data) {
        state.fullSurveys = data.surveys;
    },
    [API_SAR_MUTS_SET_FULL_SELECTED_SURVEYS](state, data) {
        state.fullSelectedSurveys = data.surveys;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
