import ApiService from "@/core/services/api.service";

// action types
export const API_TIRE_MANAGEMENT_REPORTS_ACTION_LOAD = "action_tire_management_reports_load";

// muts


const state = {

}

const getters = { 

}

const actions = {
    [API_TIRE_MANAGEMENT_REPORTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("tire_management_reports/get", context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
};