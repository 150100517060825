import { range } from "./ArrayHelper";
import moment from "moment";
import "moment-duration-format"

export default {
    getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    },
    getQuarter(date = new Date()) {
        return Math.floor(date.getMonth() / 3 + 1);
    },
    getQuarterFrom(year, quarter) {
        var startDate = new Date();
        startDate.setFullYear(year, (quarter - 1) * 3, 1);
        return moment(startDate).format("MM/DD/YYYY");
    },
    getQuarterTo(year, quarter) {
        quarter++;
        const month = (quarter - 1) * 3;
        const daysInMonth = this.getDaysInMonth(year, month);
        var endDate = new Date(year, month - 1, daysInMonth);
        return moment(endDate).format("MM/DD/YYYY");
    },
    getYearsFrom(start = 1898) {
        return range(start, new Date().getFullYear()).reverse();
    },
    getMonthDiff(startDate, endDate) {
        var months;
        months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth();
        months += endDate.getMonth();
        var dayDiff = endDate.getDate() - startDate.getDate();
        // console.log("dayDiff: ", dayDiff);
        // console.log("months: ", months <= 0 ? 0 : (dayDiff > 0 ? months + 1 : months));
        return months <= 0 ? 0 : (dayDiff > 0 ? months + 1 : months);
    },
    getTimeFromSeconds(seconds, format = "hh:mm") {
        seconds = Number(seconds);

        return moment.duration(seconds, "seconds").format(format, { trim: false });
    },
    getFormattedDate(date, format = "MM/DD/YYYY") {
        return moment(date).format(format);
    }
}