import ApiService from "@/core/services/api.service";

// action types
export const API_MANAGE_PRODUCTS_INIT = "action_manage_products_init";
export const API_MANAGE_PRODUCTS_GET_PRODUCTLINE = "action_manage_products_get_productline";
export const API_MANAGE_PRODUCTS_GET_PRODUCTS = "action_manage_products_get_products";
export const API_MANAGE_PRODUCTS_INIT_UPDATE = "action_manage_products_init_update";
export const API_MANAGE_PRODUCTS_GET_ALL_PRODUCTLINE = "action_manage_products_get_all_productline";
export const API_MANAGE_PRODUCTS_GET_PRODUCT = "action_manage_products_get_product";
export const API_MANAGE_PRODUCTS_SAVE_PRODUCT = "action_manage_products_save_product";


// muts
export const API_MANAGE_PRODUCTS_MUTS_INIT = "muts_manage_products_init";
export const API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTLINE = "muts_manage_products_get_productline";
export const API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTS = "muts_manage_products_get_products";
export const API_MANAGE_PRODUCTS_MUTS_INIT_UPDATE = "muts_manage_products_init_update";
export const API_MANAGE_PRODUCTS_MUTS_GET_ALL_PRODUCTLINE = "muts_manage_products_get_all_productline";
export const API_MANAGE_PRODUCTS_MUTS_GET_PRODUCT = "muts_manage_products_get_product";
export const API_MANAGE_PRODUCTS_MUTS_SAVE_PRODUCT = "muts_manage_products_save_product";

const state = {
    retread: [],
    load_range: [],
    brand: [],
    tire_size: [],
    product_line: [],
    products: [],
    update: {
        retread: [],
        load_range: [],
        brand: [],
        tire_size: [],
        product_line: [],
        product: null,
    },
}

const getters = {
    getRetreadP() {
        return state.retread;
    },
    getLoadRange() {
        return state.load_range;
    },
    getBrand() {
        return state.brand;
    },
    getTireSizeP() {
        return state.tire_size;
    },
    getProductLine() {
        return state.product_line;
    },
    getProducts() {
        return state.products;
    },
    getUpdate() {
        return state.update;
    },
}

const actions = {
    [API_MANAGE_PRODUCTS_INIT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("products/init", context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_INIT, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_GET_PRODUCTLINE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("products/get_product_lines/" + params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTLINE, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_GET_PRODUCTS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("products/get_products", params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTS, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_INIT_UPDATE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("products/init_update", context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_INIT_UPDATE, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_GET_ALL_PRODUCTLINE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("products/get_all_product_lines/" + params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_GET_ALL_PRODUCTLINE, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_GET_PRODUCT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("products/get_product/" + params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_GET_PRODUCT, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_MANAGE_PRODUCTS_SAVE_PRODUCT](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("products/save_product", params, context)
                .then(({ data }) => {
                    context.commit(API_MANAGE_PRODUCTS_MUTS_SAVE_PRODUCT, { data });
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}

const mutations = {
    [API_MANAGE_PRODUCTS_MUTS_INIT](state, { data }) {
        state.retread = data.retread;
        state.load_range = data.load_range;
        state.brand = data.brand;
        state.tire_size = data.tire_size;
    },
    [API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTLINE](state, { data }) {
        state.product_line = data;
    },
    [API_MANAGE_PRODUCTS_MUTS_GET_PRODUCTS](state, { data }) {
        state.products = data.items;
    },
    [API_MANAGE_PRODUCTS_MUTS_INIT_UPDATE](state, { data }) {
        state.update.retread = data.retread;
        state.update.load_range = data.load_range;
        state.update.brand = data.brand;
        state.update.tire_size = data.tire_size;
    },
    [API_MANAGE_PRODUCTS_MUTS_GET_ALL_PRODUCTLINE](state, { data }) {
        state.update.product_line = data;
    },
    [API_MANAGE_PRODUCTS_MUTS_GET_PRODUCT](state, { data }) {
        state.update.product = data;
    },
    [API_MANAGE_PRODUCTS_MUTS_SAVE_PRODUCT](state, { data }) {
        state.update.product = data;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
