import { ChartConfig } from "..";

import { Dataset } from "./Dataset";

import {
    GRAPH_POINTS_TYPE
}
from "@/utils/constants/FleetDashboard.constants.js"

import { groupBy } from "@/utils/helpers/ArrayHelper";

const LINE_TYPE = {
    SOLID: null, 
    DOTTED: [0, 5],
    DASHED: [10, 10]
}

class LineChartConfigOptions {
    layout = {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    };
    scales = {
        yAxes: [],
        xAxes: []
    };

    legendCallback = (chart) => {
        var innerHTML = "";
        var datasets = chart.data.datasets.slice();

        var currents = [];
        var previos = [];
        var nats = [];

        datasets.forEach(ds => {
            if(ds.lineType == GRAPH_POINTS_TYPE.CURRENT) currents.push(ds);
            if(ds.lineType == GRAPH_POINTS_TYPE.PREVIOUS) previos.push(ds);
            if(ds.lineType == GRAPH_POINTS_TYPE.NAT_AVG) nats.push(ds);
        });
        currents.forEach((current, i) => {
            
            innerHTML += `<div class="chart-legends-container">`;
            var boxClass = "";

            var legendBox = `<div class="legend-box ${boxClass}" style="border-top-color: ${current.borderColor}"></div>`
            var legendText = `<div class="legend-text">${current.label}</div>`
            innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            
            if(previos[i]) {
                boxClass = "dashed";
                legendBox = `<div class="legend-box ${boxClass}" style="border-top-color: ${previos[i].borderColor}"></div>`
                legendText = `<div class="legend-text">${previos[i].label}</div>`
                innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            }
            if(nats[i]) {
                boxClass = "dotted";
                legendBox = `<div class="legend-box ${boxClass}" style="border-top-color: ${nats[i].borderColor}"></div>`
                legendText = `<div class="legend-text">${nats[i].label}</div>`
                innerHTML += `<div class="chart-legend-item">${legendBox} ${legendText}</div>`
            }


            innerHTML += "</div>"
        });

        return innerHTML;
    };
}

class LineChartConfig extends ChartConfig{
    constructor(current, prev, avg) {
        super();

        var currentDataSets = [];
        var prevDataSets = [];
        var avgDataSets = [];

        if(current) {
            

            var datasets = groupBy(current.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                var dataset = new Dataset(label, items[0]?.color, GRAPH_POINTS_TYPE.CURRENT);
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                currentDataSets.push(dataset);
            });
    
        }

        if(prev) {
            if(!this.data.labels.length) {
                this.data.labels = prev.labels;
            }

            var datasets = groupBy(prev.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                var dataset = new Dataset(label, items[0]?.color, GRAPH_POINTS_TYPE.PREVIOUS);
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                prevDataSets.push(dataset);
            });
    
        }

        if(avg) {
            if(!this.data.labels.length) {
                this.data.labels = avg.labels;
            }

            var datasets = groupBy(avg.points, point => point["datasetId"]);
            datasets.forEach((items, label) => {
                var dataset = new Dataset(label, items[0]?.color, GRAPH_POINTS_TYPE.NAT_AVG);
                dataset.data = items.map(item => item.dataItem);
                dataset.items = items;

                avgDataSets.push(dataset);
            });
    
        }


        if(currentDataSets.length) {
            this.data.datasets = this.data.datasets.concat(currentDataSets)
        }
        if(prevDataSets.length) {
            this.data.datasets = this.data.datasets.concat(prevDataSets)
        }
        if(avgDataSets.length) {
            this.data.datasets = this.data.datasets.concat(avgDataSets)
        }

        this.data.labels = current?.labels || [];
        
        if(prev && this.data.labels.length < prev.labels.length) {
            var start = this.data.labels.length;
            for (let i = start; i < prev.labels.length; i++) {
                this.data.labels[i] = prev.labels[i];
            }
        }

        if(avg && this.data.labels.length < avg.labels.length) {
            var start = this.data.labels.length;
            for (let i = start; i < avg.labels.length; i++) {
                this.data.labels[i] = avg.labels[i];
            }
        }

        
    }
    
    type = "line";

    options = new LineChartConfigOptions();
}

export {
    LineChartConfig
}