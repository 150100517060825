import store from "@/core/services/store";
import '@/scripts/ieEvent.module.js';

export const hasSecurityWithGroup = (security, group) => {
    let correctGroup = store.getters.AppGetUser.Group.includes(group);
    
    // user has different group
    if(!correctGroup) return true;

    // user has correct group
    return hasSecurity(security);
    
}

// old checkTMKey
export const hasSecurity = (security) => {
    return !!store.getters.AppGetUser.UserAccessSecurities.find(sec => sec == security);
}
