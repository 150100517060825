import { Report } from "../../Report";
import { RepairSummaryChartConfig } from "../../../charts/bars/retreading/RepairSummary";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";

import { toCommas } from "@/utils/helpers/NumberHelper";

import { DatasetItem as BarDatasetItem} from "../../../charts/bars/Dataset";

import {
    TAB_TYPES,
    TableFields,
    COLORS,
    GRAPH_POINTS_TYPE,
    SUMMARY_LABELS,
} from "@/utils/constants/FleetDashboard.constants.js"

class RepairSummaryReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.RETREADING}, settings, models, loader);

        if(data?.tables) {
            this.tableItems = Object.values(data.tables)[0] || [];
            this.tableItemsPrev = Object.values(data.tables)[1] || [];
            this.tableItemsAll = Object.values(data.tables)[2] || [];
        }
        if(data?.graphs) {
            this.chartItems = data.graphs;
        }

        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    tableItemsPrev = [];
    tableItemsAll = [];
    chartItems = null;
    isTableSelectable = false;
    tableFields = [];
    summaryBoxes = true;

    getChartConfig(id) {
        let current, prev;
        current = getRepairSummaryChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT);
        if(this.settings.isComparePrev) {
            if (current) 
                current.labels = [];
            prev = getRepairSummaryChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS);
        }

        let config = new RepairSummaryChartConfig(current, prev);
        config.options.tooltips = new TooltipConfig(id);
        
        return config;
    };

    getChartItems() {
        return this.chartItems;
    };

    getFilteredFields() {
        if(!this.tableFields) return [];

        let filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);

            filtered.forEach(field => {
                field.sortable = false;
                field.thClass = "";
            });
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);

            filtered.forEach(field => {
                field.sortable = true;
                field.thClass = "sorting";
            });
        }

        return filtered;
    };

    getTableItems() {
        if(this.settings.isComparePrev) {
            return this.tableItemsAll;
        }
        else {
            return this.tableItems;
        }
    };

    getTiles() {
        let retread = null;
        let usedTires = null;
        let rejected = null;
        let retreadPrev = null;
        let usedTiresPrev = null;
        let rejectedPrev = null;

        let sbRetread = this.tableItems.filter(x => x['StatusBacking'] == SUMMARY_LABELS.RETREAD);
        if (sbRetread.length > 0) {
            retread = sbRetread.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        let sbUsedTires = this.tableItems.filter(x => x['StatusBacking'] == SUMMARY_LABELS.USED_TIRES);
        if (sbUsedTires.length > 0) {
            usedTires = sbUsedTires.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        let sbRejected = this.tableItems.filter(x => x['StatusBacking'] == SUMMARY_LABELS.REJECTED);
        if (sbRejected.length > 0) {
            rejected = sbRejected.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        let sbRetreadPrev = this.tableItemsPrev.filter(x => x['StatusBacking'] == SUMMARY_LABELS.RETREAD);
        if (sbRetreadPrev.length > 0) {            
            retreadPrev = sbRetreadPrev.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        let sbUsedTiresPrev = this.tableItemsPrev.filter(x => x['StatusBacking'] == SUMMARY_LABELS.USED_TIRES);
        if (sbUsedTiresPrev.length > 0) {
            usedTiresPrev = sbUsedTiresPrev.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        let sbRejectedPrev = this.tableItemsPrev.filter(x => x['StatusBacking'] == SUMMARY_LABELS.REJECTED);
        if (sbRejectedPrev.length > 0) {
            rejectedPrev= sbRejectedPrev.reduce((sum, item) => {
                return sum + item['Tires'];
            }, 0) || 0;
        }

        return {
            retread,
            usedTires,
            rejected,
            retreadPrev,
            usedTiresPrev,
            rejectedPrev,
            isComparePrev: this.settings.isComparePrev,
        }
    };

    getTilesComponent() {
        return import("../../../../result/tiles/retreading/RepairSummaryTiles.vue");
    }

    getDisabledAddBtn() {
        let tiles = this.getTiles();
        let tilesCondition = tiles.retread || tiles.usedTires || tiles.rejected;

        if (!this.tableItems || !tilesCondition) {
            return true;
        }

        return false;
    };

    getTableRowClass() {
        return (item, type) => {
            if (!item || type !== 'row') return;
            if (item.PeriodType === 1) return 'retreading-status-last-group-row';
        }
    }
}

const getRepairSummaryChart = (data, periodType) => {
    let dataInPeriod = data?.[periodType];
    if(!dataInPeriod) return null;

    let labels = [];
    let points = [];

    const currentPeriodTxt = Object.keys(data || {}).length > 1 ? "Current" : null;
    const postfix = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? "Previous" : currentPeriodTxt;
    const periodPostfixTxt = postfix ? ` (${postfix})` : "";

    const nailHolesColor = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES_PREV : COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES;
    const sectionColor = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SECTION_PREV : COLORS.RET_REPAIR_SUMMARY_SECTION;
    const spotColor = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SPOT_PREV : COLORS.RET_REPAIR_SUMMARY_SPOT;
    const beadColor = periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_BEAD_PREV : COLORS.RET_REPAIR_SUMMARY_BEAD;
    
    const getNailHolesContent = (values, postfix) => {
        return [
            {text: `# Nail Holes`, value: toCommas(values["NailHoles"])},
            {text: `Average`, value: toCommas(values["NailHolesAvg"])},
        ]
    };

    const getSectionContent = (values, postfix) => {
        return [
            {text: `# Section`, value: toCommas(values["Section"])},
            {text: `Average`, value: toCommas(values["SectionAvg"])},
        ]
    };

    const getSpotContent = (values, postfix) => {
        return [
            {text: `# Spot`, value: toCommas(values["Spot"])},
            {text: `Average`, value: toCommas(values["SpotAvg"])},
        ]
    };

    const getBeadContent = (values, postfix) => {
        return [
            {text: `# Bead`, value: toCommas(values["Bead"])},
            {text: `Average`, value: toCommas(values["BeadAvg"])},
        ]
    };

    const nailHolesLegend = {
        backgroundColor: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES_PREV : COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES,
    };
    const sectionLegend = {
        backgroundColor: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SECTION_PREV : COLORS.RET_REPAIR_SUMMARY_SECTION,
    };
    const spotLegend = {
        backgroundColor: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SPOT_PREV : COLORS.RET_REPAIR_SUMMARY_SPOT,
    };
    const beadLegend = {
        backgroundColor: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_BEAD_PREV : COLORS.RET_REPAIR_SUMMARY_BEAD,
    };

    dataInPeriod.forEach(dataRow => {
        let nailHolesTooltip = {
            title: `Nail Holes${periodPostfixTxt}`,
            content: getNailHolesContent(dataRow, postfix),
            color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES_PREV : COLORS.RET_REPAIR_SUMMARY_NAIL_HOLES,
        }
        
        let sectionTooltip = {
            title: `Section${periodPostfixTxt}`,
            content: getSectionContent(dataRow, postfix),
            color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SECTION_PREV : COLORS.RET_REPAIR_SUMMARY_SECTION,
        }

        let spotTooltip = {
            title: `Spot${periodPostfixTxt}`,
            content: getSpotContent(dataRow, postfix),
            color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_SPOT_PREV : COLORS.RET_REPAIR_SUMMARY_SPOT,
        }

        let beadTooltip = {
            title: `Bead${periodPostfixTxt}`,
            content: getBeadContent(dataRow, postfix),
            color: periodType == GRAPH_POINTS_TYPE.PREVIOUS ? COLORS.RET_REPAIR_SUMMARY_BEAD_PREV : COLORS.RET_REPAIR_SUMMARY_BEAD,
        }        

        points.push(new BarDatasetItem({
            datasetId: `Nail Holes${periodPostfixTxt}`,
            dataItem: dataRow['NailHoles'],
            datasetLabel: '',
            tooltip: nailHolesTooltip,
            color: nailHolesColor,
            type: periodType,
            legend: nailHolesLegend,
        }));
        points.push(new BarDatasetItem({
            datasetId: `Section${periodPostfixTxt}`,
            dataItem: dataRow['Section'],
            datasetLabel: '',
            tooltip: sectionTooltip,
            color: sectionColor,
            type: periodType,
            legend: sectionLegend,
        }));        
        points.push(new BarDatasetItem({
            datasetId: `Spot${periodPostfixTxt}`,
            dataItem: dataRow['Spot'],
            datasetLabel: '',
            tooltip: spotTooltip,
            color: spotColor,
            type: periodType,
            legend: spotLegend,
        }));
        points.push(new BarDatasetItem({
            datasetId: `Bead${periodPostfixTxt}`,
            dataItem: dataRow['Bead'],
            datasetLabel: '',
            tooltip: beadTooltip,
            color: beadColor,
            type: periodType,
            legend: beadLegend,
        }));

        labels.push(dataRow['StatusBacking']);
    });

    return { points, labels };
}

export {
    RepairSummaryReport
}