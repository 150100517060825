import ApiService from "@/core/services/api.service";

// action types
export const API_FAVORITE_REPORTS_ACTION_LOAD = "action_favorite_reports_load";
export const API_FAVORITE_REPORT_BY_ID_ACTION_LOAD = "action_favorite_report_by_id_load";
export const API_FAVORITE_REPORTS_ACTION_DELETE = "action_favorite_reports_delete";

// muts
export const API_FAVORITE_REPORTS_MUTS_SET = "muts_favorite_reports_set";
export const API_FAVORITE_REPORT_MUTS_SET = "muts_favorite_report_set";
export const API_FAVORITE_REPORTS_MUTS_SET_PAGE_PARAMS = "muts_favorite_reports_set_page_params";

const state = {
    favorite_reports: [],
    page_params: {
        total_items: 0,
        page_number: 1,
        page_size: 20
    },
    favorite_report: null
}

const getters = { 
    getFavoriteReports() {
        return state.favorite_reports;
    },
    getFavoriteReport() {
        return state.favorite_report;
    },
    getFavoriteReportsPageParams() {
        return state.page_params;
    }
}

const actions = {
    [API_FAVORITE_REPORTS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post(process.env.VUE_APP_PORTAL_WEB_API + "/" + "favorite_reports/get", params, context)
                .then(({ data }) => {
                    context.commit(API_FAVORITE_REPORTS_MUTS_SET, data);
                    context.commit(API_FAVORITE_REPORTS_MUTS_SET_PAGE_PARAMS, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FAVORITE_REPORT_BY_ID_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(process.env.VUE_APP_PORTAL_WEB_API + "/" + "favorite_reports/get/" + params, context)
                .then(({ data }) => {
                    context.commit(API_FAVORITE_REPORT_MUTS_SET, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_FAVORITE_REPORTS_ACTION_DELETE](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.delete(process.env.VUE_APP_PORTAL_WEB_API + "/" + "favorite_reports/delete/" + params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
}

const mutations = {
    [API_FAVORITE_REPORTS_MUTS_SET](state, data) {
        state.favorite_reports = data.favorite_reports;

        if(state.favorite_reports?.length) {
            state.favorite_reports.forEach(r => {
                let url = new URL(r.request);

                let request = location.origin + url.pathname + url.search;

                r.request = request;
            });
        }
    },
    [API_FAVORITE_REPORT_MUTS_SET](state, data) {
        state.favorite_report = data.favorite_report;
    },
    [API_FAVORITE_REPORTS_MUTS_SET_PAGE_PARAMS](state, data) {
        state.page_params.total_items = data.total_items;
        state.page_params.page_number = data.page_number;
        state.page_params.page_size = data.page_size;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};