import {
    REPORT_TYPE,
    TAB_TYPE_MAP,
    ERRORS,
    TAB_TYPES,
    REPORT_TYPE_TAB_MAP,
    RESTORE_ACTION,
    REPORT_ORDER,
    DROPDOWN_STATUS_CODES,
} from "@/utils/constants/FleetDashboard.constants.js"
import { Report } from "@/view/pages/fleet-dashboard/scripts/reports/Report";
import ReportFactory from "@/view/pages/fleet-dashboard/scripts/reports/ReportFactory";
import { compareReportOrder } from "@/view/pages/fleet-dashboard";
import { defaultState } from "./fleet.dashboard.module";

export const MUTATIONS = {
    SET_TAB_REPORTS: "(fleet-dashboard mutation) set reports for tab without report data",
    SET_ACCOUNTS: "(fleet-dashboard mutation) set accounts selects after api call",
    SET_CUSTOMER_LOCATION_GROUPS: "(fleet-dashboard mutation) set customer location groups after api call",
    SET_CUSTOMER_LOCATION_REGIONS: "(fleet-dashboard mutation) set customer location regions after api call",
    SET_LOCATION_CODES: "(fleet-dashboard mutation) set location codes after api call",
    SET_TEMPLATES: "(fleet-dashboard mutation) set templates after api call",

    SET_TIRE_BRAND_OPTIONS: "(fleet-dashboard mutation) set tire brand options",
    SET_TIRE_PRODUCT_LINE_OPTIONS: "(fleet-dashboard mutation) set tire product line options",
    SET_TIRE_SIZE_OPTIONS: "(fleet-dashboard mutation) set tire size options",

    SET_DROPDOWN_STATUS_TIRE_BRAND_OPTIONS: "(fleet-dashboard mutation) set tire brand options dropdown status",
    SET_DROPDOWN_STATUS_TIRE_PRODUCT_LINE_OPTIONS: "(fleet-dashboard mutation) set tire product line options dropdown status",
    SET_DROPDOWN_STATUS_TIRE_SIZE_OPTIONS: "(fleet-dashboard mutation) set tire size options dropdown status",
    
    UPDATE_REPORTS: "(fleet-dashboard mutation) update reports",
    UPDATE_REPORT: "(fleet-dashboard mutation) update report",
    UPDATE_REPORT_LOADER: "(fleet-dashboard mutation) update report loader",
    UPDATE_REPORT_MODELS: "(fleet-dashboard mutation) update report models",
    UPDATE_REPORT_SETTINGS: "(fleet-dashboard mutation) update report settings",
    ADD_TO_BASKET: "(fleet-dashboard mutation) add report to the basket",
    REMOVE_FROM_BASKET: "(fleet-dashboard mutation) remove report from the basket",
    UPDATE_STATE_OBJECT: "(fleet-dashboard mutation) update object in state",
    UPDATE_STATE_PROP: "(fleet-dashboard mutation) update prop in state",
    CLEAR_BASKET: "(fleet-dashboard mutation) clear basket",
    UPDATE_FILTERS: "(fleet-dashboard mutation) update filters",
    UPDATE_FILTERS_INNERTAB: "(fleet-dashboard mutation) update filters inner tab",
    RESTORE_FROM_SAVED: "(fleet-dashboard mutation) set all from saved object to object",
    SAVE_STEP: "(fleet-dashboard mutation) save entire step and place in saved prop",
    SAVE_FILTERS: "(fleet-dashboard mutation) save filters and place in saved prop",
    HANDLE_TOTAL_SELECTED_BY_INNERTAB: "(fleet-dashboard mutation) handle total selected items on filters by inner tab",
    SET_TEMP_BASKET: "(fleet-dashboard mutation) set temp basket",
    SET_BASKET: "(fleet-dashboard mutation) set basket",
    SET_BASE_PARAMS: "(fleet-dashboard mutation) set base params",
    SET_TAB: "(fleet-dashboard mutation) set tab",
    RESET_FILTERS_BY_TAB: "(fleet-dashboard mutation) reset filters by tab",
    CLEAR_STATE: "(fleet-dashboard mutation) reset state to default",
    RESET_API_LOADED_SETTING_OPTIONS: "(fleet-dashboard mutation) reset filters (settings) loaded from API to default",
    
}
export const mutations = {
    [MUTATIONS.SET_ACCOUNTS](state, data) {
        state._selects.usNationalAccountOptions = data.us_national_accounts;
        state._selects.canadianCommonOwnerOptions = data.canadian_common_owners;
        state._selects.canadianNonsigOptions = data.canadian_nonsigs;
        state._selects.locationGroupsOptions = data.cust_loc_regions;
    },
    [MUTATIONS.SET_CUSTOMER_LOCATION_GROUPS](state, data) {
        state.locationGroups = data;
    },
    [MUTATIONS.SET_CUSTOMER_LOCATION_REGIONS](state, data) {
        state.locationRegions = data;
    },
    [MUTATIONS.SET_LOCATION_CODES](state, { table, tab, innerTab}) {
        if(typeof tab == 'undefined') tab = state.selectedTab;
        if(typeof tab == 'undefined') throw ERRORS.CANT_FIND_TAB;

        const tabKey = TAB_TYPE_MAP.get(tab);

        state.filters[tabKey].tabs[innerTab].table = table;
    },
    [MUTATIONS.SET_TEMPLATES](state, data) {
        state._dashboardTemplates.items = data.items;
    },
    [MUTATIONS.SET_TAB](state, tab) {
        if(typeof(tab) == 'undefined') {
            for (const key in state.reports) {
                const reports = state.reports[key];

                if(reports.length) {
                    tab = reports[0].tabType;
                    break;
                }
            }
        }
        
        state.selectedTab = tab;
    },
    [MUTATIONS.SET_TAB_REPORTS](state, { tab, action }) {
        let reportTypes = REPORT_ORDER[tab];
        const tabKey = TAB_TYPE_MAP.get(tab);

        // getting basket reports from basket/temp-basket
        let basketReports = ((action == RESTORE_ACTION.RUN || action == RESTORE_ACTION.EDIT) ? state.tempBasket[tabKey] : state.basketReports[tabKey]) || [];
        
        // getting reports from tab
        let reports = state.reports[tabKey].slice() || [];

        // getting filters for tab
        const filters = state.filters[tabKey];

        // cancel active reports on tab
        state.reports[tabKey].forEach(report => report.cancelTokenSource.cancel());

        // clear reports on tab
        state.reports[tabKey] = [];

        reportTypes.forEach(reportType => {

            const baseReportData = {
                report_type: reportType,
                tab_type: tab,
            }
        
            const basketReport = basketReports
                .find(br => br.reportType == reportType);
            
            const existingReport = reports
                .find(r => r.reportType == reportType);


            if(basketReport) {
                let report = ReportFactory.createWithFiltersOnExistingReportForAction(
                    baseReportData,
                    basketReport,
                    filters,
                    action
                );
                basketReport.settings = report.settings;
                basketReport.models = report.models;

                state.reports[tabKey].push(report);
            }
            else if(existingReport) {
                state.reports[tabKey].push(ReportFactory.createWithFiltersOnExistingReportForAction(
                    baseReportData,
                    existingReport, 
                    filters,
                    action
                ));
            }
            else {
                let report = ReportFactory.createWithFilters(baseReportData, filters);
                state.reports[tabKey].push(report);
            }

        });

    },
    [MUTATIONS.UPDATE_REPORTS](state, {reports, part}) {

        var service = [];
        var purchases = [];
        var retreading = [];
        var casingdurability =[];
        reports.forEach((reportData, index) => {

            switch (reportData.report_type) {
                case REPORT_TYPE.TOP_5_SERVICING_DEALERS:
                case REPORT_TYPE.FLEET_HQ_CALLS_BY_MONTH:
                case REPORT_TYPE.FLEET_HQ_CALLS_BY_STATE:
                case REPORT_TYPE.FLEET_HQ_CALLS_AVG_ROLL_TIME: {
                    service.push({
                        index: index,
                        reportType: reportData.report_type
                    });
                    break;
                }

                case REPORT_TYPE.EMERGENCY_SERVICE:
                case REPORT_TYPE.RETREAD_SALES_TO_NEW_TIRES_SALES:
                case REPORT_TYPE.SERVICE_EMERGENCY_VS_SCHEDULED:
                case REPORT_TYPE.REPAIR_AND_RETREAD_COST:
                case REPORT_TYPE.TOP_PRODUCTS: {
                    purchases.push({
                        index: index,
                        reportType: reportData.report_type
                    });
                    break;
                }

                case REPORT_TYPE.TOP_5_RETREADERS:
                case REPORT_TYPE.REJECTED_TIRE_FAILURES_BY_REASON:
                case REPORT_TYPE.RETREAD_TURNAROUND_TIME:
                case REPORT_TYPE.REPAIR_SUMMARY: {
                    retreading.push({
                        index: index,
                        reportType: reportData.report_type
                    });
                    break;
                }

                case REPORT_TYPE.STATISTICS_CASING_DURABILITY:
                case REPORT_TYPE.TOP_10_CASING_BRANDS_CASING_DURABILITY:
                case REPORT_TYPE.TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY:
                case REPORT_TYPE.AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY:
                case REPORT_TYPE.REJECT_TIRE_RATE_CASING_DURABILITY:
                case REPORT_TYPE.CASINGS_SUBMITTED_CASING_DURABILITY:{
                    casingdurability.push({
                        index: index,
                        reportType: reportData.report_type
                    });
                    break;
                }

                default:
                    break;
            }
        });

        if(service.length) {
            state.reports.service = state.reports.service.map(r => {
                const indexInfo = service.find(info => info.reportType == r.reportType);
                if(indexInfo) {

                    return ReportFactory.update(reports[indexInfo.index], r, part);
                }
                else {
                    return r;
                }
            })
        }

        if(purchases.length) {
            state.reports.purchases = state.reports.purchases.map(r => {
                const indexInfo = purchases.find(info => info.reportType == r.reportType);
                if(indexInfo) {

                    return ReportFactory.update(reports[indexInfo.index], r, part);
                }
                else {
                    return r;
                }
            })
        }

        if(retreading.length) {
            state.reports.retreading = state.reports.retreading.map(r => {
                const indexInfo = retreading.find(info => info.reportType == r.reportType);
                if(indexInfo) {

                    return ReportFactory.update(reports[indexInfo.index], r, part);
                }
                else {
                    return r;
                }
            })
        }

        if(casingdurability.length) {
            state.reports.casingdurability = state.reports.casingdurability.map(r => {
                const indexInfo = casingdurability.find(info => info.reportType == r.reportType);
                if(indexInfo) {

                    return ReportFactory.update(reports[indexInfo.index], r, part);
                }
                else {
                    return r;
                }
            })
        }

    },
    [MUTATIONS.UPDATE_REPORT](state, {reportType, params}) {
        let report = state.reports[REPORT_TYPE_TAB_MAP.get(reportType)].find(r => r.reportType == reportType);
        if(report) {
            for (const key in params) {
                if(report.hasOwnProperty(key)) {
                    report[key] = params[key];
                }
            }
        }
    },
    [MUTATIONS.UPDATE_REPORT_MODELS](state, params) {
        let report = state.reports[REPORT_TYPE_TAB_MAP.get(params.reportType)].find(r => r.reportType == params.reportType);
        if(report) {
            for (const key in params) {
                if(report.models.hasOwnProperty(key)) {
                    report.models[key] = params[key];
                }
            }
        }
    },
    [MUTATIONS.UPDATE_REPORT_SETTINGS](state, params) {
        let report = state.reports[REPORT_TYPE_TAB_MAP.get(params.reportType)].find(r => r.reportType == params.reportType);
        if(report) {
            for (const key in params) {
                if(report.settings.hasOwnProperty(key)) {
                    report.settings[key] = params[key];
                }
            }
        }
    },
    [MUTATIONS.UPDATE_REPORT_LOADER](state, params) {
        let report = state.reports[REPORT_TYPE_TAB_MAP.get(params.reportType)].find(r => r.reportType == params.reportType);
        if(report) {
            report.loader[params.part] = params.loader;
        }

    },
    [MUTATIONS.ADD_TO_BASKET](state, report) {
        switch(report.tabType){
            case TAB_TYPES.PURCHASES:
                let purchaseReport = state.basketReports.purchases.find(r => r.reportType == report.reportType);
                if(purchaseReport) {
                    purchaseReport.models = report.models;
                    purchaseReport.settings = report.settings;
                } 
                else {
                    state.basketReports.purchases.push(new Report(report, report.settings, report.models));
                }

                state.basketReports.purchases.sort((a, b) => compareReportOrder(a, b, TAB_TYPES.PURCHASES));
                break;

            case TAB_TYPES.SERVICE:
                let serviceReport = state.basketReports.service.find(r => r.reportType == report.reportType);
                if(serviceReport) {
                    serviceReport.models = report.models;
                    serviceReport.settings = report.settings;
                }
                else {
                    state.basketReports.service.push(new Report(report, report.settings, report.models));

                }
                
                state.basketReports.service.sort((a, b) => compareReportOrder(a, b, TAB_TYPES.SERVICE));
                break;

            case TAB_TYPES.RETREADING:
                let retreadingReport = state.basketReports.retreading.find(r => r.reportType == report.reportType);
                if(retreadingReport) {
                    retreadingReport.models = report.models;
                    retreadingReport.settings = report.settings;
                }
                else {
                    state.basketReports.retreading.push(new Report(report, report.settings, report.models));
                }
                
                state.basketReports.retreading.sort((a, b) => compareReportOrder(a, b, TAB_TYPES.RETREADING));
                break;  
                
            case TAB_TYPES.CASING_DURABILITY:
                let casingDurabilityReport = state.basketReports.casingdurability.find(r => r.reportType == report.reportType);
                if(casingDurabilityReport) {
                    casingDurabilityReport.models = report.models;
                    casingDurabilityReport.settings = report.settings;
                }
                else {
                    state.basketReports.casingdurability.push(new Report(report, report.settings, report.models));
                }
                
                state.basketReports.casingdurability.sort((a, b) => compareReportOrder(a, b, TAB_TYPES.CASING_DURABILITY));
                break;  

            default:
                break;
        }
    },
    [MUTATIONS.REMOVE_FROM_BASKET](state, report) {
        switch(report.tabType){
            case TAB_TYPES.PURCHASES:
                const indexP = state.basketReports.purchases.findIndex(r => r.reportType === report.reportType);
                state.basketReports.purchases.splice(indexP, 1);
                break;
            case TAB_TYPES.SERVICE:
                const indexS = state.basketReports.service.findIndex(r => r.reportType === report.reportType);
                state.basketReports.service.splice(indexS, 1);
                break;
            case TAB_TYPES.RETREADING:
                const indexR = state.basketReports.retreading.findIndex(r => r.reportType === report.reportType);
                state.basketReports.retreading.splice(indexR, 1);
                break;    
            case TAB_TYPES.CASING_DURABILITY:
                const indexC = state.basketReports.casingdurability.findIndex(r => r.reportType === report.reportType);
                state.basketReports.casingdurability.splice(indexC, 1);
                break;        
            default:
                break;
        }
    },
    [MUTATIONS.UPDATE_STATE_OBJECT](state, { key, obj }) {
        for (const objKey in obj) {
            if (state[key] && state[key].hasOwnProperty(objKey)) {
                state[key][objKey] = obj[objKey];
            }
        }

    },
    [MUTATIONS.UPDATE_STATE_PROP](state, { key, value }) {
        if(state.hasOwnProperty(key)) {
            state[key] = value;
        }
    },
    [MUTATIONS.CLEAR_BASKET](state) {
        for (const key in state.basketReports) {
            state.basketReports[key] = [];
        }
    },
    [MUTATIONS.UPDATE_FILTERS](state, { tab, obj }) {
        if(typeof tab == 'undefined') tab = state.selectedTab;
        if(typeof tab == 'undefined') throw ERRORS.CANT_FIND_TAB;

        const tabKey = TAB_TYPE_MAP.get(tab);

        for (const objKey in obj) {
            if (state.filters[tabKey] && state.filters[tabKey].hasOwnProperty(objKey)) {
                state.filters[tabKey][objKey] = obj[objKey];
            }
        }

    },
    [MUTATIONS.RESET_FILTERS_BY_TAB](state, tab) {
        if(typeof tab == 'undefined') tab = state.selectedTab;
        if(typeof tab == 'undefined') throw ERRORS.CANT_FIND_TAB;

        const tabKey = TAB_TYPE_MAP.get(tab);

        for (const key in state.filters[tabKey].tabs) {
            var innerTab = state.filters[tabKey].tabs[key];
            
            innerTab.selectedIDs = [];
            innerTab.selectedLocations = [];
            innerTab.isAll = true;
            innerTab.currentPage = 1;
            innerTab.perPage = 10;
            innerTab.orderBy = 1;
            innerTab.isSortDesc = false;
            innerTab.keyword = "";
        }

    },
    [MUTATIONS.UPDATE_FILTERS_INNERTAB](state, { tab, innerTab = 0, obj }) {
        if(typeof tab == 'undefined') tab = state.selectedTab;
        if(typeof tab == 'undefined') throw ERRORS.CANT_FIND_TAB;

        const tabKey = TAB_TYPE_MAP.get(tab);

        for (const objKey in obj) {
            if (state.filters[tabKey]?.tabs[innerTab] && state.filters[tabKey].tabs[innerTab].hasOwnProperty(objKey)) {
                state.filters[tabKey].tabs[innerTab][objKey] = obj[objKey];
            }
        }

    },
    [MUTATIONS.RESTORE_FROM_SAVED](state, stateKey) {
        if(Object.keys(state[stateKey].saved || {}).length) {
            for (const key in state[stateKey]) {
                if(key !== "saved" && state[stateKey].saved.hasOwnProperty(key)) {
                    state[stateKey][key] = state[stateKey].saved[key];
                }
            }
        }
    },
    [MUTATIONS.SAVE_STEP](state, stateKey) {
        var toSave = JSON.parse(JSON.stringify(state[stateKey]));
        delete toSave["saved"];

        state[stateKey].saved = toSave;
    },
    [MUTATIONS.SAVE_FILTERS](state, tab) {
        if(typeof tab == 'undefined') tab = state.selectedTab;
        const tabKey = TAB_TYPE_MAP.get(tab);

        if(!state.filters[tabKey]) return;

        var saved = {};
        saved["serviceTypes"] = Object.assign({}, state.filters[tabKey].serviceTypes);
        saved["unitLocations"] = Object.assign({}, state.filters[tabKey].unitLocations);
        saved["wheelPositions"] = Object.assign({}, state.filters[tabKey].wheelPositions);
        saved["status"] = Object.assign({}, state.filters[tabKey].status);

        var tabs = {};
        for (const key in state.filters[tabKey].tabs) {
            const innerTab = state.filters[tabKey].tabs[key];
            tabs[key] = {
                selectedIDs: innerTab.selectedIDs,
                selectedLocations: innerTab.selectedLocations,
                isAll: innerTab.isAll,
            }
        }
        saved["tabs"] = tabs;
        state.filters[tabKey].saved = Object.assign({}, saved);
    },
    [MUTATIONS.HANDLE_TOTAL_SELECTED_BY_INNERTAB](state, { row, tab, innerTab }) {
        if(typeof tab == 'undefined') tab = state.selectedTab;

        const tabKey = TAB_TYPE_MAP.get(tab);

        if(!state.filters[tabKey]) return;

        if(row) {
            if(row.isSelected){
                state.filters[tabKey].tabs[innerTab].table.total_selected_items++;
            } else {
                state.filters[tabKey].tabs[innerTab].table.total_selected_items--;
            }
        }
        else {
            state.filters[tabKey].tabs[innerTab].table.total_selected_items = 0;
        }
    },
    [MUTATIONS.SET_TEMP_BASKET](state, basket) {
        if(typeof basket != 'object') return;

        for (const key in basket) {
            let reports = [];
            if(Array.isArray(basket[key])) {
                reports = basket[key];
            }

            state.tempBasket[key] = reports.filter(r => Object.values(REPORT_TYPE).includes(r.reportType)).slice();   
        }
        
    },
    [MUTATIONS.SET_BASKET](state, basket = state.tempBasket) {
        if(typeof basket != 'object') return;

        for (const key in basket) {
            if(state.basketReports.hasOwnProperty(key)) {
                var reports = [];
                if(Array.isArray(basket[key])) {
                    reports = basket[key];
                }
                
                reports = reports.map(r => {
                    return new Report(r, r.settings, r.models);
                });
                state.basketReports[key] = reports;   
            }
        }

        
    },
    [MUTATIONS.SET_BASE_PARAMS](state, baseParams) {
        state.baseParams = Object.assign({}, baseParams);
    },
    [MUTATIONS.CLEAR_STATE](state, keys = []) {
        for (const key in defaultState) {
            if (key.startsWith('_') || (keys.length && keys.includes(key))) {
                continue;
            }
            state[key] = JSON.parse(JSON.stringify(defaultState[key]));
        }
    },

    [MUTATIONS.SET_TIRE_BRAND_OPTIONS](state, { brands, reportType }) {
        state.tireBrandOptions[reportType] = brands;
        state.dropDownStatus.tireBrandDd[reportType] = DROPDOWN_STATUS_CODES.LOADED;
    },
    [MUTATIONS.SET_TIRE_PRODUCT_LINE_OPTIONS](state, { productLines, reportType }) {
        state.tireProductLineOptions[reportType] = productLines;
        state.dropDownStatus.tireProductLineDd[reportType] = DROPDOWN_STATUS_CODES.LOADED;
    },
    [MUTATIONS.SET_TIRE_SIZE_OPTIONS](state, { tireSizes, reportType }) {
        state.tireSizeOption[reportType] = tireSizes;
        state.dropDownStatus.tireSizeDd[reportType] = DROPDOWN_STATUS_CODES.LOADED;
    },

    [MUTATIONS.SET_DROPDOWN_STATUS_TIRE_BRAND_OPTIONS](state, { data, reportType }) {
        state.dropDownStatus.tireBrandDd[reportType] = data;
    },
    [MUTATIONS.SET_DROPDOWN_STATUS_TIRE_PRODUCT_LINE_OPTIONS](state, { data, reportType }) {
        state.dropDownStatus.tireProductLineDd[reportType]  = data;
    },
    [MUTATIONS.SET_DROPDOWN_STATUS_TIRE_SIZE_OPTIONS](state, { data, reportType }) {
        state.dropDownStatus.tireSizeDd[reportType]  = data;
    },

    [MUTATIONS.RESET_API_LOADED_SETTING_OPTIONS](state) {
        state.tireBrandOptions = [];
        state.tireProductLineOptions = [];
        state.tireSizeOption = [];
        Object.keys(REPORT_TYPE).forEach(reportTypeKey => {
            const reportTypeValue = REPORT_TYPE[reportTypeKey];
            state.dropDownStatus.tireBrandDd[reportTypeValue] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
        });
        Object.keys(REPORT_TYPE).forEach(reportTypeKey => {
            const reportTypeValue = REPORT_TYPE[reportTypeKey];
            state.dropDownStatus.tireProductLineDd[reportTypeValue] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
        });
        Object.keys(REPORT_TYPE).forEach(reportTypeKey => {
            const reportTypeValue = REPORT_TYPE[reportTypeKey];
            state.dropDownStatus.tireSizeDd[reportTypeValue] = DROPDOWN_STATUS_CODES.NEVER_LOADED;
        });    
    },

}