import Vue from "vue";
import Vuex from "vuex";

import ApiAuth from "./api/auth.module";
import AppState from "./app.state.module"; 
// login
import ApiForgotPswd from './api/forgotPassword.module';
import ApiNewRegistration from './api/newRegistration.module';
//locales
import ApiAdminLocales from './api/admin.locales.module';
import ApiTranslations from './api/translations.module';

// common controls
import ApiCommonControls from './api/common.controls.module';
import ApiLoginPortalCommonControls from './api/common.controls.login.portal.module';

// tireoptix
import ApiFleetTireOptixReporting from './api/fleetTireOptixReporting/fleet.tireoptix.reporting.module';
import ApiFleetTireOptixReportingDetail from './api/fleetTireOptixReporting/fleet.tireoptix.reporting.detail.module';
import ApiDealerTireOptixReporting from './api/dealerTireOptixReporting/dealer.tireoptix.reporting.module';

// checkpoint
import ApiCheckPointReporting from './api/checkpoint/checkpoint.reporting.module';
import ApiCheckPointReportingDetail from './api/checkpoint/checkpoint.reporting.detail.module';
import APiTrailerReadinessReporting from './api/trailer-readiness-reporting/trailer.readiness.reporting.module';

// leasesales
import ApiLeaseSalesConfigurator from './api/leasesales/leasesales.configurator.module';

// leasesales
import ApiManageSpecs from './api/manage-specs/manage.specs.module';

//favorite reports
import FavoriteReports from './api/favoriteReports/favoriteReports.module';
import FavoriteReportsAdd from './api/favoriteReports/favoriteReportsAdd.module';

//tire management reports
import TireManagementReports from './api/tireManagementReports.module';

//scrap-analysis-report (SAR)
import DealerSelects from './api/dealer/dealer.selects.module';


//scrap-analysis-report (SAR)
import SelectAccStep from './api/scrap-analysis-reporting/select.account.step.module';
import ScrapAnalysis from './api/scrap-analysis-reporting/scrap.analysis.report.module';
import SelectFiltersStep from './api/scrap-analysis-reporting/select.filters.step.module';
//product-analysis-report (PAR)
import SelectAccountStepPAR from './api/product-analysis-report/select.account.step.module';
import SelectProductStepPAR from './api/product-analysis-report/select.product.step.module';
import ProductAnalysisReport from './api/product-analysis-report/product.analysis.report.module';
//product-wear-rate-report (ProductWareRateReport)
import SelectAccountStepProductWareRateReport from './api/product-wear-rate-report/select.account.step.module';
import FilterProductWearRateReport from './api/product-wear-rate-report/report.filters.module';
import ProductWearRateReportResult from './api/product-wear-rate-report/report.result.module';
//fleet-conditional-report (FCR)
import FCRSelectAccStep from './api/fleet-condition-reporting/select.accounts.step.module';
import FCRSelectFltrsStep from './api/fleet-condition-reporting/select.filters.step.module';
import FCR from './api/fleet-condition-reporting/fcr.module';
//torque-summary-report (TSR)
import TSRSelectLocStep from './api/torque-summary-report/select.locations.step.module.js';
import TSRSelectFiltrsStep from './api/torque-summary-report/select.filters.step.module.js';
import TSR from './api/torque-summary-report/torque.summary.report.module.js';

// fleet-survey (FS)
import FleetSurvey from './api/fleet-survey/fleet.survey.module';
import FleetSurveyDB from './api/fleet-survey/fleet.survey.db.module';

// unit-history (UH)
import UnitHistoryModule from "./api/unit-history-report/unit.history.module";

// fleet-dashboard (FD)
import FleetDashboard from './api/fleet-dashboard/fleet.dashboard.module';

// cross-portals
import CrossPortalsModule from "./api/cross.portals.module";

// manage-products
import ManageProductsModule from "./api/manage-products/manage-products.module";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ApiAuth,
    AppState,
    ApiAdminLocales,
    CrossPortalsModule,

    ApiCommonControls,
    ApiLoginPortalCommonControls,
    
    ApiForgotPswd,
    ApiNewRegistration,
    
    ApiTranslations,

    ApiFleetTireOptixReporting,
    ApiFleetTireOptixReportingDetail,

    ApiCheckPointReporting,
    ApiCheckPointReportingDetail,

    APiTrailerReadinessReporting,

    ApiLeaseSalesConfigurator,

    ApiManageSpecs,
    
    ApiDealerTireOptixReporting,
    
    FavoriteReports,
    FavoriteReportsAdd,

    TireManagementReports,

    DealerSelects,

    SelectFiltersStep,
    SelectAccStep,
    ScrapAnalysis,

    FCR,
    FCRSelectAccStep,
    FCRSelectFltrsStep,

    TSR,
    TSRSelectLocStep,
    TSRSelectFiltrsStep,
    
    SelectAccountStepPAR,
    SelectProductStepPAR,
    ProductAnalysisReport,

    SelectAccountStepProductWareRateReport,
    FilterProductWearRateReport,
    ProductWearRateReportResult,

    FleetSurvey,
    FleetSurveyDB,
    
    UnitHistoryModule,
    
    FleetDashboard,

    ManageProductsModule,

  }
});