import Enum from '@/utils/helpers/EnumHelper.js';

const DateTypes = Enum({
    LAST_30_DAYS: "0",
    MONTH_TO_DATE: "1",
    YEAR_TO_DATE: "2",
    QUARTER: "3",
    CUSTOM: "4",
});
const DateTypeLabels = Enum({
    LAST_30_DAYS: "Last 30 Days",
    MONTH_TO_DATE: "Month to Date",
    YEAR_TO_DATE: "Year to Date",
    QUARTER: "Quarter",
    CUSTOM: "Custom",
});

export {
    DateTypes,
    DateTypeLabels
}