import { Report } from "../../../../scripts/reports/Report";
import { RepairAndRetreadCostConfig } from "../../../charts/linear/purchase/RepairAndRetreadCost";
import { TooltipConfig } from "../../../charts/linear/TooltipConfig";

import {
    getRepairAndRetreadItems,
    getRepairAndRetreadChart
} from "./PurchaseReportHelper";

import {
    TAB_TYPES,
    TableFields,
    GRAPH_POINTS_TYPE
} from "@/utils/constants/FleetDashboard.constants.js"

import { groupBy } from "@/utils/helpers/ArrayHelper";

class RepairAndRetreadCostReport extends Report {
    constructor(data, settings, models, loader) {
        super({reportType: data.report_type, tabType: TAB_TYPES.PURCHASES}, settings, models, loader);
        if(data?.tables) this.tableItems = Object.values(data.tables)[0] || [];
        if(data?.graphs) {
            this.chartItems = data?.graphs;
        }
        
        this.settings.tireStatuses = null;
        this.settings.wheelPositions = null;
        this.settings.serviceTypes = null;
        this.settings.unitLocations = null;
        this.settings.isIncludeNatAvg = null;
        this.isTableSelectable = true;
        this.tableFields = TableFields[this.reportType];
    }

    tableItems = [];
    chartItems = null;
    isTableSelectable = false;
    tableFields = [];

    getChartConfig(id) {
        var current, prev;
        if(this.settings.isComparePrev) {
            prev = getRepairAndRetreadChart(this.chartItems, GRAPH_POINTS_TYPE.PREVIOUS, this.models.selectedMonth)
        }

        current = getRepairAndRetreadChart(this.chartItems, GRAPH_POINTS_TYPE.CURRENT, this.models.selectedMonth)
        var config = new RepairAndRetreadCostConfig(current, prev);
        config.options.tooltips = new TooltipConfig(id);
        return config;
    };

    getChartItems() {

        return this.chartItems;
    };

    getDisabledAddBtn() {
        if(this.tableItems.length && this.chartItems && Object.keys(this.chartItems).length) return false;

        return true;
    }
    
    getFilteredFields() {
        if(!this.tableFields) return [];

        var filtered = this.tableFields;
        if(this.settings.isComparePrev) {
            filtered = filtered.filter(f => f.show || f.showPrev);
        }
        else {
            filtered = filtered.filter(f => f.show || f.showCurrent);
        }

        if(this.models.selectedMonth) {
            filtered = filtered.filter(f => !f.showMonth);
        }
        else {
            filtered = filtered.filter(f => !f.showWeek);
        }
        
        return filtered;
    };

    getTableItems() {
        var grouped = groupBy(this.tableItems, week => week["MonthString"]);
        return getRepairAndRetreadItems(grouped, this.models.selectedMonth);
    };
}

export {
    RepairAndRetreadCostReport
}