import {loginPortalApiAxios} from "@/core/services/api.service";

// action types
export const API_LOGIN_PORTAL_COMMON_CONTROLS_DEALERS_ACTION_LOAD = "action_login_portal_common_controls_get_dealers";
export const API_LOGIN_PORTAL_COMMON_CONTROLS_CTSC_REGIONS_ACTION_LOAD = "action_login_portal_common_controls_get_ctsc_regions";
export const API_LOGIN_PORTAL_COMMON_CONTROLS_DEALER_LOCATIONS_ACTION_LOAD = "action_login_portal_common_controls_get_dealer_locations";

// muts
export const API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALERS = "muts_login_portal_common_controls_set_dealers";
export const API_LOGIN_PORTAL_COMMON_CONTROLS_SET_CTSC_REGIONS = "muts_login_portal_common_controls_set_ctsc_regions";
export const API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALER_LOCATIONS = "muts_login_portal_common_controls_set_dealer_locations";

const state = {
    dealers: [],
    ctsc_regions: [],
    dealer_locations: [],

    isLoaded: {
        dealers: false,
        ctsc_regions: false,
        dealer_locations: false,
    },
};

const getters = {
    getLoginPortalCommonControlsIsLoaded: () => state.isLoaded,
    getLoginPortalCommonControlsDealers: () => state.dealers,
    getLoginPortalCommonControlsCtscRegions: () => state.ctsc_regions,
    getLoginPortalCommonControlsDealerLocations: () => state.dealer_locations,
};

const actions = {
    [API_LOGIN_PORTAL_COMMON_CONTROLS_DEALERS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            loginPortalApiAxios.get("dealer_portal/get_dealers", params, context)
                .then(({ data }) => {
                    context.commit(API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALERS, data.dealers);
                    resolve(data.dealers);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LOGIN_PORTAL_COMMON_CONTROLS_CTSC_REGIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            loginPortalApiAxios.get("dealer_portal/get_dealer_ctsc_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_LOGIN_PORTAL_COMMON_CONTROLS_SET_CTSC_REGIONS, data.dealers_ctsc);
                    resolve(data.dealers_ctsc);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_LOGIN_PORTAL_COMMON_CONTROLS_DEALER_LOCATIONS_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            loginPortalApiAxios.post("dealer_portal/get_dealer_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALER_LOCATIONS, data.dealers_locations);
                    resolve(data.dealers_locations);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALERS](state, data) {
        state.dealers = data;
        state.isLoaded.dealers = true;
    },
    [API_LOGIN_PORTAL_COMMON_CONTROLS_SET_CTSC_REGIONS](state, data) {
        state.ctsc_regions = data;
        state.isLoaded.ctsc_regions = true;
    },
    [API_LOGIN_PORTAL_COMMON_CONTROLS_SET_DEALER_LOCATIONS](state, data) {
        state.dealer_locations = data.map(dloc => ({ value: dloc.id, text: dloc.text }));
        state.isLoaded.dealer_locations = true;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};